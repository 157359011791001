
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../../utils/imageUtils';
import * as widgetUtils from '../../../utils/widgetUtils';
import * as textUtils from '../../../utils/textUtils';
import * as widgetConstants from '../../../constants/widgetConstants';
import _ from 'lodash';
import ToastrService from '../../../services/toastrService';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { withModalContext } from '../../../services/modalService';

class TasksModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialiseTooltips = this.initialiseTooltips.bind(this);
        this.getTooltipData = this.getTooltipData.bind(this);
        this.tooltipControls = [];
        this.state = {
            searchText: ''
        };        
    }

    componentDidMount() {
       this.initialiseTooltips();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.tasks !== prevProps.tasks) {
            this.initialiseTooltips();
        }
    }

    initialiseTooltips() {
        const tooltipControls = window.$(this.tooltipControls.filter(t => t));
        if (tooltipControls.length !== 0 ) {
            tooltipControls.tooltip({boundary: 'viewport'});
            tooltipControls.on('click', function(e){
                e.preventDefault();
            });
        }  
    }

    editTask(event, task) {
        event.preventDefault();
        this.props.editTask(task);
    }

    deleteTask(event, task) {
        event.preventDefault();
        const deleteAction = () => this.props.deleteTask(task);
        const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to delete this task? This action is irreversible.`} />
        this.props.showModal(() => deleteConfirmationModal, { isOpen: true })
    }

    getTooltipData(task) {
        const widgets = this.props.dashboard.Widgets;
        const matchingWidgets = widgets.filter(w => w.FieldBindings.map(b => b.TaskID).includes(task.ID));
        //we need to also include any tasks that might have a variable using this item like a filter with an additional input
        const matchingTasks = this.props.tasks.filter(t => t.ID !== task.ID && (t.Variables.find(v => v.InputVariableID === task.ID || (v.Inputs && v.Inputs.includes(task.ID)) || (v.FormulaInputs && v.FormulaInputs.includes(task.ID)) || (v.FunctionInputs && v.FunctionInputs.includes(task.ID)) || v.TaskID === task.ID)));
        let tooltipCount = matchingWidgets.length + matchingTasks.length;
        let tooltipText = `<p>Used in ${matchingWidgets.length} ${textUtils.pluralise('widget', matchingWidgets.length)}`;
        if(matchingWidgets.length) {
            tooltipText += ':</p><ul>';
            matchingWidgets.forEach(widget => tooltipText += `<li>${widget.Name}</li>`);
            tooltipText += '</ul>';
        }
        else {
            tooltipText += '</p>'
        }
        tooltipText += `<br /><p>Used in ${matchingTasks.length} ${textUtils.pluralise('task', matchingTasks.length)}`;
        if(matchingTasks.length) {
            tooltipText += ':</p><ul>';
            matchingTasks.forEach(task => tooltipText += `<li>${task.Name}</li>`);
            tooltipText += '</ul>';
        }
        return {tooltipCount, tooltipText};
    }

    render() {
        const searchTextLower = this.state.searchText ? this.state.searchText.trim().toLowerCase() : null;
        const tasks = this.props.tasks.filter(t => !searchTextLower || t.Name.toLowerCase().indexOf(searchTextLower) > -1);
        this.tooltipControls = [];
        return (
            <div className="modal modal-side modal-side--newwidget fade" tabIndex="-1" role="dialog" id="modal-tasks" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header align-items-center pt-2">
                            <h5 className="modal-title">Tasks</h5>
                            <div className="modal-actions mr-4">
                                <a href="#" className="btn-text btn-text-grey" data-toggle="modal" data-target="#modal-new-widget" data-dismiss="modal">+ Add widget</a>
                                <a href="#" className="btn btn--outline ml-3"data-toggle="modal" data-target="#modal-newtask" data-dismiss="modal" onClick={this.props.clearSelectedTask}>+ New task</a>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        <form className="modal-headbar d-flex pb-2 align-items-center">
                            <p className="text-muted m-0 small">{`${tasks.length} ${textUtils.pluralise('task', tasks.length)}`}</p>
                            <div className="form-group form-search mb-0 ml-auto">
                                <i className="aheadicon-search form__picto"></i>
                                <input className="form-control form-control-sm" type="text" placeholder="Quick search" value={this.state.searchText} onChange={(event) => this.setState({ searchText: event.target.value })} />
                            </div>
                        </form>
                        <div className="modal-body pt-0">
                            <div className="tasks-list">            
                                {
                                    tasks.map(task => {
                                        const {tooltipCount, tooltipText} = this.getTooltipData(task);
                                        return (
                                            <div className="task-item" key={task.ID}>
                                                <a href="#" className="task-item__desc">
                                                    <p>{task.Name}</p>
                                                    <p className="task-item__sub">
                                                        <span className="badge" data-toggle="tooltip" data-placement="top" data-html="true" ref={control => this.tooltipControls.push(control)} title={tooltipText}>{tooltipCount}</span>
                                                        {task.Variables.map(v => v.Name).join(' / ')}
                                                    </p>
                                                </a>
                                                <div className="task-item__actions">
                                                    <a href="#" className="btn btn-simple btn-edittask" data-toggle="modal" data-target="#modal-newtask" data-dismiss="modal" onClick={(event) => this.editTask(event, task)}>
                                                        <i className="aheadicon-edit"></i>
                                                    </a>
                                                    <a href="#" className="btn btn-simple btn-deletetask" onClick={(event) => this.deleteTask(event, task)}>
                                                        <i className="aheadicon-delete"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        );
                                    })
                                }                                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default withModalContext(TasksModal);