import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';
import * as userActions from '../../actions/userActions';
import validator from 'validator';
import 'react-image-crop/dist/ReactCrop.css';

class ChangePasswordModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.changePassword = this.changePassword.bind(this);
        this.onChange = this.onChange.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.state = 
        { 
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
         };
    }

    changePassword(event) {
        debugger;
        event.preventDefault();
        if(this.formIsValid()) {
            if(this.props.adminSet) {
                this.props.actions.setPassword(this.props.user.ID, this.state.newPassword);
            }
            else {
                this.props.actions.changePassword(this.state.oldPassword, this.state.newPassword);
            }
            
            window.$("#modal").modal('hide');
        }
    }

    onChange(event) {
        const field = event.target.name;
        const value = event.target.value;
        let newState = Object.assign({}, this.state);
        newState[field] = value;
        return this.setState(newState);
    }

    formIsValid() {
        const state = this.state;
        if(validator.isEmpty(state.oldPassword) && !this.props.adminSet) {
            this.props.actions.setChangePasswordError('The password is missing');
            return false;
        }
        else if(validator.isEmpty(state.newPassword)) {
            this.props.actions.setChangePasswordError('The new password is missing');
            return false;
        }
        else if(!validator.isLength(state.newPassword, {min: 8, max: undefined})) {
            this.props.actions.setChangePasswordError('The password must be a minimum of 8 characters');
            return false;
        }
        else if(validator.isEmpty(state.confirmPassword)) {
            this.props.actions.setChangePasswordError('The password confirmation is missing');
            return false;
        }
        else if(!validator.equals(state.newPassword, state.confirmPassword)) {
            this.props.actions.setChangePasswordError('The password and confirmation do not match');
            return false;
        }
        return true;
    }

    render() {
        return (     
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{this.props.adminSet ? 'Set password' : 'Change password'}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                        </button>
                    </div>
                    <div className="modal-body">                    
                        <div className="modal__inner">                    
                            <form className="form">  
                                {
                                    !this.props.adminSet ?
                                        <div className="form-group form-group--tight">
                                            <label>Enter current password</label>
                                            <input type="password" className="form-control" name="oldPassword" value={this.state.oldPassword} onChange={this.onChange} placeholder="" />
                                            <p className="form__error">This input is mandatory</p>
                                        </div>  
                                        : null
                                }     
                                <div className="form-group form-group--tight">
                                    <label>Enter new password</label>
                                    <input type="password" className="form-control" name="newPassword" value={this.state.newPassword} onChange={this.onChange} placeholder="" />
                                    <p className="form__error">This input is mandatory</p>
                                </div>                        
                                <div className="form-group">
                                    <label>Repeat new password</label>
                                    <input type="password" className="form-control" name="confirmPassword" value={this.state.confirmPassword} onChange={this.onChange} placeholder="" />
                                    <p className="form__error">This input is mandatory</p>
                                </div>
                
                                <div className="form-group">
                                    <button type="submit" className="btn btn--primary btn-block" onClick={this.changePassword}>Change password</button>
                                </div>       
                                {this.props.changePasswordError ?
                                    <React.Fragment>
                                        <div className="distancer"></div>    
                                            <div className="alert alert-danger">
                                                {this.props.changePasswordError}
                                                <button type="button" className="close" onClick={this.props.actions.clearChangePasswordError}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>                                    
                                    </React.Fragment>
                                    : null
                                } 
                            </form>                      
                        </div>                    
                    </div>
                </div>
            </div>
        );
    }
}

ChangePasswordModal.propTypes = {
    user: PropTypes.object,
    actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    let user;
    let adminSet = false;
    if(ownProps.user) {
        user = ownProps.user;
        adminSet = true;
    }
    else {
        user = state.account.user;
    }
    return { user, adminSet, changePasswordError: state.account.changePasswordError };
}

function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, accountActions, userActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);