import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function clientReducer(state = initialState.client, action) {
    switch(action.type) {
        case types.GET_CLIENTS_SUCCESS:
            const clients = [...action.clients];
            return {
                ...state,
                clients
            }
        case types.LOGOUT:       
            return {
                ...initialState.client
            };    
        default:
            return state;
    }
}