/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../../../../actions/dashboardActions';
import * as userActions from '../../../../../actions/userActions';
import $ from 'jquery';
import * as imageUtils from '../../../../../utils/imageUtils';
import * as widgetUtils from '../../../../../utils/widgetUtils';
import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import highchartsSolidGuage from 'highcharts/modules/solid-gauge';
import _ from 'lodash';

highchartsMore(Highcharts);
highchartsSolidGuage(Highcharts);

class GaugeChart extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getPercentage = this.getPercentage.bind(this);
        this.initialiseData = this.initialiseData.bind(this);
        this.getChartOptions = this.getChartOptions.bind(this);
        this.initialiseChart = this.initialiseChart.bind(this);
        this.getGaugeColour = this.getGaugeColour.bind(this);
        this.getGaugeBackgroundColour = this.getGaugeBackgroundColour.bind(this);
        this.getBackgroundColour = this.getBackgroundColour.bind(this);
        this.getTitleText = this.getTitleText.bind(this);
        this.getTitleColour = this.getTitleColour.bind(this);
        this.getTitleFontSize = this.getTitleFontSize.bind(this);
        this.getLabelFontSize = this.getLabelFontSize.bind(this);
        this.getLabelText = this.getLabelText.bind(this);
        this.state = {
        };
    }

    componentWillMount() { 
    }   
    
    componentDidMount() {      
        this.initialiseData();
        //this.initialiseChart(this.chartRef);
    } 

    componentDidUpdate(prevProps, prevState) {
        if(this.props.widget !== prevProps.widget || this.props.widgetSettings !== prevProps.widgetSettings || this.props.tasks !== prevProps.tasks) {
            this.initialiseData();
        }
    }

    componentWillUnmount() {
        this.chart = null;
    }

    initialiseData() {
        const data = {};
        this.props.widget.CustomVariables.forEach(variable => {
            data[variable.Name] = variable.DefaultValue;
        });
        this.props.widgetSettings.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
            if(adjustment.Append) {
                data[adjustment.Name] += parseFloat(adjustment.Append);
            }
            else {
                data[adjustment.Name] = adjustment.Override;
            }
        });
        data['tasks'] = this.props.tasks;
        this.setState(data, () => {            
            if(this.chart) {
                this.chart.update(this.getChartOptions(), true, true);            
                this.chart.reflow();
                /*const percentage = this.getPercentage() || 0;
                const point = this.chart.series[0].points[0];      
                if(redraw) {
                    const gaugeColour = this.getGaugeColour();
                    this.chart.series[0].data[0].color = gaugeColour;
                    this.chart.series[0].data[0].graphic.element.attributes["fill"].value = gaugeColour;
                    const gaugeBackgroundColour = this.getGaugeBackgroundColour();
                    this.chart.options.pane.background[0].backgroundColor = gaugeBackgroundColour;
                    this.chart.pane[0].background[0].element.attributes["fill"].value = gaugeBackgroundColour;
                    const backgroundColour = this.getBackgroundColour();
                    this.chart.options.chart.backgroundColor = backgroundColour;
                    this.chart.chartBackground.element.attributes["fill"].value = backgroundColour;
                    this.chart.title.element.textContent = this.getTitleText();
                    const titleColour = this.getTitleColour();
                    this.chart.title.element.style.fill = titleColour;
                    this.chart.title.element.style.fontSize = this.getTitleFontSize();
                    const labelColour = this.getLabelColour();
                    this.chart.series[0].options.dataLabels.style.color = labelColour;
                    this.chart.series[0].options.dataLabels.format = this.getLabelText();
                    //this.chart.redraw();
                }
                point.update(percentage);
                this.chart.reflow();*/
            }
            else {
                this.initialiseChart(this.chartRef)
            }
        });
    }

    getPercentage() {        
        let percentage = 0;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                //we only get the widgetHierarchy prop if we're in a nested control
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'Percentage')) {
                    const percentageBinding = this.state.tasks[binding.TaskID];
                    if(percentageBinding && percentageBinding.currentValue) {                   
                        percentage = _.round(Math.min(percentageBinding.currentValue, 100), 1);
                        break;
                    }
                }
            }    
        }         
        return percentage || this.state.percentage || 0;
    }

    getGaugeColour() {
        return this.state.gaugeColour || '#078507';
    }

    getGaugeBackgroundColour() {
        return this.state.gaugeBackgroundColour || '#eee';
    }

    getBackgroundColour() {
        return this.state.backgroundColour || 'fff';
    }

    getTitleText() {
        return this.state.showTitle ? this.state.titleText : ''
    }

    getTitleColour() {
        return this.state.titleColour;
    }

    getTitleFontSize() {
        if(this.state.titleSize === 'Small') {
            return '8px';
        }
        else if(this.state.titleSize === 'Medium') {
            return '12px';
        }
        else if(this.state.titleSize === 'Large') {
            return '16px';
        }
        return '16px';
    }

    getLabelColour() {
        return this.state.labelColour;
    }

    getLabelFontSize() {
        if(this.state.labelSize === 'Small') {
            return '8px';
        }
        else if(this.state.labelSize === 'Medium') {
            return '12px';
        }
        else if(this.state.labelSize === 'Large') {
            return '16px';
        }
        return '16px';
    }

    getLabelText() {
        const text = this.state.labelText || '{y}%';
        return `<div style="text-align:center"><span style="font-size:${this.getLabelFontSize()}">${text}</span></div>`;
    }

    initialiseChart(container){
        this.chart = new Highcharts.Chart(container, this.getChartOptions());
    }

    getChartOptions() {
        return {
            chart: {
                type: 'solidgauge',
                backgroundColor: this.getBackgroundColour(),
                events: {
                    //render: renderIcons
                },
                style: {
                    fontFamily: 'Open Sans'
                }
            },
            title: {
                text: this.getTitleText(),
                style: {
                    fontSize: this.getTitleFontSize(),
                    color: this.getTitleColour()
                }
            },

            tooltip: {
                enabled: false
            },

            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{ // Track for Move
                    outerRadius: '112%',
                    innerRadius: '88%',
                    backgroundColor: this.getGaugeBackgroundColour(),
                    borderWidth: 0
                }]
            },

            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },
            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: true,
                        //formatter: function () { return this.y + '%%%' },
                        style: {
                            color: this.getLabelColour(),
                            textOutline: 'none'
                        },
                        verticalAlign: 'middle',
                        //y: 5,
                        borderWidth: 0,
                        useHTML: true
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },
            series: [{
                name: 'Move',
                data: [{
                    color: this.getGaugeColour(),
                    radius: '112%',
                    innerRadius: '88%',
                    y: this.getPercentage()
                }],
                dataLabels: {
                    format: this.getLabelText()
                }
            }],
            credits: {
                enabled: false
            }
        };
    }

    render() {
        return (              
            <div className="element element--gauge" style={{width: '100%', height: '100%'}}>       
                <div className="chart chart--gauge" id="chart-gauge" ref={(control) => this.chartRef = control}></div>                
            </div>
        );
    }
}

GaugeChart.propTypes = {
};

export default GaugeChart;