export default {
    account: {
        token: null,
        tokenExpiry: null,
        refreshToken: null,
        user: null,
        loginError: null,
        signupError: null,
        forgotPasswordEmailSent: false,
        forgotPasswordError: null,
        changePasswordError: null,
        passwordReset: false,
        resetPasswordError: null,
        accountSettingsError: null,
        refreshTokenPromise: null,
        refreshTokenTimeoutHandle: null,
        refreshingToken: false
    },    
    dashboard: {
        currentDashboard: null,
        connected: false,
        dashboards: [],
        dashboardsLoading: false,
        dashboardItemsLoading: [],
        widgets: []
    },
    playlist: {
        playlists: [],
        playlistsLoading: false,
        createUpdatePlaylistError: null
    },
    template: {
        new: [],
        popular: []
    },
    user: {
        users: [],
        usersLoading: false,
        createUpdateUserError: null
    },
    client: {
        clients: []
    }
};