
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import * as widgetUtils from '../../../../utils/widgetUtils';
import _ from 'lodash';

class CalculationModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateCalculationText = this.updateCalculationText.bind(this);        
    }

    componentDidMount() { 
               
    }  

    updateCalculationText(event) {        
        let modifier = {...this.props.modifier};
        modifier.Formula = event.target.value;
        this.props.updateModifier(modifier);
    }  

    render() {  
        const variableNameIDMappings = {};
        this.props.modifier.Inputs.forEach(variableID => {
            const variable = this.props.task.Variables.find(v => v.ID === variableID);
            if(variable) {
                //the user would have entered the name of the variable, but we want to map it back to the ID of the variable 
                variableNameIDMappings[variable.Name] = variableID;
            }
        });
        const calculationText = widgetUtils.formatFormulaForReading(this.props.modifier.Formula, variableNameIDMappings);
        return (
            <form className="form">
                <div className="form-group">
                    <textarea id="calculationText" className="form-control form-area" name="" id="" cols="30" rows="4" placeholder="Start typing your calculation" onChange={this.updateCalculationText}>{calculationText}</textarea>
                </div>
                <span className="text-muted small d-block">{`Enter an Excel-style formula using the inputs. You can use arithmetic operators (+, -, /, *, %, ^), logical operators (AND(), OR(), NOT(), XOR()), and comparison operators (=, >, >=, <, <=, <>). You can use built-in variables like TRUE, FALSE, and NULL. The & operator can be used for string concatenation`}</span>
                <div></div>
            </form>     
        );
    }

}

export default CalculationModifier;