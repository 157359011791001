import * as types from './actionTypes';
import UserService from '../services/userService';
import ToastrService from '../services/toastrService';
import { push } from 'react-router-redux';
import * as dataUtils from '../utils/dataUtils';

export function getUsers(){
    return function(dispatch, getState) {
        const state = getState();
        if(state.user.users.length === 0) {
            dispatch(setUsersLoading(true));
            return UserService.getUsers().then(users => {
                if(users.length > 1) {
                    dataUtils.sortUsers(users);
                }
                dispatch({ type: types.GET_USERS_SUCCESS, users });
                dispatch(setUsersLoading(false));
            }).catch(error => {
                dispatch(setUsersLoading(false));
                throw(error);
            });
        }
    };
}

export function setUsersLoading(loading) {
    return { type: types.USERS_LOADING, loading };
}

export function createUser(email, password, firstName, lastName, role, clientID, clientUserID, emailAlertsEnabled) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        dispatch({ type: types.CLEAR_CREATE_UPDATE_USER_ERROR })
        return UserService.createUser(email, password, firstName, lastName, role, clientID, clientUserID, emailAlertsEnabled).then(user => {
            dispatch({ type: types.USER_CREATED, user });
            dispatch(push('/Users'));
            toastrService.showSuccess('', 'User has been created successfully!', null)
        }).catch(error => {
            if(error.message === 'Payment Required') {
                toastrService.showError('Unable to add player', 'You have reached the maximum number of dashboards allowed for this account.', null);
            }
            else {
                dispatch({ type: types.SET_CREATE_UPDATE_USER_ERROR, error: error })
            }
        });
    };
}

export function updateUser(id, email, firstName, lastName, role, clientUserID, avatar, emailAlertsEnabled) {    
    return function(dispatch) {
        dispatch({ type: types.CLEAR_CREATE_UPDATE_USER_ERROR })
        const toastrService = new ToastrService();
        return UserService.updateUser(id, email, firstName, lastName, role, clientUserID, avatar, emailAlertsEnabled).then(user => {
            debugger;
            dispatch({ type: types.USER_UPDATED, user });
            dispatch(push('/Users'));
            toastrService.showSuccess('', 'User has been updated successfully!', null);
        }).catch(error => {
            dispatch({ type: types.SET_CREATE_UPDATE_USER_ERROR, error: error })
         });
    };
}

export function deleteUser(id) {
    return function(dispatch) {
        return UserService.deleteUser(id).then(data => {
            dispatch({ type: types.USER_DELETED, id });
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'User has been deleted successfully!', null)
        }).catch(error => {
            throw(error);
        });
    };
}

export function setPassword(userID, password) {    
    return function(dispatch) {
        dispatch({ type: types.CLEAR_CHANGE_PASSWORD_ERROR })
        const toastrService = new ToastrService();
        return UserService.setPassword(userID, password).then(payload => {
            debugger;
            toastrService.showSuccess('', 'The password has been set successfully!', null);
        }).catch(error => {
            if(error.message === 'Unable to change password') {
                toastrService.showError('', 'Something went wrong, unable to set password', null);
            }
            else {
                toastrService.showError('', error.message, null);
            }
         });
    };
}

export function setCreateUpdateUserError(error) {
    //allow the component to set an error directly and wrap it in an error object
    return { type: types.SET_CREATE_UPDATE_USER_ERROR, error: new Error(error) };
}

export function clearCreateUpdateUserError() {
    return { type: types.CLEAR_CREATE_UPDATE_USER_ERROR };
}