/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import * as widgetUtils from '../../../../../utils/widgetUtils';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'
import _ from 'lodash';

class DropDown extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialiseData = this.initialiseData.bind(this);
        this.getInitialValue = this.getInitialValue.bind(this);
        this.getData = this.getData.bind(this);
        this.getStyles = this.getStyles.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getFormattedData = this.getFormattedData.bind(this);
        this.getDisplayMember = this.getDisplayMember.bind(this);
        this.getValueMember = this.getValueMember.bind(this);
        this.getValue = this.getValue.bind(this);
        this.state = {
            selectedValue: '',
            data: [],
            displayMember: null,
            valueMember: null
        };
    }

    componentWillMount() {   
        this.initialiseData();
        //we should call the selection changed with the initial value incase it's set
    }       

    componentDidMount() {
        const control = window.$(this.control);
        control.select2({
            placeholder: this.getPlaceholder(),
        })
        .on('select2:select', this.handleChange)
        .on('select2:unselect', this.handleChange);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.widget !== prevProps.widget || this.props.widgetSettings !== prevProps.widgetSettings || this.props.tasks !== prevProps.tasks) {
            this.initialiseData();
        }
        if(!this.state.selectedValues !== prevState.selectedValues) {
            //reinitialise the select2 control
            const control = window.$(this.control);
            control.select2('destroy');
            control.off('select2:select');
            control.off('select2:unselect');
            control.select2({
                placeholder: this.getPlaceholder(),
            })
            .on('select2:select', this.handleChange)
            .on('select2:unselect', this.handleChange);
        }
    }

    componentWillUnmount() {
        if(this.control) {
            const control = window.$(this.control);
            control.select2("destroy");
        }
    }

    initialiseData() {
        const data = {};
        this.props.widget.CustomVariables.forEach(variable => {
            data[variable.Name] = variable.DefaultValue;
        });
        this.props.widgetSettings.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
            if(adjustment.Append) {
                data[adjustment.Name] += parseFloat(adjustment.Append);
            }
            else {
                data[adjustment.Name] = adjustment.Override;
            }
        });
        data.tasks = this.props.tasks;
        this.setState(data, () => {
            const selectedValue = this.initialised ? this.state.selectedValue : this.getInitialValue();           
            this.setState({
                selectedValue,
                data: this.getData(),
                displayMember: this.getDisplayMember() || data['displayMember'],
                valueMember: this.getValueMember() || data['valueMember']
            })
        });
        this.initialised = true;
    }

    getInitialValue() {
        let initialValue = '';
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'InitialValue')) {
                    const initialValueBinding = this.state.tasks[binding.TaskID];
                    if(initialValueBinding && initialValueBinding.currentValue) {                    
                        initialValue = initialValueBinding.currentValue;
                        break;
                    }
                }
            }  
        }           
        return initialValue;
    }

    getDisplayMember() {
        let displayMember;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'DisplayMember')) {
                    const displayMemberBinding = this.state.tasks[binding.TaskID];
                    if(displayMemberBinding && displayMemberBinding.currentValue) {                    
                        displayMember = displayMemberBinding.currentValue;
                        break;
                    }
                }
            }
        }             
        return displayMember;
    }

    getValueMember() {
        let valueMember;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'ValueMember')) {
                    const valueMemberBinding = this.state.tasks[binding.TaskID];
                    if(valueMemberBinding && valueMemberBinding.currentValue) {                    
                        valueMember = valueMemberBinding.currentValue;
                        break;
                    }
                }
            }
        }             
        return valueMember;
    }

    getData() {        
        let dataSource = [];
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'DataSource')) {
                    const dataSourceBinding = this.state.tasks[binding.TaskID];
                    if(dataSourceBinding && dataSourceBinding.currentValue) {                    
                        dataSource = dataSourceBinding.currentValue;
                        break;
                    }
                }
            }
        }   
        return dataSource;
    }

    getFormattedData() {
        let data = this.state.data;//.filter(d => !this.state.selectedValues.includes(d));
        const displayMember = this.state.displayMember;
        const valueMember = this.state.valueMember;
        return data.map(item => this.formatItem(item, displayMember, valueMember));
    }

    formatItem(item, displayMember, valueMember) {
        if(typeof item != 'object') {
            //we have a simple type, map it to labels and values
            return { text: item, id: item };
        }
        else {
            return { text: item[displayMember], id: item[valueMember] };
        }
    }

    getVisibility() {
        let visible = true;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'Visible')) {
                    const visibilityBinding = this.state.tasks[binding.TaskID];
                    if(visibilityBinding && visibilityBinding.currentValue) {                    
                        visible = visibilityBinding.currentValue;
                        break;
                    }
                }
            }
        }             
        return visible;
    }

    getStyles() {
        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              color: this.state.textColour,
              fontWeight: this.state.bold ? 'bold' : 'normal',
              fontSize: this.getFontSize(this.state.fontSize)

            }),
            control: () => ({
              // none of react-select's styles are passed to <Control />
              width: 150,
            }),
            singleValue: (provided, state) => {
              const opacity = state.isDisabled ? 0.5 : 1;
              const transition = 'opacity 300ms';
          
              return { ...provided, opacity, transition };
            }
        }
        return customStyles;
    }   

    getFontSize(sizeDesc) {
        if(sizeDesc === 'Medium') {
            return '12px';
        }
        else if(sizeDesc === 'Large') {
            return '16px';
        }
        else {
            return '12px';
        }
    }

    getPlaceholder() {
        return this.state.placeholderText || 'Please select';
    }
    
    getValue(id) {
        const isObject = this.state.data.length && typeof this.state.data[0] === 'object';
        return this.state.data.find(d => isObject ? d[this.state.valueMember] === id : d === id);
    }

    handleChange = event => {
        //get the original value, pass that up
        const data = event.params.data;
        if(data) {            
            const selectedValue = data.selected ? this.getValue(data.id) : '';           
            this.setState({ selectedValue });
            this.props.onDataChange(this.props.widgetHierarchy, 'SelectedValue', selectedValue);
        }
    };

    render() {
        const formattedData = this.getFormattedData();
        const selectedValue = this.state.selectedValue && typeof this.state.selectedValue === 'object' ? this.state.selectedValue[this.state.valueMember] : this.state.selectedValue
        return (  
            <select className="js-example-basic-multiple" ref={(control) => this.control = control} value={selectedValue} readOnly>
                <option key="empty" value=''></option>
                {
                    formattedData.map(dataItem =>
                        <option key={dataItem.id} value={dataItem.id}>{dataItem.text}</option>
                    )
                }
            </select> 
        );
    }
}

DropDown.propTypes = {
};

export default DropDown;