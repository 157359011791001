
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

class FlattenModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateDistinct = this.updateDistinct.bind(this);        
    }

    componentDidMount() { 
               
    }  

    updateDistinct(event) {        
        let modifier = {...this.props.modifier};
        modifier.Distinct = event.target.checked;
        this.props.updateModifier(modifier);
    }  

    render() {  
        const templateText = this.props.modifier.TemplateString;
        return (
            <form className="form">
                <div className="form-group">
                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" checked={this.props.modifier.Distinct} id={`check-${this.props.modifier.ID}`} onClick={(e) => this.updateDistinct(e)} />
                    <label className="custom-control-label" htmlFor={`check-${this.props.modifier.ID}`}>Distinct</label>
                </div>
                </div>
            </form>     
        );
    }

}

export default FlattenModifier;