
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as playlistActions from '../../actions/playlistActions';
import NewItemMenu from '../nav/NewItemMenu';
import PlaylistItem from './PlaylistItem';

class Playlists extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getPlaylistSummaryText = this.getPlaylistSummaryText.bind(this);
        this.getNextIndex = this.getNextIndex.bind(this);
        this.setRef = this.setRef.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
        this.summaryIndex = 0;
        this.state = {
            open: true
        };
    }

    componentWillMount() {

    }

    componentDidMount() {      
        this.props.actions.getPlaylists();
    }   

    getPlaylistSummaryText(playlists) {
        let summaryBuilder = [];
        const playlistsLength = playlists.length;
        summaryBuilder.push(`${playlists.length} playlist${playlistsLength === 1 ? '' : 's'}`);
        return summaryBuilder;
    }

    getNextIndex() {
        this.summaryIndex++;
        return this.summaryIndex;
    }

    setRef(control) {
        this.panel = control;
    }

    togglePanel(event) {
        event.preventDefault();
        const panel = window.$(this.panel);
        panel.collapse('toggle');
        const target = window.$(event.currentTarget);
        target.toggleClass('open');
        const expanded = target.attr('aria-expanded') === 'true';
        target.attr('aria-expanded', expanded ? 'false' : 'true');
    }

    render() {
        return (
            <React.Fragment>
                <div className="main__header">
                    <div className="main__stats">
                        <p>{this.getPlaylistSummaryText(this.props.playlists)}</p>
                    </div>
                    <NewItemMenu />
                </div>
                <div className={`main__body scroll ${this.props.playlistsLoading ? 'spinner' : ''}`}>     
                    {
                        !this.props.playlistsLoading ?
                            <React.Fragment>      
                                <a href={null} className="btn-dashgroup" data-toggle="collapse" data-target="#playlists-main" aria-expanded="true">
                                    <h5>Playlists</h5>
                                    {/*<p class="btn-dashgroup__stats">7 playlists<span class="distancerh">&middot;</span>5 active</p>*/}
                                </a>
                                <div className={`dashboards collapse ${this.state.open ? 'show' : ''}`} ref={this.setRef} id="playlists-main">                   
                                    <div className="dashboards__inner">                   
                                        <div className="dashthumbs">     
                                            {
                                                this.props.playlists.map(p => {
                                                    return <PlaylistItem key={p.ID} playlist={p} role={this.props.role} actions={this.props.actions} />   
                                                })
                                            }  
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        : null
                    }
                </div>
            </React.Fragment>
        );
    }
}

Playlists.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    const playlists = state.playlist.playlists;
    const role = state.account.user.Role;
    const userID = state.account.user.ID;
    return { 
        playlists, 
        playlistsLoading: state.playlist.playlistsLoading, 
        role, 
        userID
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //playlist actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, playlistActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Playlists);