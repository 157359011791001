import React from 'react';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

class CropModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.imageCropping = this.imageCropping.bind(this);
        this.imageCropped = this.imageCropped.bind(this);
        this.onImageLoaded = this.onImageLoaded.bind(this);
        this.state = 
        { 
            crop: {
                aspect: this.props.aspectRatio || 1
            }
         };
    }

    componentDidMount() {      
    
    }

    imageCropping(crop, pixelCrop) {
        this.setState({ crop, pixelCrop });
    }

    imageCropped(event) {
        debugger;
        this.props.imageCropped(event, this.state.pixelCrop);
    }

    onImageLoaded = (image) => {
        debugger;
        const crop = makeAspectCrop({
            x: 0,
            y: 0,
            aspect: this.props.aspectRatio || 1,
            width: 100,
            height: 100
            }, image.width / image.height);
        const pixelCrop = {
            height: crop.height / 100 * image.height,
            width: crop.width / 100 * image.width,
            x: 0,
            y: 0
        }
        this.setState({
            crop: crop,
            pixelCrop: pixelCrop
        });
    }

    render() {
        return (
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Crop image</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                        </button>
                    </div>
                    <div className="modal-body">                    
                        <div>   
                            <form className="form" onSubmit={this.imageCropped}>     
                                <div className={`form-group text-center ${this.props.isRounded ? '' : 'ReactCrop__crop-selection--square'}`}>
                                    <ReactCrop src={this.props.avatar} onImageLoaded={this.onImageLoaded} onChange={this.imageCropping} crop={this.state.crop} />                 
                                </div>                
                                <div className="form-group">
                                    <div className="modal__inner">
                                        <button type="submit" className="btn btn--primary btn-block">Crop image</button>
                                    </div>
                                </div>  
                            </form> 
                        </div>                    
                    </div>
                </div>
            </div>
        )
    }
}

export default CropModal;