import * as types from './actionTypes';
import roleTypes from '../constants/roleTypes';
import ClientService from '../services/clientService';

export function getClients(){
    return function(dispatch, getState) {
        const state = getState();
        if(state.client.clients.length === 0 && state.account.user) {
            const user = state.account.user;
            if(user.Role === roleTypes.SuperUser) {
                return ClientService.getClients().then(clients => {
                    if(clients.length > 1) {
                        clients.sort((a, b) => {
                            if(a.Name < b.Name) {
                                return -1;
                            }
                            else if(b.Name < a.Name) {
                                return 1;
                            }
                            else {
                                return 0;
                            }
                        });
                    }
                    dispatch({ type: types.GET_CLIENTS_SUCCESS, clients });
                }).catch(error => {
                    throw(error);
                });
            }
            else if(user.Role === roleTypes.Admin) {
                return ClientService.getClient(user.ClientID).then(client => {
                    if(client) {
                        const clients = [client];
                        dispatch({ type: types.GET_CLIENTS_SUCCESS, clients });
                    }
                }).catch(error => {
                    throw(error);
                });
            }
        }
    };
}