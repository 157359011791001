import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = (props) => {
    const { component: Component, authenticated, allowRoles, role, ...rest } = props;
    return <Route
        {...rest}
        render={props =>
            authenticated === true ? 
                allowRoles && allowRoles.length && allowRoles.find(r => r === role) == null ? //we're restricting the roles and the user doesn't have the required role, redirect to the home page
                    <Redirect to="/" />
                    : 
                    <Component {...props} /> 
                :
                <Redirect to="/Account/Login" />
            }
    />
} 

PrivateRoute.propTypes = {
    authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    authenticated: state.account.user != null,
    role: state.account.user ? state.account.user.Role : null,
    pathname: state.routing.location.pathname
});

export default connect(mapStateToProps)(PrivateRoute);