import BaseService from './baseService';

class AccountService {

    static getUser() {
        return BaseService.getData('/Account/Me')
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                console.log('Unable to get user');
                throw new Error('Unable to get user');
            });
    }

    static login(email, password) {
        return BaseService.postData('/Token', { username: email, password, grant_type: 'password' })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                //debugger;
                console.log('Unable to log in');
                throw new Error('Unable to log in');                
            });
    }  

    static forgotPassword(email) {
        return BaseService.postData('/Account/ForgotPassword', { email })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                //debugger;
                console.log('Unable to generate password reset instructions');
                throw new Error('Unable to generate password reset instructions');              
            });
    }  
    
    static resetPassword(email, code, password) {
        return BaseService.postData('/Account/ResetPassword', { email, code, password })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                //debugger;
                let message;
                if(error.message.toLowerCase().indexOf('password') > -1) {
                    message = error.message;
                }
                else {
                    message = 'Unable to reset password';
                }
                console.log(message);
                throw new Error(message);                
            });
    }  
    
    static changePassword(oldPassword, newPassword) {
        return BaseService.postData('/Account/ChangePassword', { oldPassword, newPassword })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                //debugger;
                let message;
                if(error.message.toLowerCase().indexOf('password') > -1) {
                    message = error.message;
                }
                else {
                    message = 'Unable to change password';
                }
                console.log(message);
                throw new Error(message);                              
            });
    } 

    static updateAccountSettings(email, firstName, lastName, avatar, emailAlertsEnabled) {
        return BaseService.putData('/Account/Me', { email, firstName, lastName, avatar, emailAlertsEnabled})
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                //debugger;
                console.log('Unable to save settings');
                throw new Error('Unable to save settings');                
            });
    } 
    
}

export default AccountService;