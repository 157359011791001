
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import * as widgetConstants from '../../../../constants/widgetConstants';


class AggregateModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateAggregateDetails = this.updateAggregateDetails.bind(this);        
    }

    componentDidMount() { 
               
    }  

    updateAggregateDetails(fieldName, value) {    
        let modifier = {...this.props.modifier};
        modifier[fieldName] = value;
        this.props.updateModifier(modifier);
    }  

    updateFunctionInput(index, value) {
        let modifier = {...this.props.modifier};
        modifier.FunctionInputs = [...modifier.FunctionInputs];
        modifier.FunctionInputs[index] = value;
        this.props.updateModifier(modifier);
    }

    getAggregateMarkup(inputType) {
        const modifier = this.props.modifier;
        const aggregateFunctionName = modifier.AggregateFunction;
        const aggregateFunctions = widgetConstants.aggregateFunctions.filter(f => !f.acceptableTypes || !f.acceptableTypes.length || f.acceptableTypes.includes(inputType));
        const aggregateFunction = aggregateFunctionName && aggregateFunctions.length ? aggregateFunctions.find(f => f.name === aggregateFunctionName) : null;
        const functionInputs = modifier.FunctionInputs;
        if(aggregateFunctions.length) {
            return (
                <React.Fragment>
                    <span className="text-muted small d-block">Select an aggregate function</span>
                    <select className="form-control" value={aggregateFunction ? aggregateFunction.name : null} onChange={(e) => this.updateAggregateDetails('AggregateFunction', e.target.value)}>
                        <option value={null}></option>
                        {
                            aggregateFunctions.map(f =>
                                <option key={f.name} value={f.name}>{f.description}</option>
                            )
                        }
                    </select>
                    {
                        aggregateFunction && aggregateFunction.inputs && aggregateFunction.inputs.length ?
                            aggregateFunction.inputs.filter(i => !i.isEnabled || i.isEnabled(this.props.inputType)).map((functionInput, i) => 
                                <React.Fragment key={functionInput.name}>
                                    <span className="text-muted small d-block pt-2">{functionInput.name}</span>
                                    {this.getFunctionInputMarkup(functionInput, i, functionInputs && functionInputs.length - 1 >= i ? functionInputs[i] : null)}                                        
                                </React.Fragment>
                            )
                        : null
                    }
                </React.Fragment> 
            )}
        else {
            return null;
        }
    }

    getFunctionInputMarkup(functionInput, index, inputValue) {
        if(functionInput.type === 'Integer') {
            return <input type="number" className="form-control" placeholder={functionInput.description} onChange={(e) => this.updateFunctionInput(index, e.target.value ? parseInt(e.target.value, 10) : null)} value={inputValue} />
        }
        else if(functionInput.type === 'Boolean') {
            return <input type="checkbox" className="" onClick={(e) => this.updateFunctionInput(index, e.target.checked)} checked={inputValue} />
        }
        else if(functionInput.type === 'Text') {
            return <input type="text" className="form-control" placeholder={functionInput.description} onChange={(e) => this.updateFunctionInput(index, e.target.value)} value={inputValue} />
        }
        else {
            return null;
        }
    }

    getHintText(inputType) {
        if(inputType === 'Date' || inputType === 'DateTime') {
            return <span className="text-muted small d-block">Please use the moment syntax for formatting, see <a href="https://momentjs.com/docs/#/displaying/format" target="_blank">this link</a> for more information</span>

        }
        else {
            return null;
        }
    }

    render() {  
        const inputType = this.props.inputType;
        const getHintText = this.getHintText(inputType);

        return (
            <form className="form">
                <div className="form-group">
                    {this.getAggregateMarkup(inputType)}
                </div>
                {
                    getHintText || null
                }
            </form>     
        );
    }

}

export default AggregateModifier;