import React from 'react';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../utils/imageUtils';
import { withModalContext } from '../../services/modalService';
import roleTypes from '../../constants/roleTypes';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';

const PlaylistItem = ({playlist, role, showModal, actions}) => {
    const id = playlist.ID;
    const name = playlist.Name;
    const image = playlist.Thumbnail;

    const getPlaylistImage = () => {
        const imageData = image ? `url(${imageUtils.getAvatarBase64Url(image)})` : `url(${require('../../images/dash-race-playlist.png')})`;
        return imageData;
    }

    const deletePlaylist = (event) => {
        event.preventDefault();
        const deleteAction = () => actions.deletePlaylist(id);
        const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to delete playlist ${name}? This action is irreversible.`} />
        showModal(() => deleteConfirmationModal, { isOpen: true })
    }

    return (
        <div className="dashthumb" title={name}>
            {
                <React.Fragment>
                    <Link to={`/Playlists/${id}`}>
                        <div className="dashthumb__img" style={{backgroundImage: getPlaylistImage()}}>
                            <div className="dashthumb__title">
                                <h5>{name}</h5>
                            </div>
                        </div>
                    </Link>
                    <div className="dashthumb__actions">
                        <a href={null} className="btn-thumbactions" data-toggle="dropdown"><i className="aheadicon-dots"></i></a>
                        <div className="dropdown-menu dropdown-menu--animated dropdown-menu--withicons">
                            <Link to={`/Playlists/${id}`} className="dropdown-item"><i className="aheadicon-eye"></i>View</Link>
                            {
                                role === roleTypes.Admin || role === roleTypes.Manager || role === roleTypes.SuperUser ?
                                    <React.Fragment>
                                        <Link to={`/Playlists/${id}/Edit`} className="dropdown-item"><i className="aheadicon-edit"></i>Edit</Link>
                                        <Link to={`/Playlists/${id}/Access`} className="dropdown-item"><i className="aheadicon-access"></i>Access</Link>
                                        <Link to={`/Playlists/${id}/Settings`} className="dropdown-item"><i className="aheadicon-settings"></i>Settings</Link>
                                        <a className="dropdown-item" href={null} onClick={deletePlaylist}><i className="aheadicon-delete"></i>Delete</a>
                                    </React.Fragment>
                                : 
                                    null
                            }
                            
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

export default withModalContext(PlaylistItem);