
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import * as widgetConstants from '../../../../constants/widgetConstants';
import * as widgetUtils from '../../../../utils/widgetUtils';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'

class GroupingModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
        this.addPojection = this.addPojection.bind(this);
        this.updateProjectionField = this.updateProjectionField.bind(this);
        this.deleteProjection = this.deleteProjection.bind(this);
        this.updateGroupingFunction = this.updateGroupingFunction.bind(this);
        this.setUseGroupFunction = this.setUseGroupFunction.bind(this);
        this.state = {
            useGroupingFunction: this.props.modifier.GroupingFunction ? true : false
        }
    }

    componentDidMount() { 
        const control = window.$(this.control);
        control.select2({
            placeholder: 'Select one or more fields to group by',
        })
        .on('select2:select', this.handleChange)
        .on('select2:unselect', this.handleChange);
        control.val(this.props.modifier.GroupBy).trigger('change');
    }  

    componentDidUpdate(prevProps, prevState) {
    }

    handleChange = event => {
        const data = event.params.data;
        if(data) {
            const modifier = {...this.props.modifier};
            modifier.GroupBy = [...modifier.GroupBy];
            if(data.selected) {
                modifier.GroupBy.push(data.id);
            }
            else{
                _.pull(modifier.GroupBy, data.id);
            }
            this.props.updateModifier(modifier);
            //the code below is to force the selected items to be shown in the selected order
            const target = window.$(event.target);
            const element = window.$(event.params.data.element);     
            if(data.selected) {       
                window.setTimeout(() => {  
                    if (target.find(':selected').length > 1) {
                        const second = target.find(':selected').eq(-2);                
                        element.detach();
                        second.after(element);
                    } else {
                        element.detach();
                        window.$(target).prepend(element);
                    }           
                    target.trigger('change');
                }, 1);
            }
            else { 
                //this seems to lead to dupes         
                if (target.find(":selected").length) {
                    target.find(":selected").eq(-1).after(element);
                }
            }
        }
    }

    addPojection(e) {
        e.preventDefault();
        const modifier = {...this.props.modifier};
        modifier.Projections = [...modifier.Projections];
        modifier.Projections.push({ ProjectionFieldName: '', AggregateFunction: '' });
        this.props.updateModifier(modifier);
    }

    updateProjectionField(e, fieldName, projection) {
        e.preventDefault();
        const modifier = {...this.props.modifier};
        modifier.Projections = [...modifier.Projections];
        const projectionIndex = modifier.Projections.indexOf(projection);
        projection = {...projection};
        projection[fieldName] = e.target.value;
        modifier.Projections[projectionIndex] = projection;
        this.props.updateModifier(modifier);
    }

    deleteProjection(e, projection) {
        e.preventDefault();
        const modifier = {...this.props.modifier};
        modifier.Projections = [...modifier.Projections];
        _.pull(modifier.Projections, projection);
        this.props.updateModifier(modifier);
    }

    updateGroupingFunction(e) {
        e.preventDefault();
        const modifier = {...this.props.modifier};
        modifier.GroupingFunction = e.target.value;
        this.props.updateModifier(modifier);
    }

    setUseGroupFunction(event) {
        this.setState({ useGroupingFunction: event.target.checked });
        const modifier = {...this.props.modifier};
        modifier.GroupBy = [];
        const control = window.$(this.control);
        control.val([]).trigger('change');
        if(!event.target.checked) {
            modifier.GroupingFunction = '';
        }
        this.props.updateModifier(modifier);
    }

    render() {  
        const modifier = this.props.modifier;
        const keys = this.props.initialItem ? Object.keys(this.props.initialItem) : [];
        const groupingFields = [...modifier.GroupBy];
        keys.filter(k => !modifier.Projections.map(p => p.ProjectionFieldName).includes(k)).forEach(k => {
            //add any keys that aren't already in the list of grouping fields. we add the existing fields 1st so they can appear top in the list in the 
            //selected order which means select2 will display them in the correct order
            if(groupingFields.indexOf(k) === -1) {
                groupingFields.push(k);
            }
        });
        const availableProjectionFields = keys.filter(k => !modifier.GroupBy.includes(k));
        const groupingFunction = this.state.useGroupingFunction && modifier.GroupingFunction ? widgetConstants.groupingFunctions.find(f => f.name === modifier.GroupingFunction) : null;
        return (
            <React.Fragment>
                <div className="form-group mb-1 text-right">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="checkGrouping" checked={this.state.useGroupingFunction} onChange={this.setUseGroupFunction} />
                        <label className="custom-control-label" htmlFor="checkGrouping">Use grouping function</label>
                    </div>
                </div>
                {
                    this.state.useGroupingFunction ?
                        <div className="form-group form-group--groupingfunc">
                            <select className="form-control" name="" id="" value={modifier.GroupingFunction} onChange={this.updateGroupingFunction}>
                                <option value="" disabled selected>Select function</option>
                                {
                                    widgetConstants.groupingFunctions.map(f =>
                                        <option key={f.name} value={f.name}>{f.name}</option>
                                    )
                                }
                            </select>
                            {
                                groupingFunction ?
                                    <span className="text-muted small d-block">{groupingFunction.description}</span>
                                    : null
                            }
                        </div>
                        : null
                }      
                <form className="form">
                    <div className="form-group mb-2">
                        <label>
                            {
                            this.state.useGroupingFunction ? 
                                `Fields${groupingFunction ? (': ' + groupingFunction.fieldNames) : '' }` 
                                : 'Field(s) you want to group by'
                            }
                        </label>
                        <select className="form-control form--multi" multiple="multiple" ref={(control) => this.control = control} data-placeholder="Select one or more inputs">
                            <option value="" disabled>Select one or more fields</option>
                            {
                                groupingFields.map(key => 
                                    <option key={key} value={key}>{key}</option>     
                                )
                            }
                        </select>
                    </div>
                    {
                        modifier.GroupBy.length ?
                            <React.Fragment>           
                                <div className="form-group">
                                    <label className="my-3">Select field(s) you want to project</label>
                                    {
                                        modifier.Projections.length ?
                                            <React.Fragment>
                                                <div className="row mx-n1">
                                                    <p className="small text-muted col-7 mb-1 px-1">Field</p>
                                                    <p className="small text-muted col-5 mb-1 px-1">Selector</p>
                                                </div>  
                                                {
                                                    modifier.Projections.map(p => 
                                                        <div className="row mx-n1 projection">
                                                            <div className="col-7 px-1">
                                                                <div className="form-group mb-1">
                                                                    <select className="form-control form--multi" data-placeholder="Select field" value={p.ProjectionFieldName} onChange={(e) => this.updateProjectionField(e, 'ProjectionFieldName', p)}>
                                                                        <option value="" disabled>Select field</option>
                                                                        {
                                                                            availableProjectionFields.map(f => 
                                                                                <option key={f} value={f}>{f}</option>
                                                                            )
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-4 px-1">
                                                                <div className="form-group mb-1">
                                                                    <select className="form-control" value={p.AggregateFunction} onChange={(e) => this.updateProjectionField(e, 'AggregateFunction', p)}>
                                                                        <option value="" disabled>Select</option>
                                                                        {
                                                                            widgetConstants.aggregateFunctions.map(f => <option key={f.name} value={f.name}>{f.name}</option>)
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-1 px-1">
                                                                <button className="btn btn-simple btn-delete" onClick={(e) => this.deleteProjection(e, p)}>
                                                                    <i className="aheadicon-delete"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }    
                                            </React.Fragment>
                                            : null
                                    }
                                    <div className="pt-1">
                                        <a href="#" className="btn-text small" onClick={this.addPojection}>+ Add field</a>
                                    </div>                            
                                </div>
                            </React.Fragment>
                            : null
                    }                
                </form>  
            </React.Fragment>
        );
    }

}

export default GroupingModifier;