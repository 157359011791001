
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import roleTypes from '../../../constants/roleTypes';
import _ from 'lodash';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'
import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.css";

class ConfigureWidget extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleDashboardChange = this.handleDashboardChange.bind(this);
        this.handleClientChange = this.handleClientChange.bind(this);
        this.initialiseDashboardDropDown = this.initialiseDashboardDropDown.bind(this);
        this.initialiseClientDropDown = this.initialiseClientDropDown.bind(this);
        this.initialiseSlider = this.initialiseSlider.bind(this);    
    }

    componentDidMount() {
        this.initialiseDashboardDropDown();
        this.initialiseClientDropDown();        
        this.initialiseSlider();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.public && !this.props.public) {
            this.initialiseDashboardDropDown();
            this.initialiseClientDropDown();
        }
        if(this.props.columnCount !== prevProps.columnCount) {
            const columnsSlider = window.$('*[data-selection-name="columnCount"]', window.$('#frmConfigureWidget')).find('.touchslide__slider')[0].noUiSlider;
            const value = parseInt(columnsSlider.get(), 10);
            if(value !== this.props.columnCount) {
                columnsSlider.set(this.props.columnCount);
            }
        }
        if(this.props.rowCount !== prevProps.rowCount) {
            const rowsSlider = window.$('*[data-selection-name="rowCount"]', window.$('#frmConfigureWidget')).find('.touchslide__slider')[0].noUiSlider;
            const value = parseInt(rowsSlider.get(), 10);
            if(value !== this.props.rowCount) {
                rowsSlider.set(this.props.rowCount);
            }
        }
    }

    initialiseDashboardDropDown() {
        const dashboardDropDown = window.$(this.dashboardDropDown);
        dashboardDropDown.select2({
            placeholder: 'Select one or more dashboards to assign'
        })
        .on('select2:select', this.handleDashboardChange)
        .on('select2:unselect', this.handleDashboardChange);
        dashboardDropDown.select2('val', this.props.dashboardIDs);
    }

    initialiseClientDropDown() {
        const clientDropDown = window.$(this.clientDropDown);
        clientDropDown.select2({
            placeholder: 'Select one or more clients to assign'
        })
        .on('select2:select', this.handleClientChange)
        .on('select2:unselect', this.handleClientChange);
        clientDropDown.select2('val', this.props.clientIDs);
    }

    initialiseSlider() {
        const $ = window.$;
        $('.touchslide', $('#frmConfigureWidget')).each((index, slider) => {   
            slider = $(slider); 
            var sliderEl = slider.find('.touchslide__slider')[0],
                minValue = parseInt( slider.attr('data-min') ),
                maxValue = parseInt( slider.attr('data-max') ),
                initValue = parseInt( slider.attr('data-init') );        
            
            if(!sliderEl.noUiSlider) {
                noUiSlider.create(sliderEl, {
                    start: [initValue],
                    connect: 'lower',
                    range: {
                      'min': minValue,
                      'max': maxValue
                    }
                });                  
                sliderEl.noUiSlider.on('slide', (values) => this.onSlide(values, slider));     
            }   
            else {
                //debugger;
            }            
          });
    }

    onSlide(values, slider) {
        const selectionName = slider.attr('data-selection-name');
        const sliderValue = slider.find('.touchslide__val');
        const slideValue = Math.floor(values[0]);
        sliderValue.text(slideValue);
        this.props.updateValue(selectionName, slideValue);
    }

    handleDashboardChange(event) {
        const data = event.params.data;
        if(data) {            
            const dashboardIDs = [...this.props.dashboardIDs];
            if(data.selected) {
                dashboardIDs.push(data.id);
            }
            else{
                _.pull(dashboardIDs, data.id);
            }
            this.props.updateValue('dashboardIDs', dashboardIDs);
        }
    }

    handleClientChange(event) {
        const data = event.params.data;
        if(data) {            
            const clientIDs = [...this.props.clientIDs];
            if(data.selected) {
                clientIDs.push(data.id);
            }
            else{
                _.pull(clientIDs, data.id);
            }
            this.props.updateValue('clientIDs', clientIDs);
        }
    }

    render() {
        return (            
            <form id="frmConfigureWidget" className="form">
                <div className="form-group">
                    <label>Widget name</label>
                    <input type="text" className="form-control" value={this.props.widgetName} onChange={(e) => this.props.updateValue('widgetName', e.target.value)} />
                    <div className="custom-control custom-checkbox mt-1">
                        <input type="checkbox" className="custom-control-input" id="publicWidget" checked={!this.props.public} onChange={(e) => this.props.updateValue('public', !e.target.checked)} />
                        <label className="custom-control-label" htmlFor="publicWidget">Restrict widget to specific dashboards {this.props.user.Role === roleTypes.SuperUser ? ' / clients' : ''}</label>
                    </div>
                </div>
                {
                    !this.props.public ?
                        <React.Fragment>
                            <div className="form-group">
                                <label>Dashboards</label>
                                <select className="form-control form--multi" multiple="multiple" ref={(control) => this.dashboardDropDown = control} data-placeholder="Select one or more dashboards">
                                    <option value={null} disabled>Select one or more dashboards</option>
                                    {
                                        this.props.dashboards.filter(d => d.Template.Type === 'Widget').map(dashboard => 
                                            <option key={dashboard.ID} value={dashboard.ID}>{dashboard.Name}</option>     
                                        )
                                    }
                                </select>
                            </div>
                            {
                                this.props.user.Role === roleTypes.SuperUser ?
                                    <div className="form-group">
                                        <label>Clients</label>
                                        <select className="form-control form--multi" multiple="multiple" ref={(control) => this.clientDropDown = control} data-placeholder="Select one or more clients">
                                            <option value={null} disabled>Select one or more clients</option>
                                            {
                                                this.props.clients.map(client => 
                                                    <option key={client.ID} value={client.ID}>{client.Name}</option>     
                                                )
                                            }
                                        </select>
                                    </div>
                                    : null
                            }
                        </React.Fragment>
                        : null
                }                
                <div className="d-flex align-items-center">
                    <div className="form-group flex-fill">
                        <label>Number of columns</label>   
                        <div className="touchslide" data-min="1" data-max="12" data-init={this.props.columnCount} data-val={this.props.columnCount} data-step="1" data-selection-name="columnCount">
                            <div className="touchslide__slider"></div>
                        </div>
                    </div>
                    <div className="form-group mb-0 col-3 pr-0">
                        <input className="form-control text-center" type="number" step="1" min="1" max="12" required placeholder="Enter value" value={this.props.columnCount} onChange={(event) => this.props.updateValue('columnCount', parseInt(event.target.value, 10))} />
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div className="form-group flex-fill">
                        <label>Number of rows</label>
                        <div className="touchslide" data-min="1" data-max="12" data-init={this.props.rowCount} data-val={this.props.rowCount} data-step="1" data-selection-name="rowCount">
                            <div className="touchslide__slider"></div>
                        </div>
                    </div>
                    <div className="form-group mb-0 col-3 pr-0">
                        <input className="form-control text-center" type="number" step="1" min="1" max="12" required placeholder="Enter value" value={this.props.rowCount} onChange={(event) => this.props.updateValue('rowCount', parseInt(event.target.value, 10))} />
                    </div>
                </div>
            </form>
        );
    }
};

export default ConfigureWidget;