/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {preDefinedDateRanges} from '../../../constants/widgetConstants';
import * as textUtils from '../../../utils/textUtils';
import * as widgetUtils from '../../../utils/widgetUtils';
import _ from 'lodash';
import $ from 'jquery';
import moment from 'moment';
import ToastrService from '../../../services/toastrService';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { withModalContext } from '../../../services/modalService';

class DateRangeConfiguration extends React.Component {
    constructor(props, context) {
        super(props, context); 
        this.addDateRange = this.addDateRange.bind(this);
        this.setCustomDateRange = this.setCustomDateRange.bind(this);
        this.getCurrentDateRanges = this.getCurrentDateRanges.bind(this);
        this.updateDateRangeValue = this.updateDateRangeValue.bind(this);
        this.saveDateRanges = this.saveDateRanges.bind(this);
        this.initialiseDatePicker = this.initialiseDatePicker.bind(this);
        this.cancelCustomDateRange = this.cancelCustomDateRange.bind(this);
        this.editRelativeFunction = this.editRelativeFunction.bind(this);
        this.deleteRelativeFunction = this.deleteRelativeFunction.bind(this);
        this.getRelativeDate = this.getRelativeDate.bind(this);
        this.getCustomDateRange = this.getCustomDateRange.bind(this);
        this.relativeFunctions = [{
            functionName: 'startOf',
            displayName: 'startOf(period)',
            description: 'start of',
            showUnits: false
        }, {
            functionName: 'endOf',
            displayName: 'endOf(period)',
            description: 'end of',
            showUnits: false
        }, {
            functionName: 'add',
            displayName: 'add(units, period)',
            description: 'add',
            showUnits: true
        }, {
            functionName: 'subtract',
            displayName: 'subtract(units, period)',
            description: 'subtract',
            showUnits: true
        }]
        this.state = {
            dateRanges: this.getCurrentDateRanges(),
            customStartDateRelative: false,
            customStartDate: null,
            customStartMomentActions: [],
            customEndDateRelative: false,
            customEndDate: null,
            customEndMomentActions: [],
            dateFormat: 'DD MM YYYY',
            displayCustomName: false,
            customName: '',
            customDisplayText: '-',
            addingStartFormula: false,
            addingEndFormula: false,
            functionName: null,
            showFunctionUnit: false,
            functionUnit: 0,
            functionPeriod: '',
            actionInEdit: null
        };
    }

    componentWillMount() {

    }

    componentDidMount() {      
        
    }     

    componentWillUpdate(nextProps, nextState) {
        const displayRelatedFields = ['displayCustomName', 'customName', 'customStartDate', 'customStartDateRelative', 'dateFormat', 'includeEndDate', 'customEndDate', 'customEndDateRelative', 'customStartMomentActions', 'customEndMomentActions']
        for(let i = 0; i < displayRelatedFields.length; i++) {
            const fieldName = displayRelatedFields[i]
            if(this.state[fieldName] !== nextState[fieldName]) {
                this.setState({ customDisplayText: widgetUtils.getDateDisplay(this.getCustomDateRange(nextState)) });
                break;
            }
        }
    }

    componentDidUpdate(prevProps, prevState) { 
              
    }

    componentWillUnmount() {
       
    }

    getCurrentDateRanges() {
        const value = this.props.getVariableValue(this.props.variable);
        return value || [this.getNewDateRange()];
    }    

    getNewDateRange() {
        return {
            custom: false, 
            value: null
        };
    }

    addDateRange(event) {
        event.preventDefault();
        const dateRanges = [...this.state.dateRanges];
        const dateRange = this.getNewDateRange();        
        dateRanges.push(dateRange);
        this.setState({ dateRanges });
    }

    getCustomDateRange(state = this.state) {
        const dateRange = {
            custom: true,
            displayText: state.displayCustomName ? state.customName : state.dateFormat,
            value: state.customDisplayText,
            formatDisplayText: !state.displayCustomName,
            includeEndDate: state.includeEndDate,
            start: {
                relative: state.customStartDateRelative,
                momentActions: state.customStartMomentActions
            },
            end: {
                relative: state.customEndDateRelative,
                momentActions: state.customEndMomentActions
            }
        } 
        if(!state.customStartDateRelative) {
            dateRange.start.value = state.customStartDate;
        }
        if(!state.customEndDateRelative) {
            dateRange.end.value = state.customEndDate;
        }
        return dateRange;
    }

    setCustomDateRange(event, originalDateRange) {        
        event.preventDefault();
        const dateRanges = [...this.state.dateRanges];
        const index = dateRanges.indexOf(originalDateRange);
        const dateRange = this.getCustomDateRange();        
        dateRanges.splice(index, 1, dateRange);
        this.setState({ dateRanges });
    }

    updateDateRangeValue(event, dateRange) {
        event.preventDefault();
        const dateRanges = [...this.state.dateRanges];
        const index = dateRanges.indexOf(dateRange);
        dateRange = {...dateRange, value: event.target.value, custom: event.target.value === 'custom'};
        delete dateRange.start;
        delete dateRange.end;
        dateRanges.splice(index, 1, dateRange);
        this.setState({ dateRanges });    
    }

    cancelCustomDateRange(event, dateRange) {
        event.preventDefault();
        const dateRanges = [...this.state.dateRanges];
        const index = dateRanges.indexOf(dateRange);
        dateRange = {...dateRange, custom: false, value: null};
        delete dateRange.start;
        delete dateRange.end;
        dateRanges.splice(index, 1, dateRange);
        this.setState({ dateRanges });    
    }

    removeDateRange(event, dateRange) {
        event.preventDefault();
        const deleteAction = () => {
            const dateRanges = [...this.state.dateRanges];
            _.pull(dateRanges, dateRange);
            this.setState({ dateRanges });   
        } 
        const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to delete this function? This action is irreversible.`} />
        this.props.showModal(() => deleteConfirmationModal, { isOpen: true })
    }

    saveDateRanges(event) {
        //from any custom date ranges, delete the value field as it will be recalculated
        const dateRanges = [...this.state.dateRanges];
        dateRanges.filter(dateRange => dateRange.custom).forEach(dateRange => delete dateRange.value);
        this.props.updateVariableValue(event, this.props.variable, () => dateRanges);
        this.props.close();
    }

    initialiseDatePicker(control, fieldName) {
        window.$(control).daterangepicker({
           singleDatePicker: true,
           locale: {
               format: 'DD-MM-YYYY'
           },
           opens: 'right',
           autoApply: true,
           showCustomRangeLabel: false,
           alwaysShowCalendars: true,
           autoUpdateInput: false,
           showDropdowns: true
       });
       window.$(control).on('apply.daterangepicker', (ev, picker) => {
           const state = {...this.state};
           state[fieldName] = picker.startDate;//.format('DD-MM-YYYY') or .toDate() (moment);
           this.setState(state);
       });
   }

    getDateRangeDropdown(dateRange) {
        let customValue;
        if(dateRange.custom) {
            if(dateRange.value) {
                customValue = dateRange.value;
            }
            else {
                customValue = widgetUtils.getDateDisplay(dateRange);
            }
        }
        return (
            <div className="row mx-n1 mb-2 daterange">
                <div className="col-11 px-1">
                    <select className="form-control" value={dateRange.custom ? customValue : dateRange.value} onChange={(event) => this.updateDateRangeValue(event, dateRange)}>
                        <option key="default" value={null} selected disabled>Select date range</option>
                        {
                            preDefinedDateRanges.map(preDefinedDateRange =>
                                <option key={preDefinedDateRange.name} value={preDefinedDateRange.name}>{preDefinedDateRange.displayText}</option>
                            )
                        }
                        {
                            dateRange.custom ? 
                                <option key="customValue" value={customValue}>{customValue}</option>
                                : null
                        }
                        <option key="custom" value="custom">Custom Date Range</option>
                    </select>
                </div>
                <div className="col-1 px-1">
                    <button className="btn btn-simple btn-delete" onClick={(event) => this.removeDateRange(event, dateRange)}>
                        <i className="aheadicon-delete"></i>
                    </button>
                </div>
            </div>
        );
    }

    getRelativeDateMarkup(isStartDate) {
        const momentActions = isStartDate ? this.state.customStartMomentActions : this.state.customEndMomentActions;
        return (
            <div className="form--popin">
                <div className="slist">
                    {
                        momentActions.length ?       
                            momentActions.map(momentAction => 
                                <div className="slist__item">
                                    <div className="slist__content">
                                        <p>{momentAction.name}(<span className="badge">{momentAction.parameters[0]}</span>{momentAction.parameters.length == 2 ? (<span>, <span className="badge">{textUtils.pluralise(momentAction.parameters[1], momentAction.parameters[0])}</span></span>) : null})</p>                           
                                    </div>
                                    <div className="slist__action">
                                        <a href="#" className="btn btn-simple btn-edititem" onClick={(event) => this.editRelativeFunction(event, momentAction, isStartDate)}>
                                            <i className="aheadicon-edit"></i>
                                        </a>
                                        <a href="#" className="btn btn-simple btn-removeitem" onClick={(event) => this.deleteRelativeFunction(event, momentAction, isStartDate)}>
                                            <i className="aheadicon-delete"></i>
                                        </a>
                                    </div>
                                </div>
                            )
                            : null
                    }
                </div>
                <div className="dropdown drop-formulainline" style={{display: (isStartDate ? this.state.addingStartFormula : this.state.addingEndFormula) ? 'none' : null}}>
                    <button className="btn-text btn--withicon dropdown-toggle" type="button" id="dropdownDateFormula" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="aheadicon-plus"></i>
                        Add function
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownDateFormula">
                        <h6 className="dropdown-header">Select function</h6>
                        {
                            this.relativeFunctions.map(relativeFunction => 
                                <button className="dropdown-item" type="button" onClick={() => this.setState({ addingStartFormula: isStartDate, addingEndFormula: !isStartDate, functionName: relativeFunction.functionName, functionPeriod: '', functionUnit: 1, showFunctionUnit: relativeFunction.showUnits, actionInEdit: null })}>{relativeFunction.displayName}</button>
                            )
                        }
                    </div>
                </div>
                <div className={`card card--light card--period mt-1 ${!(isStartDate ? this.state.addingStartFormula : this.state.addingEndFormula) ? 'hidden-block' : ''}`}>
                    <div className="card-body">                        
                        <label className="text-muted">{this.state.functionName}</label>
                        <div className="row">
                            {
                                this.state.showFunctionUnit ?
                                    <div className="col-md-6">
                                        <div className="form-group mb-0">
                                            <label>Unit</label>
                                            <input className="form-control" type="number" placeholder="Unit" required value={this.state.functionUnit} onChange={(event) => this.setState({ functionUnit: event.target.value })} />                                                                            
                                        </div>
                                    </div>
                                    : null
                            }         
                            <div className="col-md-6">
                                <div className="form-group mb-0">
                                    <label>Period</label>
                                    <select className="form-control" value={this.state.functionPeriod} onChange={(event) => this.setState({ functionPeriod: event.target.value })}>
                                        <option value="" selected disabled>Select period</option>
                                        <option value="day">day</option>
                                        <option value="week">week</option>
                                        <option value="month">month</option>
                                        <option value="quarter">quarter</option>
                                        <option value="year">year</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <a href="#" className="btn btn--outline btn-cancel" onClick={() => this.setState({ addingStartFormula: isStartDate ? false : this.state.addingStartFormula, addingEndFormula: !isStartDate ? false : this.state.addingEndFormula, actionInEdit: null })}>Cancel</a>&nbsp;
                        <a href="#" className="btn btn-primary btn-addformula" onClick={(e) => this.addRelativeFunction(e, isStartDate)}>{this.state.actionInEdit ? 'Edit function' : 'Add function'}</a>
                    </div>
                </div>
            </div>
        )
    }

    getCustomDateRangeInput(dateRange) {
        const relativeStartDate = this.state.customStartDateRelative ? this.getRelativeDate(true) : null;
        const relativeEndDate = this.state.customEndDateRelative ? this.getRelativeDate(false) : null;
        return (
            <div className="card">
                <div className="card-header">
                    <h6 className="text-muted mt-2 mb-0">Set custom date range</h6>
                </div>
                <div className="card-body">
                    <p className="badge badge--dark btn-block text-left">
                        <span className="text-muted">Start date:</span> {this.state.customStartDateRelative ? (relativeStartDate ? relativeStartDate.format(this.state.dateFormat) : 'Not set') : (this.state.customStartDate ? this.state.customStartDate.format(this.state.dateFormat) : 'Not set')} <span className="text-muted">| End date:</span> {this.state.customEndDateRelative ? (relativeEndDate ? relativeEndDate.format(this.state.dateFormat) : 'Not set') : (this.state.customEndDate ? this.state.customEndDate.format(this.state.dateFormat) : 'Not set')}
                    </p>
                    <div className="form-group">
                        <div className="d-flex align-items-center">
                            <label className="text-muted small">Start date</label>
                            <div className="form-switch flex-fill text-right pb-2">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="relativeSwitchStart" value={this.state.customStartDateRelative} onClick={(event) => this.relativeDateChanged(event, true)} />
                                    <label className="custom-control-label" htmlFor="relativeSwitchStart">Relative</label>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.customStartDateRelative ? 
                                this.getRelativeDateMarkup(true)
                                : 
                                <div className="form--prefix">
                                    <i className="aheadicon-date form__picto"></i>
                                    <input className="form-control form-date" type="text" value={this.state.customStartDate ? this.state.customStartDate.format(this.state.dateFormat) : ''} placeholder="Select date" ref={(control) => this.initialiseDatePicker(control, 'customStartDate')} />
                                </div>
                        }  
                    </div> 
                    <div className="form-group">
                        <div className="d-flex align-items-center">
                            <label className="text-muted small">End date</label>
                            <div className="form-switch flex-fill text-right pb-2">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="relativeSwitchEnd" value={this.state.customEndDateRelative} onClick={(event) => this.relativeDateChanged(event, false)} />
                                    <label className="custom-control-label" htmlFor="relativeSwitchEnd">Relative</label>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.customEndDateRelative ?
                                this.getRelativeDateMarkup(false)
                                : 
                                <div className="form--prefix">
                                    <i className="aheadicon-date form__picto"></i>
                                    <input className="form-control form-date" type="text" value={this.state.customEndDate ? this.state.customEndDate.format(this.state.dateFormat) : ''} placeholder="Select date" ref={(control) => this.initialiseDatePicker(control, 'customEndDate')} />
                                </div>
                        }
                    </div>
                    <div className="form-group">
                        <p className="badge badge--dark btn-block text-left">
                            <span className="text-muted">Display:</span> {this.state.customDisplayText}
                        </p>
                        <div className="form-switch flex-fill text-right pb-2">                            
                            {
                                !this.state.displayCustomName ?
                                    <div className="custom-control custom-switch d-inline">
                                        <input type="checkbox" className="custom-control-input" id="switchIncludeEndDate" value={this.state.includeEndDate} onClick={(event) => this.setState({includeEndDate: event.target.checked})} />
                                        <label className="custom-control-label" htmlFor="switchIncludeEndDate">Include end date</label>
                                    </div>
                                    : null
                            }           
                            <div className="custom-control custom-switch d-inline ml-3">
                                <input type="checkbox" className="custom-control-input" id="switchCustomRangeName" value={this.state.displayCustomName} onClick={(event) => this.setState({displayCustomName: event.target.checked})} />
                                <label className="custom-control-label" htmlFor="switchCustomRangeName">Display custom name</label>
                            </div>                 
                        </div>
                        {
                            this.state.displayCustomName ?
                                <React.Fragment>
                                    <label>Date range name</label>
                                    <input className="form-control" type="text" placeholder="Enter date range name" value={this.state.customName} onChange={(event) => this.setState({ customName: event.target.value })} />               
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <label>Date format</label>
                                    <input className="form-control" type="text" placeholder="Enter date format, e.g. DD MM YYYY" value={this.state.dateFormat} onChange={(event) => this.setState({ dateFormat: event.target.value })} />
                                </React.Fragment>
                        }
                     </div>
                </div>
                <div className="card-footer text-right">
                    <a href="#" className="btn btn--outline btn-cancel" onClick={(event) => this.cancelCustomDateRange(event, dateRange)}>Cancel</a>
                    &nbsp;
                    <a href="#" className="btn btn-primary btn-addrange" onClick={(event) => this.setCustomDateRange(event, dateRange)}>Add range</a>
                </div>
            </div>        
        )
    }

    relativeDateChanged(event, isStartDate) {
        const checked = event.target.checked;
        const state = {...this.state};
        if(isStartDate) {
            state.customStartDateRelative = checked; 
            if(checked) {
                state.customStartDate = null;
            }
            else {
                state.customStartMomentActions = [];
            }
        }
        else {
            state.customEndDateRelative = checked; 
            if(checked) {
                state.customEndDate = null;
            }
            else {
                state.customEndMomentActions = [];
            }
        }
        this.setState(state);
    }

    getRelativeDate(isStartDate, state = this.state) {
        const momentActions = isStartDate ? [...state.customStartMomentActions] : [...state.customEndMomentActions];
        return widgetUtils.getRelativeDate(momentActions);        
    }

    addRelativeFunction(event, isStartDate) {
        event.preventDefault();
        const toastrService = new ToastrService();
        const updating = this.state.actionInEdit ? true : false;
        if(this.state.functionPeriod && (this.state.functionUnit || !this.state.showFunctionUnit)) {
            const momentActions = isStartDate ? [...this.state.customStartMomentActions] : [...this.state.customEndMomentActions];
            const momentAction = { name: this.state.functionName, parameters: [this.state.functionPeriod] };
            if(this.state.showFunctionUnit) {
                //add the function unit as the 1st parameter
                momentAction.parameters.unshift(this.state.functionUnit);
            }
            if(updating) {
                //we're updating
                const actionIndex = momentActions.indexOf(this.state.actionInEdit);
                momentActions.splice(actionIndex, 1, momentAction);
            }
            else {
                //we're adding
                momentActions.push(momentAction);
            }            
            this.setState({
                customStartMomentActions: isStartDate ? momentActions : this.state.customStartMomentActions,
                customEndMomentActions: !isStartDate ? momentActions : this.state.customEndMomentActions,
                addingStartFormula: false,
                addingEndFormula: false,
                functionName: null,
                showFunctionUnit: false,
                functionUnit: 0,
                functionPeriod: '',
                actionInEdit: null
            });
            toastrService.showSuccess('', `Function has been ${updating ? 'updated': 'added'}`);
        }
        else {
            toastrService.showError('', 'Please enter the function details');
        }
    }

    editRelativeFunction(event, momentAction, isStartDate) {
        event.preventDefault();
        this.setState({
            actionInEdit: momentAction,
            showFunctionUnit: momentAction.parameters.length === 2,
            functionUnit: momentAction.parameters.length === 2 ? momentAction.parameters[0] : 0,
            functionPeriod: momentAction.parameters.length === 2 ? momentAction.parameters[1] : momentAction.parameters[0],
            functionName: momentAction.name,
            addingStartFormula: isStartDate ? true: false,
            addingEndFormula: !isStartDate ? true: false
        });
    }

    deleteRelativeFunction(event, momentAction, isStartDate) {
        event.preventDefault();
        const momentActions = isStartDate ? [...this.state.customStartMomentActions] : [...this.state.customEndMomentActions];
        _.pull(momentActions, momentAction);
        this.setState({
            customStartMomentActions: isStartDate ? momentActions : this.state.customStartMomentActions,
            customEndMomentActions: !isStartDate ? momentActions : this.state.customEndMomentActions
        });
        const toastrService = new ToastrService();
        toastrService.showSuccess('', 'Function has been deleted');
    }

    render() {        
        return (
            <div>
                <button type="button" className="close" aria-label="Close" onClick={this.props.close} style={{paddingTop: 0, marginTop: '-15px', paddingRight: '5px'}}>
                    <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                </button>
                <p className="small text-muted pt-2 pb-3">Select from the pre-selected date ranges or create custom ranges</p>
                {
                    this.state.dateRanges.map(dateRange => dateRange.custom && (!dateRange.start || !dateRange.end) ? this.getCustomDateRangeInput(dateRange) : this.getDateRangeDropdown(dateRange))           
                }
                {
                    !this.state.dateRanges.find(dateRange => dateRange.custom && (!dateRange.start || !dateRange.end)) ?
                        <div>
                            <a className="btn-text small" onClick={this.addDateRange}>+ Add date range</a>
                        </div>
                        : null
                }
                <button className="btn btn-primary mt-5" onClick={this.saveDateRanges}>Save date ranges</button>
            </div>
        );
    }
}

DateRangeConfiguration.propTypes = {
    actions: PropTypes.object.isRequired,
    dashboard: PropTypes.object
};

export default withModalContext(DateRangeConfiguration);