
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';


class TemplateModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateTemplateText = this.updateTemplateText.bind(this);        
    }

    componentDidMount() { 
               
    }  

    updateTemplateText(event) {        
        let modifier = {...this.props.modifier};
        modifier.TemplateString = event.target.value;
        this.props.updateModifier(modifier);
    }  

    render() {  
        const templateText = this.props.modifier.TemplateString;
        return (
            <form className="form">
                <div className="form-group">
                    <textarea id="templateText" className="form-control form-area" name="" id="" cols="30" rows="4" placeholder="Start typing your template" onChange={this.updateTemplateText}>{templateText}</textarea>
                </div>
                <span className="text-muted small d-block">Please use the mustache syntax for templating, see <a href="http://mustache.github.io/mustache.5.html" target="_blank">this link</a> for more information</span>
                <div></div>
            </form>     
        );
    }

}

export default TemplateModifier;