import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function templateReducer(state = initialState.template, action) {
    //check if this has the saved state included
    //debugger;
    
    switch(action.type) {
        case types.GET_TEMPLATES_SUCCESS:
            const templates = [...action.results.templates];
            if(action.results.tag === 'New') {
                return {
                    ...state,
                    new: templates
                }
            }    
            else if(action.results.tag === 'Popular') {
                return {
                    ...state,
                    popular: templates
                }
            }
            else {
                return state;
            }
        case types.LOGOUT:       
            return {
                ...initialState.template
            };    
        default:
            return state;
    }
}