import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function accountReducer(state = initialState.account, action) {
    //check if this has the saved state included
    let user;
    switch(action.type) {
        case types.LOGIN_SUCCESS:
        case types.TOKEN_REFRESHED:
            const { access_token, refresh_token } = action.payload;   
            const expires = new Date(action.payload[".expires"]);
            if(action.type === types.LOGIN_SUCCESS || state.user) { //if it's a token refresh, make sure we haven't logged out while this call was in-flight
                return {
                    ...state,
                    token: access_token,
                    tokenExpiry: expires,
                    refreshToken: refresh_token,
                    refreshingToken: false
                }
            }
            else {
                return state;
            }
        case types.TOKEN_REFRESH_FAILED:
            return {
                ...state,
                refreshingToken: false
            }  
        case types.GET_USER_SUCCESS:
            user = action.user;         
            return {
                ...state,
                user
            }   
        case types.TOKEN_REFRESHING:
            return {
                ...state,
                refreshTokenPromise: action.refreshTokenPromise,
                refreshingToken: true
            }         
        case types.SET_REFRESH_TOKEN_TIMEOUT_HANDLE:
            return {
                ...state,
                refreshTokenTimeoutHandle: action.timeoutHandle
            }
        case types.LOGOUT:       
            return {
                ...initialState.account,
                refreshTokenPromise: state.refreshTokenPromise //we can't just set the promise to null, else this causes some errors if we're waiting for the result
            };         
        case types.SET_LOGIN_ERROR:
            return {
                ...state,
                loginError: action.error.message
            }
        case types.CLEAR_LOGIN_ERROR:
            return {
                ...state,
                loginError: null
            }
        case types.SET_SIGNUP_ERROR:
            return {
                ...state,
                signupError: action.error.message
            }
        case types.CLEAR_SIGNUP_ERROR:
            return {
                ...state,
                signupError: null
            }
        case types.FORGOT_PASSWORD_EMAIL_SENT:     
            return {
                ...state,
                forgotPasswordEmailSent: action.sent
            }    
        case types.SET_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordError: action.error.message
            }
        case types.CLEAR_FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPasswordError: null
            }
        case types.PASSWORD_RESET:     
            return {
                ...state,
                passwordReset: action.reset
            }               
        case types.SET_RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordError: action.error.message
            }
        case types.CLEAR_RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPasswordError: null
            }
        case types.SET_CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                changePasswordError: action.error.message
            }
        case types.CLEAR_CHANGE_PASSWORD_ERROR:
            return {
                ...state,
                changePasswordError: null
            }
        case types.SET_ACCOUNT_SETTINGS_ERROR:
            return {
                ...state,
                accountSettingsError: action.error.message
            }
        case types.CLEAR_ACCOUNT_SETTINGS_ERROR:
            return {
                ...state,
                accountSettingsError: null
            }
        default:
            return state;
    }
}