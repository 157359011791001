
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as dashboardActions from '../../actions/dashboardActions';
import * as templateActions from '../../actions/templateActions';
import * as userActions from '../../actions/userActions';
import NewItemMenu from '../nav/NewItemMenu';
import DashboardPanel from './DashboardPanel';
import roleTypes from '../../constants/roleTypes';

class Dashboards extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getDashboardSummaryText = this.getDashboardSummaryText.bind(this);
        this.getNextIndex = this.getNextIndex.bind(this);
        this.summaryIndex = 0;
    }

    componentWillMount() {

    }

    componentDidMount() {      
        this.props.actions.getDashboards();
        this.props.actions.getTemplates('New');
        this.props.actions.getTemplates('Popular');
    }   

    getDashboardSummaryText(dashboards, includeBreak = true) {
        let summaryBuilder = [];
        const dashboardsLength = dashboards.length;
        summaryBuilder.push(`${dashboards.length} dashboard${dashboardsLength === 1 ? '' : 's'}`);
        if(this.props.showActiveCount) {
            summaryBuilder.push(<span key={this.getNextIndex()} className="distancerh">&middot;</span>);
            if(includeBreak) {
                summaryBuilder.push(<br key={this.getNextIndex()} />);
            }
            const enabledDashboardsLength = dashboards.filter(d => d.Enabled).length;
            summaryBuilder.push(`${enabledDashboardsLength} active`);
        }
        return summaryBuilder;
    }

    getNextIndex() {
        this.summaryIndex++;
        return this.summaryIndex;
    }

    render() {
        let multiPlayerDashboardsOpen = true;
        let singlePlayerDashboardsOpen = true;
        let newDashboardsOpen = true;
        let popularDashboardsOpen = true;
        /*if(this.props.dashboardsMulti.length) {
            multiPlayerDashboardsOpen = true;
        }
        else if(this.props.dashboardsSingle.length) {
            singlePlayerDashboardsOpen = true;
        }
        else if(this.props.dashboardsNew.length) {
            newDashboardsOpen = true;
        }
        else if(this.props.dashboardsNew.length) {
            popularDashboardsOpen = true;
        }*/
        return (
            <React.Fragment>
                <div className="main__header">
                    <div className="main__stats">
                        <p>{this.getDashboardSummaryText(this.props.dashboards)}</p>
                    </div>
                    <NewItemMenu />
                </div>
                
                <div className={`main__body scroll ${this.props.dashboardsLoading ? 'spinner' : ''}`}>     
                    {
                        !this.props.dashboardsLoading ?
                            <React.Fragment>      
                                <DashboardPanel name="Multi player dashboards" open={multiPlayerDashboardsOpen} dashboards={this.props.dashboardsMulti} getDashboardSummaryText={this.getDashboardSummaryText} role={this.props.role} userID={this.props.userID} />
                                <DashboardPanel name="Single player dashboards" open={singlePlayerDashboardsOpen} dashboards={this.props.dashboardsSingle} getDashboardSummaryText={this.getDashboardSummaryText} role={this.props.role} userID={this.props.userID} />
                                <DashboardPanel name="New dashboards" open={newDashboardsOpen} dashboards={this.props.dashboardsNew} getDashboardSummaryText={this.getDashboardSummaryText} role={this.props.role} userID={this.props.userID} />                    
                                <DashboardPanel name="Popular dashboards" open={popularDashboardsOpen} dashboards={this.props.dashboardsPopular} getDashboardSummaryText={this.getDashboardSummaryText} role={this.props.role} userID={this.props.userID} />                    
                            </React.Fragment>
                        : null
                    }
                </div>
            </React.Fragment>
        );
    }
}

Dashboards.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapTemplate(template) {
    return {
        ID: template.ID,
        Name: template.Name,
        Enabled: true,
        Locked: true,
        Thumbnail: template.Thumbnail
    }
}

function mapStateToProps(state, ownProps) {
    const dashboardsAll = state.dashboard.dashboards;
    let dashboards = null;
    const role = state.account.user.Role;
    //check if we have access to all dashboard we get - we're an admin/super user
    if(role === roleTypes.SuperUser || role === roleTypes.Admin) {
        dashboards = dashboardsAll;
    } 
    else {
        const userID = state.account.user.ID;
        dashboards = dashboardsAll.filter(d => d.UserIDs.includes(userID) || (d.PlayerMode === 'Single' && d.Players.find(p => p.UserIDs.includes(userID)) != null));
    }
    const dashboardsMulti = dashboards.filter(d => d.PlayerMode === 'Multi');
    const dashboardsSingle = dashboards.filter(d => d.PlayerMode !== 'Multi');
    const showActiveCount = dashboards.length !== dashboards.filter(d => d.Enabled).length;
    const dashboardsNew = state.template.new.map(mapTemplate);
    const dashboardsPopular = state.template.popular.map(mapTemplate);
    const userID = state.account.user.ID;
    return { 
        dashboards, 
        dashboardsMulti, 
        dashboardsSingle, 
        dashboardsNew, 
        dashboardsPopular, 
        showActiveCount, 
        dashboardsLoading: state.dashboard.dashboardsLoading, 
        role, 
        userID
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //dashboard actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, dashboardActions, templateActions, userActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboards);