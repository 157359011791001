/* eslint-disable react/jsx-no-bind */
import React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../../../actions/dashboardActions';
import * as userActions from '../../../../actions/userActions';
import { withModalContext } from '../../../../services/modalService';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import $ from 'jquery';
import * as imageUtils from '../../../../utils/imageUtils';
import * as widgetUtils from '../../../../utils/widgetUtils';
import '../../../../styles/gridstack.css';
import '../../../../styles/gridstack-extra.css';
import '../../../../styles/style-dashboard.css';
import GridItem from './GridItem';
import Chart from './controls/Chart';
const GridStackUI = require('./scripts/gridstack');

class Widgets extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialise = this.initialise.bind(this);
        this.resolveBindings = this.resolveBindings.bind(this);
        this.getPlayerID = this.getPlayerID.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
        this.setGridItemRef = this.setGridItemRef.bind(this);
        this.removeGridNodesForWidget = this.removeGridNodesForWidget.bind(this);
        this.state = {
            widgets: []
        };
    }

    componentWillMount() {
        window.$('body').addClass('dashbg');
    }

    componentDidMount() {    
        if(!this.props.widgets.length) {
            this.props.actions.getWidgets();
        }
        if(this.props.dashboard && this.props.dashboard.Widgets && !this.props.dashboard.Widgets.length) {
            //we have a dashboard but it doesn't have any widgets
            window.$('body').addClass('dashempty');
        }
        this.initialise();
    }   

    componentWillUpdate(nextProps, nextState) {
        if(nextProps.dashboard && nextProps.dashboard.Widgets) {
            const body = window.$('body');
            if(!nextProps.dashboard.Widgets.length && !body.hasClass('dashempty')) {
                //we have a dashboard but it doesn't have any widgets
                body.addClass('dashempty');
            }
            else if (nextProps.dashboard.Widgets.length && body.hasClass('dashempty')) {
                body.removeClass('dashempty');
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.dashboard !== prevProps.dashboard || this.props.data !== prevProps.data || this.props.dashboard.Tasks !== prevProps.dashboard.Tasks || this.props.dashboard.Widgets !== prevProps.dashboard.Widgets || this.props.users !== prevProps.users ) {
            this.initialise();
        }
    }

    componentWillUnmount() {
        this.grid = null;
        window.$('body').removeClass('dashbg');
    }

    getPlayerID() {
        return this.props.match.params.playerID || this.props.playerID;
    }

    initialise() {
        const dashboard = this.props.dashboard;
        const template = dashboard.Template;

        let dashboardData = {};
        //initialise using the template fields and then override with the adjustments
        const templateKeys = Object.keys(template.Variables);
        templateKeys.forEach(key => {
            const variable = template.Variables[key];
            dashboardData[variable.Name] = variable.DefaultValue;
        });
        dashboard.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
            if(adjustment.Append) {
                dashboardData[adjustment.Name] += parseFloat(adjustment.Append);
            }
            else {
                dashboardData[adjustment.Name] = adjustment.Override;
            }
        });

        let tasks = widgetUtils.initialiseTasks(dashboard, dashboard.Tasks, this.getPlayerID(), this.props.users, this.props.data);  
        //if we already had some tasks resolved and there were any output tasks set, set the values again so we don't lose them
        if(this.state.tasks) {
            const taskKeys = Object.keys(tasks);
            taskKeys.forEach(taskKey => {
                const task = tasks[taskKey];
                const variableKeys = Object.keys(task.variables);
                variableKeys.forEach(variableKey => {
                    const variable = task.variables[variableKey];
                    if(variable.Type === 'OutputValue') {
                        const existingTask = this.state.tasks[taskKey];
                        if(existingTask) {
                            const existingVariable = existingTask.variables[variableKey];
                            if(existingVariable && existingVariable.currentValue) {
                                variable.currentValue = existingVariable.currentValue;
                            }
                        }
                    }
                })
            })
        }
        //resolve the other tasks
        tasks = widgetUtils.resolveTasks(dashboard.Tasks, tasks);
        dashboardData['tasks'] = tasks;

        this.setState(dashboardData, () => {
            //this.resolveBindings();
            if(this.gridStackRef !== null) {
                const gridStack = $(this.gridStackRef);
                gridStack.gridstack({
                    cellHeight: this.state.cellHeight || 60,
                    //cellHeight: 90 || 60,
                    verticalMargin: 20,
                    maxHeight: 10000, //this is a custom parameter which we've implemented
                    float: true,
                    animate: false,
                    staticGrid: true
                });
                this.grid = gridStack.data('gridstack');
            }   
        });      
    }

    resolveBindings() {
        const tasks = {...this.state.tasks} 
        const keys = Object.keys(tasks);
        for(let i = 0; i < keys; i++) {
            const task = tasks[keys[i]];
            /*if(variable.Type !== 'OutputValue') {

            }*/
        }
    }

    onDataChange(widgetHierarchy, propertyName, value) {
        //what about output variables declared on a lower level? do we need the griditem component to do similar logic?
        let tasks = {...this.state.tasks};
        let tasksUpdated = false;
        const taskKeys = Object.keys(tasks);
        for(let i = 0; i < taskKeys.length; i++) {
            const taskID = taskKeys[i];
            const task = tasks[taskID];
            const variableKeys = Object.keys(task.variables);            
            for(let j = 0; j < variableKeys.length; j++) {
                const variableID = variableKeys[j];
                const variable = task.variables[variableID];
                if(variable.Type === 'OutputValue') {
                    //we store the property name in the last item of the variable field name hierarchy
                    if(widgetUtils.bindingMatch(variable.WidgetHierarchy, variable.OutputFieldName, widgetHierarchy, propertyName)) {
                        //we have the right variable, set the value
                        tasks[taskID] = {...tasks[taskID]};
                        const variablesUpdated = {...task.variables};                        
                        variablesUpdated[variableID] = Object.assign({}, variablesUpdated[variableID], {currentValue: value});
                        tasks[taskID].variables = variablesUpdated;
                        //tasksUpdated[taskID].currentValue = variablesUpdated[]
                        //resolve the other variables again
                        tasks = widgetUtils.resolveTasks(this.props.dashboard.Tasks, tasks);
                        tasksUpdated = true;
                    }
                    /*const propertyNameMatch = _.last(variable.WidgetHierarchy) === propertyName;
                    if(widgetHierarchy.length === variable.WidgetHierarchy.length - 1 && propertyNameMatch) {
                        let matching = true;
                        for(let j = 0; j < widgetHierarchy.length; j++) {
                            if(widgetHierarchy[j] !== variable.WidgetHierarchy[j]) {
                                matching = false;
                                break;
                            }
                        }
                        if(matching) {
                            //we have the right variable, set the value
                            const variablesUpdated = {...variables};                        
                            variablesUpdated[keys[i]] = Object.assign({}, variablesUpdated[keys[i]], {currentValue: value});
                            this.setState({ variables: variablesUpdated });
                            break;
                        }
                    }*/
                }
            }
        }
        if(tasksUpdated) {
            this.setState({ tasks });
        }
    }

    setGridItemRef(control) {
        this.newItemRef = control;
        if(this.grid && control) {
            //we've added a new item after gridStack was initialised, add the item to gridstack
            const nodes = this.grid.grid.nodes;
            const controlID = control.dataset.gsId;
            if(!nodes.find(n => n.id === controlID)) {
                this.grid.makeWidget($(this.newItemRef));
            }
        }
    }

    removeGridNodesForWidget(widgetInstanceID, excludeItemIDs = []) {
        if(this.grid) {
            this.grid.grid.nodes.filter(n => n.el[0].dataset.widgetId === widgetInstanceID && !excludeItemIDs.includes(n.el[0].dataset.gsId)).forEach(node => {
                this.grid.removeWidget(node.el);
            });
        }
    }

    render() {
        const columnCount = this.state.columnCount || 12;
        const rowCount = this.state.rowCount || 0;
        return (
            <React.Fragment>
                <main className={`cdash ${this.props.dashboard.Template.Theme === 'Dark' ? 'dashbg': ''}`}>
                    <div className="empty empty--dashboard empty--dashboardtop">
                        <div className="empty__body">
                            <i className="aheadicon-empty-dash-alt empty__picto"></i>
                            <h5 className="mt-3">This dashboard is not yet configured</h5>
                            <p>Your dashboard does not contain any widgets or data. {this.props.role === 'Viewer' ? 'Please contact an administrator.' : 'Please configure it.'}</p>
                            {
                                this.props.role !== 'Viewer' ?
                                    <Link to={`${this.props.location.pathname}/WidgetSettings`} class="btn btn-primary btn--withicon mt-4">
                                        <i class="aheadicon-settings-alt"></i>
                                        Customise Now
                                    </Link>
                                    : null
                            }
                            
                        </div>
                    </div>    
    
                    <div className={`grid-stack grid-stack-${columnCount}`} ref={(control) => this.gridStackRef = control} data-gs-width={columnCount} data-gs-height={rowCount} style={{marginBottom: '10px'}}>
                    {
                        this.props.dashboard.Widgets.map((item, i) => 
                            <GridItem 
                                key={item.ID} 
                                dashboard={this.props.dashboard}
                                widget={this.props.widgets.find(w => w.ID === item.WidgetID)} 
                                widgets={this.props.widgets} 
                                tasks={this.state.tasks} 
                                widgetSettings={item} 
                                xPos={item.X} 
                                yPos={item.Y} 
                                width={item.Width} 
                                height={item.Height} 
                                index={i} 
                                widgetHierarchy={[item.ID]}
                                onDataChange={this.onDataChange}
                                gridColumnCount={this.state.columnCount || 12}
                                onRef={this.setGridItemRef} 
                                removeGridNodesForWidget={this.removeGridNodesForWidget}
                            />
                        )
                    }                        
                    </div>
                </main>               
            </React.Fragment>
        );
    }
}

Widgets.propTypes = {
    actions: PropTypes.object.isRequired,
    dashboard: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    return {
        widgets: state.dashboard.widgets,
        role: state.account.user.Role
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //dashboard actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, dashboardActions, userActions), dispatch)
    };
}

export default withRouter(withModalContext(connect(mapStateToProps, mapDispatchToProps)(Widgets)));