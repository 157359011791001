/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../../actions/dashboardActions';
import * as userActions from '../../../actions/userActions';
import { withModalContext } from '../../../services/modalService';
import _ from 'lodash';
import $ from 'jquery';
import NewWidgetModal from './NewWidgetModal';
import NewTaskModal from './NewTaskModal';
import EditWidgetModal from './EditWidgetModal';
import SelectDataModal from './SelectDataModal';
import '../../../styles/gridstack.css';
import '../../../styles/gridstack-extra.css';
import '../../../styles/style-dashboard.css';
import GridItem from '../templates/Widgets/GridItem';
import { Draggable } from '@shopify/draggable';
import roleTypes from '../../../constants/roleTypes';
import DashboardService from '../../../services/dashboardService';
import ToastrService from '../../../services/toastrService';
import * as widgetUtils from '../../../utils/widgetUtils';
import TasksModal from './TasksModal';
import ManualInputModal from './ManualInputModal';
import ConfigureWidgetModal from './ConfigureWidgetModal';
const GridStackUI = require('../templates/Widgets/scripts/gridstack');
require('../templates/Widgets/scripts/gridstack.nojQueryUI');

class WidgetSettings extends React.Component {
    constructor(props, context) {
        super(props, context); 
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.initialiseWidgets = this.initialiseWidgets.bind(this);
        this.initialiseWidgetsDragDrop = this.initialiseWidgetsDragDrop.bind(this);
        this.initialiseGridStack = this.initialiseGridStack.bind(this);
        this.getGridStackItem = this.getGridStackItem.bind(this);
        this.getGridColumnCount = this.getGridColumnCount.bind(this);
        this.getGridRowCount = this.getGridRowCount.bind(this);
        this.getGridCellHeight = this.getGridCellHeight.bind(this);
        this.openSecondModal = this.openSecondModal.bind(this);
        this.updateTask = this.updateTask.bind(this);
        this.createModifier = this.createModifier.bind(this);
        this.updateModifier = this.updateModifier.bind(this);
        this.deleteModifier = this.deleteModifier.bind(this);
        this.removeModifierIncludeField = this.removeModifierIncludeField.bind(this);
        this.saveTask = this.saveTask.bind(this);
        this.editTask = this.editTask.bind(this);
        this.deleteTask = this.deleteTask.bind(this);
        this.loadDashboardData = this.loadDashboardData.bind(this);
        this.initialiseTasks = this.initialiseTasks.bind(this);
        this.getPlayerID = this.getPlayerID.bind(this);
        this.saveWidget = this.saveWidget.bind(this);
        this.getNewTask = this.getNewTask.bind(this);
        this.getTempID = this.getTempID.bind(this);
        this.getWidgetName = this.getWidgetName.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
        this.openEditWidgetModal = this.openEditWidgetModal.bind(this);
        this.clearSelectedTask = this.clearSelectedTask.bind(this);
        this.setGridItemRef = this.setGridItemRef.bind(this);
        this.removeGridNodesForWidget = this.removeGridNodesForWidget.bind(this);
        this.tempIDCounters = {};
        this.state = {
            widgets: [],
            newTask: this.getNewTask(),
            resolvedTasks: [],
            selectedTask: null,
            selectedModifier: null,
            selectedWidgetInstance: null,
            selectedWidgetInstanceParent: null,
            selectedWidget: null,
            outputTaskValues: [] //this is to temporarily store the output values of any widgets where the value has been set before an output task has been created, so we can still set the value if we get a task
        };
    }

    componentDidMount() {      
        window.$('body').addClass('dashbg');
        const dashboardID = this.props.match.params.id;
        if(!this.props.dashboard) {
            //if we're browsing directly to this page, we might not have the dashboard details
            this.props.actions.getDashboard(dashboardID);
        }
        else {
            this.props.actions.checkDashboardVersion(dashboardID);
            if(this.props.dashboard.Widgets && !this.props.dashboard.Widgets.length) {
                window.$('body').addClass('dashempty');
            }
        }
        if(!this.props.users || !this.props.users.length) {
            this.props.actions.getUsers();
        }
        if(!this.props.widgets.length) {
            this.props.actions.getWidgets();
        }
        if(this.props.widgets && this.props.widgets.length && this.props.dashboard && this.props.dashboard.Widgets && this.props.dashboard.Widgets.length && !this.state.widgets.length) {
            //we have widgets defined in the dashboard, but we haven't added them to the state yet
            this.initialiseWidgets(this.props);
        }
        this.initialiseGridStack();
        if(this.props.dashboard) {
            this.loadDashboardData();
        }
        if(this.props.dashboard) {
            this.initialiseTasks();
        }
    }     

    componentWillUpdate(nextProps, nextState) {
        const willHaveDashboard = !this.props.dashboard && nextProps.dashboard;
        const haveWidgets = this.props.dashboard && this.state.widgets.length;
        const willHaveWidgets = nextProps.dashboard && !this.state.widgets.length && nextState.widgets.length;
        const widgetsDeleted = this.state.widgets.length > 0 && nextState.widgets.length === 0;
        if((willHaveDashboard && !willHaveWidgets) || widgetsDeleted) {
            window.$('body').addClass('dashempty');
        }
        else if(!haveWidgets && willHaveWidgets) {
            window.$('body').removeClass('dashempty');
        }
        if(this.state.widgets.length > nextState.widgets.length) {
            //we've removed an item, delete it from the grid before React removes it from the DOM
            const removedItem = $(this.removedItem);
            if(removedItem.length) {
                this.grid.removeWidget(removedItem, false);
            }
        }
        if(nextProps.dashboard && nextProps.widgets && nextProps.dashboard.Widgets && nextProps.dashboard.Widgets.length && !this.state.widgets.length && !nextState.widgets.length) {
            //we have widgets defined in the dashboard, but we haven't added them to the state yet
            this.initialiseWidgets(nextProps);
        }
        if(this.props.dashboard && this.props.dashboard.Widgets && nextProps.dashboard && nextProps.dashboard.Widgets && this.props.dashboard.Widgets) {
            if(this.props.dashboard.Widgets.length < nextProps.dashboard.Widgets.length) {
                const newWidget = nextProps.dashboard.Widgets.find(w1 => !this.props.dashboard.Widgets.find(w2 => w2.ID === w1.ID));
                if(newWidget) {
                    const widgets = [...this.state.widgets];
                    const widget = this.props.widgets.find(w => w.ID === newWidget.WidgetID);
                    widgets.push(this.getGridStackItem(widget, newWidget, this.state.widgets.length));
                    this.setState({widgets}, () => {
                        //we're now calling this when the ref is set
                        //this.grid.makeWidget($(this.newItemRef));
                    });
                }
            }
            else if(this.props.dashboard.Widgets.length > nextProps.dashboard.Widgets.length) {
                const deletedWidget = this.props.dashboard.Widgets.find(w1 => !nextProps.dashboard.Widgets.find(w2 => w2.ID === w1.ID));
                if(deletedWidget) {
                    const widgets = [...this.state.widgets];
                    const widgetItem = widgets.find(w => w.key === deletedWidget.ID);
                    _.pull(widgets, widgetItem);
                    this.setState({widgets});
                }
            }

        }
    }
    componentDidUpdate(prevProps, prevState) {

        /*if(this.state.widgets.length > prevState.widgets.length) {
            //we could always just take the last item here?
            
        }*/
        if(this.props.dashboard && !prevProps.dashboard) {
            this.initialiseWidgetsDragDrop();  
        }  
        
        if((!this.props.loading && this.state.dataLoaded) && (prevProps.loading || !prevState.dataLoaded)) {
            //we would have re-rendered gridstack (removed the markup because we were loading, then added it back) so re-initialise it
            this.initialiseGridStack();
        }
        if(this.props.dashboard && prevProps.dashboard && this.props.dashboard.Widgets !== prevProps.dashboard.Widgets) {
            this.initialiseWidgets(this.props);
        }
        if(this.props.dashboard && !prevProps.dashboard) {
            this.loadDashboardData();
        }
        if(this.props.dashboard) {
            if(this.props.dashboard !== prevProps.dashboard || this.state.data !== prevState.data || this.props.dashboard.Tasks !== prevProps.dashboard.Tasks || this.props.dashboard.Widgets !== prevProps.dashboard.Widgets || this.props.users !== prevProps.users ) {
                this.initialiseTasks();
            }
        }
        if(this.state.newTask !== prevState.newTask && this.state.newTask) {
            if(this.state.newTask.Variables.length) {
                this.initialiseTasks(this.state.newTask);
            }
        }
        else if(this.state.selectedTask && prevState.selectedTask && this.state.selectedTask !== prevState.selectedTask) {
            //we've changed something on an existing task
            if(this.state.selectedTask.Variables.length) {
                this.initialiseTasks(null, this.state.selectedTask);
            }
        }
        if(this.state.resolvedTasks !== prevState.resolvedTasks) {
            //create the widgets again so we can pass in the latest resolved tasks
            this.initialiseWidgets(this.props);
        }
    }

    componentWillUnmount() {
        this.widgetsDraggable = null;
        this.grid = null;
        window.$('body').removeClass('dashbg');
    }

    getNewTask() {
        const id = this.getTempID('Task');
        return {
            ID: id,
            Name: id,
            Variables: []
        };
    }

    getTempID(prefix) {
        if(!Object.keys(this.tempIDCounters).includes(prefix)) {
            this.tempIDCounters[prefix] = 0;
        }
        const id = ++this.tempIDCounters[prefix];
        return `${prefix}${id}`;
    }

    loadDashboardData() {
        let playerID = this.getPlayerID();
        DashboardService.getDashboardData(this.props.dashboard.ID, this.props.dashboard.PlayerMode === 'Single' ? playerID : null)
            .then(data => this.setState({
                data,
                dataLoaded: true
            }))
            .catch(err => {});
    }

    getPlayerID() {
        return this.props.dashboard && this.props.dashboard.PlayerMode === 'Single' ? this.props.match.params.playerID : null;
    }

    initialiseTasks(newTask = null, selectedTask = null) {
        let tasks = [...this.props.dashboard.Tasks];
        if(newTask) {
            tasks = tasks.concat(newTask);
        }
        if(selectedTask) {
            const taskIndex = tasks.findIndex(t => t.ID === selectedTask.ID);
            tasks.splice(taskIndex, 1, selectedTask);
        }
        let resolvedTasks = widgetUtils.initialiseTasks(this.props.dashboard, tasks, this.getPlayerID(), this.props.users, this.state.data);
        if(this.state.outputTaskValues.length) {
            //add any output values if we have tasks for them
            const taskKeys = Object.keys(resolvedTasks);
            taskKeys.forEach(taskKey => {
                const task = resolvedTasks[taskKey];
                const variableKeys = Object.keys(task.variables);
                variableKeys.forEach(variableKey => {
                    const variable = task.variables[variableKey];
                    if(variable.Type === 'OutputValue') {
                        let outputTaskValue = this.state.outputTaskValues.find(o => o.widgetHierarchy.join('-') === variable.WidgetHierarchy.join('-') && o.propertyName === variable.OutputFieldName);
                        if(outputTaskValue) {
                            variable.currentValue = outputTaskValue.value;
                            if(variableKeys.length === 1 || variableKeys[variableKeys.length - 1] === variableKey) {
                                //set the value on the task
                                task.currentValue = variable.currentValue;
                            }
                        }                                                
                    }
                })
            })
        }
        resolvedTasks = widgetUtils.resolveTasks(tasks, resolvedTasks, true);
        this.setState({ resolvedTasks });
    }

    setGridItemRef(control) {
        this.newItemRef = control;
        if(this.grid && control) {
            //we've added a new item after gridStack was initialised, add the item to gridstack
            const nodes = this.grid.grid.nodes;
            const controlID = control.dataset.gsId;
            if(!nodes.find(n => n.id === controlID)) {
                this.grid.makeWidget($(this.newItemRef));
            }
        }
    }

    getGridStackItem(widget, widgetInstance, index) {
        return <GridItem 
                    key={widgetInstance.ID} 
                    dashboard={this.props.dashboard}
                    widget={widget} 
                    widgets={this.props.widgets} 
                    tasks={this.state.resolvedTasks} 
                    widgetSettings={widgetInstance} 
                    isEditable={true} 
                    editWidget={this.openEditWidgetModal} 
                    removeWidget={(gridItem) => this.removeWidget(widgetInstance, gridItem)} 
                    xPos={widgetInstance.X} 
                    yPos={widgetInstance.Y} 
                    width={widgetInstance.Width} 
                    height={widgetInstance.Height} 
                    widgetHierarchy={[widgetInstance.ID]}
                    onRef={this.setGridItemRef} 
                    onDataChange={this.onDataChange}
                    index={index}
                    gridColumnCount={this.getGridColumnCount()}
                    removeGridNodesForWidget={this.removeGridNodesForWidget} />;
    }

    initialiseWidgets(props) {
        const dashboardWidgetItems = props.dashboard.Widgets;
        const allWidgets = props.widgets;
        const widgets = [];
        for(let i = 0; i < dashboardWidgetItems.length; i++) {
            const widgetItem = dashboardWidgetItems[i];
            const widget = allWidgets.find(w => w.ID === widgetItem.WidgetID);
            widgets.push(this.getGridStackItem(widget, widgetItem, widgets.length));
        }
        this.setState({ widgets, selectedWidget: null, selectedWidgetInstance: null, selectedWidgetInstanceParent: null });
    }

    getGridRowCount() {
        return this.state.rowCount || 0;
    }

    getGridColumnCount() {
        return this.state.columnCount || 12;
    }

    getGridCellHeight() {
        //not sure why, but this needs to be 90, else our nested widget doesn't fit - fixed
        //return 90;
        return this.state.cellHeight || 80;
    }

    initialiseGridStack() {
        if(this.props.dashboard) {
            const template = this.props.dashboard.Template;

            let dashboardData = {};
            //initialise using the template fields and then override with the adjustments
            const templateKeys = Object.keys(template.Variables);
            templateKeys.forEach(key => {
                const variable = template.Variables[key];
                dashboardData[variable.Name] = variable.DefaultValue;
            });
            this.props.dashboard.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
                if(adjustment.Append) {
                    dashboardData[adjustment.Name] += parseFloat(adjustment.Append);
                }
                else {
                    dashboardData[adjustment.Name] = adjustment.Override;
                }
            });
            this.setState(dashboardData, () => {
                this.livePosX = null;
                this.livePosY = null;  
        
                const gridStack = $(this.gridStackRef);
                if(gridStack.length !== 0) {
                    gridStack.gridstack({
                        cellHeight: this.getGridCellHeight(),
                        width: this.getGridColumnCount(),
                        verticalMargin: 20,
                        maxHeight: 10000, //this is a custom parameter which we've implemented
                        float: true,
                        animate: false
                    });
                    this.grid = gridStack.data('gridstack');
        
                    gridStack.on('change', (event, items) => this.handleChange(items));
                }       
        
                this.dragItems = $('.widgets-list .widget-item');
                this.dragDropZones = gridStack;
                this.currentWidget = null;  
                this.currentWidgetID = null;
            })  
        }     
    }

    initialiseWidgetsDragDrop() {
        if(this.draggable) {
            this.draggable.destroy();
        }
        if(!this.props.loading && this.state.dataLoaded) {
            const widgetList = $('.widgets-list', $('#modal-new-widget'))[0];
            this.draggable = new Draggable([widgetList], {
                draggable: '.widget-item',
                mirror: {
                    appendTo: 'body',
                }
            })
            .on('drag:start', (dragEvent) => {
                $(this.gridStackRef).addClass('over');
                this.handleDragStart(dragEvent);
                const source = $(dragEvent.source);
                const position = source.position();
                this.leftOffset = dragEvent.sensorEvent.clientX - position.left;
                this.topOffset = dragEvent.sensorEvent.clientY - position.top;            
            })
            .on('drag:move', (dragEvent) => {
                this.handleDragOver(dragEvent);
            })        
            .on('drag:stop', (dragEvent) => {
                //remove the over class from the container
                $(this.gridStackRef).removeClass('over');
                this.handleDrop(dragEvent);
            })
        }
    }

    //-- Start drag
    handleDragStart(e) {
        console.log("Drag started...");
        //the event object is different based on how we're doing the drag so try both
        const control = $(e.currentTarget || e.originalSource);
        control.addClass('dragged');
        $('body').addClass('drag-in-progress');

        this.currentWidgetID = control.attr('data-wid');
        this.currentWidget = control;

        if(e.originalEvent.dataTransfer) {
            e.originalEvent.dataTransfer.effectAllowed = 'move';
        }
    }
    
    //-- Dragging over target elements
    handleDragOver(e) {
        //console.log("Dragging over..." + e.target || e.originalSource);

        if (e.preventDefault) {
            e.preventDefault();
        }
        if(e.originalEvent.dataTransfer) {
            e.originalEvent.dataTransfer.dropEffect = 'move';
        }  

        //-- Insert new content between block level elements
        let elementOver = $(e.target || e.originalSource);

        //-- Calculate drag position to use it to drop
        //-- element to exact location on the grid
        let gridWidth = $(this.gridStackRef).width();
        let gridHeight = $(this.gridStackRef).height();
        let posX = (e.pageX || e.originalEvent.pageX) - elementOver.offset().left;
        let posY = (e.pageY || e.originalEvent.pageY) - elementOver.offset().top;
        //let posX = e.pageX - $(this).offset().left;
        //let posY = e.pageY - $(this).offset().top;
        let cellWidth = gridWidth / this.getGridColumnCount();

        this.livePosX = Math.floor( posX / cellWidth );
        this.livePosY = Math.floor( posY / this.getGridCellHeight() );
    }


    //-- Entering drag target
    handleDragEnter(e) {
    // this / e.target is the current hover target.
        e.preventDefault();

        console.log('Drop zone entered')
        $(this).addClass('over');
    }

    //-- Leaving drag target
    handleDragLeave(e) {
        console.log('Left drop zone')
        $(this).removeClass('over');  // this / e.target is previous target element.
    }


    //-- Dropping element
    handleDrop(e) {
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        const widget = this.props.widgets.find(w => w.ID === this.currentWidgetID);

        let wX = this.livePosX,
        wY = this.livePosY,
        wW = widget.DefaultWidth,
        wH = widget.DefaultHeight;

        const widgetInstance = {
            widgetID: widget.ID,
            x: wX,
            y: wY,
            width: wW,
            height: wH,
            name: this.getWidgetName(widget.Slug || widget.Name.replace(/\s+/g,''))
        };

        this.props.actions.addWidget(this.props.dashboard.ID, widgetInstance);
        this.dragItems.removeClass('dragged');

        //-- Remove Helper element
        $('.dragSpacer', this.dragDropZones).remove();
        $('body').removeClass('drag-in-progress');
        this.currentWidget = null;
        this.currentWidgetID = null;

        return false;
    }

    handleChange(items) {
        if(items && items.length) {
            const changedItem = items[0];
            const changedWidgetID = changedItem.el[0].dataset.widgetId;
            const widget = this.props.dashboard.Widgets.find(w => w.ID === changedWidgetID);
            if(widget) {
                //make sure something has changed as we also end up here when new items are added, e.g. in an iteration modifier
                if(widget.X !== changedItem.x || widget.Y !== changedItem.y || widget.Width !== changedItem.width || widget.Height !== changedItem.height) {
                    let updatedWidget = {...widget, X: changedItem.x, Y: changedItem.y, Width: changedItem.width, Height: changedItem.height};
                    this.props.actions.updateWidget(this.props.dashboard.ID, updatedWidget);
                }
            }
        }
    }

    getWidgetName(prefix) {
        let widgetName;
        while(!widgetName || this.props.dashboard.Widgets.find(w => w.Name === widgetName) != null) {
            widgetName = this.getTempID(prefix);
        }
        return widgetName;
    }

    removeWidget(widget, gridItem) {
        if(window.confirm('Delete item?')) {
            this.removedItem = gridItem.gridItemRef;
            this.props.actions.removeWidget(this.props.dashboard.ID, widget.ID);
        }
    }

    updateTask(task) {
        if(task.ID === this.state.newTask.ID) {
            this.setState({ newTask: task });
        }
        else {
            this.setState({ selectedTask: task });
        }
    }
    
    createModifier(modifier) {
        const task = this.state.selectedTask ? {...this.state.selectedTask} : {...this.state.newTask};
        const variables = [...task.Variables];
        variables.push(modifier);
        task.Variables = variables;
        if(this.state.selectedTask) {
            this.setState({ selectedTask: task });
        }
        else {
            this.setState({ newTask: task });
        }
    }

    updateModifier(modifier) {
        const task = this.state.selectedTask ? {...this.state.selectedTask} : {...this.state.newTask};
        const variables = [...task.Variables];
        const variablesIndex = variables.findIndex((v) => v.ID === modifier.ID);
        variables.splice(variablesIndex, 1, modifier);
        task.Variables = variables;
        if(this.state.selectedTask) {
            this.setState({ selectedTask: task });
        }
        else {
            this.setState({ newTask: task });
        }
    }
    
    deleteModifier(modifier) {
        const task = this.state.selectedTask ? {...this.state.selectedTask} : {...this.state.newTask};
        task.Variables = [...task.Variables];
        const variableIndex = task.Variables.indexOf(modifier);
        if(task.Variables.length > variableIndex + 1) {
            //we have modifiers after it, update their input to the input of the modifier being deleted            
            const inputs = modifier.InputVariableID ? [modifier.InputVariableID] : modifier.Inputs;
            const nextVariable = {...task.Variables[variableIndex + 1]};
            if(nextVariable) {
                if(nextVariable.Inputs) {
                    nextVariable.Inputs = inputs;
                }
                else {
                    nextVariable.InputVariableID = inputs[0]
                }
                task.Variables.splice(variableIndex + 1, 1, nextVariable);
            }
        }
        task.Variables.splice(variableIndex, 1);
        //check if our variables in state has this resolved and remove it
        const resolvedTasks = {...this.state.resolvedTasks};
        const resolvedTask = {...resolvedTasks[task.ID]};
        const taskVariables = {...resolvedTask.variables};
        delete taskVariables[modifier.ID];
        resolvedTask.variables = taskVariables;
        resolvedTasks[task.ID] = resolvedTask;
        if(this.state.selectedTask) {
            this.setState({ selectedTask: task, resolvedTasks });
        }
        else {
            this.setState({ newTask: task, resolvedTasks });
            if(this.tempIDCounters[modifier.Type] === 1) {
                this.tempIDCounters[modifier.Type] = 0;
            }
        }
    }

    editTask(task) {
        this.setState({ selectedTask: task });
    }

    clearSelectedTask() {
        this.setState({ selectedTask: null });
    }

    deleteTask(task) {
        this.props.actions.removeTask(this.props.dashboard.ID, task.ID)
            .then(dashboard => {
                const toastrService = new ToastrService();
                toastrService.showSuccess('', 'Task has been deleted successfully!');
            })
            .catch(error => {
                const toastrService = new ToastrService();
                toastrService.showError('', 'Sorry, something went wrong, unable to delete task');
            });
    }
    
    openSecondModal(dataInputType, selectedModifier) {
        this.setState({ dataInputType, selectedModifier })
        if(dataInputType === 'UserDefined') {
            window.$('#modal-newdata-manual').modal('show');
            window.$('body').addClass('modal-open-secondary');        
            window.$('#modal-newdata-manual').on('hide.bs.modal', function(){
                window.$('body').removeClass('modal-open-secondary');
            });
        }
        else {
            window.$('#modal-newdata').modal('show');
            window.$('body').addClass('modal-open-secondary');        
            window.$('#modal-newdata').on('hide.bs.modal', function(){
                window.$('body').removeClass('modal-open-secondary');
            });
        }
    }

    openEditWidgetModal(widgetInstance, widget, parentWidget = null) {           
        this.setState({selectedWidgetInstance: widgetInstance, selectedWidget: widget, selectedWidgetInstanceParent: parentWidget});
        window.$('#modal-edit-widget').modal('show');
    }

    removeModifierIncludeField(taskID, inputVariable, fieldName) {
        if(taskID === this.state.newTask.ID) {
            const newTask = {...this.state.newTask};
            const variableIndex = newTask.Variables.indexOf(inputVariable);
            let variables = [...newTask.Variables];
            let variable = variables[variableIndex];
            if(variable.IncludeFields.length === 1 && variable.IncludeFields[0] === fieldName) {
                //we're removing the only property, so just remove the modifier
                variables.splice(variableIndex, 1);
                /*const linkedTransformationModifier = modifiers.find(m => m.Type === 'Transformation' && m.Input.VariableName === (task.Name || task.Type));
                if(linkedTransformationModifier) {
                    const modifierIndex = modifiers.indexOf(linkedTransformationModifier);
                    modifiers = [...modifiers];
                    modifiers.splice(modifierIndex, 1);
                }*/
                if(this.tempIDCounters[inputVariable.Type] === 1) {
                    this.tempIDCounters[inputVariable.Type] = 0;
                }
            }
            else {
                variable = {...variable};
                const fieldIndex = variable.IncludeFields.indexOf(fieldName);
                variable.IncludeFields = [...variable.IncludeFields];
                variable.IncludeFields.splice(fieldIndex, 1);
                variables[variableIndex] = variable;
            }
            newTask.Variables = variables;
            this.setState({ newTask });
        }
    }

    async saveTask(taskID, taskName) {
        if(taskID === this.state.newTask.ID) {
            //we need to create a new task, create the task 1st (minus the modifiers)

            const task = { Name: taskName }
            await this.props.actions.addTask(this.props.dashboard.ID, task)
                .then(async dashboard => {
                    //the name has to be unique so we can find the correct task using the task name
                    const task = dashboard.Tasks.find(t => t.Name === taskName);
                    //create the variables
                    //we need to map the temporary variable IDs to the read IDs that the API will create so we can update any linked variables
                    const variableIDMappings = {};
                    const variables = [...this.state.newTask.Variables];
                    for(let i = 0; i < variables.length; i++) {
                        let variable = variables[i];
                        const variableID = variable.ID;
                        variable.ID = null;
                        //update any temp IDs with the real ones
                        if(variable.InputVariableID && variableIDMappings[variable.InputVariableID]) {
                            variable.InputVariableID = variableIDMappings[variable.InputVariableID];
                        };
                        if(variable.Inputs && variable.Inputs.length) {
                            for(let j = 0; j < variable.Inputs.length; j++) {
                                variable.Inputs[j] = variableIDMappings[variable.Inputs[j]];
                            }
                        }
                        if(variable.Type === 'Calculation') {
                            //update the formula to replace any variable names with IDs
                            const variableNameIDMappings = {};
                            variable.Inputs.forEach(input => {
                                const variable = dashboard.Tasks.find(t => t.ID === task.ID).Variables.find(v => v.ID === input);
                                if(variable) {
                                    variableNameIDMappings[variable.Name] = variableIDMappings[variable.ID] || variable.ID;
                                }
                            });
                            variable.Formula = widgetUtils.formatFormulaForParsing(variable.Formula, variableNameIDMappings);
                        }
                        await this.props.actions.addVariable(this.props.dashboard.ID, task.ID, variable).then(dashboard => {
                            //the variable name has to be unique so we can find the correct variable using the variable name
                            variable = dashboard.Tasks.find(t => t.ID === task.ID).Variables.find(v => v.Name === variable.Name);
                            //now we have the real ID, store the mapping
                            variableIDMappings[variableID] = variable.ID;
                            if(i === variables.length - 1) {
                                //it's the last one
                                this.tempIDCounters = {};
                                this.setState({ newTask: this.getNewTask() });
                                const toastrService = new ToastrService();
                                toastrService.showSuccess('', 'Task has been saved successfully!');
                            }
                            return true;
                        })
                        .catch(error => {
                            throw(error);
                        });
                    }
                })
                .catch(error => {
                    const toastrService = new ToastrService();
                    toastrService.showError('', 'Sorry, something went wrong, unable to create task');
                });       
        }
        else {
            //if we have any new modifiers, we need to create them 1st so we can link the IDs properly, and then we finally call Save on the whole task
            //can we check if the modifier existed originally? we make the changes to the savedTask object but should still have the original
            const originalTask = this.props.dashboard.Tasks.find(t => t.ID === taskID);
            const originalVariableIDs = originalTask.Variables.map(v => v.ID);
            let selectedTask = {...this.state.selectedTask};
            const newVariables = selectedTask.Variables.filter(v => !originalVariableIDs.includes(v.ID));
            //we need to map the temporary variable IDs to the read IDs that the API will create so we can update any linked variables
            const variableIDMappings = {};
            await newVariables.forEach(async variable => {
                const variableID = variable.ID;
                variable.ID = null;
                await this.props.actions.addVariable(this.props.dashboard.ID, selectedTask.ID, variable).then(dashboard => {
                    //the variable name has to be unique so we can find the correct variable using the variable name
                    const variableIndex = selectedTask.Variables.findIndex(v => v.ID === variableID); 
                    variable = dashboard.Tasks.find(t => t.ID === selectedTask.ID).Variables.find(v => v.Name === variable.Name);
                    //now we have the real ID, store the mapping
                    variableIDMappings[variableID] = variable.ID;       
                    selectedTask.Variables.splice(variableIndex, 1, variable);
                    return true;
                })
                .catch(error => {
                    throw(error);
                })
            }); 
            for(let i = 0; i < selectedTask.Variables.length; i++) {
                let variable = selectedTask.Variables[i];
                const originalVariable = originalTask.Variables.find(v => v.ID === variable.ID);
                if(originalVariable && variable !== originalVariable) {
                    //we updated the variable, send an update to the API
                    //update any temp IDs with the real ones
                    if(variable.InputVariableID && variableIDMappings[variable.InputVariableID]) {
                        variable.InputVariableID = variableIDMappings[variable.InputVariableID];
                    };
                    if(variable.Inputs && variable.Inputs.length) {
                        for(let j = 0; j < variable.Inputs.length; j++) {
                            if(variableIDMappings[variable.Inputs[j]]) {
                                variable.Inputs[j] = variableIDMappings[variable.Inputs[j]];
                            }
                        }
                    }
                    if(variable.Type === 'Calculation' || variable.Type === 'Filter') {
                        //update the formula to replace any variable names with IDs
                        const variableNameIDMappings = {};
                        const inputs = variable.Type === 'Calculation' ? variable.Inputs : variable.FormulaInputs;
                        inputs.forEach(input => {
                            const variable = this.props.dashboard.Tasks.find(t => t.ID === selectedTask.ID).Variables.find(v => v.ID === input);
                            if(variable) {
                                variableNameIDMappings[variable.Name] = variableIDMappings[variable.ID] || variable.ID;
                            }
                        });
                        variable.Formula = widgetUtils.formatFormulaForParsing(variable.Formula, variableNameIDMappings);
                    }
                    await this.props.actions.updateVariable(this.props.dashboard.ID, selectedTask.ID, variable);
                }                
            }
            //find any deleted variables
            const selectedVariableIDs = selectedTask.Variables.map(v => v.ID);
            const deletedVariableIDs = originalVariableIDs.filter(v => !selectedVariableIDs.includes(v));
            for(let i = 0; i < deletedVariableIDs.length; i++) {
                let variableID = deletedVariableIDs[i];
                await this.props.actions.removeVariable(this.props.dashboard.ID, selectedTask.ID, variableID);
            }
            const task = { ID: selectedTask.ID, Name: taskName, Description: selectedTask.Description };
            this.props.actions.updateTask(this.props.dashboard.ID, task)
                .then(dashboard => {
                    const toastrService = new ToastrService();
                    toastrService.showSuccess('', 'Task has been saved successfully!');
                    this.setState({ selectedTask: null });
                })                
        }
    }

    saveWidget(widgetInstance) {
        this.props.actions.updateWidget(this.props.dashboard.ID, widgetInstance);
        /*let widget = this.state.widgets.find(w => w.key === widgetInstance.ID);
        const widgetIndex = this.state.widgets.indexOf(widget);
        const widgets = [...this.state.widgets];
        widget = {...widget};
        widget.props = {...widget.props};
        widget.props.widgetSettings = widgetInstance;
        widget.props.tasks = this.state.resolvedTasks;
        widgets.splice(widgetIndex, 1, widget);
        this.setState({ widgets });*/
        window.$('#modal-edit-widget').modal('hide');
    }

    onDataChange(widgetHierarchy, propertyName, value) {
        const outputTaskValues = [...this.state.outputTaskValues];
        let outputTaskValue = outputTaskValues.find(o => o.widgetHierarchy.join('-') === widgetHierarchy.join('-') && o.propertyName === propertyName);
        if(!outputTaskValue) {
            outputTaskValue = { widgetHierarchy, propertyName, value };
            outputTaskValues.push(outputTaskValue);
        }
        else {
            const index = outputTaskValues.indexOf(outputTaskValue);
            outputTaskValue = {...outputTaskValue};
            outputTaskValue.value = value;
            outputTaskValues.splice(index, 1, outputTaskValue);
        }        

        let resolvedTasks = {...this.state.resolvedTasks};
        const taskKeys = Object.keys(resolvedTasks);
        for(let i = 0; i < taskKeys.length; i++) {
            const taskID = taskKeys[i];
            const task = resolvedTasks[taskID];
            const variableKeys = Object.keys(task.variables);
            for(let j = 0; j < variableKeys.length; j++) {
                const variableID = variableKeys[j];
                const variable = task.variables[variableID];
                if(variable.Type === 'OutputValue') {
                    //we store the property name in the last item of the variable field name hierarchy
                    if(widgetUtils.bindingMatch(variable.WidgetHierarchy, variable.OutputFieldName, widgetHierarchy, propertyName)) {
                        //we have the right variable, set the value
                        resolvedTasks[taskID] = {...resolvedTasks[taskID]};
                        const variablesUpdated = {...task.variables};                        
                        variablesUpdated[variableID] = Object.assign({}, variablesUpdated[variableID], {currentValue: value});
                        resolvedTasks[taskID].variables = variablesUpdated;
                        const finalVariable = resolvedTasks[taskID].variables[variableKeys[variableKeys.length - 1]];
                        if(finalVariable) {
                            resolvedTasks[taskID].currentValue = finalVariable.currentValue;
                            resolvedTasks[taskID].outputType = finalVariable.OutputType;
                        }
                        //resolve the other variables again
                        resolvedTasks = widgetUtils.resolveTasks(this.props.dashboard.Tasks, resolvedTasks);
                        break;
                    }
                    /*const propertyNameMatch = _.last(variable.WidgetHierarchy) === propertyName;
                    if(widgetHierarchy.length === variable.WidgetHierarchy.length - 1 && propertyNameMatch) {
                        let matching = true;
                        for(let j = 0; j < widgetHierarchy.length; j++) {
                            if(widgetHierarchy[j] !== variable.WidgetHierarchy[j]) {
                                matching = false;
                                break;
                            }
                        }
                        if(matching) {
                            //we have the right variable, set the value
                            const variablesUpdated = {...variables};                        
                            variablesUpdated[keys[i]] = Object.assign({}, variablesUpdated[keys[i]], {currentValue: value});
                            this.setState({ variables: variablesUpdated });
                            break;
                        }
                    }*/
                }
            }
        }
        this.setState({ outputTaskValues, resolvedTasks });
    }

    removeGridNodesForWidget(widgetInstanceID, excludeItemIDs = []) {
        if(this.grid) {
            this.grid.grid.nodes.filter(n => n.el[0].dataset.widgetId === widgetInstanceID && !excludeItemIDs.includes(n.el[0].dataset.gsId)).forEach(node => {
                this.grid.removeWidget(node.el);
            });
        }
    }

    render() {
        const dashboard = this.props.dashboard;
        return (
            <div className={`${this.props.loading || !this.state.dataLoaded ? 'spinner' : ''}`}>
            {
                !this.props.loading && this.state.dataLoaded ?
                    <React.Fragment>
                        <a href="#" className="btn btn--primary btn-newwidget" data-toggle="modal" data-target="#modal-new-widget">
                            <i className="aheadicon-plus"></i>
                            <br />Add widget
                        </a>
                        <a href="#" className="btn btn--primary btn-tasks" data-toggle="modal" data-target="#modal-tasks">
                            Tasks
                        </a>
                        <main className="cdash">                
                            <div className="empty empty--dashboard">
                                <div className="empty__body">
                                    <i className="aheadicon-empty-dash empty__picto"></i>
                                    <h5 className="mt-3">Welcome to your new dashboard!</h5>
                                    <p>You don't have any widgets added yet. Click top right button and drag and drop any number of widgets and re-arrange them the way you like.</p>
                                </div>
                            </div>            
                            <div className="grid-stack" ref={(control) => this.gridStackRef = control} data-gs-width={this.getGridColumnCount()} data-gs-height={this.getGridRowCount()} style={{marginBottom: '10px'}}>
                                {this.state.widgets}                        
                            </div>        
                        </main>   
                    
                        {/* Modal: Add widget */}  
                        <NewWidgetModal 
                            widgets={this.props.widgets} 
                            initialise={this.initialiseWidgetsDragDrop} 
                            clearSelectedTask={this.clearSelectedTask}
                            dashboard={this.props.dashboard}
                            user={this.props.user} />         
                        
                        {/* Modal: Create task */}
                        <NewTaskModal 
                            dashboard={this.props.dashboard}
                            openSecondModal={this.openSecondModal} 
                            openEditWidgetModal={this.openEditWidgetModal} 
                            task={this.state.selectedTask || this.state.newTask} 
                            newTask={this.state.selectedTask ? false : true}
                            removeModifierIncludeField={this.removeModifierIncludeField}
                            createModifier={this.createModifier}
                            updateModifier={this.updateModifier}
                            deleteModifier={this.deleteModifier}
                            saveTask={this.saveTask}
                            resolvedTasks={this.state.resolvedTasks}
                            getTempID={this.getTempID} />               
                        
                        {/*Modal: Select data*/}
                        <SelectDataModal 
                            inputType={this.state.dataInputType} 
                            dashboard={this.props.dashboard}
                            playerID={this.getPlayerID()}
                            users={this.props.users}
                            widgets={this.props.widgets}
                            task={this.state.selectedTask || this.state.newTask}
                            //players={this.getPlayers()}                            
                            updateTask={this.updateTask}
                            tasks={this.state.tasks}
                            dashboardData={this.state.data}
                            selectedModifier={this.state.selectedModifier}
                            resolvedTasks={this.state.resolvedTasks}
                            getTempID={this.getTempID} />    

                        {/*Modal: manual input*/}
                        <ManualInputModal 
                            inputType={this.state.dataInputType} 
                            dashboard={this.props.dashboard}
                            task={this.state.selectedTask || this.state.newTask}  
                            updateTask={this.updateTask}
                            tasks={this.state.tasks}
                            selectedModifier={this.state.selectedModifier}
                            getTempID={this.getTempID} />            
                        
                        {/*Modal: Edit widget */}
                        <EditWidgetModal 
                            tasks={this.props.dashboard ? this.props.dashboard.Tasks : []}
                            dashboard={this.props.dashboard}
                            widgets={this.props.widgets}
                            onDataChange={this.onDataChange}
                            resolvedTasks={this.state.resolvedTasks}
                            widgetInstance={this.state.selectedWidgetInstance}
                            widgetInstanceParent={this.state.selectedWidgetInstanceParent}
                            widget={this.state.selectedWidget}
                            saveWidget={this.saveWidget} />

                        <TasksModal
                            tasks={this.props.dashboard ? this.props.dashboard.Tasks : []}
                            deleteTask={this.deleteTask}
                            editTask={this.editTask}
                            clearSelectedTask={this.clearSelectedTask}
                            dashboard={this.props.dashboard} />
                        
                        <ConfigureWidgetModal
                            user={this.props.user}                            
                            dashboard={this.props.dashboard}
                            dashboards={this.props.dashboards}
                            clients={this.props.clients}
                            history={this.props.history} />

                    </React.Fragment>
                    : null
                }
            </div>
        );
    }
}

WidgetSettings.propTypes = {
    actions: PropTypes.object.isRequired,
    dashboard: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const dashboardID = ownProps.match.params.id;
    const dashboard = state.dashboard.dashboards.filter(d => d.Template && d.Template.ID).find(d => d.ID === dashboardID);    
    return { 
        dashboard, 
        dashboards: state.dashboard.dashboards,
        loading: state.dashboard.dashboardsLoading || state.dashboard.dashboardItemsLoading.includes(dashboardID) || state.user.usersLoading, 
        users: state.user.users,
        user: state.account.user,
        widgets: state.dashboard.widgets,
        clients: state.client.clients
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //dashboard actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, dashboardActions, userActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(WidgetSettings));