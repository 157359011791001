import React from 'react';

class Modal extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.modalEventHandlerSet = false;
        this.setModalEventHandler = this.setModalEventHandler.bind(this);
    }

    componentDidMount() {
        this.setModalEventHandler();    
    }
   
    componentDidUpdate(prevProps) {
        if(!this.props.children) {
            //we'll render without the modal so need to hook this event up again for the next render
            this.modalEventHandlerSet = false;
        }
        if(!this.modalEventHandlerSet && this.props.children) {
            this.setModalEventHandler();
        }
        if (this.props.children && !prevProps.children) {
            window.$("#modal").modal('show');
        }
        /*if(this.props.className) {
            window.$('#modal').addClass(this.props.className);
        }*/
    }

    setModalEventHandler() {
        const modal = window.$("#modal");
        if(modal.length && this.props.hideModal) {
            modal.on('hidden.bs.modal', () => this.props.hideModal()); //this updates the state to show the modal is hidden
            this.modalEventHandlerSet = true;
        }
    }
  
    render() {
        return (
            this.props.children ?
                <div className={`modal fade ${this.props.className || ''}`} tabIndex="-1" role="dialog" id="modal">
                    {this.props.children}
                </div> 
                : null            
        );
      
    }
  }

export default Modal;