import React from 'react';
import * as imageUtils from '../../utils/imageUtils';

class AddUserModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
        this.selectUser = this.selectUser.bind(this);
        this.getUserClass = this.getUserClass.bind(this);
        this.getUserImage = this.getUserImage.bind(this);
        this.addUser = this.addUser.bind(this);
        this.state = 
        { 
            selectedUser: null
        };
    }

    componentDidMount() {    
        const tooltipControls = window.$(this.tooltipControls);
        if (tooltipControls.length !== 0 ) {
            tooltipControls.tooltip();
            tooltipControls.on('click', function(e){
                e.preventDefault();
            });
        }  
    }

    getUserClass(user) {
        return this.state.selectedUser && this.state.selectedUser.ID === user.ID ? 'active' : null;
    }

    getUserImage(user) {
        const image = user.Avatar;
        const imageData = image ? imageUtils.getAvatarBase64Url(image) : require('../../images/avatar-empty.png');
        return imageData;
    }

    selectUser(event, user) {
        event.preventDefault();
        this.setState({
            selectedUser: user
        });
    }

    addUser(event) {
        event.preventDefault();
        if(this.state.selectedUser) {
            //this.props.actions.addUser(this.props.dashboardID, this.props.playerID, this.state.selectedUser.ID);
            this.props.addUser(this.state.selectedUser.ID);
            window.$('#modal').modal('hide');
        }
    }

    onChange(event) {
        const field = event.target.name;
        let newState = Object.assign({}, this.state);
        let value = event.target.value;
        newState[field] = value;
        return this.setState(newState);   
    }

    render() {
        const {users} = this.props;
        return (     
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form className="form" onSubmit={this.addUser}>
                        <div className="modal-header">
                            <h5 className="modal-title">Add user to {this.props.subject}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal__inner modal__inner--wide">
                                <div className="form-group">
                                    <label>Select user</label>
                                    <div className="list-users scrollbar">
                                    {
                                        users.length ?
                                            users.map(user =>
                                                <a key={user.ID} href={null} className={`user ${this.getUserClass(user)}`} onClick={(e) => this.selectUser(e, user)}>
                                                    <div className="avatar">
                                                        <img src={this.getUserImage(user)} alt="Avatar"/>
                                                    </div>
                                                    <div className="user__name">
                                                        <p className="user__title">{`${user.FirstName} ${user.LastName}`}</p>
                                                        <p className="small grey">{user.Email}</p>
                                                    </div>
                                                </a>
                                            )
                                        :
                                            <p /> /*Add some default text here*/
                                    }                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer modal-footer--center">
                            <button className="btn btn--default btn--halfwide" data-dismiss="modal">Cancel</button>
                            <input type="submit" className="btn btn--primary btn--halfwide" value="Add User" />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default AddUserModal;


