import * as types from './actionTypes';
import PlaylistService from '../services/playlistService';
import ToastrService from '../services/toastrService';
import { push } from 'react-router-redux';

export function getPlaylists(){
    return function(dispatch, getState) {
        const state = getState();
        if(state.playlist.playlists.length === 0) {
            dispatch(setPlaylistsLoading(true));
            return PlaylistService.getPlaylists().then(playlists => {
                dispatch({ type: types.GET_PLAYLISTS_SUCCESS, playlists });
                dispatch(setPlaylistsLoading(false));
            }).catch(error => {
                dispatch(setPlaylistsLoading(false));
                throw(error);
            });
        }
    };
}

export function setPlaylistsLoading(loading) {
    return { type: types.PLAYLISTS_LOADING, loading };
}

export function createPlaylist(name, clientID, thumbnailData) {
    return function(dispatch) {
        const toastrService = new ToastrService();
        dispatch({ type: types.CLEAR_CREATE_UPDATE_PLAYLIST_ERROR })
        return PlaylistService.createPlaylist(name, clientID, thumbnailData).then(playlist => {
            dispatch({ type: types.PLAYLIST_CREATED, playlist });
            dispatch(push('/Playlists'));
            toastrService.showSuccess('', 'Playlist has been created successfully!', null)
        }).catch(error => {
            dispatch({ type: types.SET_CREATE_UPDATE_PLAYLIST_ERROR, error: error })
        });
    };
}

export function updatePlaylist(id, name, thumbnailData) {    
    return function(dispatch) {
        dispatch({ type: types.CLEAR_CREATE_UPDATE_PLAYLIST_ERROR })
        const toastrService = new ToastrService();
        return PlaylistService.updatePlaylist(id, name, thumbnailData).then(playlist => {
            debugger;
            dispatch({ type: types.PLAYLIST_UPDATED, playlist });
            dispatch(push('/Playlists'));
            toastrService.showSuccess('', 'Playlist has been updated successfully!', null);
        }).catch(error => {
            dispatch({ type: types.SET_CREATE_UPDATE_PLAYLIST_ERROR, error: error })
         });
    };
}

export function deletePlaylist(id) {
    return function(dispatch) {
        return PlaylistService.deletePlaylist(id).then(data => {
            dispatch({ type: types.PLAYLIST_DELETED, id });
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'Playlist has been deleted successfully!', null)
        }).catch(error => {
            throw(error);
        });
    };
}

export function setCreateUpdatePlaylistError(error) {
    //allow the component to set an error directly and wrap it in an error object
    return { type: types.SET_CREATE_UPDATE_PLAYLIST_ERROR, error: new Error(error) };
}

export function clearCreateUpdatePlaylistError() {
    return { type: types.CLEAR_CREATE_UPDATE_PLAYLIST_ERROR };
}

export function addPlaylistItem(playlistID, dashboardID, playerID, seconds, useSpecifiedDuration, waitUntilAnimationCompletes, order){
    return function(dispatch, getState) {
        return PlaylistService.addPlaylistItem(playlistID, dashboardID, playerID, seconds, useSpecifiedDuration, waitUntilAnimationCompletes, order).then(playlist => {
            dispatch({ type: types.PLAYLIST_UPDATED, playlist });
        }).catch(error => {
            throw(error);
        });
    };
}

export function updatePlaylistItem(playlistID, itemID, dashboardID, playerID, seconds, useSpecifiedDuration, waitUntilAnimationCompletes, order){
    return function(dispatch, getState) {
        return PlaylistService.updatePlaylistItem(playlistID, itemID, dashboardID, playerID, seconds, useSpecifiedDuration, waitUntilAnimationCompletes, order).then(playlist => {
            dispatch({ type: types.PLAYLIST_UPDATED, playlist });
        }).catch(error => {
            throw(error);
        });
    };
}

export function deletePlaylistItem(playlistID, itemID){
    return function(dispatch, getState) {
        return PlaylistService.deletePlaylistItem(playlistID, itemID).then(playlist => {
            debugger;
            dispatch({ type: types.PLAYLIST_UPDATED, playlist });
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'Item has been deleted successfully!', null);
        }).catch(error => {
            throw(error);
        });
    };
}

export function deletePlaylistItems(playlistID, itemIDs){
    debugger;
    return function(dispatch, getState) {
        let p = Promise.resolve();
        if(itemIDs.length) {
            for(let i = 0; i < itemIDs.length; i++) {
                p = p.then(_ => 
                    PlaylistService.deletePlaylistItem(playlistID, itemIDs[i]).then(playlist => {
                        debugger;
                        dispatch({ type: types.PLAYLIST_UPDATED, playlist });
                    })
                    .catch(error => {
                        throw(error);
                    })
                );
            }
            p.then(_ => {
                debugger;
                const toastrService = new ToastrService();
                toastrService.showSuccess('', `Item${itemIDs.length > 1 ? 's have' : ' has'} been deleted successfully!`, null)
            });
        }
        return p;
        
    };
}

export function addUser(playlistID, userID){
    return function(dispatch, getState) {
        return PlaylistService.addUser(playlistID, userID).then(playlist => {
            dispatch({ type: types.PLAYLIST_UPDATED, playlist });
        }).catch(error => {
            throw(error);
        });
    };
}

export function removeUser(playlistID, userID){
    return function(dispatch, getState) {
        return PlaylistService.deleteUser(playlistID, userID).then(playlist => {
            debugger;
            dispatch({ type: types.PLAYLIST_UPDATED, playlist });
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'User has been removed successfully!', null)
        }).catch(error => {
            throw(error);
        });
    };
}

export function removeUsers(playlistID, userIDs){
    debugger;
    return function(dispatch, getState) {
        let p = Promise.resolve();
        if(userIDs.length) {
            for(let i = 0; i < userIDs.length; i++) {
                p = p.then(_ => 
                    PlaylistService.deleteUser(playlistID, userIDs[i]).then(playlist => {
                        debugger;
                        dispatch({ type: types.PLAYLIST_UPDATED, playlist });
                    })
                    .catch(error => {
                        throw(error);
                    })
                );
            }
            p.then(_ => {
                debugger;
                const toastrService = new ToastrService();
                toastrService.showSuccess('', `User${userIDs.length > 1 ? 's have' : ' has'} been removed successfully!`, null)
            });
        }
        return p;
        
    };
}