/* eslint-disable react/jsx-no-bind */
import React, {Component} from 'react';

class Track extends Component {
  
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.label !== this.props.label;
    }

    render() {
        return (
            <div className="track">
                {
                    this.props.label ? <p className="track__label">{this.props.label}</p> : null
                }
                <div className="track__start">
                    <img src={require('./images/track-start-horses.png')} alt="Track start"/>
                </div>
                <div className="track__line" style={{ backgroundImage: `url(${require('./images/track-bottom.png')})`}}></div>
                <div className="track__grass"></div>
                <div className="track__finish"></div>
            </div>
        );
    }
  }

export default Track;