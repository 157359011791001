import {combineReducers} from 'redux';
import { routerReducer } from 'react-router-redux';
import account from './accountReducer';
import dashboard from './dashboardReducer';
import playlist from './playlistReducer';
import template from './templateReducer';
import user from './userReducer';
import client from './clientReducer';

const rootReducer = combineReducers({
    account,
    dashboard,
    playlist,
    template,
    user,
    client,
    routing: routerReducer
});

export default rootReducer;
