import React from 'react';
import ReactDOM from 'react-dom';
import './styles/bootstrap.min.css';
import '../node_modules/toastr/build/toastr.min.css';
import '../node_modules/react-dragula/dist/dragula.min.css';
import './styles/style.css';
import * as serviceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import {loadState} from './utils/localStorage';
import initialState from './reducers/initialState';
import BaseService from './services/baseService';
import * as types from './actions/actionTypes';
import * as urlUtils from './utils/urlUtils';

import jQuery from 'jquery';
import * as RoutesModule from './routes';
import tether from 'tether';
window.jQuery = jQuery;
window.$ = jQuery;
global.Tether = tether;

require('bootstrap');

let routes = RoutesModule.routes;
// Create browser history to use in the Redux store
const history = createBrowserHistory();

const persistedState = loadState();
const updatedState = Object.assign({}, initialState, persistedState);
const store = configureStore(history, updatedState);

if(updatedState.account.token) {
    BaseService.setToken(updatedState.account.token);
    store.dispatch({ type: types.SCHEDULE_TOKEN_REFRESH, payload: { tokenExpiry: updatedState.account.tokenExpiry, refreshToken: updatedState.account.refreshToken} });
}
/*store.subscribe(_.throttle(() => {
    const account = store.getState().account;
    saveState({
        account: {
            token: account.token,
            tokenExpiry: account.tokenExpiry,
            refreshToken: account.refreshToken,
            user: account.user
        } 
    });
}, 1000));*/

//const store = configureStore(history);

history.listen(function (location) {
    setBackground(location);
    setDashboardClass(location);
 });

setBackground(window.location);
setDashboardClass(window.location);

function setBackground(location) {
    /*if(location.pathname !== '/Account/Login' && location.pathname !== '/Account/ForgotPassword' && location.pathname !== '/Account/ResetPassword') {
        window.$('body').removeClass('greybg');
    }
    else {
        window.$('body').addClass('greybg');
    }*/
}

function setDashboardClass(location) {
    if(urlUtils.isDashboard(location.pathname)) {
        window.$('body').addClass('racepage');
    }
    else {
        window.$('body').removeClass('racepage');
    }
    
    /*if(location.pathname === '/Dashboards/Custom' || location.pathname.indexOf('WidgetSettings') > -1) {
        window.$('body').addClass('dashbg');
    }*/
}

setBodyClickEvents();

function setBodyClickEvents() {
    window.$('body').on('click', function(e){
        if (window.$(e.target).parents('.side').length === 0 && !window.$(e.target).hasClass('btn-mobilemenu')) {
            window.$('body').removeClass('showside');
        }
        if (!window.$(e.target).hasClass('btn-new') && window.$('.btn-new').hasClass('open')) {
            window.$('.new').removeClass('show');
            window.$('.btn-new').removeClass('open');
        }
    });
}

function renderApp() {
    // This code starts up the React app when it runs in a browser. It sets up the routing configuration
    // and injects the app into a DOM element.
    ReactDOM.render(
        <Provider store={ store }>
            <ConnectedRouter history={ history } children={ routes } />
        </Provider>,
        document.getElementById('root')
    );
}

renderApp();
serviceWorker.unregister();
//registerServiceWorker();
