import React from 'react';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import * as imageUtils from '../../utils/imageUtils';

class UserAccessItem extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getImage = this.getImage.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    componentDidMount() {    
        
    }

    getImage() {
        const image = this.props.avatar;
        const imageData = image ? imageUtils.getAvatarBase64Url(image) : require('../../images/avatar-empty.png');
        return imageData;
    }

    deleteUser(event) {
        event.preventDefault();
        const deleteAction = () => this.props.removeUser(this.props.id);
        const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to remove user ${this.props.name} from this ${this.props.subject}?`} />
        this.props.showModal(() => deleteConfirmationModal, { isOpen: true })
    }

    render() {
        return (   
            <div className={`useritem ${this.props.selected ? 'useritem--selected' : ''}`}>
                <div className="useritem__drag">
                    <i className="aheadicon-drag"></i>
                </div>
                <div className="useritem__check">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" name="selected" className="custom-control-input" id={`user${this.props.index}`} checked={this.props.selected} onChange={(e) => this.props.toggleSelectedUser(e, this.props.id)} />
                        <label className="custom-control-label" htmlFor={`user${this.props.index}`}>&nbsp;</label>
                    </div>
                </div>
                <div className="useritem__pic">
                  <div className="avatar">
                        <img src={this.getImage()} alt={this.props.name}/>
                  </div>
                </div>
                <div className="useritem__title">
                    <h5>{this.props.name}</h5>
                    <p className="useritem__email">{this.props.email}</p>
                </div>
                <div className="useritem__actions">
                    <a href={null} className="btn-action btn-delete" onClick={this.deleteUser}><i className="aheadicon-delete"></i></a>
                </div>
            </div>
        );
    }
}

export default UserAccessItem;