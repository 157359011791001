
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../../utils/imageUtils';
import * as widgetUtils from '../../../utils/widgetUtils';
import * as widgetConstants from '../../../constants/widgetConstants';
import _ from 'lodash';
import ToastrService from '../../../services/toastrService';

class SelectDataModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.primaryOptionSelected = this.primaryOptionSelected.bind(this);
        this.getSelectionText = this.getSelectionText.bind(this);
        this.selectData = this.selectData.bind(this);
        this.getVariableValue = this.getVariableValue.bind(this);
        this.getProperties = this.getProperties.bind(this);
        this.getSecondaryProperties = this.getSecondaryProperties.bind(this);
        this.getDefaultDatasetID = this.getDefaultDatasetID.bind(this);
        this.state = {
            selectedOptions: [],
            selectedOptionsSecondary: [],
            selectedDatasetID: this.getDefaultDatasetID()
        };
        
    }

    componentWillMount() {
    }

    componentDidMount() { 
               
    } 
    
    componentWillUpdate(nextProps, nextState) {
        if(this.props.inputType !== nextProps.inputType || this.props.task !== nextProps.task || this.props.selectedModifier !== nextProps.selectedModifier) {
            let selectedOptions = nextProps.selectedModifier ? this.getProperties(nextProps.selectedModifier) : [];
            let selectedOptionsSecondary = nextProps.inputType === 'OutputValue' ? this.getSecondaryProperties(nextProps.selectedModifier) : [];
            let selectedDatasetID = this.getDefaultDatasetID(nextProps);
            this.setState({
                selectedOptions,
                selectedOptionsSecondary,
                selectedTask: nextProps.task,
                selectedModifier: nextProps.selectedModifier,
                selectedDatasetID
            })
        }
        else if(this.state.selectedDatasetID !== nextState.selectedDatasetID) {
            //if we're on the original dataset, default to the original properties
            const selectedOptions = nextProps.selectedModifier && nextProps.selectedModifier.DatasetID === nextState.selectedDatasetID ? this.getProperties(nextProps.selectedModifier) : [];
            this.setState({
                selectedOptions,
                selectedOptionsSecondary: []
            })
        }
    }

    getProperties(task) {
        if(task.Type === 'ExistingTask') {
            if(task.TaskID) {
                const existingTask = this.props.dashboard.Tasks.find(t => t.ID === task.TaskID);
                if(existingTask) {
                    return [existingTask.Name];
                }
            }
            return [];
        }
        else if(task.Type === 'OutputValue') {
            const widgetInstanceID = task.WidgetHierarchy[task.WidgetHierarchy.length - 1];            
            if(widgetInstanceID) {
                const existingWidget = widgetUtils.findWidgetInstance(widgetInstanceID, this.props.dashboard.Widgets, this.props.widgets);
                if(existingWidget) {
                    return [existingWidget.widgetHierarchy.join('_')];
                }
            }            
            return [];
        }
        else {
            return task.IncludeFields || [];
        }
    }

    getSecondaryProperties(task) {
        if(task && task.Type === 'OutputValue' && task.OutputFieldName) {            
            return [task.OutputFieldName];
        }
        else {
            return [];
        }
    }

    getDefaultDatasetID(props = this.props) {
        if(props.inputType === 'DataSource') {
            if(props.selectedModifier) {
                //default it from the modifier
                return props.selectedModifier.DatasetID || props.dashboard.DatasetIDs[0];
            }
            else {
                return props.dashboard.DatasetIDs[0];
            }
        }
    }

    getVariableValue(inputType) {
        let variableValues;
        if(inputType === 'Dashboard') {
            variableValues = widgetUtils.getDashboardVariableValues(this.props.dashboard);
        }
        else if(inputType === 'Player') {
            variableValues = widgetUtils.getPlayerVariableValues(this.props.dashboard, this.props.playerID, this.props.users);
        }
        else if(inputType === 'DataSource') {
            let dashboardData = this.props.dashboardData;
            let selectedDatasetID;
            if(!this.props.dashboard.RuleEngineID && dashboardData) {
                //we will have multiple datasets in the data, find the correct one
                selectedDatasetID = this.state.selectedDatasetID;
            } 
            variableValues = widgetUtils.getDataSourceVariableValues(dashboardData, null, this.props.dashboard, selectedDatasetID);
        }
        return variableValues;
    }

    getSelectionOptions() {
        const options = [];
        const inputType = this.props.inputType;
        if(inputType === "Player" && this.props.dashboard) {
            let player;
            const variableValue = this.getVariableValue(inputType);
            if(this.props.dashboard.PlayerMode === 'Single') {
                player = variableValue.currentValue;
            }
            else {
                player = variableValue.currentValue && variableValue.currentValue.length ? variableValue.currentValue[0] : null;
            }
            if(player) {
                Object.keys(player).forEach(item => options.push(item));
            };
        }
        else if(inputType === "Dashboard") {
            const variableValue = this.getVariableValue(inputType);
            Object.keys(variableValue.currentValue).forEach(item => options.push(item));
        }
        else if(inputType === "ExistingTask") {
            this.props.dashboard.Tasks.forEach(task => options.push(task.Name));
        }
        else if(inputType === 'DataSource') {
            const variableValue = this.getVariableValue(inputType);
            if(variableValue && variableValue.currentValue) {
                let data;
                if(Array.isArray(variableValue.currentValue)) {
                    if(variableValue.currentValue.length) {
                        data = variableValue.currentValue[0];
                    }
                } 
                else {
                    data = variableValue.currentValue;
                }
                if(data) {
                    Object.keys(data).forEach(item => options.push(item));
                }
            }            
        }
        else {
            if(this.props.dashboard) {
                const dashboardWidgets = this.props.dashboard.Widgets;
                const widgets = this.props.widgets;
                dashboardWidgets.forEach(dashboardWidget => {
                    //we don't just want the name, if it's a nested widget, then we want the children - full hierarchy with a dot separator
                    options.push(dashboardWidget.Name);
                    //we'll need this on the 2nd selection level
                    //const widget = widgets.find(w => w.ID === dashboardWidget.WidgetID);
                    //if(widget) {

                    //}
                })
            }
        }
        return options;
    }

    getTabTitle() {
        const inputType = this.props.inputType;
        if(inputType === 'Player') {
            return 'Player data';
        }
        else if(inputType === 'Dashboard') {
            return 'Dashboard data';
        }
        else if(inputType === 'ExistingTask') {
            return 'Existing Task';
        }
        else if(inputType === 'ManualInput') {
            return 'Manual Input';
        }
        else if(inputType === 'OutputValue') {
            return 'Existing Widgets';
        }
        else if(inputType === 'DataSource') {
            return 'Imported Data';
        }
    }

    primaryOptionSelected(option) {
        let selectedOptions;
        if(this.props.inputType === 'ExistingTask' || this.props.inputType === 'OutputValue') {
            //we can only have 1 selected option
            selectedOptions = [option];
        }
        else {
            selectedOptions = [...this.state.selectedOptions];
            if(selectedOptions.includes(option)) {
                _.pull(selectedOptions, option);
            }
            else {
                selectedOptions.push(option);
            }
        }
        this.setState({ selectedOptions, selectedOptionsSecondary: [] })        
    }

    secondaryOptionSelected(option) {
        let selectedOptionsSecondary = [option];        
        this.setState({ selectedOptionsSecondary })        
    }

    getSelectionText() {
        const inputType = this.props.inputType;
        if(inputType === 'Player' || inputType === 'Dashboard' || inputType === 'DataSource') {
            return 'Select Property';
        }
        else if (inputType === 'ExistingTask'){
            return 'Select Task';
        }
        else if(inputType === 'OutputValue'){
            return 'Select Widget';
        }
        else {
            return '';
        }
    }    

    selectData(event) {
        event.preventDefault();
        const inputType = this.props.inputType;
        if(this.state.selectedOptions.length && (inputType !== 'OutputValue' || this.state.selectedOptionsSecondary.length)) {
            let outputType;
            let singlePlayer = this.props.dashboard && this.props.dashboard.PlayerMode === 'Single';
            if(inputType === 'Dashboard' || (inputType === 'Player' && singlePlayer) || inputType === 'DataSource') {
                outputType = inputType === 'DataSource' && Array.isArray(this.props.dashboardData) ? 'Array' : 'Object';
                if(this.state.selectedOptions.length > 1) {
                    //modifierOutputType = 'Object';
                }
                else {
                    //const input = inputType === 'Dashboard' ? this.props.variables.Dashboard : this.props.variables.Player;
                    //const fieldName = this.state.selectedOptions[0];
                    //const value = input.currentValue[fieldName];
                    //modifierOutputType = widgetUtils.getObjectType(value);
                }
            }
            else if(inputType === 'Player' && !singlePlayer) {
                outputType = 'Array';
            }
            else if(inputType === 'OutputValue') {
                const dashboardWidget = this.props.dashboard.Widgets.find(w => w.Name === this.state.selectedOptions[0]);
                if(dashboardWidget) {
                    const widget = this.props.widgets.find(w => w.ID === dashboardWidget.WidgetID);
                    if(widget) {
                        const output = widget.Outputs.find(o => o.Name === this.state.selectedOptionsSecondary[0]);
                        if(output) {
                            outputType = output.Type;
                        }
                    }
                }
            }
            let task = {...this.props.task};
            task.Variables = [...task.Variables];
            if(!this.props.selectedModifier) {
                //create the variable for the data input
                const variableID = this.props.getTempID(inputType);
                const variable = { ID: variableID, Name: variableID, Type: inputType };
                if(inputType === 'ExistingTask') {
                    const existingTask = this.props.dashboard.Tasks.find(t => t.Name === this.state.selectedOptions[0]);
                    const preferredName = `ExistingTask - ${existingTask.Name}`;
                    if(task.Variables.find(v => v.Name === preferredName) == null) {
                        variable.Name = preferredName;
                    }
                    variable.TaskID = existingTask.ID;
                    outputType = existingTask.Variables.length ? existingTask.Variables[existingTask.Variables.length - 1].OutputType : null;
                    //if the existing task name is unique in the current task, update the task name to the existing task name
                }
                else if(inputType === 'OutputValue') {
                    const dashboardWidget = this.props.dashboard.Widgets.find(w => w.Name === this.state.selectedOptions[0]);
                    if(dashboardWidget) {
                        variable.WidgetHierarchy = [dashboardWidget.ID];
                        variable.OutputFieldName = this.state.selectedOptionsSecondary[0];
                        const preferredName = `${this.state.selectedOptions[0]}_${this.state.selectedOptionsSecondary[0]}`;
                        if(task.Variables.find(v => v.Name === preferredName) == null) {
                            variable.Name = preferredName;
                        }
                    }
                }
                else if(inputType === 'UserDefined') {
                    //variable.Value = //whatever was entered
                }
                else if((inputType === 'Dashboard' || inputType === 'Player' || inputType === 'DataSource') && this.state.selectedOptions) {
                    variable.IncludeFields = this.state.selectedOptions;
                }
                variable.OutputType = outputType;
                if(inputType === 'DataSource') {
                    variable.DatasetID = this.state.selectedDatasetID;
                }
                task.Variables.push(variable);
            }
            else {
                //update the task
                const variableIndex = task.Variables.indexOf(this.props.selectedModifier);
                const variable = {...this.props.selectedModifier};
                if((inputType === 'Dashboard' || inputType === 'Player' || inputType === 'DataSource') && this.state.selectedOptions) {
                    variable.IncludeFields = this.state.selectedOptions;
                }
                else if(inputType === 'ExistingTask') {
                    const existingTask = this.props.dashboard.Tasks.find(t => t.Name === this.state.selectedOptions[0]);
                    const preferredName = `ExistingTask - ${existingTask.Name}`;
                    if(existingTask.Variables.find(v => v.Name === preferredName) == null) {
                        variable.Name = preferredName;
                    }
                    variable.TaskID = existingTask.ID;
                    outputType = existingTask.Variables.length ? existingTask.Variables[existingTask.Variables.length - 1].OutputType : null;
                    //if the existing task name is unique in the current task, update the task name to the existing task name
                }
                if(inputType === 'DataSource') {
                    variable.DatasetID = this.state.selectedDatasetID;
                }
                variable.OutputType = outputType;
                task.Variables.splice(variableIndex, 1, variable);
            }
            this.props.updateTask(task);
            this.setState({ selectedOptions: [], selectedTask: null, selectedModifier: null })
            window.$('#modal-newdata').modal('hide');
        }
        else {
            const toastrService = new ToastrService();
            let message = '';
            if(this.props.inputType === 'ExistingTask') {
                message = 'Please select a task';
            }
            else if(inputType === 'OutputValue') {
                if(!this.state.selectedOptions.length) {
                    message = 'Please select a widget';
                }
                else if(!this.state.selectedOptionsSecondary.length) {
                    message = 'Please select an output field';
                }
            }
            else {
                message = 'Please select some data properties';
            }
            toastrService.showError('', message);
        }
    }

    render() {
        const { inputType } = this.props;
        let outputValueSelectedWidget;
        if(inputType === 'OutputValue' && this.state.selectedOptions && this.state.selectedOptions.length === 1) {
            const selectedDashboardWidget = this.props.dashboard.Widgets.find(w => w.Name === this.state.selectedOptions[0]);
            if(selectedDashboardWidget) {
                outputValueSelectedWidget = this.props.widgets.find(w => w.ID ===  selectedDashboardWidget.WidgetID);
            }
        }       
        return (
            <div className="modal modal-side modal-side--newdata fade" tabIndex="-1" role="dialog" id="modal-newdata" data-backdrop="false" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Select data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        {/*Side tabs*/}
                        <ul className="nav nav-tabs nav-tabs--simple" id="dataTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="true">Data</a>
                            </li>
                        </ul>
                        <div className="modal-body scrollbar">                           
                        
                            <div className="mt-5"></div>
        
                            {/* Steps list */}
                        
                            <div className="tab-content mt-4" id="dataTabContent">                
                
                                {/* Tab 1: Data */}
                                <div className="tab-pane fade show active" id="data" role="tabpanel" aria-labelledby="data-datatab">     
                                    <div className="d-flex data-panes">
                                        <div className="data-panes__input">
                                            <div className="inputs">
                                                <a href="#" className="selected">{this.getTabTitle()}</a>
                                            </div>
                                        </div>
                                        <div className="data-panes__content">                      
                    
                                            {/* Attribute select */ }
                                            <div className="steps mb-5">                    
                                                {/* Step: dual */}
                                                <div className={`step__dual ${inputType === 'OutputValue' ? 'd-flex' : ''}`}>                                        
                                                    <div className="card card--step card--only">
                                                        {
                                                            inputType === 'DataSource' && !this.props.dashboard.RuleEngineID ?
                                                                /*we're using the raw datasets*/
                                                                <React.Fragment>
                                                                    <div className="card-header py-3">
                                                                        <p className="m-0 text-muted small">Select dataset:</p>                                                                        
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <select className="form-control" value={this.state.selectedDatasetID} onChange={(event) => this.setState({ selectedDatasetID: event.target.value })}>
                                                                            {
                                                                                this.props.dashboardData.map(dataset =>                                                                                 
                                                                                    <option key={dataset.ID} value={dataset.ID}>{dataset.Name}</option>
                                                                                )
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </React.Fragment>
                                                                : null
                                                        }
                                                        <div className="card-header py-3">
                                                            <p className="m-0 text-muted small">{this.getSelectionText()}</p>
                                                        </div>
                                                        <div className="card-body">                
                                                            <div className="list-select scrollbar">                    
                                                                {
                                                                    this.getSelectionOptions().map(option =>
                                                                        <div key={option} className={`list__item ${this.state.selectedOptions.includes(option) ? 'list__item--selected' : ''}`} onClick={() => this.primaryOptionSelected(option)}>
                                                                            <p className="list__name">{option}</p>
                                                                            <div className="checker">
                                                                                <i className="aheadicon-checkmark"></i>
                                                                            </div>
                                                                        </div> 
                                                                    )
                                                                }  
                                                            </div>                
                                                        </div>
                                                    </div>
                                                    {
                                                        inputType === 'OutputValue' ?
                                                            <div className="card card--step ml-2">
                                                                <div className="card-header py-3">
                                                                    <p className="m-0 text-muted small">Select Output Field</p>
                                                                </div>
                                                                <div className="card-body">                
                                                                    <div className="list-select scrollbar">   
                                                                    {
                                                                        outputValueSelectedWidget && outputValueSelectedWidget.Outputs.length ?
                                                                            outputValueSelectedWidget.Outputs.map(output =>
                                                                                <div key={output.Name} className={`list__item ${this.state.selectedOptionsSecondary.includes(output.Name) ? 'list__item--selected' : ''}`} onClick={() => this.secondaryOptionSelected(output.Name)}>
                                                                                    <p className="list__name">{output.Name}</p>
                                                                                    <div className="checker">
                                                                                        <i className="aheadicon-checkmark"></i>
                                                                                    </div>
                                                                                </div>     
                                                                            )
                                                                            : <span className="text-muted small">No output fields available</span>
                                                                    }                      
                                                                    </div>                
                                                                </div>
                                                            </div>
                                                        : null
                                                    }  
                                                </div>
                                            </div>
                
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-default" data-dismiss="modal">Cancel</a>
                            <a href="#" className="btn btn-primary" onClick={this.selectData}>Select data</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default SelectDataModal;