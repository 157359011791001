import React from 'react';
import { ModalConsumer } from './ModalContext';
import Modal from './Modal';

const ModalRoot = () => (
  <ModalConsumer>
    {({ component: Component, props, hideModal }) =>
      <Modal hideModal={hideModal}>{Component ? <Component {...props} onRequestClose={hideModal} /> : null}</Modal>
    }
  </ModalConsumer>
);

export default ModalRoot;