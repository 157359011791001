import * as types from './actionTypes';
//import { HubConnection, TransportType } from "@aspnet/signalr";
import DashboardService from '../services/dashboardService';
import ToastrService from '../services/toastrService';
import { push } from 'react-router-redux';

let currentConnection;
let disconnectPromise;

export function getDashboards(){
    return function(dispatch, getState) {
        const state = getState();
        let p = Promise.resolve();
        if(state.dashboard.dashboards.length === 0) {
            p = p.then(_ => {
                dispatch(setDashboardsLoading(true));
                return DashboardService.getDashboards().then(dashboards => {
                    dispatch({ type: types.GET_DASHBOARDS_SUCCESS, dashboards });
                    dispatch(setDashboardsLoading(false));
                    return dashboards;
                }).catch(error => {
                    dispatch(setDashboardsLoading(false));
                    throw(error);
                });
            });
        }
        return p;
    };
}

export function getDashboard(dashboardID, reloading = false){
    return function(dispatch, getState) {
        //try to get all dashboards 1st (this will check it's empty). we won't necessarily need it yet, but individual dashboards
        //with the full data should enrich the list. we don't want to later override the full data of 1
        return getDashboards()(dispatch, getState).then(dashboards => {
            if(!reloading) {
                dispatch({ type: types.DASHBOARD_LOADING, payload: { dashboardID, loading : true }});
            }
            return DashboardService.getDashboard(dashboardID).then(dashboard => {
                dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
                if(!reloading) {
                    dispatch({ type: types.DASHBOARD_LOADING, payload: { dashboardID, loading : false }});
                }
                return dashboard;
            });
        }).catch(error => {
            dispatch({ type: types.DASHBOARD_LOADING, payload: { dashboardID, loading : false }});
            throw(error);
        });
    };
}

export function getDashboardList(dashboardIDs){
    //debugger;
    return function(dispatch, getState) {
        return getDashboards()(dispatch, getState).then(dashboards => {     
            let p = Promise.resolve();
            if(dashboardIDs.length) {
                for(let i = 0; i < dashboardIDs.length; i++) {
                    p = p.then(_ => {
                        const dashboardID = dashboardIDs[i];
                        dispatch({ type: types.DASHBOARD_LOADING, payload: { dashboardID, loading : true }});
                        return DashboardService.getDashboard(dashboardID).then(dashboard => {
                            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
                            dispatch({ type: types.DASHBOARD_LOADING, payload: { dashboardID, loading : false }});
                            return dashboard;
                        });
                    });
                }
            }
            return p;
        });
    };
}

export function checkDashboardVersion(dashboardID){
    return function(dispatch, getState) {
        const state = getState();
        const existingDashboard = state.dashboard.dashboards.find(d => d.ID === dashboardID);
        if(existingDashboard) {
            const existingDashboardVersion = existingDashboard.Version;
            return DashboardService.getDashboardVersion(dashboardID).then(version => {
                if(version > existingDashboardVersion) {
                    dispatch(getDashboard(dashboardID, true));
                }
            }).catch(error => {
                
            });
        }
    };
}

export function updateDashboardData(dashboardID, playerID, data) {
    return function(dispatch, getState) {
        const dashboardData = { id: dashboardID, playerID, data }
        dispatch({ type: types.DASHBOARD_DATA_RECEIVED, dashboardData });
    }
}

export function setDashboardsLoading(loading) {
    return { type: types.DASHBOARDS_LOADING, loading };
}

export function setCurrentDashboardLoaded(dashboardID) {
    return { type: types.CURRENT_DASHBOARD_LOADED, id: dashboardID };
}

export function connectToHub(dashboardID, playlistID, playerID){
    return function(dispatch, getState) {
        try {
            if(currentConnection) {
                console.log('We have a current connection');
                const connectionState = currentConnection.connection.connectionState;
                console.log(`Connection state is ${connectionState}`)
                if(connectionState === 1) { //state 1 is connected 
                    disconnectFromHub();
                }
            }
        }
        catch(err){ }
        //incase we're disconnecting, wait for it
        let promise = disconnectPromise ? disconnectPromise : Promise.resolve();
        promise.then(_ => {
            console.log('Connecting to hub');
            
            //if we currently have a connection active, we should disconnect
            console.log('Getting a hub connection');
            currentConnection = DashboardService.getHubConnection(dashboardID, playlistID, playerID);
            console.log('Got a hub connection');
            //connection.hub.qs = `id=${dashboardID}`
            currentConnection.on('send', data => {
                //debugger;
                const dashboardData = { id: dashboardID, playerID, data: data }
            dispatch({ type: types.DASHBOARD_DATA_RECEIVED, dashboardData });
                console.log(`Data received for dashboard ${dashboardID}: ${data}`);
            });    
            currentConnection.on('DashboardDataUpdated', data => {
                const dashboardData = { id: dashboardID, playerID, data: data }
                dispatch({ type: types.DASHBOARD_DATA_RECEIVED, dashboardData });
                console.log('DataUpdated: ' + data);
            });   
            currentConnection.on('DashboardDataDatasetUpdated', dataset => {
                if(dataset) {
                    const state = getState();
                    const dashboardData = state.dashboard.currentDashboard;
                    if(dashboardData) { 
                        const datasetIndex = dashboardData.data.findIndex(d => d.ID === dataset.ID);
                        if(datasetIndex > -1) {
                            //we have this dataset present, so update it
                            const updatedDashboardData = {...dashboardData};
                            const updatedData = [...updatedDashboardData.data];
                            const updatedDataset = {...updatedData[datasetIndex]};
                            updatedDataset.Data = dataset.Data;
                            updatedData.splice(datasetIndex, 1, updatedDataset);
                            updatedDashboardData.data = updatedData;
                            dispatch({ type: types.DASHBOARD_DATA_RECEIVED, dashboardData: updatedDashboardData });
                            console.log('DatasetUpdated: ' + dataset);
                        }
                    }
                }
            }); 
            currentConnection.on('DashboardUpdated', data => {
                //debugger;
                const dashboardID = data.ID;
                const dashboardVersion = data.Version;
                const state = getState();
                const existingDashboard = state.dashboard.dashboards.find(d => d.ID === dashboardID);
                if(existingDashboard) {
                    const existingDashboardVersion = existingDashboard.Version;
                    if(dashboardVersion > existingDashboardVersion) {
                        dispatch(getDashboard(dashboardID));
                    }
                }
                console.log('DashboardUpdated: ' + data);
            });   
            currentConnection.on('DashboardDisconnect', data => {
                //debugger;
                //the redirect here will send a disconnection message as we unmount the dashboard
                dispatch(push('/'));
                const toastrService = new ToastrService();
                if(data === 'Unauthorised') {
                    toastrService.showInfo('Unauthorised', 'You don\'t have access to this dashboard, please contact your administrator');
                }
                else if(data === 'NewSession') {
                    toastrService.showInfo('Dashboard disconnected', 'You have connected in another location');
                }
                console.log(`Dashboard ${dashboardID} has been disconnected`);
            })
            currentConnection.onclose(error => {
                //debugger;
                console.log('connection closed');
                if(error) {
                    if(DashboardService.connectionStopped) {
                        console.log('Connection was stopped');
                    }
                    else {
                        console.log('Connection closed in error, try reconnecting');
                        reconnect(currentConnection, dispatch, getState);
                    }                
                }
            }) 
            startConnection(currentConnection, dispatch, getState);
        });
    };
}

function startConnection(connection, dispatch, getState) {
    DashboardService.startConnection(connection)
    .catch(err => {
        //debugger;
        let unauthorised = false;
        if(err && err.statusCode && err.statusCode === 401) {
            unauthorised = true;
        }
        console.log(`${new Date()}: Error while establishing connection`);
        if(!unauthorised && !DashboardService.connectionStopped) {
            setTimeout(() => reconnect(connection, dispatch, getState), 2000);
        }
        else if(unauthorised) {
            //debugger;
            const state = getState();
            //check if we have a token but it's just expired, if so, refresh and then try reconnecting
            const token = state.account.token;
            const tokenExpiry = state.account.tokenExpiry;
            if(token && new Date(tokenExpiry) < new Date()) {                
                console.log('Token has expired, triggering refresh');
                dispatch({ type: types.SCHEDULE_TOKEN_REFRESH });
                setTimeout(() => reconnect(connection, dispatch, getState), 5000);
            }
            else {
                console.log('Unauthorised');
            }
        }
    });
}

function reconnect(connection, dispatch, getState) {
    if(!DashboardService.connectionStopped && connection === currentConnection) {
        //DashboardService.connectionStopped = false;
        console.log(`${new Date()}: Reconnecting...`);
        if(DashboardService.getAccessToken()) {
            setTimeout(() => startConnection(connection, dispatch, getState), 2000);
        }
        else {
            //debugger;
            console.log(`${new Date()}: Unable to reconnect, the login session has expired`);
        }
    }
    else if(connection !== currentConnection) {
        console.log(`${new Date()}: Abandoning reconnect, the connection has changed`)
    }
}
        
export function disconnectFromHub(){
    return function(dispatch) {
        if(currentConnection) {
            console.log(`${new Date()}: Disconnecting from hub`);
            disconnectPromise = DashboardService.stopConnection(currentConnection)
                .then(_ => {
                    console.log(`${new Date()}: Completed disconnect`)
                    return Promise.resolve();
                });        
        }     
    };
}

export function addPlayer(dashboardID, userID, clientUserID, label, order, avatarUserEditable){
    return function(dispatch, getState) {
        return DashboardService.addPlayer(dashboardID, userID, clientUserID, label, order, avatarUserEditable).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
        }).catch(error => {
            if(error.message === 'Payment Required') {
                const toastrService = new ToastrService();
                toastrService.showError('Unable to add player', 'You have reached the maximum number of dashboards allowed for this account.', null);
            }
            else {
                throw(error);
            }
        });
    };
}

export function updatePlayer(dashboardID, playerID, userID, clientUserID, label, order, avatarUserEditable){
    return function(dispatch, getState) {
        return DashboardService.updatePlayer(dashboardID, playerID, userID, clientUserID, label, order, avatarUserEditable).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
        }).catch(error => {
            throw(error);
        });
    };
}

export function deletePlayer(dashboardID, playerID){
    return function(dispatch, getState) {
        return DashboardService.deletePlayer(dashboardID, playerID).then(dashboard => {
            //debugger;
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'Player has been deleted successfully!', null);
        }).catch(error => {
            throw(error);
        });
    };
}

export function deletePlayers(dashboardID, playerIDs){
    //debugger;
    return function(dispatch, getState) {
        let p = Promise.resolve();
        if(playerIDs.length) {
            for(let i = 0; i < playerIDs.length; i++) {
                p = p.then(_ => 
                    DashboardService.deletePlayer(dashboardID, playerIDs[i]).then(dashboard => {
                        //debugger;
                        dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
                    })
                    .catch(error => {
                        throw(error);
                    })
                );
            }
            p.then(_ => {
                //debugger;
                const toastrService = new ToastrService();
                toastrService.showSuccess('', `Player${playerIDs.length > 1 ? 's have' : ' has'} been deleted successfully!`, null)
            });
        }
        return p;
        
    };
}

export function addUser(dashboardID, playerID, userID){
    return function(dispatch, getState) {
        return DashboardService.addUser(dashboardID, playerID, userID).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
        }).catch(error => {
            throw(error);
        });
    };
}

export function removeUser(dashboardID, playerID, userID){
    return function(dispatch, getState) {
        return DashboardService.deleteUser(dashboardID, playerID, userID).then(dashboard => {
            //debugger;
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'User has been removed successfully!', null)
        }).catch(error => {
            throw(error);
        });
    };
}

export function removeUsers(dashboardID, playerID, userIDs){
    //debugger;
    return function(dispatch, getState) {
        let p = Promise.resolve();
        if(userIDs.length) {
            for(let i = 0; i < userIDs.length; i++) {
                p = p.then(_ => 
                    DashboardService.deleteUser(dashboardID, playerID, userIDs[i]).then(dashboard => {
                        //debugger;
                        dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
                    })
                    .catch(error => {
                        throw(error);
                    })
                );
            }
            p.then(_ => {
                //debugger;
                const toastrService = new ToastrService();
                toastrService.showSuccess('', `User${userIDs.length > 1 ? 's have' : ' has'} been removed successfully!`, null)
            });
        }
        return p;
        
    };
}

export function applyPlayerAdjustments(dashboardID, playerID, adjustments){
    //debugger;
    return function(dispatch, getState) {
        let p = Promise.resolve();
        if(adjustments.length) {
            for(let i = 0; i < adjustments.length; i++) {
                p = p.then(_ => {
                    const adjustment = adjustments[i];
                    if(adjustment.type === 'CREATE') {
                        return DashboardService.addPlayerAdjustment(dashboardID, playerID, adjustment.name, true, adjustment.override, adjustment.append).then(dashboard => {
                            //debugger;
                            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
                        })
                        .catch(error => {
                            throw(error);
                        })
                    }
                    else if(adjustment.type === 'UPDATE') {
                        return DashboardService.updatePlayerAdjustment(dashboardID, playerID, adjustment.name, true, adjustment.override, adjustment.append).then(dashboard => {
                            //debugger;
                            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
                        })
                        .catch(error => {
                            throw(error);
                        })
                    }
                    else if(adjustment.type === 'DELETE') {
                        return DashboardService.deletePlayerAdjustment(dashboardID, playerID, adjustment.name).then(dashboard => {
                            //debugger;
                            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
                        })
                        .catch(error => {
                            throw(error);
                        })
                    }
                });
            }
            p.then(_ => {
                //debugger;
                const toastrService = new ToastrService();
                toastrService.showSuccess('', `Overrides have been applied successfully!`, null)
            })
            .catch(error => {
                const toastrService = new ToastrService();
                toastrService.showError('', 'Something went wrong, unable to save overrides', null)
            });
        }
        return p;
        
    };
}

export function updatePlayerAvatar(dashboardID, playerID, playerSelection){
    return function(dispatch, getState) {
        const toastrService = new ToastrService();
        return DashboardService.updatePlayerAvatar(dashboardID, playerID, playerSelection).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
            toastrService.showSuccess('', `Avatar has been saved successfully!`, null)
        }).catch(error => {
            toastrService.showError('', 'Something went wrong, unable to save avatar', null)
        });
    };
}

export function getWidgets(){
    return function(dispatch, getState) {
        return DashboardService.getWidgets().then(widgets => {
            dispatch({ type: types.GET_WIDGETS_SUCCESS, widgets });
        }).catch(error => {
            throw(error);
        });
    };
}

export function createWidget(widget) {
    return function(dispatch, getState) {
        return DashboardService.createWidget(widget).then(widget => {
            dispatch({ type: types.CREATE_WIDGET_SUCCESS, widget });
            return widget;
        }).catch(error => {
            throw(error);
        });
    };
}

export function addWidget(dashboardID, widgetInstance){
    return function(dispatch, getState) {
        return DashboardService.addWidget(dashboardID, widgetInstance).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
        }).catch(error => {
            throw(error);
        });
    };
}

export function updateWidget(dashboardID, widgetInstance){
    return function(dispatch, getState) {
        return DashboardService.updateWidget(dashboardID, widgetInstance).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
        }).catch(error => {
            throw(error);
        });
    };
}

export function removeWidget(dashboardID, widgetInstanceID){
    return function(dispatch, getState) {
        return DashboardService.removeWidget(dashboardID, widgetInstanceID).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
        }).catch(error => {
            throw(error);
        });
    };
}

export function addTask(dashboardID, task){
    return function(dispatch, getState) {
        return DashboardService.addTask(dashboardID, task).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
            return dashboard;
        }).catch(error => {
            throw(error);
        });
    };
}

export function updateTask(dashboardID, task){
    return function(dispatch, getState) {
        return DashboardService.updateTask(dashboardID, task).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
        }).catch(error => {
            throw(error);
        });
    };
}

export function removeTask(dashboardID, taskID){
    return function(dispatch, getState) {
        return DashboardService.removeTask(dashboardID, taskID).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
            return dashboard;
        }).catch(error => {
            throw(error);
        });
    };
}

export function addVariable(dashboardID, taskID, variable){
    return function(dispatch, getState) {
        return DashboardService.addVariable(dashboardID, taskID, variable).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
            return dashboard;
        }).catch(error => {
            throw(error);
        });
    };
}

export function updateVariable(dashboardID, taskID, variable){
    return function(dispatch, getState) {
        return DashboardService.updateVariable(dashboardID, taskID, variable).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
        }).catch(error => {
            throw(error);
        });
    };
}

export function removeVariable(dashboardID, taskID, variableID){
    return function(dispatch, getState) {
        return DashboardService.removeVariable(dashboardID, taskID, variableID).then(dashboard => {
            dispatch({ type: types.DASHBOARD_UPDATED, dashboard });
        }).catch(error => {
            throw(error);
        });
    };
}