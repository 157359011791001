import React from 'react';
import ReactDOM from 'react-dom';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import PlayerOverridesModal from './PlayerOverridesModal';
import { Link } from 'react-router-dom';
import Avatar from './Avatar';

class DashboardSettingsPlayerItem extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.deletePlayer = this.deletePlayer.bind(this);
        this.onChange = this.onChange.bind(this);
        this.overrideValues = this.overrideValues.bind(this);
        this.setRef = this.setRef.bind(this);
        this.tooltipControls = [];
    }

    componentDidMount() {
        let tooltipControls = [];
        for(let i = 0; i < this.tooltipControls.length; i++) {
            tooltipControls.push(ReactDOM.findDOMNode(this.tooltipControls[i]));
        }
        tooltipControls = window.$(tooltipControls);
        if (tooltipControls.length !== 0 ) {
            tooltipControls.tooltip({boundary: 'viewport'});
        }
    }

    componentWillUnmount() {
        let tooltipControls = [];
        for(let i = 0; i < this.tooltipControls.length; i++) {
            tooltipControls.push(ReactDOM.findDOMNode(this.tooltipControls[i]));
        }
        tooltipControls = window.$(tooltipControls);
        if (tooltipControls.length !== 0 ) {
            tooltipControls.tooltip('dispose');
        }
    }

    deletePlayer(event) {
        event.preventDefault();
        const deleteAction = () => this.props.actions.deletePlayer(this.props.dashboard.ID, this.props.player.ID);
        const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to delete player ${this.props.player.Label}? This action is irreversible.`} />
        this.props.showModal(() => deleteConfirmationModal, { isOpen: true })
    }

    setRef(control) {
        this.tooltipControls.push(control);
    }

    onChange(event) {
        const field = event.target.name;
        let newState = Object.assign({}, this.state);
        if(field === 'selected') {
            const checked = event.target.checked;
            newState[field] = checked;
            return this.setState(newState);
        }
        else {
            let value = event.target.value;
            newState[field] = value;
            return this.setState(newState);
        }       
    }

    overrideValues(event) {
        event.preventDefault();
        const playerOverridesModal = <PlayerOverridesModal key={this.props.player.ID} actions={this.props.actions} player={this.props.player} dashboard={this.props.dashboard}  />
        this.props.showModal(() => playerOverridesModal, { isOpen: true })
    }

    render() {
        const {dashboard, player} = this.props;
        return (     
            <div className={`useritem ${this.props.selected ? 'useritem--selected' : ''}`} index={this.props.index} order={this.props.order} data-player-id={player.ID}>
                <div className="useritem__drag">
                    <i className="aheadicon-drag"></i>
                </div>
                <div className="useritem__check">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" name="selected" className="custom-control-input" id={`user${this.props.index}`} checked={this.props.selected} onChange={(e) => this.props.toggleSelectedPlayer(e, player.ID)} />
                        <label className="custom-control-label" htmlFor={`user${this.props.index}`}>&nbsp;</label>
                    </div>
                </div>
                {
                    dashboard.Template.AvatarTemplate ?
                        <div className="useritem__pic" onClick={this.props.editAvatar} style={{cursor: 'pointer'}}>
                            <Avatar avatarSelection={player.AvatarSelection} dashboard={this.props.dashboard} />
                        </div>
                        : null
                }                
                <div className="useritem__title">
                    <h5>{player.Label}</h5>
                    <p className="useritem__email">{player.Email}</p>
                </div>
                {/*<div className="useritem__id">
                    <span className="badge badge--user">{this.props.userID}</span>
                </div>*/}
                <div className="useritem__override">
                    <a href={null} className="btn-text" onClick={this.overrideValues}>Override values</a>
                </div>
                <div className="useritem__actions">
                    {
                        dashboard.Template.AvatarTemplate ?
                            <Link to={`/Dashboards/${dashboard.ID}/Settings/${player.ID}/Avatar`} ref={this.setRef} className="btn-action" title="Customise avatar" data-toggle="tooltip" data-delay="500" data-placement="top">
                                <i className="aheadicon-customize"></i>
                            </Link>
                        : null
                    }
                    <a href={null} className="btn-action" onClick={this.props.updatePlayer} ref={this.setRef} title="Edit Player" data-toggle="tooltip" data-delay="500" data-placement="top">
                        <i className="aheadicon-edit"></i>
                    </a>
                    <a href={null} className="btn-action btn-delete" onClick={this.deletePlayer}><i className="aheadicon-delete"></i></a>
                </div>
            </div>
        );
    }
}

/*DashboardSettingsPlayerItem.propTypes = {
    user: PropTypes.object,
    error: PropTypes.string,
    emailSent: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired
};*/

export default DashboardSettingsPlayerItem;