import React from 'react';
import { ModalConsumer } from '../components/common/ModalContext';

export function withModalContext(Component) {
    return function ModalContextComponent(props) {
        return (
            <ModalConsumer>
                {({ showModal, hideModal }) => (
                    <Component {...props} showModal={showModal} hideModal={hideModal} />
                )}
            </ModalConsumer>
        );
    };
  }