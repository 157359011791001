import * as types from '../actions/actionTypes';
import initialState from './initialState';
import _ from 'lodash';

export default function playlistReducer(state = initialState.playlist, action) {

    switch(action.type) {
        case types.GET_PLAYLISTS_SUCCESS:
            const playlists = [...action.playlists];         
            return {
                ...state,
                playlists
            }   
        case types.PLAYLISTS_LOADING:
        {
            const playlistsLoading = action.loading;  
            return {
                ...state,
                playlistsLoading
            }   
        }
        case types.PLAYLIST_CREATED:
        {
            const playlists = [...state.playlists];
            playlists.push(action.playlist)     
            return {
                ...state,
                playlists
            } 
        }
        case types.PLAYLIST_UPDATED:
        {
            const playlists = [...state.playlists];
            const playlist = playlists.find(p => p.ID === action.playlist.ID);
            const playlistIndex = _.indexOf(playlists, playlist);
            playlists.splice(playlistIndex, 1, action.playlist);
            return {
                ...state,
                playlists
            } 
        }
        case types.PLAYLIST_DELETED:
        {
            const playlists = [...state.playlists];
            _.remove(playlists, p => p.ID === action.id);            
            return {
                ...state,
                playlists
            } 
        }
        case types.SET_CREATE_UPDATE_PLAYLIST_ERROR:
            return {
                ...state,
                createUpdatePlaylistError: action.error.message
            }
        case types.CLEAR_CREATE_UPDATE_PLAYLIST_ERROR:
            return {
                ...state,
                createUpdatePlaylistError: null
            }
        case types.LOGOUT:       
            return {
                ...initialState.playlist
            };    
        default:
            return state;
    }
}