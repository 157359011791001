
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import * as widgetUtils from '../../../../utils/widgetUtils';

class FilterModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateFilterText = this.updateFilterText.bind(this); 
         _.debounce(this.updateFilterText, 200);
        this.handleChange = this.handleChange.bind(this);       
    }

    componentDidMount() {                
        const control = window.$(this.filterByControl);
        control.select2({
            placeholder: 'Select one or more inputs'
        })
        .on('select2:select', this.handleChange)
        .on('select2:unselect', this.handleChange);
        const initialData = this.props.modifier.FormulaInputs;        
        control.val(initialData).trigger('change');
    }  

    componentDidUpdate(prevProps) {
        if(this.props.modifier !== prevProps.modifier && this.props.modifier.FormulaInputs !== prevProps.modifier.FormulaInputs && this.filterByControl) {
            const initialData = this.props.modifier.FormulaInputs;     
            const control = window.$(this.filterByControl);   
            control.val(initialData).trigger('change');
        }
    }

    handleChange = event => {
        const data = event.params.data;
        if(data) {
            const modifier = {...this.props.modifier};
            modifier.FormulaInputs = [...modifier.FormulaInputs];
            if(data.selected) {
                //add the task id
                modifier.FormulaInputs.push(data.id);
            }
            else{
                const existingInput = modifier.FormulaInputs.find(a => a === data.id);
                _.pull(modifier.FormulaInputs, existingInput);
            }
            this.props.updateModifier(modifier);
        }
    } 

    updateFilterText(event) {   
        let modifier = {...this.props.modifier};
        modifier.Formula = event.target.value;
        this.props.updateModifier(modifier);
    }  

    render() {  
        const variableNameIDMappings = {};
        this.props.modifier.FormulaInputs.forEach(taskID => {
            const task = this.props.dashboard.Tasks.find(t => t.ID === taskID);
            if(task) {
                //the user would have entered the name of the task, but we want to map it back to the ID of the task 
                variableNameIDMappings[task.Name] = taskID;
            }
        });
        const filterText = widgetUtils.formatFormulaForReading(this.props.modifier.Formula, variableNameIDMappings);
        return (
            <form className="form">
                <div className="form-group">
                    <p className="small mb-0"><span className="text-muted">Input data:</span> {this.props.input ? this.props.input.Name : ''}</p>
                </div>
                <div className="form-group">
                    <label>Filter by (optional)</label>
                    <select className="form-control form--multi" multiple="multiple" ref={(control) => this.filterByControl = control}>
                        <option key="default" value="0" disabled>Select one or more sources</option>
                        {
                            this.props.dashboard.Tasks.map(task => 
                                <option key={task.ID} value={task.ID}>{task.Name}</option>
                            )
                        }
                    </select>
                </div>
                <div className="form-group">
                    <textarea id="filterText" className="form-control form-area" cols="30" rows="4" placeholder="Start typing your filter" onChange={(event) => this.updateFilterText(event)} value={filterText} />
                </div>
                <span className="text-muted small d-block">{`Enter an Excel-style formula using the inputs which will return a true/false result. You can use arithmetic operators (+, -, /, *, %, ^), logical operators (AND(), OR(), NOT(), XOR()), and comparison operators (=, >, >=, <, <=, <>). You can use built-in variables like TRUE, FALSE, and NULL. The & operator can be used for string concatenation`}</span>
            </form>     
        );
    }

}

export default FilterModifier;