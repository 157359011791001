import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import App from './components/App';
import LoginPage from './components/auth/LoginPage';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import AccountSettings from './components/account/AccountSettings';
import Dashboards from './components/dashboards/Dashboards';
import Dashboard from './components/dashboards/Dashboard';
import DashboardSettings from './components/dashboards/DashboardSettings';
import WidgetSettings from './components/dashboards/widgetSettings/WidgetSettings';
import DashboardAccess from './components/dashboards/DashboardAccess';
import AvatarSettings from './components/dashboards/AvatarSettings';
import Playlists from './components/playlists/Playlists';
import Playlist from './components/playlists/Playlist';
import PlaylistSettings from './components/playlists/PlaylistSettings';
import CreateUpdatePlaylist from './components/playlists/CreateUpdatePlaylist';
import PlaylistAccess from './components/playlists/PlaylistAccess';
import Users from './components/users/Users';
import CreateUpdateUser from './components/users/CreateUpdateUser';
import NotFound from './components/common/NotFound';
import ScrollToTop from './components/common/ScrollToTop';
import PrivateRoute from './components/common/PrivateRoute';
import roleTypes from './constants/roleTypes';
import NewWidget from './components/dashboards/widgetSettings/NewWidget';
//import CustomDashboard from './components/dashboards/templates/Custom/CustomDashboard';

export const routes = <ScrollToTop>
    <App>
        <Switch>
            <Route exact path="/Account/Login" component={LoginPage} />
            <Route exact path="/Account/ForgotPassword" component={ForgotPassword} />
            <Route exact path="/Account/ResetPassword" component={ResetPassword} />
            <Redirect exact from="/" to="/Dashboards" />
            <PrivateRoute exact path="/Account/Settings" component={AccountSettings} />
            <PrivateRoute exact path="/Dashboards" component={Dashboards} />
            {/*<PrivateRoute exact path="/Dashboards/Custom" component={CustomDashboard} />*/}
            <PrivateRoute exact path="/Dashboards/:id" component={Dashboard} />
            <PrivateRoute exact path="/Dashboards/:id/Player/:playerID" component={Dashboard} />
            <PrivateRoute exact path="/Dashboards/:id/Settings" component={DashboardSettings} allowRoles={[roleTypes.Admin, roleTypes.SuperUser, roleTypes.Manager]}  />
            <PrivateRoute exact path="/Dashboards/:id/WidgetSettings" component={WidgetSettings} allowRoles={[roleTypes.Admin, roleTypes.SuperUser, roleTypes.Manager]}  />
            <PrivateRoute exact path="/Dashboards/:id/NewWidget" component={NewWidget} allowRoles={[roleTypes.Admin, roleTypes.SuperUser, roleTypes.Manager]}  />
            <PrivateRoute exact path="/Dashboards/:id/Player/:playerID/WidgetSettings" component={WidgetSettings} allowRoles={[roleTypes.Admin, roleTypes.SuperUser, roleTypes.Manager]}  />
            <PrivateRoute exact path="/Dashboards/:id/Access" component={DashboardAccess} />
            <PrivateRoute exact path="/Dashboards/:id/Player/:playerID/Access" component={DashboardAccess} />
            <PrivateRoute exact path="/Dashboards/:id/Settings/:playerID/Avatar" component={AvatarSettings} />
            <PrivateRoute exact path="/Playlists" component={Playlists} />
            <PrivateRoute exact path="/Playlists/Create" component={CreateUpdatePlaylist} allowRoles={[roleTypes.Admin, roleTypes.SuperUser]} />
            <PrivateRoute exact path="/Playlists/:id" component={Playlist} />
            <PrivateRoute exact path="/Playlists/:id/Edit" component={CreateUpdatePlaylist} allowRoles={[roleTypes.Admin, roleTypes.SuperUser]} />
            <PrivateRoute exact path="/Playlists/:id/Settings" component={PlaylistSettings} allowRoles={[roleTypes.Admin, roleTypes.SuperUser, roleTypes.Manager]}  />
            <PrivateRoute exact path="/Playlists/:id/Access" component={PlaylistAccess} />
            <PrivateRoute exact path="/Users" component={Users} allowRoles={[roleTypes.Admin, roleTypes.SuperUser]} />
            <PrivateRoute exact path="/Users/Create" component={CreateUpdateUser} allowRoles={[roleTypes.Admin, roleTypes.SuperUser]} />
            <PrivateRoute exact path="/Users/:id" component={CreateUpdateUser} allowRoles={[roleTypes.Admin, roleTypes.SuperUser]} />
            <Route path="*" component={NotFound} />
        </Switch>
    </App>
</ScrollToTop>;