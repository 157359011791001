import toastr from 'toastr';

class ToastrService {

    constructor() {
        this.toastrOptions =  {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-bottom-right",
            "preventDuplicates": true,
            "showDuration": "300",
            "hideDuration": "1000",
            "timeOut": "4000",
            "extendedTimeOut": "1000",
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        };
    }

    showInfo(title, message, clickAction) {     
        toastr.options = this.toastrOptions;
        toastr.options.onclick = clickAction;
        toastr.info(message, title);
    };

    showWarning(title, message, clickAction) {     
        toastr.options = this.toastrOptions;
        toastr.options.onclick = clickAction;
        toastr.warning(message, title);
    };

    showSuccess(title, message, clickAction) {     
        toastr.options = this.toastrOptions;
        toastr.options.onclick = clickAction;
        toastr.success(message, title);
    };

    showError(title, message, clickAction) {     
        debugger;
        toastr.options = this.toastrOptions;
        toastr.options.onclick = clickAction;
        toastr.error(message, title);
    };
}
export default ToastrService;