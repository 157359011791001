/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as widgetUtils from '../../../../../utils/widgetUtils';
import 'daterangepicker/daterangepicker.js';
import 'daterangepicker/daterangepicker.css';
import '../../../../../styles/style-daterangepicker.css';

class DatePicker extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialiseData = this.initialiseData.bind(this);
        this.initialiseDatePicker = this.initialiseDatePicker.bind(this);
        this.getStyles = this.getStyles.bind(this);
        this.getFontSize = this.getFontSize.bind(this);
        this.getMinDate = this.getMinDate.bind(this);
        this.getMaxDate = this.getMaxDate.bind(this);
        /*this.getInitialValue = this.getInitialValue.bind(this);
        this.getData = this.getData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getFormattedData = this.getFormattedData.bind(this);*/
        this.state = {
            selectedDateText: ''
        };
    }

    componentWillMount() {   
        //this.initialiseData();
    }       

    componentDidMount() {
        this.initialiseData();                 
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.widget !== prevProps.widget || this.props.widgetSettings !== prevProps.widgetSettings || this.props.tasks !== prevProps.tasks) {
            this.initialiseData();
        }
    }

    initialiseData() {
        const data = {};
        if(this.props.widget) {
            this.props.widget.CustomVariables.forEach(variable => {
                data[variable.Name] = variable.DefaultValue;
            });
        }
        if(this.props.widgetSettings) {
            this.props.widgetSettings.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
                if(adjustment.Append) {
                    data[adjustment.Name] += parseFloat(adjustment.Append);
                }
                else {
                    data[adjustment.Name] = adjustment.Override;
                }
            });
        }        
        data.tasks = this.props.tasks;
        this.setState(data, () => {
            //once we've set the variables, we can initialise the date picker
            this.initialiseDatePicker();
        });
    }

    initialiseDatePicker() {
         window.$(this.control).daterangepicker({
            singleDatePicker: true,
            minDate: this.getMinDate(),
            maxDate: this.getMaxDate(),
            //startDate: null,
            //endDate: null,
            locale: {
                format: 'DD-MM-YYYY'
            },
            opens: 'right',
            autoApply: true,
            showCustomRangeLabel: false,
            alwaysShowCalendars: true,
            autoUpdateInput: false,
            showDropdowns: true
        });
        window.$(this.control).on('apply.daterangepicker', (ev, picker) => {
            if(this.props.onDataChange) {
                this.props.onDataChange(this.props.widgetHierarchy, 'SelectedDate',  picker.startDate.toDate()); 
            }
            this.setState({selectedDateText: `${picker.startDate.format('DD-MM-YYYY')}`});
        });
    }

    formatItem(item, displayMember) {
        if(typeof item != 'object') {
            //we have a simple type, map it to labels and values
            return { label: item, value: item };
        }
        else {
            return { label: item[displayMember], value: item };
        }
    }

    getVisibility() {
        let visible = true;
        if(this.state.tasks) {
            if(this.props.widgetSettings) {
                for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                    const binding = this.props.widgetSettings.FieldBindings[i];
                    if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'Visible')) {
                        const visibilityBinding = this.state.tasks[binding.TaskID];
                        if(visibilityBinding && visibilityBinding.currentValue) {                    
                            visible = visibilityBinding.currentValue;
                            break;
                        }
                    }
                } 
            }
        }                    
        return visible;
    }

    getStyles() {
        const style = {};
        if(this.state.textColour) {
            style['color'] = this.state.textColour;
        }
        if(this.state.bold) {
            style['fontWeight'] = 'bold';
        }
        if(this.state.fontSize) {            
            style['fontSize'] = this.getFontSize(this.state.fontSize);
        }
        return style;
    }   

    getFontSize(sizeDesc) {
        if(sizeDesc === 'Small') {
            return '8px';
        }
        else if(sizeDesc === 'Medium') {
            return '12px';
        }
        else if(sizeDesc === 'Large') {
            return '16px';
        }
        else {
            return '12px';
        }
    }

    getPlaceholder() {
        return this.state.placeholderText || 'Select a date';
    }

    getMinDate() {
        return this.state.minimumDate || '';
    }

    getMaxDate() {
        return this.state.maximumDate || '';
    }
    
    handleChange = selectedValues => {
        //get the original value, pass that up
        const values = selectedValues.map(item => this.state.data.find(d => d === item.value));
        this.setState({ selectedValues: values });
        if(this.props.onDataChange) {
            this.props.onDataChange(this.props.widgetHierarchy, 'SelectedValue', values);
        }
    };

    render() {
        return (  
            <input className="form-control" style={this.getStyles()} type="text" name="date" placeholder={this.getPlaceholder()} ref={control => this.control = control} autoComplete="off" value={this.state.selectedDateText}/>           
        );
    }
}

DatePicker.propTypes = {
};

export default DatePicker;