
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as dashboardActions from '../../actions/dashboardActions';
import * as userActions from '../../actions/userActions';
import roleTypes from '../../constants/roleTypes';
import { withModalContext } from '../../services/modalService';

class Dashboard extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getDashboardDataString = this.getDashboardDataString.bind(this);
        this.getDashboardDataStringProcessed = this.getDashboardDataStringProcessed.bind(this);
        this.initialiseDashboard = this.initialiseDashboard.bind(this);
        this.getDashboardContent = this.getDashboardContent.bind(this);
    }

    componentDidMount() {      
        this.initialiseDashboard()
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.id !== this.props.id) {
            //a playlist has changed the dashboard, reinitialise
            this.props.actions.disconnectFromHub();
            this.initialiseDashboard();
        }
    }

    componentWillUnmount() {
        console.log(`${new Date()}: Dashboard unmounting, disconnecting from hub`);
        this.props.actions.disconnectFromHub();        
        //close any modals that might be open - incase we're in a scoreboard
        window.$('#modal').modal('hide');
        this.props.hideModal();
    }

    initialiseDashboard() {
        //the dashboard ID will either be set in the url, or as a prop (if it's being loaded from a playlist)
        //the playerID will be set in the same way if it has been set
        //we handle both cases in the mapStateToProps and pass it in as a prop
        const dashboardID = this.props.id;
        const playlistID = this.props.playlistID;
        const playerID = this.props.playerID;
        console.log(`${new Date()}: Initialising dashboard, setting the current dashboard loaded to ${dashboardID}`);
        this.props.actions.setCurrentDashboardLoaded(dashboardID);
        
        if(!this.props.dashboard) {
            //if we're browsing directly to this page, we might not have the dashboard details
            this.props.actions.getDashboard(dashboardID);
        }
        else {
            this.props.actions.checkDashboardVersion(dashboardID);
        }
        if(!this.props.users || this.props.users.length === 0) {
            this.props.actions.getUsers();
        }
        console.log(`${new Date()}: Connecting to hub, dashboardID ${dashboardID}, playlistID ${playlistID}, playerID ${playerID}`);
        this.props.actions.connectToHub(dashboardID, playlistID, playerID);
    }
    
    getDashboardDataString() {
        if(this.props.dashboardData) {
            return JSON.stringify(this.props.dashboardData, null, 2);
        }
        else {
            return 'Waiting for data';
        }
    }

    getDashboardDataStringProcessed() {
        if(this.props.dashboardData && this.props.dashboard) {
            const data = this.props.dashboard.Players.map(player => {
                let playerDashboardData = {};
                if(player.ClientUserID) {
                    playerDashboardData = Object.assign({}, this.props.dashboardData.find(d => d.id + '' === player.ClientUserID));
                }
                player.Adjustments.forEach(adjustment => {
                    if(adjustment.Append) {
                        playerDashboardData[adjustment.Name] += parseFloat(adjustment.Append);
                    }
                    else {
                        playerDashboardData[adjustment.Name] = adjustment.Override;
                    }
                    
                })
                playerDashboardData.label = player.Label;
                playerDashboardData.order = player.Order;
                return playerDashboardData;
            });
            return JSON.stringify(data, null, 2);
        }
        else {
            return 'Waiting for data';
        }
    }

    getDashboardContent() {
        if(this.props.dashboard.Template.Slug === 'bike-race') {
            const BikeRace = require('./templates/BikeRace/MultiPlayer/BikeRace').default;
            return <BikeRace dashboard={this.props.dashboard} data={this.props.dashboardData} dashboardsInGroup={this.props.dashboardsInGroup} users={this.props.users} playlistID={this.props.playlistID} onAnimationFinished={this.props.onAnimationFinished} showModal={this.props.showModal} hideModal={this.props.hideModal} />
        }
        else if(this.props.dashboard.Template.Slug && this.props.dashboard.Template.Slug.indexOf('bike-race-beat-your-best') > -1) {
            const BikeRace = require('./templates/BikeRace/SinglePlayer/BikeRace').default;
            return <BikeRace dashboard={this.props.dashboard} data={this.props.dashboardData} dashboardsInGroup={this.props.dashboardsInGroup} users={this.props.users} actions={this.props.actions} playlistID={this.props.playlistID} playerID={this.props.playerID} onAnimationFinished={this.props.onAnimationFinished} showModal={this.props.showModal} hideModal={this.props.hideModal} />
        }
        else if(this.props.dashboard.Template.Slug === 'car-race') {
            const CarRace = require('./templates/CarRace/CarRace').default;
            return <CarRace dashboard={this.props.dashboard} data={this.props.dashboardData} dashboardsInGroup={this.props.dashboardsInGroup} users={this.props.users} actions={this.props.actions} playlistID={this.props.playlistID} onAnimationFinished={this.props.onAnimationFinished} showModal={this.props.showModal} hideModal={this.props.hideModal} />
        }
        else if(this.props.dashboard.Template.Slug === 'horse-race') {
            const HorseRace = require('./templates/HorseRace/HorseRace').default;
            return <HorseRace dashboard={this.props.dashboard} data={this.props.dashboardData} dashboardsInGroup={this.props.dashboardsInGroup} users={this.props.users} actions={this.props.actions} playlistID={this.props.playlistID} onAnimationFinished={this.props.onAnimationFinished} showModal={this.props.showModal} hideModal={this.props.hideModal} />
        }
        else if(this.props.dashboard.Template.Slug === 'tournament') {
            const Tournament = require('./templates/Tournament/Tournament').default;
            return <Tournament dashboard={this.props.dashboard} data={this.props.dashboardData} dashboardsInGroup={this.props.dashboardsInGroup} users={this.props.users} actions={this.props.actions} playlistID={this.props.playlistID} onAnimationFinished={this.props.onAnimationFinished} showModal={this.props.showModal} hideModal={this.props.hideModal} />
        }
        else if(this.props.dashboard.Template.Slug === 'widget-template') {
            const Widgets = require('./templates/Widgets/Widgets').default;
            return <Widgets dashboard={this.props.dashboard} data={this.props.dashboardData} dashboardsInGroup={this.props.dashboardsInGroup} users={this.props.users} actions={this.props.actions} playlistID={this.props.playlistID} onAnimationFinished={this.props.onAnimationFinished} showModal={this.props.showModal} hideModal={this.props.hideModal} />
        }
        else {
            return  <div>
                <h2>{this.props.dashboard.Name}</h2>
                <br />
                Raw dashboard data:
                <br />
                <span>
                    <pre>{this.getDashboardDataString()}</pre>
                </span>
                <br />
                <br />
                Processed dashboard data:
                <br />
                <span>
                    {this.getDashboardDataStringProcessed()}
                </span>
            </div> 
        }
    }

    render() {
        return (
            <div className={this.props.dashboardLoading ? 'spinner' : ''}>
            {
                this.props.dashboard ? 
                    this.getDashboardContent()
                    : 
                    !this.props.dashboardLoading ? 
                        <div className="pane__body scrollbar">
                            <div className="empty">
                                <p>Dashboard not found</p>
                            </div>
                        </div>
                    : null
            }
            </div>
           
        );
    }
}

Dashboard.propTypes = {
    actions: PropTypes.object.isRequired,
    dashboard: PropTypes.object,
    dashboardData: (props, propName, componentName) => {
        if(props['dashboard'] && props[propName]) {
            if(props['dashboard'].PlayerMode === 'Single' && typeof(props[propName]) !== 'object') {
                return new Error('Please provide the dashboard data');
            }
            else if(props['dashboard'].PlayerMode !== 'Single' && Array.isArray(props[propName])) {
                return new Error('Please provide the dashboard data');
            }
        }
    }
};

function mapStateToProps(state, ownProps) {
    const dashboardID = ownProps.match && ownProps.match.path.indexOf('/Dashboards/') > -1 ? ownProps.match.params.id : ownProps.id;
    const playerID = ownProps.match && ownProps.match.path.indexOf('/Dashboards/') > -1 ? ownProps.match.params.playerID : ownProps.playerID;   
    let dashboardData;
    if(state.dashboard.currentDashboard && state.dashboard.currentDashboard.id === dashboardID) {
        dashboardData = state.dashboard.currentDashboard.data;
    }
    const users = state.user.users;
    let dashboard = null;
    //if we have the Template ID, then we've loaded the full dashboard details
    const matchingDashboard = state.dashboard.dashboards.filter(d => d.Template && d.Template.ID).find(d => d.ID === dashboardID);
    if(matchingDashboard) {
        const myRole = state.account.user.Role;
        //check if we have access to the dashboard, or we're an admin/super user, or we're in a playlist (playlist access overrides dashboard access)
        if(ownProps.playlistID || myRole === roleTypes.SuperUser || myRole === roleTypes.Admin) {
            dashboard = matchingDashboard;
        } 
        else if(matchingDashboard.UserIDs.includes(state.account.user.ID)) {
            dashboard = matchingDashboard;
        }
        else if(matchingDashboard.PlayerMode === 'Single') {
            const player = matchingDashboard.Players.find(p => p.ID === playerID);
            if(player && player.UserIDs.includes(state.account.user.ID)) {
                dashboard = matchingDashboard;
            }
        }  
    }
    const dashboardsInGroup = dashboard && dashboard.Group && dashboard.Group.GroupName ? state.dashboard.dashboards.filter(d => d.Group && d.Group.GroupName === dashboard.Group.GroupName) : []
    return { 
        id: dashboardID, 
        playerID,
        dashboard, 
        dashboardData, 
        dashboardLoading: state.dashboard.dashboardsLoading || state.dashboard.dashboardItemsLoading.includes(dashboardID), 
        dashboardsInGroup,
        users
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //dashboard actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, dashboardActions, userActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withModalContext(Dashboard));