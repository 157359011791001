import BaseService from './baseService';
import * as signalR from "@aspnet/signalr";
import urls from '../constants/urls.js';

//const signalR = require("@aspnet/signalr");

class DashboardService {
    constructor() {
        this.connectionStopped = false;
        //this.connection = null;
    }

    static getDashboards() {
        return BaseService.getData('/Dashboards?includeFullDetails=false')
            .then(data => {
                return Object.assign([], data);
            })
            .catch(error => {
                console.log('Unable to get dashboards');
                throw new Error('Unable to get dashboards');
            });
    }

    static getDashboard(dashboardID) {
        return BaseService.getData(`/Dashboards/${dashboardID}`)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                console.log('Unable to get dashboard');
                throw new Error('Unable to get dashboard');
            });
    }

    static getDashboardVersion(dashboardID) {
        return BaseService.getData(`/Dashboards/${dashboardID}/Version`)
            .then(data => {
                return data;
            })
            .catch(error => {
                console.log('Unable to get dashboard version');
                throw new Error('Unable to get dashboard version');
            });
    }

    static getAccessToken() {
        return BaseService.token;
    }

    static getHubConnection(dashboardID, playlistID, playerID) {
        const url = `${urls.API_URL}/Hubs/Dashboard?dashboardID=${dashboardID}&playlistID=${playlistID || ''}&playerID=${playerID || ''}`;
        let connection = new signalR.HubConnectionBuilder()
            .withUrl(url, { accessTokenFactory: this.getAccessToken })
            .build();
        return connection;
    }

    static startConnection(connection) {
        this.connectionStopped = false;
        console.log(`${new Date()}: Connecting...`);
        
        const connectionState = connection.connection.connectionState;
        if(connectionState !== 1) { //state 1 is connected 
            return connection.start()
            .then(() => {
                console.log(`${new Date()}: Connected!`)
            })
            .catch((err) => {
                    throw(err);
            });
        }
        else {
            return Promise.resolve();
        }
    }

    static stopConnection(connection) {
        this.connectionStopped = true;
        console.log(`${new Date()}: Calling connection stop`);
        return connection.stop()
            .then(() => {
              console.log(`${new Date()}: Hub connection stopped`);
              return true;
            })
            .catch((err) => {
                console.log(`${new Date()}: Error while disconnecting connection`);
                return false;
            });
    }

    static addPlayer(dashboardID, userID, clientUserID, label, order, avatarUserEditable) {
        return BaseService.postData(`/Dashboards/${dashboardID}/Players`, { userID, clientUserID, label, order, avatarUserEditable })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                if(error.message === 'Payment Required') {
                    throw new Error(error.message);
                }
                else {
                    const message = 'Unable to add player';
                    console.log(message);
                    throw new Error(message);   
                }
            });
    }  

    static updatePlayer(dashboardID, playerID, userID, clientUserID, label, order, avatarUserEditable) {
        return BaseService.putData(`/Dashboards/${dashboardID}/Players/${playerID}`, { id: playerID, userID, clientUserID, label, order, avatarUserEditable })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to update player';
                console.log(message);
                throw new Error(message);                
            });
    } 

    static deletePlayer(dashboardID, playerID) {
        return BaseService.deleteData(`/Dashboards/${dashboardID}/Players/${playerID}`)
            .then(data => {
                return data;
            })
            .catch(error => {
                debugger;
                const message = 'Unable to delete player';
                console.log(message);
                throw new Error(message);  
            });
    }

    static addUser(dashboardID, playerID, userID) {
        return BaseService.postData(`/Dashboards/${dashboardID}/${playerID ? `Players/${playerID}/` : ''}Users`, { userID})
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to add user';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static deleteUser(dashboardID, playerID, userID) {
        return BaseService.deleteData(`/Dashboards/${dashboardID}/${playerID ? `Players/${playerID}/` : ''}Users/${userID}`)
            .then(data => {
                return data;
            })
            .catch(error => {
                debugger;
                const message = 'Unable to delete user';
                console.log(message);
                throw new Error(message);  
            });
    }

    static getDashboardData(dashboardID, playerID) {
        return BaseService.getData(`/Dashboards/${dashboardID}/${playerID ? `Players/${playerID}/` : ''}data`)
            .then(data => {
                return playerID && !Array.isArray(data) ? Object.assign({}, data) : Object.assign([], data);
            })
            .catch(error => {
                console.log('Unable to get dashboard data');
                throw new Error('Unable to get dashboard data');
            });
    }

    static addPlayerAdjustment(dashboardID, playerID, name, enabled, override, append) {
        debugger;
        return BaseService.postData(`/Dashboards/${dashboardID}/Players/${playerID}/Adjustments`, { name, enabled, override, append })
            .then(data => {
                debugger;
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to add adjustment';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static updatePlayerAdjustment(dashboardID, playerID, name, enabled, override, append) {
        return BaseService.putData(`/Dashboards/${dashboardID}/Players/${playerID}/Adjustments/${name}`, { name, enabled, override, append })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to update adjustment';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static deletePlayerAdjustment(dashboardID, playerID, name) {
        return BaseService.deleteData(`/Dashboards/${dashboardID}/Players/${playerID}/Adjustments/${name}`)
            .then(data => {
                return data;
            })
            .catch(error => {
                debugger;
                const message = 'Unable to delete adjustment';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static updatePlayerAvatar(dashboardID, playerID, playerSelection) {
        return BaseService.putData(`/Dashboards/${dashboardID}/Players/${playerID}/Avatar`, playerSelection)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to update avatar';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static getWidgets() {
        return BaseService.getData('/Widgets')
            .then(data => {
                return Object.assign([], data);
            })
            .catch(error => {
                console.log('Unable to get widgets');
                throw new Error('Unable to get widgets');
            });
    }

    static createWidget(widget) {
        return BaseService.postData(`/Widgets`, widget)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to create widget';
                console.log(message);
                throw new Error(message); 
            })
    }

    static addWidget(dashboardID, widgetInstance) {
        return BaseService.postData(`/Dashboards/${dashboardID}/Widgets/`, widgetInstance)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to add widget';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static updateWidget(dashboardID, widgetInstance) {
        return BaseService.putData(`/Dashboards/${dashboardID}/Widgets/${widgetInstance.ID}`, widgetInstance)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to update widget';
                console.log(message);
                throw new Error(message);                
            });
    } 

    static removeWidget(dashboardID, widgetInstanceID) {
        return BaseService.deleteData(`/Dashboards/${dashboardID}/Widgets/${widgetInstanceID}`)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to delete widget';
                console.log(message);
                throw new Error(message);                
            });
    } 

    static addTask(dashboardID, task) {
        return BaseService.postData(`/Dashboards/${dashboardID}/Tasks`, task)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to add task';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static updateTask(dashboardID, task) {
        return BaseService.putData(`/Dashboards/${dashboardID}/Tasks/${task.ID}`, task)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to update task';
                console.log(message);
                throw new Error(message);                
            });
    } 

    static removeTask(dashboardID, taskID) {
        return BaseService.deleteData(`/Dashboards/${dashboardID}/Tasks/${taskID}`)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to delete task';
                console.log(message);
                throw new Error(message);                
            });
    } 

    static addVariable(dashboardID, taskID, variable) {
        return BaseService.postData(`/Dashboards/${dashboardID}/Tasks/${taskID}/Variables`, variable)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to add variable';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static updateVariable(dashboardID, taskID, variable) {
        return BaseService.putData(`/Dashboards/${dashboardID}/Tasks/${taskID}/Variables/${variable.ID}`, variable)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to update variable';
                console.log(message);
                throw new Error(message);                
            });
    } 

    static removeVariable(dashboardID, taskID, variableID) {
        return BaseService.deleteData(`/Dashboards/${dashboardID}/Tasks/${taskID}/Variables/${variableID}`)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to delete variable';
                console.log(message);
                throw new Error(message);                
            });
    } 
}

export default DashboardService;