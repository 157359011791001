import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo-ahead-of-the-game.png';
import * as imageUtils from '../../utils/imageUtils';

const MainSidebar = (props) => { 

    const getAvatarSrc = () => {
        return props.user.Avatar ? imageUtils.getAvatarBase64Url(props.user.Avatar) : require('../../images/avatar-empty.png')
    }

    const logout = (event) => {
        event.preventDefault();
        props.actions.logout();
    }

    const closeSide = (event) => {
        event.preventDefault();
        window.$('body').removeClass('showside');
    }

    const getTabClass = (tabName) => {
      return props.location.indexOf(tabName) > -1 ? 'active' : '';
    }

    return (
        <section className="side">
            <a href={null} className="btn-closeside" onClick={closeSide}><i className="aheadicon-x"></i></a>

            <div className="side__branding">
              <img src={logo} alt="Ahead of the Game"/>
            </div>
            
            <div className="side__user">
              <a href={null} className="btn-user" data-toggle="dropdown">
                <i className="aheadicon-caret"></i>
                <div className="avatar">
                  <img src={getAvatarSrc()} alt="Avatar"/>
                </div>
                <div className="user__info">
                  <p>{props.user.FirstName}</p>
                  <p className="badge user__type">{props.user.Role}</p>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu--animated dropdown-menu--withicons">
                <Link className="dropdown-item" to="/Account/Settings"><i className="aheadicon-settings"></i>Settings</Link>
                <a className="dropdown-item" href={null} onClick={logout}><i className="aheadicon-logout"></i>Logout</a>
              </div>
            </div>
            
            <div className="side__links scroll">
              <ul className="list-unstyled list-links">
                <li>
                  <Link to="/Dashboards" className={getTabClass('Dashboards')}><i className="aheadicon-dashboards"></i>Dashboards</Link>
                </li>
                <li>
                  <Link to="/Playlists" className={getTabClass('Playlists')}><i className="aheadicon-playlists"></i>Playlists</Link>
                </li>
                {
                  props.user.Role === 'Admin' || props.user.Role === 'SuperUser' ?
                    <li>
                      <Link to="/Users" className={getTabClass('Users')}><i className="aheadicon-users"></i>Users</Link>
                    </li>
                    : null
                }
                
              </ul>
            </div>

        </section>
    );
};

export default MainSidebar;