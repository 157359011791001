import BaseService from './baseService';

class TemplateService {
    static getTemplates(tag) {
        return BaseService.getData(`/Templates?tag=${tag}`)
            .then(data => {
                return Object.assign([], data);
            })
            .catch(error => {
                console.log('Unable to get templates');
                throw new Error('Unable to get templates');
            });
    }
}

export default TemplateService;
