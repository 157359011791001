import _ from 'lodash';
import moment from 'moment';
import * as widgetUtils from '../utils/widgetUtils';

export const taskInputTypes = [{
    type: 'DataSource',
    name: 'Imported Data',
    shortName: 'ImportedData', //used for naming modifiers
    icon: 'aheadicon-imported-data'
}, {
    type: 'Dashboard',
    name: 'Dashboard',
    shortName: 'Dashboard',
    icon: 'aheadicon-dashboard-data'
}, {
    type: 'Player',
    name: 'Player',
    shortName: 'Player',
    icon: 'aheadicon-player-data'
}, {
    type: 'UserDefined',
    name: 'Manual Input',
    shortName: 'ManualInput',
    icon: 'aheadicon-manual-input'
}, {
    type: 'ExistingTask',
    name: 'Existing Task',
    shortName: 'ExistingTask',
    icon: 'aheadicon-existing-task'
}, {
    type: 'OutputValue',
    name: 'Data from another widget',
    shortName: 'AnotherWidget',
    icon: 'aheadicon-another-widget'
}];

export const variableTypes = [{
        type: 'Filter',
        name: 'Filter',
        icon: 'aheadicon-filter',
        description: 'Filter data by some attribute',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled for arrays
            if(modifiers.length) {
                return modifiers[modifiers.length - 1].OutputType === 'Array';
            }
            else {
                return inputVariables.length === 1 && inputVariables[0].OutputType === 'Array';
            }
        }
    }, {
        type: 'Calculation',
        name: 'Calculation',
        icon: 'aheadicon-calc',
        description: 'Use calculation to get final data',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled where the output type is numeric or an object or a date/datetime      
            const acceptableTypes = ['Integer', 'Double', 'Date', 'DateTime', 'Object'];      
            if(inputVariables.find(v => !acceptableTypes.includes(v.OutputType)) == null) {
                return true;
            }
            else {
                return modifiers.length > 0 && acceptableTypes.includes(modifiers[modifiers.length - 1].OutputType);
            }
        }
    }, {
        type: 'Aggregate',
        name: 'Aggregate',
        icon: 'aheadicon-aggregate',
        description: 'Aggregate data sources',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled for arrays
            if(modifiers.length) {
                return modifiers[modifiers.length - 1].OutputType === 'Array';
            }
            else {
                return inputVariables.length === 1 && inputVariables[0].OutputType === 'Array';
            }
        }            
    }, {
        type: 'Grouping', //this should be broken down into simple and custom
        name: 'Group',
        icon: 'aheadicon-group',
        description: 'Group the values in a list based on certain criteria',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled for arrays
            if(modifiers.length) {
                return modifiers[modifiers.length - 1].OutputType === 'Array';
            }
            else {
                return inputVariables.length === 1 && inputVariables[0].OutputType === 'Array';
            }
        }
    }, {
        type: 'Sorting',
        name: 'Sort',
        icon: 'aheadicon-sort',
        description: 'Sort the data by some attribute',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled for arrays
            if(modifiers.length) {
                return modifiers[modifiers.length - 1].OutputType === 'Array';
            }
            else {
                return inputVariables.length === 1 && inputVariables[0].OutputType === 'Array';
            }
        }
    }, {
        type: 'Merge',
        name: 'Merge',
        icon: 'aheadicon-merge',
        description: 'Combine multiple inputs based on a matching field',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
           //enabled whenever we have multiple tasks
            return inputVariables.length > 1 && !modifiers.length;
        }
    }, {
        type: 'Template',
        name: 'Template',
        icon: 'aheadicon-template',
        description: 'Apply a template string to data',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //enabled if we have task/s and they're not arrays (for arrays, they need some other modifier 1st) or where we have a modifier with an Object output
            if(modifiers.length) {
                return modifiers[modifiers.length - 1].OutputType === 'Object';
            }
            else {
                return inputVariables.find(i => i.OutputType === 'Array') == null;
            }
        }
    }, {
        type: 'Formatting',
        name: 'Format',
        icon: 'aheadicon-format',
        description: 'Format data',
        isEnabled: (inputVariables, modifiers, resolvedTasks) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled for certain types
            const acceptableTypes = ['Date', 'DateTime', 'Text', 'Integer', 'Double', 'Boolean'];
            if(modifiers.length) {
                const finalModifier = modifiers[modifiers.length - 1];
                let modifierOutputType = finalModifier.OutputType;
                const variable = widgetUtils.getVariable(finalModifier.ID, resolvedTasks);
                if(modifierOutputType === 'Object' && variable && variable.currentValue) {
                    //if we have an object with a single value and that object is a simple type, we should enable formatting
                    modifierOutputType = widgetUtils.getNestedObjectType(variable.currentValue);
                }
                return acceptableTypes.includes(modifierOutputType);
            }
            else {
                return inputVariables.length === 1 && acceptableTypes.includes(inputVariables[0].OutputType);
            }
        }
    }, {
        type: 'FieldSelection',
        name: 'Field select',
        icon: 'aheadicon-select',
        description: 'Select a field from the input object',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled if we have an object input or final modifier
            if(modifiers.length) {
                return modifiers[modifiers.length - 1].OutputType === 'Object';
            }
            else {
                return inputVariables.length === 1 && inputVariables[0].OutputType === 'Object';
            }
        }
    }, {
        type: 'Iteration',
        name: 'Iterate',
        icon: 'aheadicon-iteration',
        description: 'Iterate over a list',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled for arrays
            if(modifiers.length) {
                return modifiers[modifiers.length - 1].OutputType === 'Array';
            }
            else {
                return inputVariables.length === 1 && inputVariables[0].OutputType === 'Array';
            }
        }
    }, {
        type: 'Transformation',
        name: 'Transformation',
        icon: 'aheadicon-transform',
        description: 'Transform the structure of an input by selecting specfic fields, changing field names, and adding derived fields',
        isEnabled: (inputVariables, modifiers) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled for arrays or objects
            if(modifiers.length) {
                return modifiers[modifiers.length - 1].OutputType === 'Array' || modifiers[modifiers.length - 1].OutputType === 'Object';
            }
            else {
                return inputVariables.length === 1 && (inputVariables[0].OutputType === 'Array' || inputVariables[0].OutputType === 'Object');
            }
        }
    }, {
        type: 'Join',
        name: 'Join',
        icon: 'aheadicon-join',
        description: 'Combine multiple inputs into a single output',
        isEnabled: (inputVariables, modifiers, resolvedTasks) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //enabled if we have a single array with simple types (we'll join this to a text string), or multiple items (we'll add them to an array)
            const inputs = modifiers.length ? modifiers : inputVariables;
            if(inputs.length === 1 && inputs[0].OutputType === 'Array') {
                const variable = widgetUtils.getVariable(inputs[0].ID, resolvedTasks);
                if(variable && variable.currentValue && variable.currentValue.length) {
                    if(widgetUtils.getObjectType(variable.currentValue[0]) === 'Object' || widgetUtils.getObjectType(variable.currentValue[0]) === 'Array') {
                        return false;
                    }
                }
                //we have a single array of simple items, we can join them into a text string
                return true;
            }
            else {
                //we can join any groups of multiple inputs - just adding them all into an array
                return inputs.length > 1;
            }            
        }
    }, {
        type: 'Flatten',
        name: 'Flatten',
        icon: 'aheadicon-flatten',
        description: 'Flattens an object containing a single field to just the field',
        isEnabled: (inputVariables, modifiers, resolvedTasks) => {
            if(modifiers.length && modifiers[modifiers.length - 1].Type === 'Iteration') {
                return false;
            }
            //only enabled for arrays containing objects with a single value or just objects with a single value
            const variableID = modifiers.length ? modifiers[modifiers.length - 1].ID : inputVariables[0].ID;
            if(variableID) {
                const variable = widgetUtils.getVariable(variableID, resolvedTasks);
                if(variable && variable.currentValue) {
                    const variableType = widgetUtils.getObjectType(variable.currentValue);
                    if(variableType === 'Object') {
                        return Object.keys(variable.currentValue).length === 1;
                    }
                    else if(variableType === 'Array' && variable.currentValue.length && variable.currentValue[0] && widgetUtils.getObjectType(variable.currentValue[0]) === 'Object') {
                        return Object.keys(variable.currentValue[0]).length === 1;
                    }
                }
            }
            return false;
        }
    }
];

export const formattingFunctions = [{
    name: 'Uppercase',
    description: 'Uppercase',
    acceptableTypes: 'Text',
    func: (input) => input ? input.toUpperCase() : input,
    inputs: []
}, {
    name: 'Lowercase',
    description: 'Lowercase',
    acceptableTypes: 'Text',
    func: (input) => input ? input.toLowerCase() : input,
    inputs: []
}, {
    name: 'UppercaseFirst',
    description: 'Uppercase first character',
    acceptableTypes: 'Text',
    func: (input) => input ? _.upperFirst(input) : input,
    inputs: []
}, {
    name: 'Truncate',
    description: 'Truncate',
    acceptableTypes: 'Text',
    func: (input, maxLength, displayEllipses) => {
        if(input && maxLength && input.length > maxLength) {
            return _.truncate(input, {
                length: maxLength,
                omission: displayEllipses ? '...' : ''
            });
        }
        else {
            return input;
        }
    },
    inputs: [{
        name: 'Max Length',
        description: 'Enter the maximum length of the text',
        type: 'Integer'
    }, {
        name: 'Display Ellipses',
        description: 'Show ellipses (...) at the end when truncation occurs',
        type: 'Boolean'
    }]
}];

export const aggregateFunctions = [{
    name: 'First',
    description: 'First',
    acceptableTypes: [],
    func: (input) => input && input.length ? input[0] : input,
    inputs: []
}, {
    name: 'Last',
    description: 'Last',
    acceptableTypes: [],
    func: (input) => input && input.length ? input[input.length - 1] : input,
    inputs: []
}, {
    name: 'Sum',
    description: 'Sum',
    acceptableTypes: ['Integer', 'Double', 'Object'],
    func: (input, sumField) => {
        const simpleTypes = ['Integer', 'Double'];
        if(input && input.length) {
            const type = widgetUtils.getObjectType(input[0]);
            if(simpleTypes.includes(widgetUtils.getObjectType(input[0]))) {
                return _.sum(input);
            }
            else if(type === 'Object' && sumField) {
                let output = 0;
                input.forEach(item => {
                    const itemValue = item[sumField.trim()];
                    if(itemValue) {
                        output += itemValue;
                    }                            
                });
                return output;
            }
            return null;
        }
        else {
            return null;
        }
    },
    inputs: [{
        name: 'Sum Field',
        isEnabled: (inputType) => {
            if(inputType) {
                //const type = widgetUtils.getObjectType(input);
                return inputType === 'Object';
            }
        },
        description: 'Enter the name of the field you want to sum',
        type: 'Text'
    }]
}];

export const groupingFunctions = [{
    name: 'CalendarMonth',
    description: 'Group the data by calendar month. Select the date field to use for the grouping.',
    fieldNames: 'date',
    func: (input, groupingField, projections)  => {
        const groupingKeyFunction = (item) => {
            const date = new Date(item[groupingField]);
            return `${date.getMonth() + 1}-${date.getFullYear()}`;
        };
        const outputValuesFunction = (item) => {
            const date = moment.utc(item[groupingField]);
            const startDate = date.startOf('month').toDate();
            const endDate = date.endOf('month').toDate();
            const output = { StartDate: startDate, EndDate: endDate };
            output[groupingField] = item[groupingField];
            return output;
        };
        let result = widgetUtils.group(input, groupingKeyFunction, projections, outputValuesFunction);
        return result;
    }
}, {
    name: 'Pivot',
    description: 'Group the data by a specified field (row), outputting the value of the column specified (column), with a sum of the value specified (value). Select the row, column, and value fields. The output will be a list of unique rows, with the column and value pairs for each row',
    fieldNames: 'row, column, and value',
    func: (input, row, column, value)  => {
        const result = [];
        const groupedData = {};
        input.forEach(inputRecord => {
            const recordRow = inputRecord[row];
            if(recordRow) {               
                const groupedRecord = groupedData[recordRow] || {};
                if(!groupedRecord[row]) {
                    groupedRecord[row] = recordRow;
                }
                const recordColumn = inputRecord[column];
                if(recordColumn) {
                    if(!groupedRecord[recordColumn]) {
                        groupedRecord[recordColumn] = inputRecord[value] || 0;
                    }
                    else {
                        //we have the item, just update the sum
                        groupedRecord[recordColumn] += inputRecord[value] || 0;
                    }
                }
                groupedData[recordRow] = groupedRecord;
            }
        });
        Object.keys(groupedData).forEach(dataItem => result.push(groupedData[dataItem]));
        return result;
    }
}]

export const preDefinedDateRanges = [{
    name: 'Yesterday',
    displayText: 'Yesterday',
    formatDisplayText: false,
    start: {
        relative: true,
        momentActions: [{
            name: 'subtract',
            parameters: [
                1,
                'day'
            ]
        }, {
            name: 'startOf',
            parameters: [
                'day'
            ]
        }]
    },
    end: {
        relative: true,
        momentActions: [{
            name: 'subtract',
            parameters: [
                1,
                'day'
            ]
        },  {
            name: 'endOf',
            parameters: [
                'day'
            ]
        }]
    }
}, {
    name: 'Today',
    displayText: 'Today',
    formatDisplayText: false,
    start: {
        relative: true,
        momentActions: [{
            name: 'startOf',
            parameters: [
                'day'
            ]
        }]
    },
    end: {
        relative: true,
        momentActions: [{
            name: 'endOf',
            parameters: [
                'day'
            ]
        }]
    }
}, {
    name: 'CurrentMonth',
    displayText: 'Current Month',
    formatDisplayText: false,
    start: {
        relative: true,
        momentActions: [{
            name: 'startOf',
            parameters: [
                'month'
            ]
        }]
    },
    end: {
        relative: true,
        momentActions: [{
            name: 'endOf',
            parameters: [
                'month'
            ]
        }]
    }
}, {
    name: 'CurrentQuarter',
    displayText: 'Current Quarter',
    formatDisplayText: false,
    start: {
        relative: true,
        momentActions: [{
            name: 'startOf',
            parameters: [
                'quarter'
            ]
        }]
    },
    end: {
        relative: true,
        momentActions: [{
            name: 'endOf',
            parameters: [
                'quarter'
            ]
        }]
    }
}, {
    name: 'CurrentYear',
    displayText: 'Current Year',
    formatDisplayText: false,
    start: {
        relative: true,
        momentActions: [{
            name: 'startOf',
            parameters: [
                'year'
            ]
        }]
    },
    end: {
        relative: true,
        momentActions: [{
            name: 'endOf',
            parameters: [
                'year'
            ]
        }]
    }
}, {
    name: 'LastMonth',
    displayText: 'Last Month',
    formatDisplayText: false,
    start: {
        relative: true,
        momentActions: [{
            name: 'subtract',
            parameters: [
                1,
                'month'
            ]
        },{
            name: 'startOf',
            parameters: [
                'month'
            ]
        }]
    },
    end: {
        relative: true,
        momentActions: [{
            name: 'subtract',
            parameters: [
                1,
                'month'
            ]
        }, {
            name: 'endOf',
            parameters: [
                'day'
            ]
        }]
    }
}, {
    name: 'LastQuarter',
    displayText: 'Last Quarter',
    formatDisplayText: false,
    start: {
        relative: true,
        momentActions: [{
            name: 'subtract',
            parameters: [
                1,
                'quarter'
            ]
        },{
            name: 'startOf',
            parameters: [
                'quarter'
            ]
        }]
    },
    end: {
        relative: true,
        momentActions: [{
            name: 'subtract',
            parameters: [
                1,
                'quarter'
            ]
        }, {
            name: 'endOf',
            parameters: [
                'quarter'
            ]
        }]
    }
}, {
    name: 'LastYear',
    displayText: 'Last Year',
    formatDisplayText: false,
    start: {
        relative: true,
        momentActions: [{
            name: 'subtract',
            parameters: [
                1,
                'year'
            ]
        },{
            name: 'startOf',
            parameters: [
                'year'
            ]
        }]
    },
    end: {
        relative: true,
        momentActions: [{
            name: 'subtract',
            parameters: [
                1,
                'year'
            ]
        }, {
            name: 'endOf',
            parameters: [
                'year'
            ]
        }]
    }
}]