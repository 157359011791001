import React from 'react';
import * as imageUtils from '../../utils/imageUtils';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { withRouter } from 'react-router-dom';
import * as userActions from '../../actions/userActions';
import roleTypes from '../../constants/roleTypes';

class SelectPlayerModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.selectPlayer = this.selectPlayer.bind(this);
        this.action = this.action.bind(this);
        this.getPlayerClass = this.getPlayerClass.bind(this);
        this.getPlayers = this.getPlayers.bind(this);

        this.state = { 
            selectedPlayer: null,
        };
    }

    componentWillMount() {
        if(!this.props.users || !this.props.users.length) {
            this.props.actions.getUsers();
        }
    }

    componentDidMount() {    

    }

    selectPlayer(event, player) {
        event.preventDefault();
        this.setState({
            selectedPlayer: player
        });
    }

    action(event) {
        event.preventDefault();
        let url = `/Dashboards/${this.props.dashboard.ID}/Player/${this.state.selectedPlayer.ID}`;
        if(this.props.destination) {
            url += `/${this.props.destination}`;
        }
        this.props.history.push(url);
        window.$('#modal').modal('hide');
    }

    getPlayerClass(player) {
        return this.state.selectedPlayer && this.state.selectedPlayer.ID === player.ID ? 'active' : null;
    }

    getPlayers() {
        const users = this.props.users;
        let dashboardPlayers = null;
        const role = this.props.user.Role;
        //check if we have access to all players we get - we're an admin/super user
        if(role === roleTypes.SuperUser || role === roleTypes.Admin) {
            dashboardPlayers = this.props.dashboard.Players;
        } 
        else {
            const userID = this.props.user.ID;
            dashboardPlayers = this.props.dashboard.Players.filter(p => p.UserIDs.includes(userID));
        }

        let players = dashboardPlayers.map(player => {
            let email = '';
            let avatar = null;
            if(users && player.UserID) {
                const user = users.find(u => u.ID === player.UserID);
                if(user) {
                    email = user.Email;
                    avatar = user.Avatar;
                }
            }
            return Object.assign({}, player, { Email: email, UserAvatar: avatar });
        });
        return players;
    }

    getPlayerImage(player) {
        const image = player.UserAvatar;
        const imageData = image ? imageUtils.getAvatarBase64Url(image) : require('../../images/avatar-empty.png');
        return imageData;
    }

    getDescriptionText(destination) {
        if(destination === 'WidgetSettings') {
            return 'Please select a sample player to use for the setup:';
        }
        else {
            return `Please select the player you want to ${destination ? 'configure' : 'launch'} the dashboard${destination === 'Access' ? ' access' : ''} for:`;
        }
    }

    getSubmitText(destination) {
        if(destination === 'WidgetSettings') {
            return 'Configure dashboard';
        }
        else if(destination === 'Access') {
            return 'Configure dashboard access';
        }
        else {
            return 'Enter race';
        }
    }

    render() {
        const {destination, dashboard} = this.props;
        return (     
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form className="form" onSubmit={this.action}>
                        <div className="modal-header">
                            <h5 className="modal-title">Select player for dashboard - {dashboard.Name}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal__inner modal__inner--wide">
                                <p className="text-center">{this.getDescriptionText(destination)}</p>
                                <div className="distancer distancer--short"></div>
                            </div>
                            <div className="list-users list-users--longer scrollbar">
                                {
                                    this.getPlayers().map(p =>
                                        <a key={p.ID} href={null} className={`user ${this.getPlayerClass(p)}`} onClick={(e) => this.selectPlayer(e, p)}>
                                            <div className="avatar">
                                                <img src={this.getPlayerImage(p)} alt="Avatar"/>
                                            </div>
                                            <div className="user__name">
                                                <p className="user__title">{p.Label}</p>
                                                <p className="small grey">{p.Email}</p>
                                            </div>
                                        </a>
                                    )
                                }
                            </div>
                        </div>
                        <div className="modal-footer modal-footer--center">
                            <button type="submit" className="btn btn--default btn--halfwide" data-dismiss="modal">Cancel</button>
                            <input type="submit" className="btn btn--primary btn--halfwide" disabled={!this.state.selectedPlayer} value={this.getSubmitText(destination)} />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const dashboardID = ownProps.dashboardID;    
    return { 
        dashboard: state.dashboard.dashboards.find(d => d.ID === dashboardID), 
        users: state.user.users,
        user: state.account.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //user actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, userActions), dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectPlayerModal))