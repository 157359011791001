
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import * as widgetConstants from '../../../../constants/widgetConstants';
import * as widgetUtils from '../../../../utils/widgetUtils';

class SortingModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateSortDirection = this.updateSortDirection.bind(this);        
        this.addSort = this.addSort.bind(this);
        this.removeSort = this.removeSort.bind(this);
        this.state = {
            selectedField: '',
            selectedSortDirection: ''
        };
    }

    componentDidMount() { 
               
    }  

    updateSortDirection(direction) {    
        let modifier = {...this.props.modifier};
        modifier.SortDirection = direction;
        this.props.updateModifier(modifier);
    }  

    getSortDirections(value, onChange) {
        return <select className="form-control" value={value} onChange={onChange}>
            <option value=""></option>
            <option value="Ascending">Ascending</option>
            <option value="Descending">Descending</option>
        </select>;
    }

    addSort(event) {
        event.preventDefault();
        if(this.state.selectedField && this.state.selectedSortDirection) {
            const modifier = {...this.props.modifier};
            modifier.Sorts = [...modifier.Sorts];
            modifier.Sorts.push({ FieldName: this.state.selectedField, SortDirection: this.state.selectedSortDirection });
            this.props.updateModifier(modifier);
            this.setState({ selectedField: '', selectedSortDirection: '' });
        }
    }

    removeSort(event, fieldName) {
        event.preventDefault();
        const modifier = {...this.props.modifier};
        modifier.Sorts = [...modifier.Sorts];
        const sort = modifier.Sorts.find(s => s.FieldName === fieldName);
        const index = modifier.Sorts.indexOf(sort);
        modifier.Sorts.splice(index, 1);
        this.props.updateModifier(modifier);
    }

    render() {  
        const itemType = widgetUtils.getObjectType(this.props.initialItem);
        let remainingItemKeys;
        if(itemType === 'Object' && this.props.initialItem) {
            const itemKeys = Object.keys(this.props.initialItem);
            remainingItemKeys = itemKeys.filter(i => !this.props.modifier.Sorts.map(s => s.FieldName).includes(i));
        }
        else {
            remainingItemKeys = [];
        }
        return (
            <form className="form">
                <div className="form-group">
                    {
                        itemType === 'Object' && this.props.initialItem ?
                            <React.Fragment>
                                {
                                    this.props.modifier.Sorts.length ?
                                        <div className="pb-3">
                                            <div className="badges">
                                                {
                                                    this.props.modifier.Sorts.map(sort =>
                                                        <div key={sort.FieldName} className="badge badge--x">
                                                            <p>{`${sort.FieldName} (${sort.SortDirection})`}</p>
                                                            <a href="#" className="badge__close" onClick={(e) => this.removeSort(e, sort.FieldName)}>
                                                                <i className="aheadicon-x"></i>
                                                            </a>
                                                        </div>
                                                    )
                                                }                                
                                            </div> 
                                        </div>
                                        : null
                                }
                                {
                                    remainingItemKeys.length ?
                                        <React.Fragment>
                                            <span className="text-muted small d-block">{this.props.modifier.Sorts.length === 0 ? 'Sort by:' : 'Then by:'}</span>
                                            <div className="d-flex align-items-center pb-2">
                                                <select className="form-control" value={this.state.selectedField} onChange={(e) => this.setState({selectedField: e.target.value})}>
                                                    <option value=""></option>
                                                    {
                                                        remainingItemKeys.map(k =>
                                                            <option key={k} value={k}>{k}</option>
                                                        )
                                                    }
                                                </select>
                                                {
                                                    this.getSortDirections(this.state.selectedSortDirection, (e) => this.setState({selectedSortDirection: e.target.value}))                                               
                                                }
                                            </div>
                                            <button className="btn btn--outline btn-addmodifier" type="button" onClick={this.addSort}>
                                                + Add sort
                                            </button>
                                        </React.Fragment>
                                    : null
                                }
                            </React.Fragment>
                        :
                        <React.Fragment>
                            <span className="text-muted small d-block">Select a sort direction</span>
                            {
                                this.getSortDirections(this.props.modifier.SortDirection, (e) => this.updateSortDirection(e.target.value))
                            }
                        </React.Fragment>
                    }
                </div>
            </form>     
        );
    }

}

export default SortingModifier;