/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GridItem from '../GridItem';
import $ from 'jquery';

class Nested extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.data = [{
            "x": 1,
            "y": 0,
            "width": 1,
            "height": 1, 
            "type": "Avatar"
        }, {
            "x": 0,
            "y": 1,
            "width": 3,
            "height": 1,
            "type": "DropDown"
        }, {
            "x": 0,
            "y": 2,
            "width": 3,
            "height": 6,
            "type": "Chart"
        }];
        this.controlRefs = [];
        this.state = {
        };
    }

    componentDidMount() {  
        this.gridCellHeight = 60;
        this.gridCellWidth = 4;
        if(this.gridStackRef !== null){
            const gridStack = $(this.gridStackRef);
            gridStack.gridstack({
                cellHeight: this.gridCellHeight,
                //width: this.gridCellWidth,
                verticalMargin: 10,
                float: true,
                animate: false,
                staticGrid: true
            });
            this.grid = gridStack.data('gridstack');
        }   
    }       

    componentDidUpdate(prevProps, prevState) {
    }

    

    render() {
        return (              
            <div className="grid-stack grid-stack-3 grid-stack-nested" ref={(control) => this.gridStackRef = control} data-gs-width={3} data-gs-height={8}>
                {
                    this.data.map ((item, i) => 
                        <GridItem key={i} type={item.type} xPos={item.x} yPos={item.y} width={item.width} height={item.height} index={i} isNested />
                    )
                }                        
            </div>
        );
    }
}

Nested.propTypes = {
};

export default Nested;