import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';

class ForgotPassword extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = 
        { 
            email: ''
         };
    }

    componentWillMount() {      
        this.props.actions.clearForgotPassword();
    }

    forgotPassword(event) {
        event.preventDefault();
        this.props.actions.forgotPassword(this.state.email);
    }

    onChange(event) {
        const field = event.target.name;
        const value = event.target.value;
        let newState = Object.assign({}, this.state);
		newState[field] = value;
		return this.setState(newState);
    }

    render() {
        return (     
            this.props.user ?
                <Redirect to='/' />
                :
                <section className="loginsignup">
                    <form className="form form--loginsetup" onSubmit={this.forgotPassword}>
                        <div className="loginsetup__logo">
                            <img src={require('../../images/logo-ahead-of-the-game.png')} alt="Ahead of the game" />
                        </div>
                        <div className={`form__resetpass ${this.props.emailSent ? 'hidden-block':null}`}>    
                            <div className="loginsignup__head">
                                <h5 className="withline">Reset password</h5>
                                <p className="intro">Enter your email address and we will send you an email with instructions for resetting your password</p>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input type="email" className="form-control" required="required" name="email" value={this.state.email} onChange={this.onChange} placeholder="Your email address" />
                            </div>
                            <div className="form-group">
                                <input type="submit" className="btn btn--primary btn-block btn-retrievepassword" value="Reset password" />
                            </div>
                            <div className="distancer"></div>
                            <div className="form-group form-group--additional delimited text-center">
                                <p><Link to="/Account/Login" className="btn-text btn-backlogin">Back to login</Link></p>
                            </div>
                        </div> 
                        <div className={`form__resetpassconfirm ${this.props.emailSent ? null : 'hidden-block'}`}>   
                            <div className="loginsignup__head">              
                                <svg height="49.586px" className="picto-confirmation" version="1.1" viewBox="0 0 49.587 49.586" width="49.587px" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
                                    <path d="M49.187,24.792c0,13.469-10.922,24.393-24.394,24.393C11.321,49.186,0.4,38.262,0.4,24.792C0.4,11.318,11.321,0.4,24.793,0.4 C38.265,0.4,49.187,11.318,49.187,24.792z" fill="none" stroke="#51BA67" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8"></path>
                                    <polyline fill="none" points="15.922,24.792 22.575,31.445 35.88,18.14" stroke="#51BA67" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8"></polyline>
                                </svg>                        
                                <h5 className="withline">Reset email sent</h5>
                                <p className="intro">We've sent you an email with instructions for resetting your password. Please check your email and follow the instructions.</p>
                            </div>
                            <div className="form-group">
                                <Link to="/Account/Login" className="btn btn--primary btn-block">Return to login</Link>
                            </div>
                        </div>
                        {this.props.error ?
                            <React.Fragment>
                                <div className="distancer"></div>    
                                    <div className="alert alert-danger">
                                        {this.props.error}
                                        <button type="button" className="close" onClick={this.props.actions.clearForgotPasswordError}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>                                    
                            </React.Fragment>
                            : null
                        }
                    </form>
                </section>
        );
    }
}

ForgotPassword.propTypes = {
    user: PropTypes.object,
    error: PropTypes.string,
    emailSent: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return { user: state.account.user, error: state.account.forgotPasswordError, emailSent: state.account.forgotPasswordEmailSent === true };
}

function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);