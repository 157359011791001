
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import DashboardItem from './DashboardItem';

class DashboardPanel extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.setRef = this.setRef.bind(this);
        this.togglePanel = this.togglePanel.bind(this);
    }

    componentDidMount() {      
       
    }   

    setRef(control) {
        this.panel = control;
    }

    togglePanel(event) {
        event.preventDefault();
        const panel = window.$(this.panel);
        panel.collapse('toggle');
        const target = window.$(event.currentTarget);
        target.toggleClass('open');
        const expanded = target.attr('aria-expanded') === 'true';
        target.attr('aria-expanded', expanded ? 'false' : 'true');
    }

    render() {
        return (
            <React.Fragment>
                <a href={null} className={`btn-dashgroup ${this.props.open ? '' : 'collapsed'}`} aria-expanded={this.props.open === true} onClick={this.togglePanel}>
                    <h5>{this.props.name}</h5>
                    <p className="btn-dashgroup__stats">{this.props.getDashboardSummaryText(this.props.dashboards, false)}</p>
                </a>
                <div className={`dashboards collapse ${this.props.open ? 'show' : ''}`} ref={this.setRef}>                   
                    <div className="dashboards__inner">                   
                        <div className="dashthumbs">     
                            {
                                this.props.dashboards.map(d => {
                                    const currentUserPlayer = d.Players ? d.Players.find(p => p.UserID === this.props.userID) : null;
                                    return <DashboardItem key={d.ID} dashboard={d} role={this.props.role} currentUserPlayer={currentUserPlayer} />    
                                })
                            }  
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

DashboardPanel.propTypes = {
    role: PropTypes.string.isRequired,
    dashboards: PropTypes.array
};

export default DashboardPanel;