import * as types from '../actions/actionTypes';
import {saveState} from '../utils/localStorage';

export default function localStorageMiddleware() {
    return ({ dispatch, getState }) => next => action => {
        if (action.type === types.GET_USER_SUCCESS || action.type === types.TOKEN_REFRESHED) {
            debugger;
            const result = next(action)
            const state = getState(); //this will now give us the new state after calling next
            const account = state.account;
            saveState({
                account: {
                    token: account.token,
                    tokenExpiry: account.tokenExpiry,
                    refreshToken: account.refreshToken,
                    user: account.user
                } 
            });
            return result;
        }  
        else if(action.type === types.LOGOUT) {
            debugger;
            saveState({});
        }
        return next(action);
    };
  }
