
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../utils/imageUtils';
import { withModalContext } from '../../services/modalService';
import { withRouter } from 'react-router-dom';
import InfoModal from '../common/InfoModal';
import roleTypes from '../../constants/roleTypes';
import SelectPlayerModal from './SelectPlayerModal';

const DashboardItem = ({dashboard, showModal, role, currentUserPlayer, users, actions, history}) => { 
    const id = dashboard.ID;
    const name = dashboard.Name;
    const image = dashboard.Thumbnail;
    const locked = dashboard.Locked;
    const disabled = !dashboard.Enabled;
    
    const getDashboardImage = () => {
        const imageData = image ? `url(${imageUtils.getAvatarBase64Url(image)})` : `url(${require('../../images/dash-blank.png')})`;
        return imageData;
    }

    const getDashboardLockedModal = () => {
        return () => <InfoModal title="Unlock dashboard" bodyText="To access this dashboard, please contact your administrator" />
    }

    const showPlayerSelectionModal = (destination) => {
        return () => <SelectPlayerModal destination={destination} dashboardID={dashboard.ID} />;
    }

    const dashboardClicked = (event, destination) => {
        if(dashboard.PlayerMode === 'Single') {
            event.preventDefault();
            if(dashboard.Players.length == 1) {
                //we only have 1 player, default to that player
                let url = `/Dashboards/${dashboard.ID}/Player/${dashboard.Players[0].ID}`;
                if(destination) {
                    url += `/${destination}`;
                }
                history.push(url);
            }
            else {
                showModal(showPlayerSelectionModal(destination), { isOpen: true });
            }
        }
    }

    return (
        <div className={`dashthumb${locked ? ' dashthumb--locked' : ''}${disabled ? ' dashthumb--disabled' : ''}`} title={name}>
            {
                locked ?
                    <a href={null} onClick={() => showModal(getDashboardLockedModal(), { isOpen: true })}>
                        <div className="dashthumb__img" style={{backgroundImage: getDashboardImage()}}>
                            <div className="dashthumb__title">
                                <h5>{name}</h5>
                                <p>Locked pending purchase</p>
                            </div>
                        </div>
                    </a>
                :
                    disabled ?
                    <div className="dashthumb__img" style={{backgroundImage: getDashboardImage()}}>
                        <div className="dashthumb__title">
                            <h5>{name}</h5>
                        </div>
                    </div>
                    :
                        <React.Fragment>
                            <Link to={`/Dashboards/${id}`} onClick={(e) => dashboardClicked(e, false)}>
                                <div className="dashthumb__img" style={{backgroundImage: getDashboardImage()}}>
                                    <div className="dashthumb__title">
                                        <h5>{name}</h5>
                                    </div>
                                </div>
                            </Link>
                            <div className="dashthumb__actions">
                                <a href={null} className="btn-thumbactions" data-toggle="dropdown"><i className="aheadicon-dots"></i></a>
                                <div className="dropdown-menu dropdown-menu--animated dropdown-menu--withicons">
                                    <Link to={`/Dashboards/${id}`} className="dropdown-item" onClick={(e) => dashboardClicked(e, '')}><i className="aheadicon-eye"></i>View</Link>
                                    {
                                        role === roleTypes.Admin || role === roleTypes.Manager || role === roleTypes.SuperUser ?
                                            <React.Fragment>
                                                <Link to={`/Dashboards/${id}/Access`} className="dropdown-item" onClick={(e) => dashboardClicked(e, 'Access')}><i className="aheadicon-access"></i>Access</Link>
                                                <Link to={`/Dashboards/${id}/Settings`} className="dropdown-item"><i className="aheadicon-settings"></i>Settings</Link>
                                                {
                                                    dashboard.Template && dashboard.Template.Type === 'Widget' ?
                                                        <Link to={`/Dashboards/${id}/WidgetSettings`} className="dropdown-item" onClick={(e) => dashboardClicked(e, 'WidgetSettings')}><i className="aheadicon-settings-alt"></i>Customise</Link>
                                                        : null
                                                }
                                               {/*<a className="dropdown-item" href="#"><i className="aheadicon-delete"></i>Delete</a>*/}
                                            </React.Fragment>
                                        : 
                                            currentUserPlayer && currentUserPlayer.AvatarUserEditable ? //we'll get this if the current user is a player in the dashboard
                                                <Link to={`/Dashboards/${id}/Settings/${currentUserPlayer.ID}/Avatar`} className="dropdown-item" href={null}><i className="aheadicon-settings"></i>Avatar Settings</Link>
                                            : null
                                    }
                                    
                                </div>
                            </div>
                        </React.Fragment>
            }
        </div>
    );
};

export default withModalContext(withRouter(DashboardItem));