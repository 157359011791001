import React from 'react';
import Mustache from 'mustache';
import SVG from 'svg.js';
import * as colorUtils from '../../utils/colorUtils';

class Avatar extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getCustomisations = this.getCustomisations.bind(this);
        this.applyColours = this.applyColours.bind(this);
        this.setRef = this.setRef.bind(this);
    }

    componentDidMount() {   
        this.applyColours();
    }

    componentDidUpdate(prevProps, prevState) {
        this.applyColours();
    }

    shouldComponentUpdate(nextProps, nextState) {
        //only update if we have some new avatar selections
        let updated = false;
        const avatarSelectionNext = nextProps.avatarSelection;
        const avatarSelectionCurrent = this.props.avatarSelection;
        //the object reference should change if we have updated something
        return avatarSelectionCurrent != avatarSelectionNext;
        /*if(avatarSelectionNext.Colours.length !== avatarSelectionCurrent.Colours.length || avatarSelectionNext.Customisations.length !== avatarSelectionCurrent.Customisations.length) {
            updated = true;
        }
        else {
            for(let i = 0; i < avatarSelectionCurrent.Colours.length; i++) {
                if(avatarSelectionCurrent.Colours[i].Name !== avatarSelectionNext.Colours[i].Name || avatarSelectionCurrent.Colours[i].Selection !== avatarSelectionNext.Colours[i].Selection) {
                    updated = true;
                    break;
                }
            }
            if(!updated) {
                for(let i = 0; i < avatarSelectionCurrent.Customisations.length; i++) {
                    if(avatarSelectionCurrent.Customisations[i].Name !== avatarSelectionNext.Customisations[i].Name || avatarSelectionCurrent.Customisations[i].Selection !== avatarSelectionNext.Customisations[i].Selection) {
                        updated = true;
                        break;
                    }
                }
            }
        }
        return updated;*/
    }

    getCustomisations() {
        const customisations = {};
        this.props.dashboard.AvatarOverrides.Customisations.forEach(customisation => {
            let content = '';
            if(this.props.avatarSelection && this.props.avatarSelection.Customisations) {
                const playerCustomisation = this.props.avatarSelection.Customisations.find(c => customisation.Name === c.Name);
                if(playerCustomisation) {
                    const selectedCustomisation = customisation.Options.find(o => o.Name === playerCustomisation.Selection);
                    if(selectedCustomisation) {
                        content = selectedCustomisation.Content;
                    }
                }
            }
            if(!content) {
                //show the default
                content = customisation.Options.find(o => o.Name === customisation.DefaultOption).Content;
            }
            customisations[customisation.Name] = content;
        });
        return customisations;
    }

    applyColours() {
        this.props.dashboard.AvatarOverrides.Colours.forEach(colour => {
            colour.Options.forEach(colourOption => {
                if(colourOption.TargetIDs && colourOption.TargetIDs.length) {
                    let colour = '';
                    if(this.props.avatarSelection && this.props.avatarSelection.Colours) {
                        const playerColour = this.props.avatarSelection.Colours.find(c => colourOption.Name === c.Name);
                        if(playerColour) {
                            colour = playerColour.Selection;
                        }
                    }
                    if(!colour) {
                        colour = colourOption.DefaultColour;
                    }
                    colourOption.TargetIDs.forEach(targetID => {
                        const elementSelector = SVG.select(targetID, this.ref);
                        try {
                            elementSelector.fill(colour);
                        }
                        catch(e) { } //it might not be a valid colour so catch any exceptions
                    });
                }
            })
        });
        this.props.dashboard.AvatarOverrides.ColourAdjustments.forEach(colourAdjustment => {
            if(colourAdjustment.TargetID && colourAdjustment.ReferenceID) {
                const referenceColour = SVG.select(colourAdjustment.ReferenceID, this.ref);
                if(referenceColour && referenceColour.members.length) {
                    const referenceFill = referenceColour.members[0].attr('fill');
                    const element = SVG.select(colourAdjustment.TargetID, this.ref);
                    if(element) {
                        try {
                            if(colourAdjustment.AdjustmentType === "Darken") {
                                element.fill(colorUtils.darkenColor(referenceFill, colourAdjustment.Ratio));
                            }
                            else if(colourAdjustment.AdjustmentType === "Lighten") {
                                element.fill(colorUtils.lightenColor(referenceFill, colourAdjustment.Ratio));
                            }
                        }
                        catch(e) { } //it might not be a valid colour so catch any exceptions
                    }
                }
            }
        });
    }

    getDefaultColor(selection) {
        const override = this.state.selections.colours.find(c => c.name === selection.selectionName);
        if(override) {
            return override.selection;
        }
        else {
            return selection.defaultColor;
        }
    }

    setRef(control) {
        this.ref = control;
        if(this.props.setRef) {
            //send our ref to our parent - used for animations
            this.props.setRef(control);
        }
    }

    render() {
        let output = '';
        const { dashboard } = this.props;
        let avatarTemplate = dashboard.Template.AvatarTemplate;
        if(avatarTemplate) {
            const customisations = this.getCustomisations();
            //Mustache.escape = function(text) {return text;}; //don't escape, we no longer need this as the tags are already set not to escape using {{{}}} instead of {{}} 
            output = Mustache.render(avatarTemplate, customisations);
        }
        return (     
           <div ref={this.setRef} dangerouslySetInnerHTML={{__html: output}} />
        );
    }
}

export default Avatar;


