import {createStore, applyMiddleware, compose} from 'redux';
import rootReducer from '../reducers';
import { routerMiddleware } from 'react-router-redux';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import serviceMiddleware from '../middleware/serviceMiddleware';
import tokenRefreshMiddleware from '../middleware/tokenRefreshMiddleware';
import localStorageMiddleware from '../middleware/localStorageMiddleware';
//import { History } from 'history';

export default function configureStore(history, initialState) {
    const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    const myServiceMiddleware = serviceMiddleware();
    const myTokenRefreshMiddleware = tokenRefreshMiddleware();
    const myLocalStorageMiddleware = localStorageMiddleware();
    const store = createStore(
        rootReducer, 
        initialState,
        composeEnhancers(
            applyMiddleware(myTokenRefreshMiddleware, thunk, reduxImmutableStateInvariant(), routerMiddleware(history), myServiceMiddleware, myLocalStorageMiddleware)
          ));

    // Enable Webpack hot module replacement for reducers
    if (module.hot) {
        module.hot.accept('../reducers', () => {
            store.replaceReducer(rootReducer);
        });
    }

    return store;
}