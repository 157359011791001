/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../../../../actions/dashboardActions';
import * as userActions from '../../../../../actions/userActions';
import $ from 'jquery';
import * as imageUtils from '../../../../../utils/imageUtils';
import * as widgetUtils from '../../../../../utils/widgetUtils';
import Highcharts from 'highcharts';
import * as hcnd from 'highcharts/modules/no-data-to-display';
hcnd(Highcharts);

class BarLineChart extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialiseData = this.initialiseData.bind(this);
        this.initialiseChart = this.initialiseChart.bind(this);
        this.getData = this.getData.bind(this);
        this.getCategoryData = this.getCategoryData.bind(this);        
        this.getSeriesData = this.getSeriesData.bind(this);     
        this.getSeriesLabel = this.getSeriesLabel.bind(this);
        this.getColours = this.getColours.bind(this);
        this.getValueLabels = this.getValueLabels.bind(this);
        this.getValuePrefix = this.getValuePrefix.bind(this);
        this.getValueSuffix = this.getValueSuffix.bind(this);
        this.getChartOptions = this.getChartOptions.bind(this);
        this.getTitleFontSize = this.getTitleFontSize.bind(this);
        this.getTitleText = this.getTitleText.bind(this);
        this.getSeriesPlotData = this.getSeriesPlotData.bind(this);
        this.getSeriesTypes = this.getSeriesTypes.bind(this);
        this.state = {
        };
    }

    componentDidMount() {    
      this.initialiseData();
    }       

    componentDidUpdate(prevProps, prevState) {
      if(this.props.widget !== prevProps.widget || this.props.widgetSettings !== prevProps.widgetSettings || this.props.tasks !== prevProps.tasks) {
        this.initialiseData();
      }
    }

    componentWillUnmount() {
      this.chart = null;
    }

    initialiseData() {
      const data = {};
      this.props.widget.CustomVariables.forEach(variable => {
          data[variable.Name] = variable.DefaultValue;
      });
      this.props.widgetSettings.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
          if(adjustment.Append) {
              data[adjustment.Name] += parseFloat(adjustment.Append);
          }
          else {
              data[adjustment.Name] = adjustment.Override;
          }
      });
      data['tasks'] = this.props.tasks;
      this.setState(data, () => {      
        try {      
          if(this.chart) {
            this.chart.update(this.getChartOptions(), true, true);            
            this.chart.reflow();
          }
          else {
            this.initialiseChart(this.chartRef)
          }
        }
        catch(e) {
          console.log(e);
        }
      });
    }

    getData(fieldName, defaultValue) {        
      let data = defaultValue;
      for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
          const binding = this.props.widgetSettings.FieldBindings[i];
          //we only get the widgetHierarchy prop if we're in a nested control
          if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, fieldName)) {
              const dataBinding = this.state.tasks[binding.TaskID];
              if(dataBinding && dataBinding.currentValue) {    
                data = dataBinding.currentValue;
                break;
              }
          }
      }             
      return data;
  }

  getCategoryData() {        
    return this.getData('CategoryData', []);
  }

  getSeriesData() {        
    return this.getData('SeriesData', []);
  }

  getSeriesLabel() {        
    return this.getData('SeriesLabel', '');
  }

  getColours() {        
    return this.getData('Colours', '');
  }

  getValueLabels() {        
    return this.getData('ValueLabels', '');
  }

  getValuePrefix() {        
    return this.getData('ValuePrefix', '');
  }

  getValueSuffix() {        
    return this.getData('ValueSuffix', '');
  }

  getTitleText() {
    return this.getData('TitleText', this.state.titleText);
  }

  getSeriesTypes() {
    return this.getData('SeriesTypes', []);
  }

  initialiseChart(container){
    this.chart = new Highcharts.Chart(container, this.getChartOptions());
  }

  getChartOptions() {      
    const valuePrefix = this.getValuePrefix();
    const valueSuffix = this.getValueSuffix();
    const colours = this.getColours();

    return {
        chart: {
            type: this.props.chartType === 'bar' ? 'column' : '',
            backgroundColor: this.state.backgroundColour || 'fff',
            style: {
              fontFamily: 'Open Sans'
            },
            inverted: this.state.invertAxes
        },
        colors: colours,
        title: {
            text: this.state.showTitle ? this.getTitleText() : '',
            style: {
              fontSize: this.getTitleFontSize(),
              color: this.state.titleColour
          }
        },
        xAxis: {
            categories: this.getCategoryData(),
            title: {
              text: this.state.xAxisTitle || '', 
              enabled: this.state.xAxisTitle ? true: false
            },
            labels: {
              style: {
                fontSize: 14,
                color: '#fff'
              }
            },
            gridLineWidth: this.state.showXAxisGridlines ? 1: 0,
            visible: this.state.showXAxis
        },
        yAxis: {
            min: 0,
            labels: {
                overflow: 'justify'                  
            },
            title: {
              text: this.state.yAxisTitle || '',
              enabled: this.state.yAxisTitle ? true: false
            },
            labels: {
              style: {
                fontSize: 14,
                color: '#fff'
              }
            },
            gridLineWidth: this.state.showYAxisGridlines ? 1 : 0,
            visible: this.state.showYAxis
        },
        legend: {
          layout: 'horizontal',
          //align: 'right',
          //verticalAlign: 'middle',
          enabled: this.state.showLegend,
          itemStyle: {
            color: '#fff',
            fontWeight: 'bold'
          }
        },
        tooltip: {
            valuePrefix: valuePrefix || '',
            valueSuffix: valueSuffix || '',
            followPointer: true
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: this.state.showDataLabels,
                    color: this.state.dataLabelColour || '#fff',
                    style: {
                      textOutline: ''
                    }
                },
                borderWidth: 0,
                borderRadius: 3,
                pointPadding: 0,
                groupPadding: .1
            }
        },
        credits: {
            enabled: false
        },
        series: this.getSeriesPlotData(colours),
        lang: {
          noData: this.props.isEditable ? 'No data to display' : ''
        },
        noData: {
            style: {
                fontWeight: 'normal',
                fontSize: '15px',
                color: '#555'
            }
        }
    }
  }

  getSeriesPlotData(colours) {
    const seriesLabel = this.getSeriesLabel();
    const seriesData = this.getSeriesData();    
    const seriesTypes = this.getSeriesTypes();
    const valueLabels = this.getValueLabels();

    const seriesPlotData = [];
    if(seriesData && Array.isArray(seriesData) && seriesData.length) {
      const initialItem = seriesData[0];
      if(initialItem) {
        if(widgetUtils.getObjectType(initialItem) !== 'Object') {
          //we're plotting a single series
          seriesPlotData.push({
            name: seriesLabel,
            data: seriesData.map((dataItem, index) => {
              return {
                name: this.state.showValueLabels && valueLabels[index] ? valueLabels[index] : '',
                y: dataItem,
                color: this.getColour(colours, index)
              }
            })
          });
        }
        else {
          //we're plotting multiple series, e.g. [ { "current": 45, "target": 60 }, { "current": 30, "target": 50 } ]
          const values = Object.values(initialItem);
          if(values.length) {
            let index = 0;
            Object.keys(initialItem).forEach((key) => {
              //exclude any fields that aren't numerical
              if(!isNaN(initialItem[key])) {
                seriesPlotData.push({
                  name: key,
                  data: seriesData.map((dataItem, itemIndex) => {
                    return {
                      name: this.state.showValueLabels && valueLabels[itemIndex] ? valueLabels[itemIndex] : '',
                      y: dataItem[key]
                    }
                  }),
                  type: seriesTypes && seriesTypes[index] ? (seriesTypes[index] === 'bar' ? 'column' : null) : null
                })
                index++;
              }
            })
          } 
        }
      }
    }
    return seriesPlotData;
  }

  getTitleFontSize() {
    if(this.state.titleSize === 'Small') {
      return '8px';
    }
    else if(this.state.titleSize === 'Medium') {
        return '12px';
    }
    else if(this.state.titleSize === 'Large') {
        return '16px';
    }
    return '16px';
  }

    getColour(colours, index) {
      if(!colours.length) {
        return null;
      }
      else if(colours.length === 1) {
        return colours[0];
      }
      else if(index < colours.length) {
        return colours[index];
      }
      else {
        return colours[index % colours.length];
      }
    }

    render() {
        return (              
            <div className="element element--bar" style={{ overflow: 'hidden' }}>               
                <div className="chart chart--bar" id="chart-bar" ref={(control) => this.chartRef = control}></div>                
            </div>
        );
    }
}

BarLineChart.propTypes = {
};

export default BarLineChart;