import * as types from './actionTypes';
import AccountService from '../services/accountService';
import { push } from 'react-router-redux';
import ToastrService from '../services/toastrService';

export function login(email, password, next) {    
    return function(dispatch) {
        dispatch({type: types.CLEAR_LOGIN_ERROR});
        return AccountService.login(email, password).then(payload => {
            dispatch({ type: types.LOGIN_SUCCESS, payload });
            dispatch(getUser(next));
            const toastrService = new ToastrService();
            toastrService.showSuccess('', 'You have logged in successfully!', null);
        }).catch(error => {
            dispatch({type: types.SET_LOGIN_ERROR, error})
        });
    };
}

export function setLoginError(error) {
    //allow the component to set an error directly and wrap it in an error object
    return { type: types.SET_LOGIN_ERROR, error: new Error(error) };
    /*return function(dispatch) {
        dispatch({ type: types.SET_LOGIN_ERROR, error: new Error(error) })
    };  */
}

export function clearLoginError() {
    return { type: types.CLEAR_LOGIN_ERROR };
    /*return function(dispatch) {
        dispatch({ type: types.CLEAR_LOGIN_ERROR });
    };  */
}

export function clearSignupError() {
    return { type: types.CLEAR_SIGNUP_ERROR };
    /*return function(dispatch) {
        debugger;
        dispatch({ type: types.CLEAR_SIGNUP_ERROR });
    }; */ 
}

export function logout(){
    return function(dispatch) {
        dispatch({ type: types.LOGOUT });
        const toastrService = new ToastrService();
        toastrService.showSuccess('', 'You have logged out successfully!', null);
    };
}

/*function dispatchStatusUpdate(dispatch, type, status) {
    let loadingStatus = {status: status};
    dispatch({ type, loadingStatus });
}*/

export function getUser(next){
    return function(dispatch) {
        debugger;
        return AccountService.getUser().then(user => {
            dispatch({ type: types.GET_USER_SUCCESS, user });
            dispatch(push(next));
        }).catch(error => {
            throw(error);
        });
    };
}

export function forgotPassword(email) {    
    return function(dispatch) {
        dispatch({type: types.CLEAR_FORGOT_PASSWORD_ERROR});
        return AccountService.forgotPassword(email).then(payload => {
            const sent = true;
            dispatch({ type: types.FORGOT_PASSWORD_EMAIL_SENT, sent })
        }).catch(error => {
            dispatch({type: types.SET_FORGOT_PASSWORD_ERROR, error})
        });
    };
}

export function clearForgotPassword() {
    const sent = false;
    return { type: types.FORGOT_PASSWORD_EMAIL_SENT, sent };
    /*return function(dispatch) {
        const sent = false;
        dispatch({ type: types.FORGOT_PASSWORD_EMAIL_SENT, sent })
    };*/  
}

export function clearForgotPasswordError() {
    return { type: types.CLEAR_FORGOT_PASSWORD_ERROR };
    /*return function(dispatch) {
        debugger;
        dispatch({ type: types.CLEAR_FORGOT_PASSWORD_ERROR });
    };*/
}

export function resetPassword(email, code, password) {    
    return function(dispatch) {
        dispatch({type: types.CLEAR_RESET_PASSWORD_ERROR});
        return AccountService.resetPassword(email, code, password).then(payload => {
            const reset = true;
            dispatch({ type: types.PASSWORD_RESET, reset })
        }).catch(error => {
            dispatch({type: types.SET_RESET_PASSWORD_ERROR, error})
        });
    };
}

export function clearResetPassword() {
    const reset = false;
    return { type: types.PASSWORD_RESET, reset };
    /*return function(dispatch) {
        const reset = false;
        dispatch({ type: types.PASSWORD_RESET, reset })
    };*/
}

export function clearResetPasswordError() {
    return { type: types.CLEAR_RESET_PASSWORD_ERROR };
    /*return function(dispatch) {
        debugger;
        dispatch({ type: types.CLEAR_RESET_PASSWORD_ERROR });
    };*/
}

export function setResetPasswordError(error) {
    return { type: types.SET_RESET_PASSWORD_ERROR, error: new Error(error) };
    /*return function(dispatch) {
        dispatch({ type: types.SET_RESET_PASSWORD_ERROR, error: new Error(error) })
    };*/
}

export function changePassword(oldPassword, newPassword) {    
    return function(dispatch) {
        dispatch({ type: types.CLEAR_CHANGE_PASSWORD_ERROR })
        const toastrService = new ToastrService();
        return AccountService.changePassword(oldPassword, newPassword).then(payload => {
            toastrService.showSuccess('', 'Your password has been changed successfully!', null);
        }).catch(error => {
            if(error.message === 'Unable to change password') {
                toastrService.showError('', 'Something went wrong, unable to change your password', null);
            }
            else {
                toastrService.showError('', error.message, null);
            }
        });
    };
}

export function clearChangePasswordError() {
    return { type: types.CLEAR_CHANGE_PASSWORD_ERROR };
}

export function setChangePasswordError(error) {
    return { type: types.SET_CHANGE_PASSWORD_ERROR, error: new Error(error) };
}

export function updateAccountSettings(email, firstName, lastName, avatar, emailAlertsEnabled) {    
    return function(dispatch) {
        dispatch({ type: types.CLEAR_ACCOUNT_SETTINGS_ERROR })
        const toastrService = new ToastrService();
        return AccountService.updateAccountSettings(email, firstName, lastName, avatar, emailAlertsEnabled).then(user => {
            dispatch({ type: types.GET_USER_SUCCESS, user });
            toastrService.showSuccess('', 'Your settings have been updated successfully!', null);
        }).catch(error => {
            dispatch({ type: types.SET_ACCOUNT_SETTINGS_ERROR, error: error })
         });
    };
}

export function clearAccountSettingsError() {
    return { type: types.CLEAR_ACCOUNT_SETTINGS_ERROR };
    /*return function(dispatch) {
        debugger;
        dispatch({ type: types.CLEAR_ACCOUNT_SETTINGS_ERROR });
    };*/ 
}

export function setAccountSettingsError(error) {
    return { type: types.SET_ACCOUNT_SETTINGS_ERROR, error: new Error(error) };
    /*return function(dispatch) {
        dispatch({ type: types.SET_ACCOUNT_SETTINGS_ERROR, error: new Error(error) })
    };*/
}