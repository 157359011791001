/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../../actions/dashboardActions';
import * as userActions from '../../../actions/userActions';
import { withModalContext } from '../../../services/modalService';
import _ from 'lodash';
import $ from 'jquery';
import NewWidgetModal from './NewWidgetModal';
import EditWidgetModal from './EditWidgetModal';
import '../../../styles/gridstack.css';
import '../../../styles/gridstack-extra.css';
import '../../../styles/style-dashboard.css';
import GridItem from '../templates/Widgets/GridItem';
import { Draggable } from '@shopify/draggable';
import roleTypes from '../../../constants/roleTypes';
import DashboardService from '../../../services/dashboardService';
import ToastrService from '../../../services/toastrService';
import * as widgetUtils from '../../../utils/widgetUtils';
const GridStackUI = require('../templates/Widgets/scripts/gridstack');
require('../templates/Widgets/scripts/gridstack.nojQueryUI');

class NewWidget extends React.Component {
    constructor(props, context) {
        super(props, context); 
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.initialiseWidgets = this.initialiseWidgets.bind(this);
        this.initialiseWidgetsDragDrop = this.initialiseWidgetsDragDrop.bind(this);
        this.initialiseGridStack = this.initialiseGridStack.bind(this);
        this.getGridStackItem = this.getGridStackItem.bind(this);
        this.getGridColumnCount = this.getGridColumnCount.bind(this);
        this.getGridRowCount = this.getGridRowCount.bind(this);
        this.getGridCellHeight = this.getGridCellHeight.bind(this);
        this.updateWidget = this.updateWidget.bind(this);
        this.getTempID = this.getTempID.bind(this);
        this.getWidgetName = this.getWidgetName.bind(this);
        this.openEditWidgetModal = this.openEditWidgetModal.bind(this);
        this.setGridItemRef = this.setGridItemRef.bind(this);
        this.saveWidget = this.saveWidget.bind(this);
        this.toggleInlineEditing = this.toggleInlineEditing.bind(this);
        this.initialiseColourPicker = this.initialiseColourPicker.bind(this);
        this.tempIDCounters = {};
        this.state = {
            ...this.props.location.state,
            widgets: [],
            selectedWidgetInstance: null,
            selectedWidget: null,
            showInstructionText: true,
            inlineEditingEnabled: false,
            backgroundColour: null
        };
    }

    componentDidMount() {      
        window.$('body').addClass('dashbg dashnew');
        const dashboardID = this.props.match.params.id;
        if(!this.props.dashboard) {
            //if we're browsing directly to this page, we might not have the dashboard details
            this.props.actions.getDashboard(dashboardID);
        }
        else {
            this.props.actions.checkDashboardVersion(dashboardID);
            if(!this.state.widgets.length && !this.state.showInstructionText) {
                this.setState({ showInstructionText: true });
            }
        }
        if(!this.props.widgets.length) {
            this.props.actions.getWidgets();
        }
        this.initialiseGridStack();
        this.initialiseColourPicker();
    }     

    componentWillUpdate(nextProps, nextState) {
        const willHaveDashboard = !this.props.dashboard && nextProps.dashboard;
        const haveWidgets = this.props.dashboard && this.state.widgets.length;
        const willHaveWidgets = nextProps.dashboard && !this.state.widgets.length && nextState.widgets.length;
        const widgetsDeleted = this.state.widgets.length > 0 && nextState.widgets.length === 0;
        if(((willHaveDashboard && !willHaveWidgets) || widgetsDeleted) && !this.state.showInstructionText) {
            this.setState({ showInstructionText: true });
        }
        else if(!haveWidgets && willHaveWidgets && this.state.showInstructionText) {
            this.setState({ showInstructionText: false });
        }
        if(this.state.widgets.length > nextState.widgets.length) {
            //we've removed an item, delete it from the grid before React removes it from the DOM
            const removedItem = $(this.removedItem);
            if(removedItem.length) {
                this.grid.removeWidget(removedItem, false);
            }
        }
        /*else if(this.state.widgets.length < nextState.widgets.length) {
            const newWidget = nextState.widgets.find(w1 => !this.state.widgets.find(w2 => w2.ID === w1.ID));
            if(newWidget) {
                const widgets = [...this.state.widgets];
                const widget = this.props.widgets.find(w => w.ID === newWidget.WidgetID);
                widgets.push(this.getGridStackItem(widget, newWidget, this.state.widgets.length));
                this.setState({widgets});
            }
        }*/
        //do we need this?
        /*else if(this.props.dashboard.Widgets.length > nextProps.dashboard.Widgets.length) {
            const deletedWidget = this.props.dashboard.Widgets.find(w1 => !nextProps.dashboard.Widgets.find(w2 => w2.ID === w1.ID));
            if(deletedWidget) {
                const widgets = [...this.state.widgets];
                const widgetItem = widgets.find(w => w.key === deletedWidget.ID);
                _.pull(widgets, widgetItem);
                this.setState({widgets});
            }
        }*/
    }
    componentDidUpdate(prevProps, prevState) { 
        if((!this.props.loading && prevProps.loading) || this.state.columnCount !== prevState.columnCount || this.state.rowCount !== prevState.rowCount) {
            //we would have re-rendered gridstack (removed the markup because we were loading, then added it back) so re-initialise it
            this.initialiseGridStack();
            if(!this.props.loading && prevProps.loading) {
                this.initialiseColourPicker();
            }
        }        
    }

    componentWillUnmount() {
        this.widgetsDraggable = null;
        this.grid = null;
        window.$('body').removeClass('dashbg dashnew');
    }

    getTempID(prefix) {
        if(!Object.keys(this.tempIDCounters).includes(prefix)) {
            this.tempIDCounters[prefix] = 0;
        }
        const id = ++this.tempIDCounters[prefix];
        return `${prefix}${id}`;
    }

    setGridItemRef(control) {
        this.newItemRef = control;
        if(this.grid && control) {
            //we've added a new item after gridStack was initialised, add the item to gridstack
            const nodes = this.grid.grid.nodes;
            const controlID = control.dataset.gsId;
            if(!nodes.find(n => n.id === controlID)) {
                this.grid.makeWidget($(this.newItemRef));
            }
        }
    }

    getGridStackItem(widget, widgetInstance, index) {
        return <GridItem 
                    key={widgetInstance.ID} 
                    dashboard={this.props.dashboard}
                    widget={widget} 
                    widgets={this.props.widgets} 
                    tasks={this.state.resolvedTasks} 
                    widgetSettings={widgetInstance} 
                    isEditable={true} 
                    editWidget={this.openEditWidgetModal} 
                    removeWidget={(gridItem) => this.removeWidget(widgetInstance, gridItem)} 
                    xPos={widgetInstance.X} 
                    yPos={widgetInstance.Y} 
                    width={widgetInstance.Width} 
                    height={widgetInstance.Height} 
                    widgetHierarchy={[widgetInstance.ID]}
                    onRef={this.setGridItemRef} 
                    onDataChange={this.onDataChange}
                    index={index}
                    gridColumnCount={this.getGridColumnCount()}
                    removeGridNodesForWidget={this.removeGridNodesForWidget} />;
    }

    initialiseWidgets(props) {
        const dashboardWidgetItems = props.dashboard.Widgets;
        const allWidgets = props.widgets;
        const widgets = [];
        for(let i = 0; i < dashboardWidgetItems.length; i++) {
            const widgetItem = dashboardWidgetItems[i];
            const widget = allWidgets.find(w => w.ID === widgetItem.WidgetID);
            widgets.push(this.getGridStackItem(widget, widgetItem, widgets.length));
        }
        this.setState({ widgets, selectedWidget: null, selectedWidgetInstance: null, selectedWidgetInstanceParent: null });
    }

    getGridRowCount() {
        return this.state.rowCount || 6;
    }

    getGridColumnCount() {
        return this.state.columnCount || 4;
    }

    getGridCellHeight() {
        return 80;
    }

    initialiseGridStack() {
        this.livePosX = null;
        this.livePosY = null;  

        const gridStack = $(this.gridStackRef);
        if(gridStack.length !== 0) {
            if(this.grid) {
                //clear the data to allow it to be reloaded
                gridStack.data('gridstack', null);
            }
            gridStack.gridstack({
                cellHeight: this.getGridCellHeight(),
                width: this.getGridColumnCount(),
                verticalMargin: 0, //20,
                row: this.getGridRowCount(),
                //maxHeight: 10000, //this is a custom parameter which we've implemented
                float: true,
                animate: false
            });
            this.grid = gridStack.data('gridstack');
            gridStack.on('change', (event, items) => this.handleChange(items));
        }       

        this.dragItems = $('.widgets-list .widget-item');
        this.dragDropZones = gridStack;
        this.currentWidget = null;  
        this.currentWidgetID = null;
    }

    initialiseWidgetsDragDrop() {
        if(this.draggable) {
            this.draggable.destroy();
        }
        if(!this.props.loading) {
            const widgetList = $('.widgets-list', $('#modal-new-widget'))[0];
            this.draggable = new Draggable([widgetList], {
                draggable: '.widget-item',
                mirror: {
                    appendTo: 'body',
                }
            })
            .on('drag:start', (dragEvent) => {
                $(this.gridStackRef).addClass('over');
                this.handleDragStart(dragEvent);
                const source = $(dragEvent.source);
                const position = source.position();
                this.leftOffset = dragEvent.sensorEvent.clientX - position.left;
                this.topOffset = dragEvent.sensorEvent.clientY - position.top;            
            })
            .on('drag:move', (dragEvent) => {
                this.handleDragOver(dragEvent);
            })        
            .on('drag:stop', (dragEvent) => {
                //remove the over class from the container
                $(this.gridStackRef).removeClass('over');
                this.handleDrop(dragEvent);
            })
        }
    }

    initialiseColourPicker() {        
        const cPalette = [
            ["#20B99D", "#2ACD70", "#3597E0", "#965DAA", "#3B4657", "#2D3E4E", "#95A3A4"],
            ["#F2C216", "#E47F23", "#E44E3F", "#BCC4C7", "#7E8C8D", "#000", "#fff"]
        ];

        //-- Color Picker
        if(this.swatchRef) {
            const element = window.$(this.swatchRef);

            const defaultColor = element[0].dataset.defaultColor;
            element.spectrum({
                color: defaultColor,
                showPaletteOnly: false,
                showPalette: true,
                showInput: true,
                preferredFormat: "hex",
                palette: cPalette,
                allowEmpty:true,
                showButtons: false,
                appendTo: element.parent('.colorswatch'),
                change: (color) => {        
                    this.setState({backgroundColour: color.toHexString()});
                    element.spectrum("hide");
                }
            });
        }
    }

    //-- Start drag
    handleDragStart(e) {
        console.log("Drag started...");
        //the event object is different based on how we're doing the drag so try both
        const control = $(e.currentTarget || e.originalSource);
        control.addClass('dragged');
        $('body').addClass('drag-in-progress');

        this.currentWidgetID = control.attr('data-wid');
        this.currentWidget = control;

        if(e.originalEvent.dataTransfer) {
            e.originalEvent.dataTransfer.effectAllowed = 'move';
        }
    }
    
    //-- Dragging over target elements
    handleDragOver(e) {
        //console.log("Dragging over..." + e.target || e.originalSource);

        if (e.preventDefault) {
            e.preventDefault();
        }
        if(e.originalEvent.dataTransfer) {
            e.originalEvent.dataTransfer.dropEffect = 'move';
        }  

        //-- Insert new content between block level elements
        let elementOver = $(e.target || e.originalSource);

        //-- Calculate drag position to use it to drop
        //-- element to exact location on the grid
        let gridWidth = $(this.gridStackRef).width();
        let gridHeight = $(this.gridStackRef).height();
        let posX = (e.pageX || e.originalEvent.pageX) - elementOver.offset().left;
        let posY = (e.pageY || e.originalEvent.pageY) - elementOver.offset().top;
        let cellWidth = gridWidth / this.getGridColumnCount();

        this.livePosX = Math.floor( posX / cellWidth );
        this.livePosY = Math.floor( posY / this.getGridCellHeight() );
    }


    //-- Entering drag target
    handleDragEnter(e) {
    // this / e.target is the current hover target.
        e.preventDefault();

        console.log('Drop zone entered')
        $(this).addClass('over');
    }

    //-- Leaving drag target
    handleDragLeave(e) {
        console.log('Left drop zone')
        $(this).removeClass('over');  // this / e.target is previous target element.
    }


    //-- Dropping element
    handleDrop(e) {
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        const widget = this.props.widgets.find(w => w.ID === this.currentWidgetID);

        let wX = this.livePosX,
        wY = this.livePosY,
        wW = widget.DefaultWidth,
        wH = widget.DefaultHeight;

        let columnCount = this.state.columnCount;
        let rowCount = this.state.rowCount;
        if(wX + wW > columnCount) {
            wW = 1;
            wX = columnCount - 1;
            //columnCount = widgetInstance.X + widgetInstance.Width;
        } 
        if(wY + wH > rowCount) {
            wH = 1;
            wY = rowCount - 1;
           // rowCount = widgetInstance.Y + widgetInstance.Height;
        } 

        const widgetName = this.getWidgetName(widget.Slug);
        const widgetInstance = {
            ID: widgetName,
            WidgetID: widget.ID,
            X: wX,
            Y: wY,
            Width: wW,
            Height: wH,
            Name: widgetName,
            Adjustments: [],
            FieldBindings: []
        };
        const widgets = [...this.state.widgets];

        widgets.push(this.getGridStackItem(widget, widgetInstance, this.state.widgets.length));        
        this.setState({ widgets });
        this.dragItems.removeClass('dragged');

        //-- Remove Helper element
        $('.dragSpacer', this.dragDropZones).remove();
        $('body').removeClass('drag-in-progress');
        this.currentWidget = null;
        this.currentWidgetID = null;

        return false;
    }

    handleChange(items) {
        if(items && items.length) {
            const changedItem = items[0];
            const changedWidgetID = changedItem.el[0].dataset.widgetId;
            let gridItem = this.state.widgets.find(w => w.key === changedWidgetID);
            const gridItemIndex = this.state.widgets.indexOf(gridItem);
            if(gridItem) {
                let widget = gridItem.props.widgetSettings;
                //make sure something has changed as we also end up here when new items are added, e.g. in an iteration modifier
                if(widget.X !== changedItem.x || widget.Y !== changedItem.y || widget.Width !== changedItem.width || widget.Height !== changedItem.height) {
                    const widgets = [...this.state.widgets];
                    gridItem = {...gridItem};
                    gridItem.props = {...gridItem.props};
                    gridItem.props.widgetSettings = {...gridItem.props.widgetSettings};
                    gridItem.props.widgetSettings.X = changedItem.x;
                    gridItem.props.widgetSettings.Y = changedItem.y;
                    gridItem.props.widgetSettings.Width = changedItem.width;
                    gridItem.props.widgetSettings.Height = changedItem.height;
                    widgets[gridItemIndex] = gridItem;
                    let columnCount = this.state.columnCount;
                    let rowCount = this.state.rowCount;
                    if(changedItem.x + changedItem.width > columnCount) {
                        columnCount = changedItem.x + changedItem.width;
                    } 
                    if(changedItem.y + changedItem.height > rowCount) {
                        rowCount = changedItem.y + changedItem.height;
                    }
                    this.setState({ widgets, columnCount, rowCount });
                }
            }
        }
    }

    getWidgetName(prefix) {
        let widgetName;
        while(!widgetName || this.state.widgets.find(w => w.Name === widgetName) != null) {
            widgetName = this.getTempID(prefix);
        }
        return widgetName;
    }

    removeWidget(widget, gridItem) {
        if(window.confirm('Delete item?')) {
            this.removedItem = gridItem.gridItemRef;
            const widgets = [...this.state.widgets];
            const widgetIndex = widgets.findIndex(w => w.ID === widget.ID);
            widgets.splice(widgetIndex, 1);
            this.setState({ widgets });
        }
    }    
    
    openEditWidgetModal(widgetInstance, widget, parentWidget = null) {           
        this.setState({selectedWidgetInstance: widgetInstance, selectedWidget: widget, selectedWidgetInstanceParent: parentWidget});
        window.$('#modal-edit-widget').modal('show');
    }

    updateWidget(widgetInstance) {
        const widgets = [...this.state.widgets];
        let widget = widgets.find(w => w.key === widgetInstance.ID);
        const widgetIndex = widgets.indexOf(widget);
        widget = {...widget};
        widget.props = {...widget.props};
        widget.props.widgetSettings = widgetInstance;
        widgets[widgetIndex] = widget;
        this.setState({ widgets });
        window.$('#modal-edit-widget').modal('hide');
    }

    toggleInlineEditing(event) {
        event.preventDefault();
        if(!this.state.inlineEditingEnabled) {
            //we're going to enable it, so set the focus
            window.setTimeout(() => {
                window.$(`#inline-name`).focus();
            }, 300);
        }
        this.setState({ inlineEditingEnabled: !this.state.inlineEditingEnabled });
    }

    saveWidget(event) {
        event.preventDefault();
        if(!this.state.ID) {
            const widget = {
                Name: this.state.widgetName,
                Slug: '', //the slug doesn't matter for custom controls, we use it to map to the control for system controls
                ClientIDs: this.state.clientIDs,
                DashboardIDs: this.state.dashboardIDs,
                Category: 'Custom',
                RowCount: this.state.rowCount,
                ColumnCount: this.state.columnCount,            
                DefaultWidth: 2, //default these values
                DefaultHeight: 3,
                Enabled: true,
                CreatedBy: this.props.user.ID,
                WidgetSource: 'User',
                BackgroundColour: this.state.backgroundColour,
                Children: this.state.widgets.map(w => ({
                    WidgetID: w.props.widgetSettings.WidgetID,
                    Name: w.props.widgetSettings.Name,
                    X: w.props.widgetSettings.X,
                    Y: w.props.widgetSettings.Y,
                    Width: w.props.widgetSettings.Width,
                    Height: w.props.widgetSettings.Height,
                    //we need to check how this gets transferred to a widget instance
                    Adjustments: w.props.widgetSettings.Adjustments
                }))
            }        

            this.props.actions.createWidget(widget)
                .then(newWidget => {
                    const toastrService = new ToastrService();
                    toastrService.showSuccess('', 'Widget has been created successfully!');
                    this.setState({ID: newWidget.ID});
                    this.props.history.goBack();
                });
        }
    }

    render() {
        const dashboard = this.props.dashboard;
        const columnCount = this.getGridColumnCount();
        const rowCount = this.getGridRowCount();
        const minHeight = rowCount * this.getGridCellHeight();
        const minRowCount = Math.max(...this.state.widgets.map(w => w.props.widgetSettings.Height + w.props.widgetSettings.Y)) || 1;
        return (
            <div className={`${this.props.loading ? 'spinner' : ''}`}>
                <style>
                    {`
                        .has-gridlines::after {
                        background-size: calc(100% / ${this.state.columnCount}) calc(100% / ${this.state.rowCount});`
                    }
                    </style>
            {
                !this.props.loading ?
                    <React.Fragment>
                        <header className="dash-head">
                            <div className="dash-head-content">
                                <div>
                                    <a href="#" className="btn-text btn-back" onClick={() => this.props.history.goBack()}>
                                        <i className="aheadicon-caret"></i>
                                        Back
                                    </a>
                                </div>
                                <div className="delimiter"></div>
                                <div>
                                    <p className="dash-head-title">
                                        <span>Create custom widget:</span>
                                        {
                                            this.state.inlineEditingEnabled ?
                                                <input id="inline-name" className="form-control form-control--inline" type="text" value={this.state.widgetName} onChange={(event) => this.setState({ widgetName: event.target.value })} onBlur={this.toggleInlineEditing} onKeyPress={(event) => event.key === "Enter" ? this.toggleInlineEditing(event) : null} />
                                                : 
                                                <span className="ml-2 widget-name inline-edit inline-edit--icon" onClick={this.toggleInlineEditing}>{this.state.widgetName}</span>
                                        }   
                                        <span className="ml-4">Background Colour:</span>       
                                        <div className="colorpick ml-2">
                                            <span className="colorpick__swatch" ref={(swatch) => this.swatchRef = swatch} data-default-color={this.state.backgroundColour} style={{backgroundColor: this.state.backgroundColour, border: !this.state.backgroundColor ? 'solid 1px #555' : null}}></span>
                                            <input type="text" className="form-control" value={this.state.backgroundColour} placeholder="#" onChange={(e) => this.setState({ backgroundColour: e.target.value})} />
                                        </div>                              
                                     </p>
                                </div>
                            </div>
                            <div className="dash-head-actions">
                                <a href="#" className={`btn btn--outline ${this.state.ID ? 'disabled' : ''}`} onClick={this.saveWidget} disabled={this.state.ID}>Create widget</a>
                            </div>
                        </header>
                        <a href="#" className="btn btn--primary btn-newwidget" data-toggle="modal" data-target="#modal-new-widget">
                            <i className="aheadicon-plus"></i>
                            <br />Add widget
                        </a>
                        <main className="cdash">  
                            <div className={`grid-stack grid-stack-${columnCount} custom-widget has-gridlines`} 
                                ref={(control) => this.gridStackRef = control} 
                                data-gs-width={columnCount} 
                                data-gs-height={rowCount} 
                                data-gs-max-height={rowCount} 
                                /*data-gs-current-height={rowCount}*/ 
                                style={{
                                    position: 'absolute', 
                                    marginBottom: '10px', 
                                    minHeight, 
                                    height: minHeight, 
                                    width: '600px', 
                                    border: 'solid 1px rgb(153,153,153)', 
                                    top: '100px', 
                                    left: '50px',
                                    backgroundColor: this.state.backgroundColour
                                }}>
                                {this.state.widgets}                        
                            </div>        
                        </main>   
                        
                         {/* Modal: Add widget */}  
                         <NewWidgetModal 
                            widgets={this.props.widgets} 
                            initialise={this.initialiseWidgetsDragDrop}
                            dashboard={this.props.dashboard}
                            user={this.props.user}
                            newWidget={true} />       
                        
                        {/*Modal: Edit widget */}
                        <EditWidgetModal 
                            tasks={this.props.dashboard ? this.props.dashboard.Tasks : []}
                            dashboard={this.props.dashboard}
                            widgets={this.props.widgets}
                            onDataChange={this.onDataChange}
                            widgetInstance={this.state.selectedWidgetInstance}
                            widgetInstanceParent={null}
                            widget={this.state.selectedWidget}
                            saveWidget={this.updateWidget}
                            resolvedTasks={{}}
                            hideDataTab={true} />
                    </React.Fragment>
                    : null
                }
            </div>
        );
    }
}

NewWidget.propTypes = {
    actions: PropTypes.object.isRequired,
    dashboard: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const dashboardID = ownProps.match.params.id;
    const dashboard = state.dashboard.dashboards.filter(d => d.Template && d.Template.ID).find(d => d.ID === dashboardID);    
    return { 
        dashboards: state.dashboard.dashboards,
        dashboard, 
        clients: state.client.clients,
        loading: state.dashboard.dashboardsLoading || state.dashboard.dashboardItemsLoading.includes(dashboardID) || state.user.usersLoading, 
        user: state.account.user,
        widgets: state.dashboard.widgets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //dashboard actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, dashboardActions, userActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(NewWidget));