/* eslint-disable react/jsx-no-bind */
import React from 'react';
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import $ from 'jquery';
import '../../../../styles/gridstack.css';
import '../../../../styles/gridstack-extra.css';
import '../../../../styles/style-dashboard.css';
import GridItem from './GridItem';
import * as widgetUtils from '../../../../utils/widgetUtils';
import Nested from './controls/Nested';
const GridStackUI = require('./scripts/gridstack');

class GridItemNested extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.gridStackRefs = [];
        this.getGridItem = this.getGridItem.bind(this);
        this.setRef = this.setRef.bind(this);
        this.gridItemIDs = [];
        this.state = {
            iterationData: []
        };
    }

    componentWillMount() {
    }

    componentDidMount() {    
        this.initialise();         
    }   

    componentWillUpdate(nextProps, nextState) {
        this.gridStackRefs = [];
        this.gridItemIDs = [];        
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.widget !== prevProps.widget || this.props.widgetSettings !== prevProps.widgetSettings || this.props.tasks !== prevProps.tasks) {
            this.initialise();
        }
        if(this.state.iterationData !== prevState.iterationData) {
            //remove any nodes linked to this widget except what we've now rendered
            this.props.removeGridNodesForWidget(this.props.widgetSettings.ID, this.gridItemIDs);
        }
    }

    componentWillUnmount() {
        this.grids = null;
    }

    initialise() {
        const binding = this.props.widgetSettings.FieldBindings.find(b => !b.WidgetHierarchy || b.WidgetHierarchy.length === 0);
        let iterationData = [];
        let iterationTaskID = null;
        let iterationVariableID = null;
        if(binding && this.props.tasks) {
            const task = this.props.tasks[binding.TaskID]; 
            if(task) {
                const variableKeys = Object.keys(task.variables);
                if(variableKeys.length) {
                    const finalVariableID = variableKeys[variableKeys.length - 1];
                    const finalVariable = task.variables[finalVariableID];           
                    if(finalVariable && finalVariable.Type === 'Iteration') {    
                        iterationTaskID = binding.TaskID;
                        iterationVariableID = finalVariableID;     
                        if(finalVariable.inputValue) {
                            iterationData = finalVariable.inputValue;
                            if(finalVariable.MaxCount) {
                                iterationData = iterationData.slice(0, finalVariable.MaxCount);
                            }
                        }       
                    }
                    this.setState({
                        iterationTaskID,
                        iterationVariableID,
                        iterationData
                    }, () => {
                        /*const originalWidth = this.props.widgetSettings.Width;
                        const originalHeight = this.props.widgetSettings.Height;
                        if(iterationData.length) {
                            this.props.updateSize(originalWidth * iterationData.length, originalHeight);
                        }*/
                        this.initialiseGridStack();
                    }) 
                }
            }
        }  
        else {
            this.initialiseGridStack();
        }      
    }

    initialiseGridStack() {
        if(this.gridStackRefs && this.gridStackRefs.length) {                    
            let cellHeight = this.props.widget.CellHeight || 60;
            let verticalMargin = 20;
            if(this.props.widget.RowCount) {
                //if we know the number of rows, use a percentage-based layout to fill the parent item
                cellHeight = 97 / this.props.widget.RowCount + '%'
                //cellHeight = Math.floor(100 / this.props.widget.RowCount) + '%'
                //if we're using a percentage for the cell height, set the margin to 5%
                verticalMargin = 0;//'5%';
            }
            this.grids = [];
            this.gridStackRefs.filter(g => g != null).forEach(gridStackRef => {
                const gridStack = $(gridStackRef);
                gridStack.gridstack({
                    cellHeight,
                    verticalMargin,
                    float: true,
                    animate: false,
                    staticGrid: true
                });
                this.grids.push(gridStack.data('gridstack'));
            });            
        }  
    }

    getFieldBindings(widgetFieldBindings, item) {
        //return the bindings for this item and adjust to just include the property name
        const bindings = widgetFieldBindings.filter(b => b.WidgetHierarchy.length && b.WidgetHierarchy[0] === item.ID);
        return bindings;
    }

    getAdjustments(adjustmentOverrides, item) {
        const adjustments = [...item.Adjustments];
        adjustmentOverrides.filter(a => a.WidgetHierarchy.includes(item.ID)).forEach(adjustment => {
            const originalAdjustment = adjustments.find(a => a.Name === adjustment.Name);
            if(originalAdjustment) {
                const index = adjustments.indexOf(originalAdjustment);
                adjustments[index] = adjustment;
            }
            else {
                adjustments.push(adjustment);
            }
        });
        return adjustments;
    }

    setRef(control) {
        this.gridItemRef = control;
        if(this.props.onRef) {
            this.props.onRef(control);
        }
    }

    getGridItem(dataItem, i, iterationTaskID, iterationVariableID, columnCount, x, y, width, height) {
        let tasks = {...this.props.tasks}; 
        if(iterationTaskID && iterationVariableID) {
            let task = {...tasks[iterationTaskID]};
            let variable = task.variables[iterationVariableID];
            variable = Object.assign({}, variable, { currentValue: dataItem });
            task.variables[iterationVariableID] = variable;
            tasks[iterationTaskID] = task;
        }
        //now we have the current value, resolve the other variables that will be based on this
        //TODO: is there a way to only resolve the variables that were based on this value instead of everything?
        //maybe by filtering the tasks we pass in here
        if(this.props.dashboard) {
            tasks = widgetUtils.resolveTasks(this.props.dashboard.Tasks, tasks);
        }
        
        //only allow editing on the 1st instance as if we are repeating, they should all be the same
        const isEditable = this.props.isEditable && i === 0;
        const id = `${this.props.index}-${i}`;
        this.gridItemIDs.push(id);
        return <div className={`grid-stack-item ${isEditable ? 'grid-stack-item-draggable' : ''}`}
            data-gs-id={id}  
            data-widget-id={this.props.widgetSettings.ID}
            key={id}
            ref={this.setRef}            
            data-gs-x={x} 
            data-gs-y={y} 
            data-gs-width={width} 
            data-gs-height={height}
            onMouseOver={() => {
                if(this.resizeHandleRef) {
                    this.resizeHandleRef.style.display = 'inline';
                }
            }} 
            onMouseOut={() => {
                if(this.resizeHandleRef) {
                    this.resizeHandleRef.style.display = 'none';
                }
            }}>
            <div className={`grid-stack-item-content ${this.props.getGridItemContentClass()}`}>     
                {
                    
                    isEditable ?
                        <div className="grid-actions">
                            <a href="#" className="btn btn-simple" onClick={() => this.props.editWidget(this.props.widgetSettings, this.props.widget)}>
                                <i className="aheadicon-settings"></i>
                            </a>
                            <a href="#" className="btn btn-simple" onClick={() => this.props.removeWidget(this)}>
                                <i className="aheadicon-delete"></i>
                            </a>
                        </div>
                        : null
                } 
                <div className={`grid-stack grid-stack-${columnCount}`} ref={(control) => this.gridStackRefs.push(control)} data-gs-width={columnCount} data-gs-height={this.props.widget.RowCount || 0} style={{backgroundColor: this.props.widget.BackgroundColour}}>
                {
                    this.props.widget.Children.map ((item, j) => {
                        const widget = this.props.widgets.find(w => w.ID === item.WidgetID);

                        const widgetSettings = Object.assign({}, item, {
                            FieldBindings: this.getFieldBindings(this.props.widgetSettings.FieldBindings, item),
                            Adjustments: this.getAdjustments(this.props.widgetSettings.Adjustments, item)
                        } )
                        return <GridItem 
                            key={`${this.props.index}-${i}-${j}`} 
                            widget={widget} 
                            widgets={this.props.widgets} 
                            tasks={tasks} 
                            widgetSettings={widgetSettings}
                            xPos={item.X} 
                            yPos={item.Y} 
                            width={item.Width} 
                            height={item.Height} 
                            /*index={j}*/ 
                            //add our ID to the hierarchy we get - we need to build up the IDs going down the levels of children
                            widgetHierarchy={this.props.widgetHierarchy.concat(item.ID)}
                            onDataChange={this.props.onDataChange}
                            isNested={true}
                            isEditable={isEditable}
                            editWidget={(widgetInstance, widget) => this.props.editWidget(widgetInstance, widget, this.props.widgetSettings)}
                            removeWidget={this.props.removeWidget}
                        />
                        }
                    )
                }
                {
                    isEditable ?
                        <div className="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" ref={(control) => this.resizeHandleRef = control} style={{zIndex: 9000000, display: 'none'}}></div>
                        : null
                }   
                </div>         
            </div>      
        </div>
    }

    getItemCoordinates(repeatDirection, index, gridColumnCount, widgetSettings, width, height) {
        let x = 0;
        let y = 0;

        if(repeatDirection === 'Horizontal') {
            //calculate how many we can fit in a row
            const rowFit = Math.floor((gridColumnCount - widgetSettings.X) / widgetSettings.Width); //5, i = 7
            const rowNumber = Math.floor(index / rowFit); // 2
            const positionInRow = index % rowFit;
            x = widgetSettings.X + (widgetSettings.Width * positionInRow);
            y = widgetSettings.Y + (widgetSettings.Height * rowNumber); 
        }
        else {
            x = widgetSettings.X;
            y = widgetSettings.Y + (widgetSettings.Height * index);
        }
        return { x, y };
    }

    render() {
        const columnCount = this.props.widget.ColumnCount || 12;  
        const gridColumnCount = this.props.gridColumnCount;          
        //const variables = widgetUtils.resolveVariables(this.props.widget, this.props.variables);
        //pass any bindings down from the instance to the child
        
        const repeatDirection = this.props.widgetSettings.RepeatDirection;
        const isRepeating = repeatDirection !== 'None';        
        const itemWidth = this.props.widgetSettings.Width || this.props.widget.DefaultWidth || 2;
        const itemHeight = this.props.widgetSettings.Height || this.props.widget.DefaultHeight || 1;
        return (
            <React.Fragment>
                {/*if we're assigned as a widget to an iteration, get each item as set that datasource as a variable and pass it down */}
                {
                    this.state.iterationData.length && isRepeating ?
                        this.state.iterationData.map((dataItem, i) => {
                            const {x, y} = this.getItemCoordinates(repeatDirection, i, gridColumnCount, this.props.widgetSettings, itemWidth, itemHeight)
                            return this.getGridItem(dataItem, i, this.state.iterationTaskID, this.state.iterationVariableID, columnCount, x, y, itemWidth, itemHeight)
                        })
                    : 
                    !isRepeating ? //if we're set to repeat and there are no items, don't render anything
                        this.getGridItem(this.state.iterationData.length ? this.state.iterationData[0] : null, 0, null, null, columnCount, this.props.widgetSettings.X, this.props.widgetSettings.Y, itemWidth, itemHeight)
                        : null
                }               
            </React.Fragment>
        );
    }
}

GridItemNested.propTypes = {
};

export default GridItemNested;