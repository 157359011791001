/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../../../actions/dashboardActions';
import * as userActions from '../../../../actions/userActions';
import $ from 'jquery';
import * as imageUtils from '../../../../utils/imageUtils';
import Nested from './controls/Nested';
import Label from './controls/Label';
import Image from './controls/Image';
import DropDownMultiSelect from './controls/DropDownMultiSelect';
import _ from 'lodash';
import DateRangePicker from './controls/DateRangePicker';
import GridItemNested from './GridItemNested';
import * as widgetUtils from '../../../../utils/widgetUtils';
import GaugeChart from './controls/GaugeChart';
import BarLineChart from './controls/BarLineChart';
import DatePicker from './controls/DatePicker';
import DropDown from './controls/DropDown';
import Grid from './controls/Grid';

class GridItem extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.setGridItemRef = this.setGridItemRef.bind(this);
        this.getControl = this.getControl.bind(this);
        this.getGridItemClass = this.getGridItemClass.bind(this);
        this.getGridItemContentClass = this.getGridItemContentClass.bind(this);
        this.onDataChange = this.onDataChange.bind(this);
        //this.updateSize = this.updateSize.bind(this);
        this.state = {
            tasks: this.props.tasks,// widgetUtils.resolveTasks(this.props.widgetSettings, this.props.tasks),
            width: this.props.width,
            height: this.props.height
        };
    }

    componentDidMount() {    
    }       

    componentDidUpdate(prevProps, prevState) {
        if(this.props.widget !== prevProps.widget || this.props.widgetSettings !== prevProps.widgetSettings || this.props.tasks !== prevProps.tasks) {
            //this.setState({ tasks: widgetUtils.resolveTasks(this.props.widgetSettings, this.props.tasks) });
            this.setState({ tasks: this.props.tasks });
        }
    }

    setGridItemRef(control) {
        this.gridItemRef = control;
        if(this.props.onRef) {
            this.props.onRef(control);
        }
    }

    getControl() {
        const widget = this.props.widget;
        let tasks = this.state.tasks;
        if(widget) {
            if(widget.WidgetSource === 'System') {
                if(widget.Slug === 'label') {
                    return <Label widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} />
                }
                else if(widget.Slug === 'image') {
                    return <Image widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} />
                }
                else if(widget.Slug === 'dropdown-multi-select') {
                    return <DropDownMultiSelect widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} onDataChange={this.onDataChange} />
                }
                else if(widget.Slug === 'dropdown') {
                    return <DropDown widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} onDataChange={this.onDataChange} />
                }
                else if(widget.Slug === 'datepicker') {
                    return <DatePicker widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} onDataChange={this.onDataChange} />
                }
                else if(widget.Slug === 'daterangepicker') {
                    return <DateRangePicker widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} onDataChange={this.onDataChange} />
                }
                else if(widget.Slug === 'gauge-chart') {
                    return <GaugeChart widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} onDataChange={this.onDataChange} />
                }
                else if(widget.Slug === 'bar-chart') {
                    return <BarLineChart chartType="bar" widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} onDataChange={this.onDataChange} />
                }
                else if(widget.Slug === 'line-chart') {
                    return <BarLineChart chartType="line" widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} onDataChange={this.onDataChange} />
                }
                else if(widget.Slug === 'grid') {
                    return <Grid widget={widget} isEditable={this.props.isEditable} tasks={tasks} widgetSettings={this.props.widgetSettings} widgetHierarchy={this.props.widgetHierarchy} onDataChange={this.onDataChange} />
                }
            }
            else if(widget.Children) {
                return <GridItemNested 
                    dashboard={this.props.dashboard}
                    widget={widget} 
                    isEditable={this.props.isEditable}
                    widgets={this.props.widgets} 
                    tasks={tasks}
                    widgetSettings={this.props.widgetSettings} 
                    widgetHierarchy={this.props.widgetHierarchy} 
                    onDataChange={this.onDataChange} 
                    getGridItemContentClass={this.getGridItemContentClass} 
                    index={this.props.index} 
                    editWidget={this.props.editWidget}
                    removeWidget={this.props.removeWidget}
                    onRef={this.props.onRef}
                    gridColumnCount={this.props.gridColumnCount}
                    removeGridNodesForWidget={this.props.removeGridNodesForWidget} />
            }
            else {
                return <div style={{border: "solid 1px #aaa"}}>{this.props.widgetSettings.Name}</div>
                //return <Nested />
            }
        }
        else {
            return <div style={{border: "solid 1px #aaa"}}>{this.props.name}</div>
        }
    }

    onDataChange(widgetHierarchy, propertyName, value) {
        let found = false;
        //check if the output variable was declared on this level - it's not, except for nested widgets?
        /*const widgetVariables = this.props.widgetSettings.Variables;
        for(let i = 0; i < widgetVariables.length; i++) {
            const variable = widgetVariables[i];
            if(variable.Type === 'OutputValue') {
                //we store the property name in the last item of the variable field name hierarchy
                const propertyNameMatch = _.last(variable.WidgetHierarchy) === propertyName;
                if(widgetHierarchy.length === variable.WidgetHierarchy.length - 1 && propertyNameMatch) {
                    let matching = true;
                    for(let j = 0; j < widgetHierarchy.length; j++) {
                        if(widgetHierarchy[j] !== variable.WidgetHierarchy[j]) {
                            matching = false;
                            break;
                        }
                    }
                    if(matching) {
                        //we have the right variable, set the value
                        found = true;
                        const variables = {...this.state.variables};
                        if(!variables[variable.Name]) {
                            variables[variable.Name] = {};
                        }
                        variables[variable.Name] = Object.assign({}, variables[variable.Name], {currentValue: value});
                        this.setState({ variables });
                        break;
                    }
                }
            }
        }*/
        /*const keys = Object.keys(variables);
        for(let i = 0; i < keys.length; i++) {
            const variable = variables[keys[i]];
            if(variable.Type === 'OutputValue') {
                //we store the property name in the last item of the variable field name hierarchy
                const propertyNameMatch = _.last(variable.WidgetHierarchy) === propertyName;
                if(widgetHierarchy.length === variable.WidgetHierarchy.length - 1 && propertyNameMatch) {
                    let matching = true;
                    for(let j = 0; j < widgetHierarchy.length; j++) {
                        if(widgetHierarchy[j] !== variable.WidgetHierarchy[j]) {
                            matching = false;
                            break;
                        }
                    }
                    if(matching) {
                        //we have the right variable, set the value
                        const variablesUpdated = {...variables};
                        variablesUpdated[keys[i]].currentValue = value;
                        this.setState({ variables: variablesUpdated });
                        break;
                    }
                }
            }
        }*/
        if(!found && this.props.onDataChange) {
            this.props.onDataChange(widgetHierarchy, propertyName, value);
        }
    }

    getGridItemClass() {
        const widget = this.props.widget;
        if(widget) {
            return `grid-stack-item--${widget.Slug}`;
        }
        else {
            return '';
        }
    }

    getGridItemContentClass() {
        const widget = this.props.widget;
        return widgetUtils.getGridItemContentClass(widget);
    }

    /*updateSize(width, height) {
        this.setState({ width, height });
    }*/

    render() {
        const widget = this.props.widget;
        return (
            widget ?
                widget.Children ?
                this.getControl()
                :
                <div className={`grid-stack-item ${this.getGridItemClass()} ${this.props.isEditable && !this.props.isNested ? 'grid-stack-item-draggable' : ''}`}
                    data-gs-id={this.props.index} 
                    data-gs-x={this.props.xPos || 0} 
                    data-gs-y={this.props.yPos || 0} 
                    data-gs-width={this.state.width || 2} 
                    data-gs-height={this.state.height || 1} 
                    ref={this.setGridItemRef} 
                    data-widget-id={this.props.widgetSettings ? this.props.widgetSettings.ID : ''}
                    index={this.props.index}
                    onMouseOver={() => {
                        if(this.resizeHandleRef && !this.props.isNested) {
                            this.resizeHandleRef.style.display = 'inline';
                        }
                    }} 
                    onMouseOut={() => {
                        if(this.resizeHandleRef) {
                            this.resizeHandleRef.style.display = 'none';
                        }
                    }}>
                    <div className={`grid-stack-item-content ${this.getGridItemContentClass()}`}>                
                    {
                        this.props.isEditable ?
                            <div className="grid-actions">
                                <a href="#" className="btn btn-simple" onClick={() => this.props.editWidget(this.props.widgetSettings, widget)}>
                                    <i className="aheadicon-settings"></i>
                                </a>
                                {
                                    !this.props.isNested ?
                                        <a href="#" className="btn btn-simple" onClick={() => this.props.removeWidget(this)}>
                                            <i className="aheadicon-delete"></i>
                                        </a>
                                        : null
                                }
                            </div>
                            : null
                    } 
                    {
                        this.getControl()                         
                    }                  
                    </div>   
                    {
                        this.props.isEditable && !this.props.isNested ?
                            <div className="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" ref={(control) => this.resizeHandleRef = control} style={{zIndex: 9000000, display: 'none'}}></div>
                            : null
                    }   
                </div>
            : null       
        );
    }
}

GridItem.propTypes = {
};

export default GridItem;