
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../../../../utils/imageUtils';
import Track from '../Track';
import ScoreboardModal from './ScoreboardModal';
import _ from 'lodash';
import 'url-search-params-polyfill';
import { withRouter } from 'react-router-dom';
import * as processUtils from '../../../../../utils/processUtils';
import * as textUtils from '../../../../../utils/textUtils';
import '../styles/track-italy.css';
import {TweenLite, Linear, Power1} from 'gsap/TweenLite';
import {TimelineMax} from 'gsap/TweenMax';
import Avatar from '../../../Avatar';
import Modal from '../../../../common/Modal';
import SelectPlayerModal from '../../../SelectPlayerModal';

class BikeRace extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getPlayerData = this.getPlayerData.bind(this);
        this.getInitialPlayerData = this.getInitialPlayerData.bind(this);
        this.getMainClass = this.getMainClass.bind(this);
        this.showScoreboard = this.showScoreboard.bind(this);
        this.createBgAnim = this.createBgAnim.bind(this);
        this.createPlayerAnim = this.createPlayerAnim.bind(this);
        this.startBgAnim = this.startBgAnim.bind(this);
        this.stopBgAnim = this.stopBgAnim.bind(this);
        this.startPlayerAnim = this.startPlayerAnim.bind(this);
        this.stopPlayerAnim = this.stopPlayerAnim.bind(this);
        this.getCountdownClass = this.getCountdownClass.bind(this);
        this.setPlayerRef = this.setPlayerRef.bind(this);
        this.dashboardGroupSelected = this.dashboardGroupSelected.bind(this);
        this.startLiveTest = this.startLiveTest.bind(this);
        this.calculateLanePlayerValues = this.calculateLanePlayerValues.bind(this);
        this.processUpdatedData = this.processUpdatedData.bind(this);
        this.getPlayerID = this.getPlayerID.bind(this);
        this.getDashboardID = this.getDashboardID.bind(this);
        this.bikeTypes = {
            WorldRecord: {
                Name: 'WorldRecord',
                LabelName: 'World Record',
                Order: 1,
                ColourOverride: 'gold'
            },
            PersonalBest: {
                Name: 'PersonalBest',
                LabelName: 'Personal Best',
                Order: 2,
                ColourOverride: 'silver'
            },
            Target: {
                Name: 'Target',
                LabelName: 'Target',
                Order: 3,
                ColourOverride: 'white'
            },
            Previous: {
                Name: 'Previous',
                LabelName: 'Previous',
                Order: 4,
                ColourOverride: 'red'
            },
            Current: {
                Name: 'Current',
                LabelName: 'Current',
                Order: 5,
                ColourOverride: null
            },
        };
        this.playerRefs = [];
        this.playerTimelines = [];
        //this.tooltipControls = [];
        let animationStyle = 1;
        let testLiveMode = false;
        const searchParams = new URLSearchParams(props.location.search);
        const animationStyleParam = searchParams.get('animationStyle');
        if(animationStyleParam) {
            animationStyle = parseInt(animationStyleParam, 10);
        }
        const testLiveModeParam = searchParams.get('testLiveMode');
        if(testLiveModeParam) {
            testLiveMode = testLiveModeParam === 'true';
        }
        this.state = {
            isAnimating: false,
            finished: false,
            title: this.props.dashboard.Name,
            backgroundImage: null,
            logo: null,
            logoLarge: null,
            players: {},
            animationStyle,
            testLiveMode,
            showCountdown: false,
            countdownSecs: 3
        }
        this.initialiseDashboard = this.initialiseDashboard.bind(this);
        this.initialisePlayers = this.initialisePlayers.bind(this);
        this.getAvatar = this.getAvatar.bind(this);
        this.clearTimelines = this.clearTimelines.bind(this);
    }

    componentDidMount() {    
        this._isMounted = true;
        this.initialiseDashboard();
        //this.createBgAnim();
        //this.createPlayerAnim();
        window.$('body').addClass('racepage--bikes');
    }

    componentWillUpdate(nextProps, nextState) {
        if(this.props.dashboard.ID !== nextProps.dashboard.ID) {
            this.setState({
                players: {},
                showScoreboard: false,
                showScoreboardButton: false,
                animationStyle: 1,
                testLiveMode: false
            });
            this.stopBgAnim();
            window.$('#modal').modal('hide');
            this.playerRefs = [];
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.playersUpdated) {
            //debugger;
            const newRefs = [];
            const timelinesToKill = [];
            this.playerTimelines.forEach(timeline => {
                const children = timeline.getChildren();
                if(children && children.length && !children[0].target.isConnected) {
                    timelinesToKill.push(timeline);
                }
            });
            timelinesToKill.forEach(timeline => {
                timeline.kill();
                _.pull(this.playerTimelines, timeline);
            });
            
            this.playerRefs.forEach(ref => {
                ref = window.$(ref);
                if(ref && ref.children()[0] && !ref.children()[0].getAttribute('animationAdded')) {
                    newRefs.push(ref);
                }
            });
            if(newRefs.length) {
                const startPlayerAnimations = this.playerAnimationStarted;
                this.createPlayerAnim(newRefs);
                if(startPlayerAnimations) {
                    this.startPlayerAnim();
                }
            }
        }
        this.playersUpdated = false;
        if(prevProps.dashboard.ID !== this.props.dashboard.ID) {
            this.initialiseDashboard();
            //this.createBgAnim
            this.createPlayerAnim();
        }
        if(prevProps.data === null && this.props.data !== null) {
            this.initialisePlayers();
        }
        const dataUpdated = prevProps.data !== null && this.props.data !== null && JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data);
        const dashboardUpdated = prevProps.dashboard != this.props.dashboard;
        if(this.props.dashboard.ID && this.props.dashboard.ID === prevProps.dashboard.ID && (dataUpdated || dashboardUpdated)) {
            if(dashboardUpdated) {
                const dashboardPlayer = this.props.dashboard.Players.find(p => p.ID === this.getPlayerID());
                if(dashboardPlayer) {                    
                    let userAvatar = null;
                    if(dashboardPlayer.UserID) {
                        const user = this.props.users.find(u => u.ID === dashboardPlayer.UserID);
                        if(user) {
                            userAvatar = user.Avatar;
                        }
                    }
                    //update the player in state with the dashboard player                 
                    let player = Object.assign({}, this.state.player, dashboardPlayer, { userAvatar });
                    let title = `${this.props.dashboard.Name} - ${player.Label}`;
                    this.setState({ player, title }, () => this.processUpdatedData());
                    this.playersUpdated = true;
                }
            }
            else if(!this.initialising && dataUpdated) {
                this.processUpdatedData();
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.$('body').removeClass('racepage--bikes');
        this.clearTimelines();
    }

    clearTimelines() {
        //destroy any animation timelines
        this.playerTimelines.forEach(timeline => {
            timeline.kill();
            timeline = null;
        });
        if(this.bg) {
            this.bg.kill();
            this.bg = null;
        }
        if(this.bgSingle) {
            this.bgSingle.kill();
            this.bgSingle = null;
        }
        if(this.bgCurbTop) {
            this.bgCurbTop.kill();
            this.bgCurbTop = null;
        }
        if(this.bgCurbBot) {
            this.bgCurbBot.kill();
            this.bgCurbBot = null;
        }
        if(this.start) {
            this.start.kill();
            this.start = null;
        }
        if(this.trackline) {
            this.trackline.kill();
            this.trackline = null;
        }
    }

    getPlayerID() {
        return this.props.match.params.playerID || this.props.playerID;
    }

    getDashboardID() {
        //if we're in a playlist, then we'll pass the dashboard in as a prop
        return this.props.dashboard ? this.props.dashboard.ID : this.props.id;
    }

    processUpdatedData() {
        const players = this.getPlayerData();
        const playerKeys = Object.keys(players);
        const timePeriodRatio = this.props.data.TimePeriodRatio;
        if(playerKeys.length) {
            const speed = 8;
            let maxDuration = 0;
            for(let i = 0; i < playerKeys.length; i++) {
                const player = players[playerKeys[i]];
                const playersCurrentLaneResult = this.calculateLanePlayerValues(player, timePeriodRatio, speed, maxDuration);
                players[playerKeys[i]] = playersCurrentLaneResult[0];
                maxDuration = playersCurrentLaneResult[1];
            }
            this.setState({
                players
            });
        }
    }

    createBgAnim() {
        //initialise the background image animation
        if(this.bg) {
            this.bg.kill();
            this.bg = null;
        }
        if(this.bgSingle) {
            this.bgSingle.kill();
            this.bgSingle = null;
        }
        if(this.bgCurbTop) {
            this.bgCurbTop.kill();
            this.bgCurbTop = null;
        }
        if(this.bgCurbBot) {
            this.bgCurbBot.kill();
            this.bgCurbBot = null;
        }
        if(this.start) {
            this.start.kill();
            this.start = null;
        }
        if(this.trackline) {
            this.trackline.kill();
            this.trackline = null;
        }
        this.bg = new TimelineMax({
            repeat: -1
        });
        this.bgSingle = new TimelineMax({
            repeat: -1
        });
        this.bgCurbTop = new TimelineMax({
            repeat: -1
        });
        this.bgCurbBot = new TimelineMax({
            repeat: -1
        });
        this.start = new TimelineMax({
            repeat: 0
        });
        /*this.finish = new TimelineMax({
            repeat: 0
        });*/
        this.trackline = new TimelineMax({
            repeat: -1
        });
        /*this.flag = new TimelineMax({
            repeat: 0
        });*/
        this.bg.to(".racetrack__bg", 25, {
            backgroundPosition: "-2800px 0px",
            force3D:true,
            rotation:0.01,
            z:0.01,
            autoRound:false,
            ease: Linear.easeNone
        });
        this.bg.pause();

        //-- Single Track track bacground animation
        this.bgSingle.to(".track-dirt-single", 25, {
            backgroundPosition: "-2800px 0px",
            force3D:true,
            rotation:0.01,
            z:0.01,
            autoRound:false,
            ease: Linear.easeNone
        });
        this.bgSingle.pause();

        //-- Top curb animation
        this.bgCurbTop.to(".track__top", 25, {
            backgroundPosition: "-1400px 0px",
            force3D:true,
            rotation:0.01,
            z:0.01,
            autoRound:false,
            ease: Linear.easeNone
        });
        this.bgCurbTop.pause();
  
        //-- Bottom curb animation
        this.bgCurbBot.to(".track__bottom", 25, {
            backgroundPosition: "-1400px 0px",
            force3D:true,
            rotation:0.01,
            z:0.01,
            autoRound:false,
            ease: Linear.easeNone
        });
        this.bgCurbBot.pause();

        this.start.to(".track__start", 1.5, {left: -100, ease: Power1.ease});
        this.start.pause();
      
        /*this.finish.from(".track__finish", 2.5, {right: -130, ease: Power2.easeOut});
        this.finish.pause();*/
      
        //const duration = 5000;
        //this.trackline.to(".track__line", duration / 1000, {backgroundPosition: '100% 0', ease: Power2.ease});
        this.trackline.to(".track__line", 25, {
            backgroundPosition: "100% 0", 
            force3D:true, 
            rotation:0.01, 
            z:0.01, 
            autoRound:false, 
            ease: Linear.easeNone
        });
        //this.trackline.to(".track__line", duration / 1000, {backgroundPosition: '100% 0', ease: Linear.easeNone});
        this.trackline.pause();
      
        /*this.flag.from(".racetrack__flag", 1.5, {right: -50, ease: Power3.ease});
        this.flag.pause();*/
    }

    createPlayerAnim(playerRefs = this.playerRefs) {
        //if we have previous animation, then clear them and then add again?
        this.playerAnimationStarted = false;
        const timelineCount = this.playerTimelines.length;
        if(timelineCount) {
            for(let i = 0; i < timelineCount; i++) {
               this.playerTimelines[i].kill();
               this.playerTimelines[i] = null;
            }
            this.playerTimelines = [];
        }
        //initialise the player animation
        window.$(playerRefs.filter(p => p != null)).each((index, playerRef) => {
            try {
                playerRef = window.$(playerRef);
                const speed = 0.5;
                const delay = Math.random() / 10;
                const wheels = new TimelineMax({repeat: -1}).delay(delay);
                const movefrontleglower = new TimelineMax({repeat: -1}).delay(delay);
                const movefrontlegupper = new TimelineMax({repeat: -1}).delay(delay);
                const movebackleglower = new TimelineMax({repeat: -1}).delay(delay);
                const movebacklegupper = new TimelineMax({repeat: -1}).delay(delay);
                
                const frontwheel = playerRef.find("#wheel-front").get(0);
                const backwheel = playerRef.find("#wheel-back").get(0);
                const frontpedal = playerRef.find("#pedal-front").get(0);
                const backpedal = playerRef.find("#pedal-back").get(0);
                const frontleglower = playerRef.find("#leg-front-lower").get(0);
                const frontlegupper = playerRef.find("#leg-front-upper").get(0);
                const backleglower = playerRef.find("#leg-back-lower").get(0);
                const backlegupper = playerRef.find("#leg-back-upper").get(0);

                wheels
                    .to(frontwheel, speed, {rotation:360, transformOrigin:"center center", ease:Linear.easeNone})
                    .to(backwheel, speed, {rotation:360, transformOrigin:"center center", ease:Linear.easeNone, delay: -speed})
                    .to(frontpedal, speed, {rotation:360, transformOrigin:"3 2.5", ease:Linear.easeNone, delay: -speed})
                    .to(backpedal, speed, {rotation:360, transformOrigin:"bottom center", ease:Linear.easeNone, delay: -speed})

                movefrontleglower
                    .to(frontleglower, speed / 4, {x: -15, y: -10, rotation:48, transformOrigin:"bottom center", ease:Linear.easeNone})
                    .to(frontleglower, speed / 4, {x: -7, y: -22, rotation: 52, transformOrigin:"bottom center", scale: .95, ease:Linear.easeNone})      
                    .to(frontleglower, speed / 4, {x: 10, y: -10, rotation: 13, transformOrigin:"bottom center", scale: 1, ease:Linear.easeNone})
                    .to(frontleglower, speed / 4, {x: 0, y: 0, rotation:0, transformOrigin:"bottom center", ease:Linear.easeNone})

                movefrontlegupper
                    .to(frontlegupper, speed / 4, {x: -4, y: 5, rotation:-32, transformOrigin:"top left", ease:Linear.easeNone})    
                    .to(frontlegupper, speed / 4, {x: -2, y: 10, rotation: -48, scale: .95, transformOrigin:"top left", ease:Linear.easeNone})
                    .to(frontlegupper, speed / 4, {x: -4, y: 3, rotation: -33, scale: 1, transformOrigin:"top left", ease:Linear.easeNone})
                    .to(frontlegupper, speed / 4, {x: 0, y: 0, rotation:0, transformOrigin:"top left", ease:Linear.easeNone});
            
                movebackleglower
                    .to(backleglower, speed / 4, {x: 26, y: 7, rotation:-35, transformOrigin:"bottom center", ease:Linear.easeNone})
                    .to(backleglower, speed / 4, {x: 7, y: 20, rotation: -28, transformOrigin:"bottom center", scale: .95, ease:Linear.easeNone})      
                    .to(backleglower, speed / 4, {x: -5, y: 10, rotation: 3, transformOrigin:"bottom center", scale: 1, ease:Linear.easeNone})
                    .to(backleglower, speed / 4, {x: 0, y: 0, rotation:0, transformOrigin:"bottom center", ease:Linear.easeNone});
                    
                movebacklegupper
                    .to(backlegupper, speed / 4, {x: 6, y: 8, rotation:-5, transformOrigin:"top left", ease:Linear.easeNone})    
                    .to(backlegupper, speed / 4, {x: 2, y: 8, rotation: 25, scale: 1.05, transformOrigin:"top left", ease:Linear.easeNone})
                    .to(backlegupper, speed / 4, {x: 0, y: 5, rotation: 5, scaleX: 1, transformOrigin:"top left", ease:Linear.easeNone})
                    .to(backlegupper, speed / 4, {x: 0, y: 0, rotation:0, transformOrigin:"top left", ease:Linear.easeNone});
                    

                wheels.pause();
                movefrontleglower.pause();
                movefrontlegupper.pause();
                movebackleglower.pause();
                movebacklegupper.pause();

                this.playerTimelines.push(wheels);
                this.playerTimelines.push(movefrontleglower);
                this.playerTimelines.push(movefrontlegupper);
                this.playerTimelines.push(movebackleglower);
                this.playerTimelines.push(movebacklegupper);
                playerRef.children()[0].setAttribute('animationAdded', true);
            }
            catch(e) {
                console.log(e);
            }
        });
    }

    setPlayerRef(player) {
        this.playerRefs.push(player);
    }

    initialiseDashboard() {
        const dashboard = this.props.dashboard;
        let dashboardData = {};
        //initialise using the template fields and then override with the adjustments
        dashboard.Template.GameVariables.forEach(variable => {
            dashboardData[variable.Name] = variable.DefaultValue;
        });
        dashboard.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
            if(adjustment.Append) {
                dashboardData[adjustment.Name] += parseFloat(adjustment.Append);
            }
            else {
                dashboardData[adjustment.Name] = adjustment.Override;
            }
        });
        const playerID = this.getPlayerID();
        const matchingPlayer = dashboard.Players.find(p => p.ID === playerID);
        if(matchingPlayer) {
            let userAvatar = null;
            if(matchingPlayer.UserID) {
                const user = this.props.users.find(u => u.ID === matchingPlayer.UserID);
                if(user) {
                    userAvatar = user.Avatar;
                }
            }
            const player = Object.assign({}, matchingPlayer, { userAvatar });
            dashboardData['player'] = player;
            dashboardData['title'] = `${dashboard.Name} - ${player.Label}`;
            this.setState(dashboardData, this.initialisePlayers);
        }
    }

    calculateLanePlayerValues(playersCurrentLane, timePeriodRatio, speed, maxDuration) {
        const maxValue = _.max(playersCurrentLane.map(p => p.currentValue));
        for(let j = 0; j < playersCurrentLane.length; j++) {
            const currentPlayer = playersCurrentLane[j];
            let percentage = currentPlayer.percentage;
            if(currentPlayer.bikeName === this.bikeTypes.Current.Name) {
                percentage = this.getPercentage(currentPlayer.currentValue, maxValue);
            }
            else {
                //adjust based on the time ratio
                percentage = this.getPercentage(currentPlayer.currentValue * timePeriodRatio, maxValue);
            }
            const timeMs = Math.round((percentage / speed) * 1000); //this gives us the time to allocate to the transition to get a uniform speed
            const transitionDuration = `${timeMs}ms`; 
            maxDuration = Math.max(timeMs, maxDuration);
            const updatedPlayer = Object.assign({}, currentPlayer, { percentage, transitionDuration })
            playersCurrentLane.splice(j, 1, updatedPlayer);
        }
        return [playersCurrentLane, maxDuration]
    }
    
    async initialisePlayers() {
        this.initialising = true;
        const currentPlayerID = this.getPlayerID();
        if(this.props.data) {
            const currentData = this.props.data;
            const players = this.getPlayerData();
            const playerKeys = Object.keys(players);
            if(playerKeys.length) {
                this.setState({
                    players,
                    showCountdown: true,
                    countdownSecs: 3
                }, () => {
                    this.createPlayerAnim();
                    this.createBgAnim();
                });
                await processUtils.sleep(1000);
                if(!this._isMounted){ return };
                this.setState({
                    showCountdown: true,
                    countdownSecs: 2
                });
                await processUtils.sleep(1000);
                if(!this._isMounted){ return };
                this.setState({
                    showCountdown: true,
                    countdownSecs: 1
                });
                await processUtils.sleep(1000);
                if(!this._isMounted){ return };
                this.setState({
                    showCountdown: true,
                    countdownSecs: 0
                });
                window.setTimeout(() => {
                    if(!this._isMounted){ return };
                    this.setState({
                        showCountdown: false,
                        countdownSecs: 3
                    });
                }, 1000);
                if(!this._isMounted){ return };
                const timePeriodRatio = this.props.data.TimePeriodRatio;
                if(this.state.animationStyle === 1) {
                    const speed = 8; //10
                    let maxDuration = 0;
                    for(let i = 0; i < playerKeys.length; i++) {
                        const playersCurrentLaneResult = this.calculateLanePlayerValues(players[playerKeys[i]], timePeriodRatio, speed, maxDuration);
                        players[playerKeys[i]] = playersCurrentLaneResult[0];
                        maxDuration = playersCurrentLaneResult[1];
                    }
                    this.setState({
                        isAnimating: true
                    });

                    this.startBgAnim();
                    this.startPlayerAnim();
                    this.setState({ players }, async () => {
                        await processUtils.sleep(maxDuration);
                        if(!this._isMounted){ return };
                        if(this.state.testLiveMode) {
                            this.startLiveTest();
                        }
                        //this.stopBgAnim();
                        //this.stopPlayerAnim();
                        await processUtils.sleep(15000);
                        if(!this._isMounted){ return };
                        if(currentPlayerID === this.getPlayerID()) {
                            //make sure we haven't switched to a different dashboard
                            this.showScoreboard(players);      
                        }
                    });
                }
                else if(this.state.animationStyle === 2) {
                    const speed = 12; //10
                    this.setState({
                        isAnimating: true
                    });
                    this.startBgAnim();
                    this.startPlayerAnim();
                    for(let i = 0; i < playerKeys.length; i++) {
                        let maxDuration = 0;
                        const playersCurrentLaneResult = this.calculateLanePlayerValues(players[playerKeys[i]], timePeriodRatio, speed, maxDuration);
                        players[playerKeys[i]] = playersCurrentLaneResult[0];
                        maxDuration = playersCurrentLaneResult[1];
                        this.setState({ players });
                        await processUtils.sleep(maxDuration);
                        if(!this._isMounted){ return };
                    }
                    if(this.state.testLiveMode) {
                        this.startLiveTest();
                    }
                    await processUtils.sleep(15000);
                    if(!this._isMounted){ return };
                    if(currentPlayerID === this.getPlayerID()) {
                        //make sure we haven't switched to a different dashboard
                        this.showScoreboard(players);      
                    }   
                }
                if(currentPlayerID === this.getPlayerID() && JSON.stringify(currentData) !== JSON.stringify(this.props.data)) {
                    //we have new data available, process the update
                    this.processUpdatedData();
                }
            }
        }
        else {
            this.setState({ players: this.getInitialPlayerData() });
        }
        this.initialising = false;
    }

    getPercentage(current, target) {
        let percentage = target === 0 ? 0 : (current / target) * 100;
        if(percentage < 0) {
            percentage = 0;
        }
        if(percentage > 100) {
            percentage = 100;
        }
        return percentage;
    }

    getInitialPlayerData() {
        //get the list of players we're expecting with the default values (likely zeros) - to use for the initial view before we've received the live data
        let playerDashboardData = {};
        if(this.props.dashboard && this.state.player) {
            const player = this.state.player;
            playerDashboardData.id = player.ClientUserID;
            this.props.dashboard.Template.PlayerGameVariables.forEach(i => {
                playerDashboardData[i.Name] = i.DefaultValue; 
            });
        }
        return this.formatPlayerData(playerDashboardData);
    }

    getPlayerData() {
        let playerDashboardData = {};
        if(this.props.data && this.props.dashboard && this.state.player) {
            const player = this.state.player;
            if(player.ClientUserID && this.props.data.id + '' === player.ClientUserID) {
                playerDashboardData = Object.assign({}, this.props.data);
            }
            player.Adjustments.forEach(adjustment => {
                if(adjustment.Append) {
                    playerDashboardData[adjustment.Name] += parseFloat(adjustment.Append);
                }
                else {
                    playerDashboardData[adjustment.Name] = adjustment.Override;
                }
            });
        }
        return this.formatPlayerData(playerDashboardData);
    }

    formatPlayerData(playerDashboardData) {
        const playerData = {};
        const dataKeys = Object.keys(playerDashboardData);
        const bikeKeys = Object.keys(this.bikeTypes);
        for(let i = 0; i < dataKeys.length; i++) {
            const keyName = dataKeys[i];
            let kpi = null;
            let bikeType = null;
            for(let j = 0; j < bikeKeys.length; j++) {
                const bikeKeyName = bikeKeys[j];
                if(keyName.includes(bikeKeyName)) {
                    kpi = keyName.replace(bikeKeyName, '');
                    bikeType = this.bikeTypes[bikeKeyName];
                    break;
                }
            }
            if(kpi && bikeType) {
                if(!playerData[kpi]) {
                    playerData[kpi] = [];
                }
                //default the percentage and set it
                const percentage = 0;
                const playerItem = {
                    id: playerDashboardData.id,
                    label: bikeType === this.bikeTypes.Current ? textUtils.spacePascalCase(kpi) : bikeType.LabelName,
                    bikeName: bikeType.Name,
                    order: bikeType.Order, 
                    percentage,
                    currentValue: playerDashboardData[keyName],
                    transitionDuration: '5s',
                    colourOverride: bikeType.ColourOverride
                };
                playerData[kpi].push(playerItem);
            }
        }
        //sort the players according to the order we assigned
        const playerDataKeys = Object.keys(playerData);
        for(let i = 0; i < playerDataKeys.length; i++) {
            const playerDataArray = playerData[playerDataKeys[i]];
            playerDataArray.sort((a, b) => {
                const aOrder = a.order;
                const bOrder = b.order;
                return aOrder - bOrder;
            });
        }
        return playerData;
    }

    async startLiveTest() {
        const currentPlayerID = this.getPlayerID();
        while(true && currentPlayerID === this.getPlayerID()) {
            if(!this._isMounted){ return };
            const data = {...this.props.data};
            const keys = Object.keys(data);
            for(let i = 0; i < keys.length; i++) {
                const key = keys[i];
                if(key.indexOf('Current') >= 0) {
                    const val = data[key];
                    const multiplier = 1 + (Math.random() * 0.2); //we'll get a random number between 0 and 1 and then cap it by 0.2 by multiplying by 0.2
                    data[key] = (val || 1) * multiplier;
                }
            }
            //make sure we haven't switched to a different dashboard
            if(currentPlayerID === this.getPlayerID())  {
                this.props.actions.updateDashboardData(this.getDashboardID(), currentPlayerID, data);
            }
            await processUtils.sleep(5000);
        }
    }

    getBase64Url(rawString) {
        if(rawString) {
            return imageUtils.getBase64Url(rawString);
        }
        else {
            return "";
        }
    }

    getMainClass() {
        let className = '';
        const players = this.state.players;
        if(players) {
            const playerKeys = Object.keys(players);
            let playerCount = 0;
            if(playerKeys.length) {
                for(let i = 0; i < playerKeys.length; i++) {
                    playerCount += players[playerKeys[i]].length;
                }
            }
            if(playerCount > 7) {
                className += 'racetrack--more-than-7 ';
            }
            if(playerCount > 35) {
                className += 'racetrack--smallest-bikes ';
            }
            else if(playerCount > 21) { 
                className += 'racetrack--larger-bikes ';
            }
            else if(playerCount > 14) {
                className += 'racetrack--ultralarge-bikes ';
            }
            else if(playerCount > 7) {
                className += 'racetrack--ultralarge-bikes ';
            }
            else {
                className += 'racetrack--ultralarge-bikes ';
            }
        }
        if(this.state.isAnimating) {
            className += 'racetrack--animate ';
        }
        /*if(this.state.finished) {
            className += 'showfinish ';
        }*/
        return className;
    }

    getAvatar(player) {
        let avatarSelection = {...this.state.player.AvatarSelection};
        if(player.bikeName !== this.bikeTypes.Current.Name) {
            const selectedColours = [...this.state.player.AvatarSelection.Colours];
            //we want to apply custom colours
            this.props.dashboard.AvatarOverrides.Colours.forEach(colour => {
                if(colour.Name.toLowerCase().includes('shirt') || colour.Name.toLowerCase().includes('shorts') || colour.Name.toLowerCase().includes('shoes') || colour.Name.toLowerCase().includes('helmet')) {
                    colour.Options.forEach(option => {
                        const existingSelection = selectedColours.find(c => c.Name === option.Name);
                        if(existingSelection) {
                            _.remove(selectedColours, existingSelection);
                        }
                        selectedColours.push({
                            Name: option.Name,
                            Selection: this.bikeTypes[player.bikeName].ColourOverride
                        });
                    });
                }
            });
            avatarSelection.Colours = selectedColours;
        }
        return <Avatar avatarSelection={avatarSelection} dashboard={this.props.dashboard} setRef={this.setPlayerRef} />
    }

    async showScoreboard(players) {
        //const scoreboardPlayers = players;// {...players}; - check if this might update in real time
        //else, maybe we can use the ref to update?
        /*const scoreboardModal = <ScoreboardModal ref={this.scoreboardRef} title={this.state.title} logo={this.state.logo} getBase64Url={this.getBase64Url} players={scoreboardPlayers} userAvatar={this.state.player.userAvatar} /> 
        debugger;
        //const scoreboardModal = this.scoreboardRef;
        this.props.showModal(() => scoreboardModal, { isOpen: true })*/
        this.setState({
            showScoreboard: true,
            showScoreboardButton: true
        });
    }

    startBgAnim() {
        this.bg.play();
        TweenLite.fromTo(this.bg, 1, {timeScale:0},{timeScale:1});
        this.bgSingle.play();
        this.bgCurbTop.play();
        this.bgCurbBot.play();
        TweenLite.fromTo(this.bgSingle, 1, {timeScale:0},{timeScale:1});
        TweenLite.fromTo(this.bgCurbTop, 1, {timeScale:0},{timeScale:1});
        TweenLite.fromTo(this.bgCurbBot, 1, {timeScale:0},{timeScale:1});

        //const duration = 5000;
        this.start.play();

        //-- Animate Finish line and flag
        //this.finish.delay( (duration / 1000) - 2 ).play();
        //this.flag.delay( (duration / 1000) - 1.5 ).play();

        //-- Animate Track lines
        this.trackline.play();
    }
  
    async stopBgAnim() {
        /*this.setState({
            finished: true
        });*/
        //await processUtils.sleep(1500);
        //TweenLite.to(this.bg, 1, {timeScale:0});
        TweenLite.fromTo(this.bg, 1, {timeScale:1},{timeScale:0});
        TweenLite.fromTo(this.bgSingle, 1, {timeScale:1},{timeScale:0});
        TweenLite.fromTo(this.bgCurbTop, 1, {timeScale:1},{timeScale:0});
        TweenLite.fromTo(this.bgCurbBot, 1, {timeScale:1},{timeScale:0});
        TweenLite.fromTo(this.trackline, 1, {timeScale:1},{timeScale:0});
        //-- Animate Finish line and flag
        //this.finish.play();
        //this.flag.play();

        //-- Stop the Track lines animation
        //this.trackline.pause();
    }

    startPlayerAnim() {
        this.playerTimelines.forEach(timeline => {
            timeline.play();
            TweenLite.to(timeline, 1, {timeScale:1});
        });
        this.playerAnimationStarted = true;
    }

    async stopPlayerAnim() {
        await processUtils.sleep(1000);
        this.playerTimelines.forEach(timeline => {
            TweenLite.to(timeline, 1, {timeScale:0});
        });
    }

    getCountdownClass() {
        let className = '';
        if(this.state.showCountdown) {
            className += 'in ';
            if(this.state.countdownSecs === 0) {
                className += 'out ';
            }
        }
        return className;
    }

    getPlayersMarkup(players) {
        //this.tooltipControls = [];
        const playersMarkup = [];
        const playerKeys = Object.keys(players);
        if(playerKeys.length) {
            for(let i = 0; i < playerKeys.length; i++) {
                const playersCurrentLane = players[playerKeys[i]];
                for(let j = 0; j < playersCurrentLane.length; j++) {
                    playersMarkup.push(this.getPlayerMarkup(playersCurrentLane[j], playerKeys[i]));
                }
            }
        }
        return playersMarkup;
    }

    getPlayerMarkup(player, labelName) {
        return <li key={`${player.bikeName}_${labelName}`} style={{left: `${player.percentage}%`, transitionDuration: player.transitionDuration }} className={`${player.percentage > 8 ? 'racetrack--labelsback' : ''}`}>
            <div className="bike bike1">
                <p className="list-racers__score">{labelName === 'CallTime' ? textUtils.formatTime(player.currentValue) : _.round(player.currentValue, 2).toLocaleString()}</p>
                <p className="list-racers__name">{player.label}</p>
                {this.getAvatar(player)}
            </div>
        </li>;
    }
    
    dashboardGroupSelected(event) {
        const id = event.target.value;
        const newDashboard = this.props.dashboardsInGroup.find(d => d.ID === id);
        if(newDashboard.PlayerMode === 'Single') {
            window.$('#modal').modal('hide'); //hide any existing modals
            if(newDashboard.Players.length == 1) {
                //we only have 1 player, default to that player
                let url = `/Dashboards/${newDashboard.ID}/Player/${newDashboard.Players[0].ID}`;               
                this.props.history.push(url);
            }
            else {
                const playerSelectionModal = () => <SelectPlayerModal loadAccess={false} dashboardID={newDashboard.ID} />;
                //window.$('#modal').empty();
                this.props.showModal(playerSelectionModal, { isOpen: true });
            }            
        }
        else {
            const dashboardUrl = `/Dashboards/${id}`;
            this.props.history.push(dashboardUrl);
        }
    }

    render() {
        const playerKeys = Object.keys(this.state.players);
        return (
            <div>
                <main className={`racetrack racetrack--self racepage--single ${this.getMainClass()}`}>
                    <section className="racetrack__head">
                        <div className="racetrack__bg" style={{backgroundImage: `url(${this.getBase64Url(this.state.backgroundImage)})`}}></div>
                           <Link to={this.props.playlistID ? '/Playlists' : '/Dashboards'} className="btn btn-back btn--withicon">
                                <i className="aheadicon-arrow"></i>
                                Back
                            </Link>
                        <div className="racetrack__title">
                            <h1><span>{this.state.title}</span></h1>
                        </div>
                        <div className="racetrack__logo">
                            {/*
                                this.state.logo ?
                                    <img src={this.getBase64Url(this.state.logo)} alt={this.state.title}/>
                                    : null
                            */}
                            
                        </div>
                        <div className="ahead-logo">
                            <img src={require('../../../../../images/logo-ahead-of-the-game-tr.png')} alt="Ahead of the game"/>
                        </div>
                        <div className="racetrack__flag">
                            <img src={require('../../../../../images/track-checkered-flag.png')} alt="Checkered flag"/>
                        </div>
                    </section>
                    
                    <section className="racetrack__track">
                        <ul className="list-unstyled list-racers">
                            {this.getPlayersMarkup(this.state.players)}
                        </ul>
                        <div className="tracks">
                            {
                                playerKeys && playerKeys.length ?
                                    playerKeys.map((p, index) => 
                                        <Track key={index} label={textUtils.spacePascalCase(p).toUpperCase()} />
                                    )
                                    :
                                    null
                            }
                        </div>
                        
                    </section>
                </main>
                <div className={`countdown ${this.getCountdownClass()}`}>
                    <div className="countdown__inner">
                        <p>Race start in</p>
                        <p className="countdown__time">0:{this.state.countdownSecs.toString().padStart(2, '0')}</p>
                    </div>
                </div>
                <div className="racepage__ctrl">
                    <form className="form">
                        {
                            this.props.dashboardsInGroup.length > 1 ?
                                <div className="form-group" style={{marginRight: '5px'}}>
                                    <select className="form-control" name="selectedDashboardGroup" value={this.props.dashboard.ID} onChange={this.dashboardGroupSelected}>
                                        {
                                            this.props.dashboardsInGroup.map(d =>
                                                <option key={d.ID} value={d.ID}>{d.Group.MemberName}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                : null
                        }
                        {
                            this.state.showScoreboardButton ?
                                <div className="form-group">
                                    <a href="#" className="btn btn-outline btn-showscore"  onClick={() => this.showScoreboard(this.state.players)}>Show scoreboard</a>
                                </div>
                                : null
                        }
                    </form>
                </div>
                <Modal hideModal={() => this.setState({ showScoreboard: false })}> 
                {/*We use the Modal component directly here instead of using the context API so that we can have it in the render and bind to the data as it needs to be live*/}
                {
                    this.state.showScoreboard ? 
                        <ScoreboardModal dashboardName={this.props.dashboard.Name} playerLabel={this.state.player.Label} logo={this.state.logo} getBase64Url={this.getBase64Url} players={this.state.players} userAvatar={this.state.player.userAvatar} hideModal={() => this.setState({ showScoreboard: false })} onAnimationFinished={this.props.onAnimationFinished} /> 
                        /*We shouldn't need to pass the hideModal function into the ScoreboardModal as well as the Modal component, but thre's some bug that causes it not to work when the dashboard is launched from the select player modal */                                            
                    :null
                }
                </Modal>
            </div>
        );
    }
}

BikeRace.propTypes = {
    dashboard: PropTypes.object,
    users: PropTypes.array,
    data: PropTypes.object
};

export default withRouter(BikeRace);