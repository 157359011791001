import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as accountActions from '../../actions/accountActions';
import 'react-image-crop/dist/ReactCrop.css';
import validator from 'validator';
import ChangePasswordModal from '../common/ChangePasswordModal';
import CropModal from '../common/CropModal';
import * as imageUtils from '../../utils/imageUtils';
import { withModalContext } from '../../services/modalService';

class AccountSettings extends React.Component {
    constructor(props, context) {
        debugger;
        super(props, context);
        this.saveSettings = this.saveSettings.bind(this);
        this.previewImage = this.previewImage.bind(this);
        this.onChange = this.onChange.bind(this);
        this.imageCropped = this.imageCropped.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.state = 
        { 
            email: this.props.user.Email,
            fullName: `${this.props.user.FirstName} ${this.props.user.LastName}`,
            avatar: this.props.user.Avatar ? imageUtils.getAvatarBase64Url(this.props.user.Avatar) : '',
            emailAlertsEnabled: this.props.user.EmailAlertsEnabled
         };
    }

    componentDidMount() {      
    
    }

    saveSettings(event) {
        event.preventDefault();
        if(this.formIsValid()) {
            const nameArr = this.state.fullName.split(' ');
            const firstName = nameArr[0];
            const lastName = nameArr[1];
            const avatar = this.state.avatar ? this.state.avatar.split(',')[1] : null;
            this.props.actions.updateAccountSettings(this.state.email, firstName, lastName, avatar, this.state.emailAlertsEnabled);
        }        
    }

    formIsValid() {
        const state = this.state;
        if(!validator.isEmail(state.email)) {
            this.props.actions.setAccountSettingsError('The email address is missing');
            return false;
        }
        else if(validator.isEmpty(state.fullName)) {
            this.props.actions.setAccountSettingsError('The name is missing');
            return false;
        }
        return true;
    }

    onChange(event) {
        const field = event.target.name;
        if(field === 'avatar') {
            this.props.actions.clearAccountSettingsError();
            if(event.target.files.length) {
                imageUtils.readImage(event, this.previewImage, this.props.actions.setAccountSettingsError, 2);
            }
            else {
                this.setState({
                    avatar: ''
                });
            }              
        }
        else if(field === 'emailAlertsEnabled') {
            const checked = event.target.checked;
            this.setState({
                emailAlertsEnabled: checked
            });
        }
        else {
            const value = event.target.value;
            let newState = Object.assign({}, this.state);
            newState[field] = value;
            return this.setState(newState);
        }       
    }

    previewImage(upload) {
        this.setState({
            avatar: upload.target.result
        }, () => {
            const cropImageModal = <CropModal imageCropped={this.imageCropped} avatar={this.state.avatar} isRounded={true} />
            this.props.showModal(() => cropImageModal, { isOpen: true })
        });
    };

    imageCropped(event, pixelCrop) {
        event.preventDefault();
        window.$('#modal').modal('hide');
        const image = window.$('#imgAvatar')[0];
        const croppedImage = imageUtils.getCroppedImage(image, pixelCrop)
        this.setState({avatar: croppedImage});
    }

    render() {
        return (     
            <React.Fragment>
                <Link to="/" className="btn-close"><i className="aheadicon-x"></i></Link>
                <section className="loginsignup">        
                    <form className="form" onSubmit={this.saveSettings}>        
                        <div className="loginsignup__head">
                            <h5 className="withline">Account Settings</h5>
                            <p className="intro">Edit your avatar and account settings</p>
                        </div>        
                        <div className="form-group">
                            <div className={`upload-avatar ${this.state.avatar ? 'upload-avatar--uploaded' : ''}`}>
                                <div className="avatar">
                                    {
                                        this.state.avatar ?
                                            <img id="imgAvatar" src={this.state.avatar} alt="avatar" />
                                            : 
                                            <i className="aheadicon-plus"></i>
                                    }                                        
                                </div>
                                <input type="file" name="avatar" accept="image/*" onChange={this.onChange} />
                                <p>Upload avatar</p>
                            </div>
                        </div>        
                        <div className="form-group">
                            <label>Full name</label>
                            <input type="text" required="required" className="form-control" name="fullName" value={this.state.fullName} onChange={this.onChange} placeholder="Enter your full name" />
                            <p className="form__error">This input is mandatory</p>
                        </div>                
                        <div className="form-group">
                            <label>Email</label>
                            <input type="email" required="required" className="form-control" name="email" value={this.state.email} onChange={this.onChange} placeholder="Enter your email address" />
                        </div>
                        
                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="emailalerts" name="emailAlertsEnabled" checked={this.state.emailAlertsEnabled} onClick={this.onChange} />
                            <label className="custom-control-label" htmlFor="emailalerts">Email Alerts Enabled</label>
                            </div>                                
                        </div>
                
                        <div className="form-group text-center">
                            <a href={null} className="btn-text btn-changepassword" onClick={() => this.props.showModal(ChangePasswordModal, { isOpen: true })}>Change Password</a>
                        </div>
                        <div className="distancer distancer--short"></div>        
                        <div className="form-group">
                            <input type="submit" className="btn btn--primary btn-block" value="Save settings" />
                        </div> 

                        {this.props.accountSettingsError ?
                            <React.Fragment>
                                <div className="distancer"></div>    
                                    <div className="alert alert-danger">
                                        {this.props.accountSettingsError}
                                        <button type="button" className="close" onClick={this.props.actions.clearAccountSettingsError}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>                                    
                            </React.Fragment>
                            : null
                        }
                        <br />
                    </form>     
                </section>      
            </React.Fragment>
        );
    }
}

AccountSettings.propTypes = {
    user: PropTypes.object,
    actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return { user: state.account.user, accountSettingsError: state.account.accountSettingsError };
}

function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(AccountSettings));