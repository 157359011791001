/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../actions/dashboardActions';
import * as playlistActions from '../../actions/playlistActions';
import PlaylistSettingsDashboardItem from './PlaylistSettingsDashboardItem';
import { withModalContext } from '../../services/modalService';
import AddUpdateItemModal from './AddUpdateItemModal';
import Dragula from 'react-dragula';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
const _ = require('lodash');

class PlaylistSettings extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getItemCount = this.getItemCount.bind(this);
        this.getItems = this.getItems.bind(this);
        this.addItem = this.addItem.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.initDragula = this.initDragula.bind(this);
        this.toggleSelectedItem = this.toggleSelectedItem.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.deleteSelectedItems = this.deleteSelectedItems.bind(this);
        this.state = {
            selectedItemIDs: []
        }
    }

    componentDidMount() {      
        if(!this.props.playlist) {
            //if we're browsing directly to this page, we might not have the playlist details
            this.props.actions.getPlaylists();
        }
        if(!this.props.dashboards || !this.props.dashboards.length) {
            this.props.actions.getDashboards();
        }
    }   

    getItemCount() {
        if(!this.props.playlist || !this.props.playlist.Items) {
            return '0 items';
        }
        else {
            return `${this.props.playlist.Items.length} item${this.props.playlist.Items.length === 1 ? '' : 's'}`;
        }
    }

    getItems() {
        const dashboards = this.props.dashboards;
        if(!this.props.playlist || !this.props.playlist.Items) {
            return [];
        }
        else {
            let items = this.props.playlist.Items.map(item =>
                {
                    let dashboardName = '';
                    if(dashboards && item.DashboardID) {
                        const dashboard = dashboards.find(d => d.ID === item.DashboardID);
                        if(dashboard) {
                            dashboardName = dashboard.Name;
                        }
                    }
                    return {
                        ...item,
                        Name: dashboardName
                    };
                }
            );
            items.sort((a, b) => {
                const aOrder = a.Order;
                const bOrder = b.Order;
                return aOrder - bOrder;
            });
            return items;
        }
    }

    addItem(event, items) {       
        debugger;
        event.preventDefault();
        const dashboards = this.props.dashboards.filter(d => d.ClientID === this.props.playlist.ClientID);
        const addUpdateItemModal = <AddUpdateItemModal playlistID={this.props.playlist.ID} dashboards={dashboards} order={items.length + 1} actions={this.props.actions} />
        this.props.showModal(() => addUpdateItemModal, { isOpen: true })
    }

    updateItem(event, items, item) {
        debugger;
        event.preventDefault();
        const dashboards = this.props.dashboards.filter(d => d.ClientID === this.props.playlist.ClientID);
        const addUpdateItemModal = <AddUpdateItemModal playlistID={this.props.playlist.ID} dashboards={dashboards} item={item} actions={this.props.actions} />
        this.props.showModal(() => addUpdateItemModal, { isOpen: true })
    }

    toggleSelectedItem(event, itemID) {
        let selectedItemIDs = [...this.state.selectedItemIDs];
        if(!selectedItemIDs.find(i => i === itemID)) {
            selectedItemIDs.push(itemID);
        }
        else {
            _.pull(selectedItemIDs, itemID);
        }
        this.setState({
            selectedItemIDs
        })
    }

    isSelected(itemID) {
        return this.state.selectedItemIDs.find(i => i === itemID) ? true : false;
    }

    initDragula(componentBackingInstance) {
        if(componentBackingInstance) {
            const dragTask = Dragula([componentBackingInstance], {
                isContainer: function (el) {
                    return false;
                },
                moves: function (el, container, handle) {
                    console.log(handle);
                    return handle.classList.contains('useritem__drag');
                },
                accepts: function (el, target, source, sibling) {
                    return true;
                },
                invalid: function (el, handle) {
                    return false;
                },
                direction: 'vertical',
                copy: false,
                copySortSource: false,
                revertOnSpill: true,
                mirrorContainer: document.body,
                ignoreInputTextSelection: true
            });
            //-- Drag event started
            /*dragTask.on('drag', function(el, source){
                 console.log("Drag started: " + el);
            });*/
                
            //-- Drag event Ended / element dropped
            dragTask.on('drop', (el, target, source, sibling) => {
                let newOrder;
                if(sibling) {
                    newOrder = parseInt(sibling.getAttribute('order'), 10);
                    let originalOrder = parseInt(el.getAttribute('order'), 10);
                    if(newOrder < originalOrder) {
                        //we've moved up, so add 1 as we want to be after our new sibling
                        newOrder++;
                    }
                    else {
                        //we've moved down, so subtract 1 as we want to be before our new sibling
                        newOrder--;
                    }
                } 
                else {
                    newOrder = _.max(this.props.playlist.Items.map(i => i.Order));
                }
                const itemID = el.dataset.itemId;
                const item = this.props.playlist.Items.find(i => i.ID === itemID);
                debugger;
                if(item) {
                    this.props.actions.updatePlaylistItem(this.props.playlist.ID, item.ID, item.DashboardID, item.PlayerID, item.Seconds, item.UseSpecifiedDuration, item.WaitUntilAnimationCompletes, newOrder);
                }
                
             //console.log("Element dropped to: " + target);
            });
        }   
    }

    deleteSelectedItems(event) {
        event.preventDefault();
        if(this.state.selectedItemIDs.length) {
            const deleteAction = () => {
                this.props.actions.deletePlaylistItems(this.props.playlist.ID, this.state.selectedItemIDs)
                    .then(_ => this.setState({
                        selectedItemIDs: []
                    }));
            } 
            const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to delete the selected item${this.state.selectedItemIDs.length > 1 ? 's' : ''}? This action is irreversible.`} />
            this.props.showModal(() => deleteConfirmationModal, { isOpen: true })
        }
    }

    render() {
        const playlist = this.props.playlist;
        const items = this.getItems();
        return (
            <div className={`main__body scroll ${this.props.loading ? 'spinner' : ''}`}>
            {
                !this.props.loading ?
                    <div className="pane pane--fixed pane--dashboards">
                        <Link to="/Playlists" className="btn-close"><i className="aheadicon-x"></i></Link>
                        <div className="pane__head">
                            <div className="pane__ctrl">
                                <div className="pane__info">
                                    <h5>Playlist Settings: {playlist ? playlist.Name : ''}</h5>
                                    <p>{this.getItemCount()}</p>
                                </div>
                                {
                                    playlist ?
                                        <div className="pane__actions">
                                            {
                                                this.state.selectedItemIDs.length ?
                                                    <button href={null} className="btn btn--secondary btn-multiaction btn-delete btn--withlasticon" onClick={this.deleteSelectedItems}>Delete selected<i className="aheadicon-delete"></i></button>
                                                : null
                                            }
                                            <button href={null} className={`btn btn--primary btn-newuser btn--withlasticon ${this.state.selectedItemIDs.length ? 'hidden-xs' : ''}`} onClick={(e) => this.addItem(e, items)}>New dashboard<i className="aheadicon-plus"></i></button>
                                      </div>
                                    : null
                                }
                                
                            </div>
                        </div>
                        
                        <div className="pane__body scrollbar">
                            <form className="form">
                                <div className={`userslist userslist--play userslist--draggable`} ref={this.initDragula}>
                                    {
                                        items.map((item, i) =>
                                            <PlaylistSettingsDashboardItem key={item.ID} index={i} order={item.Order} item={item} playlist={playlist} dashboards={this.props.dashboards} showModal={this.props.showModal} actions={this.props.actions} toggleSelectedItem={this.toggleSelectedItem} selected={this.isSelected(item.ID)} updateItem={(e) => this.updateItem(e, items, item)} />
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                        
                    </div>
                    : null
                }
            </div>
        );
    }
}

PlaylistSettings.propTypes = {
    actions: PropTypes.object.isRequired,
    playlist: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const playlistID = ownProps.match.params.id;
    return { 
        playlist: state.playlist.playlists.find(p => p.ID === playlistID), 
        loading: state.playlist.playlistsLoading || state.dashboard.dashboardsLoading, 
        dashboards: state.dashboard.dashboards
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign({}, dashboardActions, playlistActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(PlaylistSettings));