import moment from 'moment';
import Mustache from 'mustache';
import _ from 'lodash';
import { formattingFunctions, aggregateFunctions, groupingFunctions } from '../constants/widgetConstants';
const FormulaParser = require('hot-formula-parser').Parser;

export function resolveTasks(tasks, resolvedTasks, initialiseIterations = false) {
    if(tasks) {
        resolvedTasks = {...resolvedTasks};
        tasks.forEach(task => {
            if(!resolvedTasks[task.ID]) {
                resolvedTasks[task.ID] = {
                    name: task.Name,
                    currentValue: null,
                    variables: { }
                }
            }
            /*if(variable.Name === 'Filtered Data Last Month') {
                debugger;
            }*/
            //resolve the variables within the task
            const resolvedTask = {...resolvedTasks[task.ID]};
            task.Variables.forEach(variable => {
                resolveVariable(variable, resolvedTask, resolvedTasks, initialiseIterations);
            })
            if(task.Variables.length) {
                const finalVariable = resolvedTask.variables[task.Variables[task.Variables.length - 1].ID];
                if(finalVariable) {
                    resolvedTask.currentValue = finalVariable.currentValue;
                    resolvedTask.outputType = finalVariable.OutputType;
                }
            }
            resolvedTasks[task.ID] = resolvedTask;
        });
    }
    return resolvedTasks;
}

function resolveVariable(variable, resolvedTask, resolvedTasks, initialiseIterations = false) {
    if(variable.Type === 'FieldSelection') {
        let value = getValue(variable.InputVariableID, resolvedTasks);
        if(value) {
            if(variable.FieldName) {
                //the field name shouldn't be blank, but let's protect against that and return the original value in that case
                value = value[variable.FieldName];
            }
            if(!resolvedTask.variables[variable.ID]) {
                resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
            }
            else {
                resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
            }
            resolvedTask.variables[variable.ID].currentValue = value;  
            resolvedTask.variables[variable.ID].OutputType = getObjectType(value);  
        }
    }
    else if(variable.Type === 'Template') {
        const templateString = variable.TemplateString;
        let inputs = {};
        if(variable.Inputs.length === 1) {
            const variableInput = variable.Inputs[0];
            inputs = getValue(variableInput, resolvedTasks);  
        }
        else {
            //name the variables
            variable.Inputs.forEach(variableID => {
                const variableInput = getVariable(variableID, resolvedTasks);
                inputs[variableInput.Name] = variableInput.currentValue;                        
            });
        }
        let output;
        if(templateString && inputs) {
            try {
                output = Mustache.render(templateString, inputs);
            }
            catch(e) {}
        }
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = output;
    }
    else if(variable.Type === 'Transformation') {
        let value = getValue(variable.InputVariableID, resolvedTasks);
        if(value) {
            if(!resolvedTask.variables[variable.ID]) {
                resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
            }
            else {
                resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
            }
            //const flattenOutput = variable.TransformationActions.length === 1 && !variable.TransformationActions[0].IsDerived && !variable.TransformationActions[0].OutputName;
            const transformFunc = ((item) => {   
                let o = {};                        
                variable.TransformationActions.forEach(action => {
                    if(!action.IsDerived) {
                        o[action.OutputName || action.OriginalName] = item[action.OriginalName];
                    }
                    else {
                        const formula = action.Formula;                   
                        var parser = new FormulaParser();
                        addCustomFunctions(parser);
                
                        const variableMappings = {};
                        parser.on('callCellValue', function(cellCoord, done) {
                            const variableKeys = Object.keys(parser.variables);
                            if (variableKeys.includes(cellCoord.label)) {
                                const value = parser.variables[cellCoord.label];
                                done(value);
                            }
                            else {
                                done('#NAME?');
                            }
                        });                     
                        //we should also add formula inputs
                        Object.keys(item).forEach(key => {   
                            parser.setVariable(key, item[key]);
                        });

                        let outputName = action.OutputName;
                        if(outputName && outputName.indexOf('{{') > -1 && outputName.indexOf('}}') > -1) {
                            //we've probably used templating
                            try {
                                outputName = Mustache.render(outputName, item);
                            }
                            catch(e) {}
                        }
                        o[outputName] = parser.parse(formatFormulaForParsing(formula, variableMappings)).result;
                    }
                });
                return o;
            });
            if(variable.OutputType === 'Array') {                        
                let output = value.map(item => transformFunc(item));                      
                resolvedTask.variables[variable.ID].currentValue = output;
            }    
            else if(variable.OutputType === 'Object') {
                const output = transformFunc(value, false);
                resolvedTask.variables[variable.ID].currentValue = output;
            } 
        }
    }
    else if(variable.Type === 'UserDefined') {
        //parse the data depending on the type and then set the value;
        let value = variable.Value;
        //looks like we're already getting it as the correct value type as we're saving it as an object
        /*if(variable.OutputType === 'Array' || variable.OutputType === 'Object') {
            value = JSON.parse(variable.Value);
        }
        else if(variable.OutputType === 'Date' || variable.OutputType === 'DateTime') {
            value = new Date(variable.Value);
        }
        else {
            value = variable.Value;
        }*/
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = value;
    }
    else if(variable.Type === 'Formatting') {
        let value = getValue(variable.InputVariableID, resolvedTasks);
        let inputType;
        const input = getVariable(variable.InputVariableID, resolvedTasks);
        if(input) {
            //the output type of the input variable is our input type
            inputType = input.OutputType;
            if(inputType === 'Object') {
                //we can't format object types, but if there's a single item in the object, try using that
                const objectKeys = Object.keys(input.currentValue);
                if(objectKeys.length === 1) {
                    inputType = getNestedObjectType(input.currentValue);
                    value = input.currentValue[objectKeys[0]];
                }                            
            }
        }                    
        if(value) {
            //we need to also support format functions etc
            if(inputType === 'Date' || inputType === 'DateTime') {
                value = moment(value).format(variable.FormatString);
            }
            else if(inputType === 'Double' && variable.FormatString) {
                value = _.round(value, parseInt(variable.FormatString, 10));
            }
            else if(inputType === 'Text' && variable.FormatFunction) {
                const formattingFunction = formattingFunctions.find(f => f.name === variable.FormatFunction);
                if(formattingFunction) {
                    ////the function inputs could be variables?
                    const functionInputs = variable.FunctionInputs.map(i => getValue(i, resolvedTasks));
                    value = formattingFunction.func(value, functionInputs);
                }
            }
            else {
                //we need to return text so if we can't format, return an empty string
                value = '';
            }
        }
        else {
            value = '';
        }
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = value;
    }
    else if(variable.Type === 'Filter') {
        let value = getValue(variable.InputVariableID, resolvedTasks);
        let filtered;
        if(value) {
            //clone it so we don't filter the original value
            filtered = [];
            if(variable.Formula) {
                //iterate through the item passing them in - the values? if they're object type, else the value called 'input'?

                let result;
                const formula = variable.Formula;                  
                var parser = new FormulaParser();
                addCustomFunctions(parser);

                const variableMappings = {};
                parser.on('callCellValue', function(cellCoord, done) {
                    const variableKeys = Object.keys(parser.variables);
                    if (variableKeys.includes(cellCoord.label)) {
                        const value = parser.variables[cellCoord.label];
                        done(value);
                    }
                    else {
                        done('#NAME?');
                    }
                });                     

                variable.FormulaInputs.forEach(taskID => { 
                    let currentValue = null;
                    const inputTask = resolvedTasks[taskID];
                    if(inputTask) {
                        currentValue = inputTask.currentValue;
                    }
                    if(currentValue) {
                        if(getObjectType(currentValue) === 'Object') {
                            //if it's an object, use it's keys as property names
                            const keys = Object.keys(currentValue);
                            keys.forEach(key => {
                                parser.setVariable(key, currentValue[key]);
                            });
                        }
                        else {
                            const parserID = `ID_${taskID.toUpperCase().replace(/\s+/g,'')}`;
                            parser.setVariable(parserID, currentValue);   
                            //we use the variable ID as the variable, but the user will enter the name so store the mapping. 
                            //This is because the name could have a structure that breaks the parsing
                            variableMappings[inputTask.name] = parserID;
                            //also add a mapping from the original ID to the parserID as we're saving records to the database using the original ID
                            variableMappings[taskID] = parserID;
                        }
                    }
                });
                const resultCache = {};
                const valueIsObject = value.length && getObjectType(value[0]) === 'Object';
                const expression = formatFormulaForParsing(formula, variableMappings);
                const variablesToInclude = {};
                const variablesToExclude = {};
                value.forEach(item => {
                    //add the item as a variable and check if the parsing returns true, add it to the result array if so
                    const itemVariables = [];
                    const itemVariableValues = [];
                    if(item && valueIsObject) {
                        //if it's an object, use it's keys as property names
                        const keys = Object.keys(item);
                        keys.forEach(key => {
                            let include = false;
                            if(!variablesToExclude[key]) {
                                include = variablesToInclude[key];
                                if(!include) {
                                    //check if the variable was included in the expression
                                    include = expression.toLowerCase().indexOf(key.toLowerCase()) > -1;
                                    if(include) {
                                        variablesToInclude[key] = true;
                                    }
                                    else {
                                        variablesToExclude[key] = true;
                                    }
                                }
                            }
                            if(include) {
                                const variableValue = item[key];
                                parser.setVariable(key, variableValue);
                                itemVariables.push(key);
                                itemVariableValues.push(variableValue);
                            }
                        });
                    }
                    else {
                        //add the item itself as a variable using the variable name of 'input'                    
                        const key = 'input';
                        parser.setVariable(key, item);
                        itemVariables.push(key);
                        itemVariableValues.push(item);
                    }
                    const cacheKey = itemVariableValues.join(',');
                    const existingResult = resultCache[cacheKey];
                    if(existingResult) {
                        result = existingResult;
                    }
                    else {
                        result = parser.parse(expression);
                        resultCache[cacheKey] = result;
                    }
                    if(result.result) {
                        filtered.push(item);
                    }
                    //we need to remove the variables we added for this item so we can evaluate afresh for the next item
                    itemVariables.forEach(v => parser.setVariable(v, null));
                })                             
            }

            //we need to also support filter functions etc
            /*filtered = filtered.filter(item => {
                let matched = true;
                const filters = variable.FilterGroup.Filters;
                //pretend they're all and filters for now
                for(let i = 0; i < filters.length; i++) {
                    const filter = filters[i];                        
                    if(filter.FilterOperator === '=') {
                        const filterField = correctType(filter.FieldName ? item[filter.FieldName] : item, filter.FilterDataType);
                        const filterValue = correctType(getValue(filter.FilterOperatorData[0], resolvedTasks), filter.FilterDataType);
                        if(filterField !== filterValue) {
                            matched = false;
                            break;
                        }
                    }
                    else if(filter.FilterOperator === '>=' || filter.FilterOperator === ">") {
                        const filterField = correctType(filter.FieldName ? item[filter.FieldName] : item, filter.FilterDataType);
                        const filterValue = correctType(getValue(filter.FilterOperatorData[0], resolvedTasks), filter.FilterDataType);
                        const lessThan = filterField < filterValue;
                        const equal = filterField === filterValue;
                        if(lessThan || (filter.FilterOperator === '>' && equal)) {
                            matched = false;
                            break;
                        }
                    }
                    else if(filter.FilterOperator === '<=' || filter.FilterOperator === "<") {
                        const filterField = correctType(filter.FieldName ? item[filter.FieldName] : item, filter.FilterDataType);
                        const filterValue = correctType(getValue(filter.FilterOperatorData[0], resolvedTasks), filter.FilterDataType);
                        const greaterThan = filterField > filterValue;
                        const equal = filterField === filterValue; 
                        if(greaterThan || (filter.FilterOperator === '<' && equal)) {
                            matched = false;
                            break;
                        }
                    }
                    else {
                        debugger;
                    }
                    //else if(filter.filt)
                }
                return matched;
            });  */                  
        }                
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = filtered;
    }
    else if(variable.Type === 'Iteration') {
        let value = getValue(variable.InputVariableID, resolvedTasks);
        if(value && value.length && variable.MaxCount && value.length > variable.MaxCount) {
            value = value.slice(0, variable.MaxCount);
        }
        //set an inputValue property, we'll use it for the iteration                
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = Object.assign({}, variable, { inputValue: value });
        }
        else {
            resolvedTask.variables[variable.ID] = Object.assign({}, resolvedTask.variables[variable.ID], { inputValue: value });
        }        
        if(initialiseIterations && value && value.length) {
            resolvedTask.variables[variable.ID].currentValue = value[0];
        }        
    }
    else if(variable.Type === 'Aggregate') {
        let value = getValue(variable.InputVariableID, resolvedTasks);
        let output;
        if(value) {
            //we need to also support format functions etc
            if(variable.AggregateFunction) {
                const aggregateFunction = aggregateFunctions.find(f => f.name === variable.AggregateFunction);
                if(aggregateFunction) {
                    output = aggregateFunction.func(value, ...variable.FunctionInputs);
                }
                else {
                    //an unsupported function?
                }
            }
        }
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = output;
    }
    else if(variable.Type === 'Calculation') {                
        let result;
        const formula = variable.Formula;
        //add a daysDiff function and a days in month function                    
        var parser = new FormulaParser();
        addCustomFunctions(parser);

        const variableMappings = {};
        /*parser.on('callVariable', function(name, done) {

            const variableID = variableMappings[name];
            if (variableID) {
                const value = parser.variables[variableID].currentValue;
                done(value);
            }
        });*/  
        parser.on('callCellValue', function(cellCoord, done) {
            const variableKeys = Object.keys(parser.variables);
            if (variableKeys.includes(cellCoord.label)) {
                const value = parser.variables[cellCoord.label];
                done(value);
            }
            else {
                done('#NAME?');
            }
        });                     

        variable.Inputs.forEach(variableID => {   
            const variableInput = getVariable(variableID, resolvedTasks);
            if(variableInput.currentValue && getObjectType(variableInput.currentValue) === 'Object') {
                //if it's an object, use it's keys as property names
                const keys = Object.keys(variableInput.currentValue);
                keys.forEach(key => {
                    parser.setVariable(key, variableInput.currentValue[key]);
                });
            }
            else {
                const parserID = `ID_${variableID.toUpperCase().replace(/\s+/g,'')}`;
                parser.setVariable(parserID, variableInput.currentValue);   
                //we use the variable ID as the variable, but the user will enter the name so store the mapping. 
                //This is because the name could have a structure that breaks the parsing
                variableMappings[variableInput.Name] = parserID;
                //also add a mapping from the original ID to the parserID as we're saving records to the database using the original ID
                variableMappings[variableID] = parserID;
            }
        });
        result = parser.parse(formatFormulaForParsing(formula, variableMappings));
        if(result.error) {
            result = result.error;
        }
        else {
            result = result.result;
        }     
        //set the output type based on the type of the result
        const outputType = getObjectType(result);
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = result;
        resolvedTask.variables[variable.ID].OutputType = outputType;
    }
    else if(variable.Type === 'Grouping' || variable.Type === 'ComplexGrouping') {
        let value = getValue(variable.InputVariableID, resolvedTasks);
        let result;
        if(value) {
            if(!variable.GroupingFunction) {
                if(variable.GroupBy.length) {
                    //this doesn't support multiple grouping fields
                    const groupingFunction = (item) => {
                        const values = variable.GroupBy.map(field => item[field]);
                        return values.join('-');
                    } ;
                    const outputValuesFunction = (item) => {
                        const output = {};
                        variable.GroupBy.forEach(field => output[field] = item[field]);
                        return output;
                    };
                    result = group(value, groupingFunction, variable.Projections, outputValuesFunction);
                }
            }
            else {
                const groupingFunction = groupingFunctions.find(f => f.name === variable.GroupingFunction);
                if(groupingFunction) {
                    const parameters = [value, ...variable.GroupBy];
                    result = groupingFunction.func(...parameters, variable.Projections, resolvedTasks);
                } 
            }
        }            
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = result;
    }
    else if(variable.Type === 'Sorting') {
        let value = getValue(variable.InputVariableID, resolvedTasks);
        let output = value ? [...value] : null;
        if(value && variable.SortDirection) {
            //this is a basic sort where we just have a sort direction, e.g. if it's just a list of integers or strings
            if(variable.SortDirection === 'Ascending') {
                output = [...output].sort((a, b) => a - b);
            }
            else {
                output = [...output].sort((a, b) => b - a);
            }
        }
        else if(value && variable.Sorts && variable.Sorts.length) {
            //this is a more complex sort where we are sorting objects by 1 or more property names
            const sortFields = variable.Sorts.map(sort => sort.FieldName);
            const sortDirections = variable.Sorts.map(sort => sort.SortDirection.toLowerCase().replace('descending', 'desc').replace('ascending', 'asc'));
            output = _.orderBy(output, sortFields, sortDirections);                
        }
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = output;
    }
    else if(variable.Type === 'Join') {
        let output;
        if(variable.OutputType === 'Text') {
            if(variable.Inputs.length === 1) {
                const value = getValue(variable.Inputs[0], resolvedTasks);
                if(value) {
                    output = value.join(variable.Separator);
                }
            }
        }
        else if(variable.OutputType === 'Array') {
            //need to add another option for object output types with multiple arrays where the 1st array is the field name?
            //check the input type, if 
            output = [];
            variable.Inputs.forEach(input => {
                const value = getValue(input, resolvedTasks);
                if(Array.isArray(value)) {
                    output = output.concat(value);
                }
                else {
                    output.push(value);
                }
            })               
        }
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = output;
    }
    else if(variable.Type === 'ExistingTask') {
        const sourceTask = resolvedTasks[variable.TaskID];
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name, OutputType: variable.OutputType };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        if(sourceTask) {
            resolvedTask.variables[variable.ID].currentValue = sourceTask.currentValue;
            resolvedTask.variables[variable.ID].OutputType = sourceTask.outputType;
        }                    
    }
    else if(variable.Type === 'Flatten') {
        let value = getValue(variable.InputVariableID, resolvedTasks);
        let result;
        if(value) {
            const type = getObjectType(value);
            const flattenObject = (object) => {
                const keys = Object.keys(object);
                if(keys.length) {
                    return object[keys[0]];
                }
                return null;
            }
            if(type === 'Object') {
                result = flattenObject(value);                            
            }
            else if(type === 'Array') {
                result = value.map(v => flattenObject(v));
            }
            if(result && variable.Distinct) {
                result = _.uniq(result);
            }
        }
        //set the output type based on the type of the result
        const outputType = getObjectType(result);
        if(!resolvedTask.variables[variable.ID]) {
            resolvedTask.variables[variable.ID] = { Name: variable.Name };
        }
        else {
            resolvedTask.variables[variable.ID] = {...resolvedTask.variables[variable.ID]};
        }
        resolvedTask.variables[variable.ID].currentValue = result;
        resolvedTask.variables[variable.ID].OutputType = outputType;
    }
}

export function bindingMatch(widgetHierarchyBinding, fieldNameBinding, widgetHierarchy, propertyName) {
    let matching = true;
    const propertyNameMatch = fieldNameBinding === propertyName;
    if(!propertyNameMatch) {
        matching = false;
    }
    /*else if(widgetHierarchyBinding.length === widgetHierarchy.length) {
        for(let i = 0; i < widgetHierarchyBinding.length; i++) {
            if(widgetHierarchyBinding[i] !== widgetHierarchy[i]) {
                matching = false;
                break;
            }
        }        
    }*/
    //we should be able to just check if the last item in both hierarchies match. A child widget can't exist in multiple parents
    else {
        matching = widgetHierarchyBinding.length && widgetHierarchy.length && _.last(widgetHierarchyBinding) === _.last(widgetHierarchy);
    }
    return matching;
}

export function getVariable(variableID, resolvedTasks) {
    const taskIDs = Object.keys(resolvedTasks);
    for(let i = 0; i < taskIDs.length; i++) {
        const variables = resolvedTasks[taskIDs[i]].variables;
        const input = variables[variableID];
        if(input) {
            return input;
        }
    }
    return null;
}

function getValue(variableID, resolvedTasks) {
    let variable = getVariable(variableID, resolvedTasks);
    return variable ? variable.currentValue : variable;
}

/*function correctType(value, type) {
    if(value) {
        //we need this to convert types for comparisons or transformations or formatting
        if(type === 'Date' || type === 'DateTime') {
            return new Date(value);
        }
    }
    return value;
}*/

export function findWidgetInstance(widgetInstanceID, dashboardWidgets, widgets) {
    const widgetHierarchy = [];
    for(let i = 0; i < dashboardWidgets.length; i++) {
        const dashboardWidget = dashboardWidgets[i];
        const widget = widgets.find(w => w.ID === dashboardWidget.WidgetID);
        if(widget) {
            if(dashboardWidget.ID === widgetInstanceID) {
                widgetHierarchy.push(dashboardWidget.Name);
                return {
                    widgetHierarchy, 
                    widget: dashboardWidget
                };
            }
            else if (widget.Children && widget.Children.length) {
                const childSearchResult = findWidgetInstance(widgetInstanceID, widget.Children, widgets);
                if(childSearchResult) {
                    widgetHierarchy.concat(childSearchResult.widgetHierarchy);
                    return {
                        widgetHierarchy, 
                        widget: childSearchResult.widget
                    };
                }
            }
        }
    }
    return null;
}

export function group(input, groupingKeyFunction, projections, outputValuesFunction) {
    let result = [];
    if(input && input.length) {
        //group the data 1st
        const groups = {};
        //add the month name as a key to make it easier to group
        input.forEach(item => {
            const key = groupingKeyFunction(item);
            if(!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(item);
        });
        //look through each group doing the projections and creating the final array
        const keys = Object.keys(groups);
        for(let i = 0; i < keys.length; i++) {
            const groupItems = groups[keys[i]];
            const item = outputValuesFunction ? outputValuesFunction(groupItems[0]) : {};
            if(projections.length) {
                projections.forEach(projection => {
                    const aggregateFunction = aggregateFunctions.find(f => f.name === projection.AggregateFunction);
                    if(aggregateFunction && projection.ProjectionFieldName) {
                        item[projection.ProjectionFieldName] = aggregateFunction.func(groupItems.map(i => i[projection.ProjectionFieldName]));
                    }                   
                });
            }
            result.push(item);
        }
    }
    return result;
}

export function initialiseTasks(dashboard, dashboardTasks, playerID, users, data) {
    const tasks = {};
    dashboardTasks.forEach(task => {
        const myTask = {
            name: task.Name,
            currentValue: null,
            variables: { }
        };
        task.Variables.forEach(variable => {
            const {ID, ...rest} = variable;
            /*if(variable.Type === 'Calculation') {
                //update the formula to replace any variable names with IDs
                const variableNameIDMappings = {};
                variable.Inputs.forEach(input => {
                    const variable = dashboard.Tasks.find(t => t.ID === task.ID).Variables.find(v => v.ID === input);
                    if(variable) {
                        variableNameIDMappings[variable.Name] = variableIDMappings[variable.ID] || variable.ID;
                    }
                });
                rest.Formula = widgetUtils.formatFormulaForParsing(rest.Formula, variableNameIDMappings);
                rest.Formula = 
            }*/
            myTask.variables[variable.ID] = {...rest, currentValue: null};
        })
        tasks[task.ID] = myTask;
    });
    //add the player/s and dashboard
    //TODO: we need to check if the user has access to the player???
    dashboardTasks.forEach(task => {
        const taskID = task.ID;
        task = tasks[task.ID];
        const variableKeys = Object.keys(task.variables);        
        variableKeys.forEach(variableKey => {
            let variable = task.variables[variableKey];
            if(variable.Type === 'DataSource') {
                //variable.currentValue = data;
                //variable.OutputType = Array.isArray(data) ? 'Array' : 'Object';
                variable = {...variable, ...getDataSourceVariableValues(data, variable.IncludeFields, dashboard, variable.DatasetID)};
            }
            else if(variable.Type === 'Dashboard') {
                variable = {...variable, ...getDashboardVariableValues(dashboard, variable.IncludeFields)};
                //variable.currentValue = { Name: dashboard.Name };
                //variable.OutputType = 'Object';
            }
            else if(variable.Type === 'Player') {
                variable = {...variable, ...getPlayerVariableValues(dashboard, playerID, users, variable.IncludeFields)};
            }
            task.variables[variableKey] = variable;
        })
        const finalVariable = task.variables[variableKeys[variableKeys.length - 1]];
        if(finalVariable) {
            task.currentValue = finalVariable.currentValue;
            task.outputType = finalVariable.OutputType;
        }
        tasks[taskID] = task;
    })
    return tasks;
}

export function getDashboardVariableValues(dashboard, includeFields) {
    const defaultFields = {
        Name: dashboard.Name
    };
    let currentValue;
    if(includeFields && includeFields.length) {
        currentValue = {};
        //there's only 1 default field so we don't need this logic but it will be needed if more fields are added in the future
        const keys = Object.keys(defaultFields);
        keys.forEach(key => {
            if(includeFields.includes(key)) {
                currentValue[key] = defaultFields[key];
            }
        });
    }
    else {
        currentValue = defaultFields;
    }
    return {
        currentValue,
        OutputType: 'Object'
    };
}

export function getDataSourceVariableValues(data, includeFields, dashboard, datasetID) {
    let currentValue;
    if(!dashboard.RuleEngineID && data) {        
        const selectedDataset = data.find(d => d.ID === (datasetID || dashboard.DatasetIDs[0]));
        if(selectedDataset) {
            data = selectedDataset.Data;
        }
    }
    const isArray = Array.isArray(data);
    if(includeFields && includeFields.length) {
        if(isArray) {
            if(data.length > 0) {
                const keys = Object.keys(data[0]);
                currentValue = data.map(dataItem => {
                    const val = {};
                    keys.forEach(key => {
                        if(includeFields.includes(key)) {
                            val[key] = dataItem[key];
                        }
                    });
                    return val;
                })
            }
            else {
                currentValue = data;
            }
        }
        else {
            if(data) {
                currentValue = {};
                const keys = Object.keys(data);
                keys.forEach(key => {
                    if(includeFields.includes(key)) {
                        currentValue[key] = data[key];
                    }
                });
            }
        }
    }
    else {
        currentValue = data;
    }
    return {
        currentValue,
        OutputType: isArray ? 'Array' : 'Object',
    };
}

export function getPlayerVariableValues(dashboard, playerID, users, includeFields) {
    let currentValue;
    let outputType;
    const getDefaultFields = (dashboardPlayer, userAvatar) => { 
        return { Label: dashboardPlayer.Label, UserAvatar: userAvatar, ClientUserID: dashboardPlayer.ClientUserID };
    };
    if(dashboard.PlayerMode === 'Single') {
        let dashboardPlayer = dashboard.Players.find(p => p.ID === playerID);
        let userAvatar = null;
        if(dashboardPlayer.UserID) {
            const user = users.find(u => u.ID === dashboardPlayer.UserID);
            if(user) {
                userAvatar = user.Avatar;
            }
        }
        const defaultFields = getDefaultFields(dashboardPlayer, userAvatar);
        if(includeFields && includeFields.length) {
            currentValue = {};
            const keys = Object.keys(defaultFields);
            keys.forEach(key => {
                if(includeFields.includes(key)) {
                    currentValue[key] = defaultFields[key];
                }
            });
        }
        else {
            currentValue = defaultFields;
        }
        outputType = 'Object';
    }
    else if(dashboard.PlayerMode === 'Multi') {
        const players = dashboard.Players.map(dashboardPlayer => {
            let userAvatar = null;
            if(dashboardPlayer.UserID) {
                const user = users.find(u => u.ID === dashboardPlayer.UserID);
                if(user) {
                    userAvatar = user.Avatar;
                }
            }
            const defaultFields = getDefaultFields(dashboardPlayer, userAvatar);
            let playerCurrentValue;
            if(includeFields && includeFields.length) {
                playerCurrentValue = {};
                const keys = Object.keys(defaultFields);
                keys.forEach(key => {
                    if(includeFields.includes(key)) {
                        playerCurrentValue[key] = defaultFields[key];
                    }
                });
            }
            else {
                playerCurrentValue = defaultFields;
            }
            return playerCurrentValue;
        })
        currentValue = players;
        outputType = 'Array';
    }
    return {
        currentValue,
        OutputType: outputType
    };
}

const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

export function getObjectType(object) {
    const type = typeof(object);
    if(type === 'number') {
        return Number.isInteger(object) ? 'Integer' : 'Double';
    }
    else if(type === 'string') {
        if(dateFormatRegex.test(object)) {
            return 'DateTime';
        }
        return 'Text';
    }
    else if(type === 'boolean') {
        return 'Boolean';
    }
    else if(type === 'object') {
        if(Array.isArray(object)) {
            return 'Array';
        }
        else if(object instanceof Date) {
            return 'DateTime';
        }
        else {
            return 'Object';
        }
    }
    return null;
}

export function getNestedObjectType(object) {
    if(object) {
        const objectKeys = Object.keys(object)
        if(objectKeys.length === 1) {
            const nestedValue = object[objectKeys[0]];
            return getObjectType(nestedValue);
        }
    }
    return 'Object';
}

function addCustomFunctions(parser) {
    parser.setFunction('shiftDate', (params) => shiftDate(...params));
    parser.setFunction('DATEVALUE', (params) => dateValue(...params));    
    parser.setFunction('DATEFORMAT', (params) => dateFormat(...params));    

}

export function formatFormulaForParsing(formula, variableMappings) {
    //variable mappings is a mapping of the variable name to the ID
    let formulaFormatted = formula;
    if(variableMappings) {
        const variableNames = Object.keys(variableMappings);
        variableNames.forEach(variableName => {
            //replace the name with the ID
            formulaFormatted = formulaFormatted.split(variableName).join(variableMappings[variableName]);
        })
    }
    return formulaFormatted;
}

export function formatFormulaForReading(formula, variableMappings) {
    //variable mappings is a mapping of the variable name to the ID
    const variableNames = Object.keys(variableMappings);
    const reverseVariableMappings = {};
    variableNames.forEach(variableName => {
        reverseVariableMappings[variableMappings[variableName]] = variableName;
    });
    const variableIDs = Object.keys(reverseVariableMappings);
    let formulaFormatted = formula;
    variableIDs.forEach(variableID => {
        //replace the ID with the name
        formulaFormatted = formulaFormatted.split(variableID).join(reverseVariableMappings[variableID]);
    });
    return formulaFormatted;
}

function shiftDate(startDate, shiftPeriod, shiftValue, shiftRoundDirection) {
    let newDate;
    if(startDate && shiftPeriod && shiftValue) {
        shiftPeriod = shiftPeriod.toLowerCase();
        newDate = moment(startDate).add(shiftValue, shiftPeriod);
        if(shiftRoundDirection) {
            shiftRoundDirection = shiftRoundDirection.toLowerCase();
            if(shiftRoundDirection === 'start') {
                newDate = newDate.startOf(shiftPeriod);
            }
            else if(shiftRoundDirection === 'end') {
                newDate = newDate.endOf(shiftPeriod);
            }
        }
        newDate = newDate.toDate();         
    }
    return newDate;
}

function dateValue(dateInput) {
    //this is copied from the formula parser library, but we want to accept date inputs instead of just strings and set the time 
    //to 0, otherwise 30 April 2019 23:59:59 and 1 May 2019 00:00 end up evaluating to the same number for comparison
    let modifier = 2;
    //let date;
    const objectType = getObjectType(dateInput);
    if(objectType === 'Text' || objectType === 'DateTime') {
        const date = new Date(dateInput).setHours(0,0,0,0);
        //date = Date.parse(params[0]);      
        if (isNaN(date)) {
          return null;
        }
        if (date <= -2203891200000) {
          modifier = 1;
        }
        const d1900 = new Date(Date.UTC(1900, 0, 1));
        return Math.ceil((date - d1900) / 86400000) + modifier;  
    }
    return null;
}

function dateFormat(date, formatString) {
    return moment(date).format(formatString);
}

export function getGridItemContentClass(widget) {
    if(widget && (widget.Slug === 'Chart' || widget.Slug === 'Nested' || widget.Children)) {
        return "grid-stack-item-content--bg";
    }   
    else {
        return "grid-stack-item-content--center";
    }
}

export function getRelativeDate(momentActions) {
    let date;
    if(momentActions.length) {
        date = moment();
        momentActions.forEach(action => {
            const func = date[action.name];
            if(func) {
                date = func.call(date, ...action.parameters)
            }                
        });
    } 
    return date;     
}

export function getDateDisplay(dateRange) {    
    if(!dateRange.formatDisplayText) {
        return dateRange.displayText;
    }
    else {
        let text = '- ';
        const startDate = dateRange.start.relative ? getRelativeDate(dateRange.start.momentActions) : moment(dateRange.start.value);
        if(startDate) {
            text = startDate.format(dateRange.displayText);
            if(dateRange.includeEndDate) {
                const endDate = dateRange.end.relative ? getRelativeDate(dateRange.end.momentActions) : moment(dateRange.end.value);
                if(endDate) {
                    text += ` - ${endDate.format(dateRange.displayText)}`;
                }
            }
        }
        return text;
    }
}