/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../actions/dashboardActions';
import * as userActions from '../../actions/userActions';
import DashboardSettingsPlayerItem from './DashboardSettingsPlayerItem';
import { withModalContext } from '../../services/modalService';
import AddUpdatePlayerModal from './AddUpdatePlayerModal';
import Dragula from 'react-dragula';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
const _ = require('lodash');

class DashboardSettings extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getPlayerCount = this.getPlayerCount.bind(this);
        this.getPlayers = this.getPlayers.bind(this);
        this.addPlayer = this.addPlayer.bind(this);
        this.updatePlayer = this.updatePlayer.bind(this);
        this.initDragula = this.initDragula.bind(this);
        this.toggleSelectedPlayer = this.toggleSelectedPlayer.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.deleteSelectedPlayers = this.deleteSelectedPlayers.bind(this);
        this.state = {
            selectedPlayerIDs: []
        }
    }

    componentDidMount() {      
        const dashboardID = this.props.match.params.id;
        if(!this.props.dashboard) {
            //if we're browsing directly to this page, we might not have the dashboard details
            this.props.actions.getDashboard(dashboardID);
        }
        else {
            this.props.actions.checkDashboardVersion(dashboardID);
        }
        if(!this.props.users || !this.props.users.length) {
            this.props.actions.getUsers();
        }
    }   

    getPlayerCount() {
        if(!this.props.dashboard || !this.props.dashboard.Players) {
            return '0 players';
        }
        else {
            return `${this.props.dashboard.Players.length} player${this.props.dashboard.Players.length === 1 ? '' : 's'}`;
        }
    }

    getPlayers() {
        const users = this.props.users;
        if(!this.props.dashboard || !this.props.dashboard.Players) {
            return [];
        }
        else {
            let players = this.props.dashboard.Players.map(player =>
                {
                    let email = '';
                    if(users && player.UserID) {
                        const user = users.find(u => u.ID === player.UserID);
                        if(user) {
                            email = user.Email;
                        }
                    }
                    return {
                        ID: player.ID,
                        Email: email,
                        UserID: player.UserID,
                        ClientUserID: player.ClientUserID,
                        Label: player.Label,
                        Order: player.Order,
                        Adjustments: player.Adjustments,
                        AvatarSelection: player.AvatarSelection,
                        AvatarUserEditable: player.AvatarUserEditable
                    }
                }
            );
            players.sort((a, b) => {
                const aOrder = a.Order;
                const bOrder = b.Order;
                return aOrder - bOrder;
            });
            return players;
        }
    }

    addPlayer(event, players) {       
        debugger;
        event.preventDefault();
        const users = this.props.users.filter(u => u.ClientID === this.props.dashboard.ClientID && !players.find(p => p.UserID === u.ID));
        const addUpdatePlayerModal = <AddUpdatePlayerModal dashboardID={this.props.dashboard.ID} users={users} order={players.length + 1} actions={this.props.actions} />
        this.props.showModal(() => addUpdatePlayerModal, { isOpen: true })
    }

    updatePlayer(event, players, player) {
        debugger;
        event.preventDefault();
        const users = this.props.users.filter(u => u.ClientID === this.props.dashboard.ClientID && (!players.find(p => p.UserID === u.ID) || player.UserID === u.ID));
        const addUpdatePlayerModal = <AddUpdatePlayerModal dashboardID={this.props.dashboard.ID} users={users} player={player} actions={this.props.actions} />
        this.props.showModal(() => addUpdatePlayerModal, { isOpen: true })
    }

    toggleSelectedPlayer(event, playerID) {
        let selectedPlayerIDs = [...this.state.selectedPlayerIDs];
        if(!selectedPlayerIDs.find(p => p === playerID)) {
            selectedPlayerIDs.push(playerID);
        }
        else {
            _.pull(selectedPlayerIDs, playerID);
        }
        this.setState({
            selectedPlayerIDs
        })
    }

    isSelected(playerID) {
        return this.state.selectedPlayerIDs.find(p => p === playerID) ? true : false;
    }

    initDragula(componentBackingInstance) {
        if(componentBackingInstance) {
            const dragTask = Dragula([componentBackingInstance], {
                isContainer: function (el) {
                    return false;
                },
                moves: function (el, container, handle) {
                    console.log(handle);
                    return handle.classList.contains('useritem__drag');
                },
                accepts: function (el, target, source, sibling) {
                    return true;
                },
                invalid: function (el, handle) {
                    return false;
                },
                direction: 'vertical',
                copy: false,
                copySortSource: false,
                revertOnSpill: true,
                mirrorContainer: document.body,
                ignoreInputTextSelection: true
            });
            //-- Drag event started
            /*dragTask.on('drag', function(el, source){
                 console.log("Drag started: " + el);
            });*/
                
            //-- Drag event Ended / element dropped
            dragTask.on('drop', (el, target, source, sibling) => {
                let newOrder;
                if(sibling) {
                    newOrder = parseInt(sibling.getAttribute('order'), 10);
                    let originalOrder = parseInt(el.getAttribute('order'), 10);
                    if(newOrder < originalOrder) {
                        //we've moved up, so add 1 as we want to be after our new sibling
                        newOrder++;
                    }
                    else {
                        //we've moved down, so subtract 1 as we want to be before our new sibling
                        newOrder--;
                    }
                } 
                else {
                    newOrder = _.max(this.props.dashboard.Players.map(i => i.Order));
                }
                const playerID = el.dataset.playerId;
                const player = this.props.dashboard.Players.find(p => p.ID === playerID);
                debugger;
                if(player) {
                    this.props.actions.updatePlayer(this.props.dashboard.ID, player.ID, player.UserID, player.ClientUserID, player.Label, newOrder, player.AvatarUserEditable);
                }
                
             //console.log("Element dropped to: " + target);
            });
        }   
    }

    deleteSelectedPlayers(event) {
        event.preventDefault();
        if(this.state.selectedPlayerIDs.length) {
            const deleteAction = () => {
                this.props.actions.deletePlayers(this.props.dashboard.ID, this.state.selectedPlayerIDs)
                    .then(_ => this.setState({
                        selectedPlayerIDs: []
                    }));
            } 
            const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to delete the selected player${this.state.selectedPlayerIDs.length > 1 ? 's' : ''}? This action is irreversible.`} />
            this.props.showModal(() => deleteConfirmationModal, { isOpen: true })
        }
    }

    render() {
        const dashboard = this.props.dashboard;
        const players = this.getPlayers();
        return (
            <div className={`main__body scroll ${this.props.loading ? 'spinner' : ''}`}>
            {
                !this.props.loading ?
                    <div className="pane pane--fixed pane--dashboards">
                        <Link to="/Dashboards" className="btn-close"><i className="aheadicon-x"></i></Link>
                        <div className="pane__head">
                            <div className="pane__ctrl">
                                <div className="pane__info">
                                    <h5>Dashboard Settings: {dashboard ? dashboard.Name : ''}</h5>
                                    <p>{this.getPlayerCount()}</p>
                                </div>
                                {
                                    dashboard ?
                                        <div className="pane__actions">
                                            {
                                                this.state.selectedPlayerIDs.length ?
                                                    <button href={null} className="btn btn--secondary btn-multiaction btn-delete btn--withlasticon" onClick={this.deleteSelectedPlayers}>Delete selected<i className="aheadicon-delete"></i></button>
                                                : null
                                            }
                                            <button href={null} className={`btn btn--primary btn-newuser btn--withlasticon ${this.state.selectedPlayerIDs.length ? 'hidden-xs' : ''}`} onClick={(e) => this.addPlayer(e, players)}>New player<i className="aheadicon-plus"></i></button>
                                      </div>
                                    : null
                                }
                                
                            </div>
                        </div>
                        
                        <div className="pane__body scrollbar">
                            <form className="form">
                                <div className={`userslist userslist--dash userslist--draggable ${dashboard && dashboard.Template.Slug === 'car-race' ? 'userlist--cars' : ''}`} ref={this.initDragula}>
                                    {
                                        players.map((player, i) =>
                                            <DashboardSettingsPlayerItem key={player.ID} index={i} order={player.Order} player={player} dashboard={dashboard} showModal={this.props.showModal} actions={this.props.actions} toggleSelectedPlayer={this.toggleSelectedPlayer} selected={this.isSelected(player.ID)} updatePlayer={(e) => this.updatePlayer(e, players, player)} editAvatar={() => this.props.history.push(`/Dashboards/${dashboard.ID}/Settings/${player.ID}/Avatar`)} />
                                        )
                                    }
                                </div>
                            </form>
                        </div>
                        
                    </div>
                    : null
                }
            </div>
        );
    }
}

DashboardSettings.propTypes = {
    actions: PropTypes.object.isRequired,
    dashboard: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const dashboardID = ownProps.match.params.id;
    const dashboard = state.dashboard.dashboards.filter(d => d.Template).find(d => d.ID === dashboardID);    
    return { 
        dashboard, 
        loading: state.dashboard.dashboardsLoading || state.dashboard.dashboardItemsLoading.includes(dashboardID) || state.user.usersLoading, 
        users: state.user.users
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //dashboard actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, dashboardActions, userActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(DashboardSettings));