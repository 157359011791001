
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../../../../utils/imageUtils';
import Track from '../Track';
import ScoreboardModal from './ScoreboardModal';
import _ from 'lodash';
import 'url-search-params-polyfill';
import { withRouter } from 'react-router-dom';
import * as processUtils from '../../../../../utils/processUtils';
import '../styles/track-italy.css';
import {TweenLite, Linear, Power1} from 'gsap/TweenLite';
import {TimelineMax} from 'gsap/TweenMax';
import Avatar from '../../../Avatar';

class BikeRace extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getPlayerData = this.getPlayerData.bind(this);
        this.getMainClass = this.getMainClass.bind(this);
        this.showScoreboard = this.showScoreboard.bind(this);
        this.createBgAnim = this.createBgAnim.bind(this);
        this.createPlayerAnim = this.createPlayerAnim.bind(this);
        this.startBgAnim = this.startBgAnim.bind(this);
        this.stopBgAnim = this.stopBgAnim.bind(this);
        this.startPlayerAnim = this.startPlayerAnim.bind(this);
        this.stopPlayerAnim = this.stopPlayerAnim.bind(this);
        this.getCountdownClass = this.getCountdownClass.bind(this);
        this.setPlayerRef = this.setPlayerRef.bind(this);
        this.isSingleTrack = this.isSingleTrack.bind(this);
        this.playerRefs = [];
        this.playerTimelines = [];
        let animationStyle = 1;
        let timeLimit = 0;
        this.playerAnimationStopped = false;
        const searchParams = new URLSearchParams(props.location.search);
        const animationStyleParam = searchParams.get('animationStyle');
        if(animationStyleParam) {
            animationStyle = parseInt(animationStyleParam, 10);
        }
        const timeLimitParam = searchParams.get('timeLimit');
        if(timeLimitParam) {
            timeLimit = parseInt(timeLimitParam, 10);
        }
        this.state = {
            isAnimating: false,
            finished: false,
            title: this.props.dashboard.Name,
            backgroundImage: null,
            logo: null,
            logoLarge: null,
            players: [],
            animationStyle,
            timeLimit,
            showCountdown: false,
            countdownSecs: 3
        }
        this.initialiseDashboard = this.initialiseDashboard.bind(this);
        this.initialisePlayers = this.initialisePlayers.bind(this);
        this.updatePlayers = this.updatePlayers.bind(this);
        this.getAvatar = this.getAvatar.bind(this);
        this.clearTimelines = this.clearTimelines.bind(this);
    }

    componentDidMount() {    
        this._isMounted = true;
        this.initialiseDashboard();
        this.createBgAnim();
        this.createPlayerAnim();
        window.$('body').addClass('racepage--bikes');
    }

    componentWillUpdate(nextProps, nextState) {
        //this.playerRefs = [];
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.playersUpdated && !this.playerAnimationStopped) {
            //debugger;
            const newRefs = [];
            const timelinesToKill = [];
            this.playerTimelines.forEach(timeline => {
                const children = timeline.getChildren();
                if(children && children.length && !children[0].target.isConnected) {
                    timelinesToKill.push(timeline);
                }
            });
            timelinesToKill.forEach(timeline => {
                timeline.kill();
                _.pull(this.playerTimelines, timeline);
            });
            
            this.playerRefs.forEach(ref => {
                ref = window.$(ref);
                if(ref && ref.children()[0] && !ref.children()[0].getAttribute('animationAdded')) {
                    newRefs.push(ref);
                }
            });
            if(newRefs.length) {
                const startPlayerAnimations = this.playerAnimationStarted;
                this.createPlayerAnim(newRefs);
                if(startPlayerAnimations) {
                    this.startPlayerAnim();
                }
            }
        }
        this.playersUpdated = false;
        if(prevProps.dashboard.ID !== this.props.dashboard.ID) {
            this.initialiseDashboard();
        }
        else if(prevProps.dashboard !== this.props.dashboard) {
            //we're on the same dashboard but it's updated
            this.updatePlayers();
            this.playersUpdated = true;
        }
        if(prevProps.data === null && this.props.data !== null) {
            this.initialisePlayers();
        }
        if(this.state.players.length && !prevState.players.length) {
            //we have the players now, set up the animation
            this.createPlayerAnim();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.$('body').removeClass('racepage--bikes');
        this.clearTimelines();
    }

    clearTimelines() {
        //destroy any animation timelines
        this.playerTimelines.forEach(timeline => {
            timeline.kill();
            //debugger; //check if the line below actually nulls it in the array
            timeline = null;
        });

        if(this.bg) {
            this.bg.kill();
            this.bg = null;
        }
        if(this.bgSingle) {
            this.bgSingle.kill();
            this.bgSingle = null;
        }
        if(this.bgCurbTop) {
            this.bgCurbTop.kill();
            this.bgCurbTop = null;
        }
        if(this.bgCurbBot) {
            this.bgCurbBot.kill();
            this.bgCurbBot = null;
        }
        if(this.start) {
            this.start.kill();
            this.start = null;
        }
        if(this.trackline) {
            this.trackline.kill();
            this.trackline = null;
        }
    }

    createBgAnim() {
        //initialise the background image animation
        this.bg = new TimelineMax({
            repeat: -1
        });
        this.bgSingle = new TimelineMax({
            repeat: -1
        });
        this.bgCurbTop = new TimelineMax({
            repeat: -1
        });
        this.bgCurbBot = new TimelineMax({
            repeat: -1
        });
        this.start = new TimelineMax({
            repeat: 0
        });
        this.trackline = new TimelineMax({
            repeat: -1
        });
        this.bg.to(".racetrack__bg", 25, {
            backgroundPosition: "-2800px 0px",
            force3D:true,
            rotation:0.01,
            z:0.01,
            autoRound:false,
            ease: Linear.easeNone
        });
        this.bg.pause();

        //-- Single Track track bacground animation
        this.bgSingle.to(".track-dirt-single", 25, {
            backgroundPosition: "-2800px 0px",
            force3D:true,
            rotation:0.01,
            z:0.01,
            autoRound:false,
            ease: Linear.easeNone
        });
        this.bgSingle.pause();

        //-- Top curb animation
        this.bgCurbTop.to(".track__top", 25, {
            backgroundPosition: "-1400px 0px",
            force3D:true,
            rotation:0.01,
            z:0.01,
            autoRound:false,
            ease: Linear.easeNone
        });
        this.bgCurbTop.pause();
  
        //-- Bottom curb animation
        this.bgCurbBot.to(".track__bottom", 25, {
            backgroundPosition: "-1400px 0px",
            force3D:true,
            rotation:0.01,
            z:0.01,
            autoRound:false,
            ease: Linear.easeNone
        });
        this.bgCurbBot.pause();

        this.start.to(".track__start", 1.5, {left: -100, ease: Power1.ease});
        this.start.pause();
      
        this.trackline.to(".track__line", 25, {
            backgroundPosition: "100% 0", 
            force3D:true, 
            rotation:0.01, 
            z:0.01, 
            autoRound:false, 
            ease: Linear.easeNone
        });
        
        this.trackline.pause();
    }

    createPlayerAnim(playerRefs = this.playerRefs) {
        //initialise the player animation
        this.playerAnimationStarted = false;
        window.$(playerRefs).each((index, playerRef) => {
            try {
                playerRef = window.$(playerRef);
                const speed = 0.5;
                const delay = Math.random() / 10;
                const wheels = new TimelineMax({repeat: -1}).delay(delay);
                const movefrontleglower = new TimelineMax({repeat: -1}).delay(delay);
                const movefrontlegupper = new TimelineMax({repeat: -1}).delay(delay);
                const movebackleglower = new TimelineMax({repeat: -1}).delay(delay);
                const movebacklegupper = new TimelineMax({repeat: -1}).delay(delay);
                
                const frontwheel = playerRef.find("#wheel-front").get(0);
                const backwheel = playerRef.find("#wheel-back").get(0);
                const frontpedal = playerRef.find("#pedal-front").get(0);
                const backpedal = playerRef.find("#pedal-back").get(0);
                const frontleglower = playerRef.find("#leg-front-lower").get(0);
                const frontlegupper = playerRef.find("#leg-front-upper").get(0);
                const backleglower = playerRef.find("#leg-back-lower").get(0);
                const backlegupper = playerRef.find("#leg-back-upper").get(0);

                wheels
                    .to(frontwheel, speed, {rotation:360, transformOrigin:"center center", ease:Linear.easeNone})
                    .to(backwheel, speed, {rotation:360, transformOrigin:"center center", ease:Linear.easeNone, delay: -speed})
                    .to(frontpedal, speed, {rotation:360, transformOrigin:"3 2.5", ease:Linear.easeNone, delay: -speed})
                    .to(backpedal, speed, {rotation:360, transformOrigin:"bottom center", ease:Linear.easeNone, delay: -speed})

                movefrontleglower
                    .to(frontleglower, speed / 4, {x: -15, y: -10, rotation:48, transformOrigin:"bottom center", ease:Linear.easeNone})
                    .to(frontleglower, speed / 4, {x: -7, y: -22, rotation: 52, transformOrigin:"bottom center", scale: .95, ease:Linear.easeNone})      
                    .to(frontleglower, speed / 4, {x: 10, y: -10, rotation: 13, transformOrigin:"bottom center", scale: 1, ease:Linear.easeNone})
                    .to(frontleglower, speed / 4, {x: 0, y: 0, rotation:0, transformOrigin:"bottom center", ease:Linear.easeNone})

                movefrontlegupper
                    .to(frontlegupper, speed / 4, {x: -4, y: 5, rotation:-32, transformOrigin:"top left", ease:Linear.easeNone})    
                    .to(frontlegupper, speed / 4, {x: -2, y: 10, rotation: -48, scale: .95, transformOrigin:"top left", ease:Linear.easeNone})
                    .to(frontlegupper, speed / 4, {x: -4, y: 3, rotation: -33, scale: 1, transformOrigin:"top left", ease:Linear.easeNone})
                    .to(frontlegupper, speed / 4, {x: 0, y: 0, rotation:0, transformOrigin:"top left", ease:Linear.easeNone});
            
                movebackleglower
                    .to(backleglower, speed / 4, {x: 26, y: 7, rotation:-35, transformOrigin:"bottom center", ease:Linear.easeNone})
                    .to(backleglower, speed / 4, {x: 7, y: 20, rotation: -28, transformOrigin:"bottom center", scale: .95, ease:Linear.easeNone})      
                    .to(backleglower, speed / 4, {x: -5, y: 10, rotation: 3, transformOrigin:"bottom center", scale: 1, ease:Linear.easeNone})
                    .to(backleglower, speed / 4, {x: 0, y: 0, rotation:0, transformOrigin:"bottom center", ease:Linear.easeNone});
                    
                movebacklegupper
                    .to(backlegupper, speed / 4, {x: 6, y: 8, rotation:-5, transformOrigin:"top left", ease:Linear.easeNone})    
                    .to(backlegupper, speed / 4, {x: 2, y: 8, rotation: 25, scale: 1.05, transformOrigin:"top left", ease:Linear.easeNone})
                    .to(backlegupper, speed / 4, {x: 0, y: 5, rotation: 5, scaleX: 1, transformOrigin:"top left", ease:Linear.easeNone})
                    .to(backlegupper, speed / 4, {x: 0, y: 0, rotation:0, transformOrigin:"top left", ease:Linear.easeNone});
                    

                wheels.pause();
                movefrontleglower.pause();
                movefrontlegupper.pause();
                movebackleglower.pause();
                movebacklegupper.pause();

                this.playerTimelines.push(wheels);
                this.playerTimelines.push(movefrontleglower);
                this.playerTimelines.push(movefrontlegupper);
                this.playerTimelines.push(movebackleglower);
                this.playerTimelines.push(movebacklegupper);
                playerRef.children()[0].setAttribute('animationAdded', true);
            }
            catch(e) {
                console.log(e);
            }
        });
    }

    setPlayerRef(player) {
        this.playerRefs.push(player);
    }

    initialiseDashboard() {
        const dashboard = this.props.dashboard;
        let dashboardData = {};
        //initialise using the template fields and then override with the adjustments
        dashboard.Template.GameVariables.forEach(variable => {
            dashboardData[variable.Name] = variable.DefaultValue;
        });
        dashboard.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
            if(adjustment.Append) {
                dashboardData[adjustment.Name] += parseFloat(adjustment.Append);
            }
            else {
                dashboardData[adjustment.Name] = adjustment.Override;
            }
        });
        const players = dashboard.Players.map(player => {
            let userAvatar = null;
            if(player.UserID) {
                const user = this.props.users.find(u => u.ID === player.UserID);
                if(user) {
                    userAvatar = user.Avatar;
                }
            }
            return {
                label: player.Label,
                id: player.ID,
                userID: player.UserID,
                clientUserID: player.ClientUserID,
                userAvatar,
                order: player.Order,
                percentage: 0,
                transitionDuration: '5s',
                avatarSelection: player.AvatarSelection
            };
        });
        players.sort((a, b) => {
            const aOrder = a.order;
            const bOrder = b.order;
            return aOrder - bOrder;
        });
        dashboardData['players'] = players;
        this.setState(dashboardData, this.initialisePlayers);
    }
    
    async initialisePlayers() {
        //check if we have an array of movements, if so then process the movements - updating the current values in the state from the movements
        // up to the latest value, and then we'll need to update the latest value after that but only after we've finished
        if(this.props.data) {
            this.initialising = true;
            const playerData = this.getPlayerData();
            if(playerData.length) {
                let players = [...this.state.players];
                this.setState({
                    showCountdown: true,
                    countdownSecs: 3
                });
                await processUtils.sleep(1000);
                if(!this._isMounted){ return };
                this.setState({
                    showCountdown: true,
                    countdownSecs: 2
                });
                await processUtils.sleep(1000);
                if(!this._isMounted){ return };
                this.setState({
                    showCountdown: true,
                    countdownSecs: 1
                });
                await processUtils.sleep(1000);
                if(!this._isMounted){ return };
                this.setState({
                    showCountdown: true,
                    countdownSecs: 0
                });
                window.setTimeout(() => {
                    if(!this._isMounted){ return };
                    this.setState({
                        showCountdown: false,
                        countdownSecs: 3
                    });
                }, 1000);
                if(!this._isMounted){ return };
                if(this.state.animationStyle === 1) {
                    let speed = 12;
                    let arrayPropertyName;
                    let arrayPropertyFound = false;
                    let itemIndex = 0;
                    while(itemIndex < playerData.length && !arrayPropertyFound) {
                        const currentPlayerData = playerData[itemIndex];
                        const keys = Object.keys(currentPlayerData);
                        for(let i = 0; i < keys.length; i++) {
                            const key = keys[i];
                            if(Array.isArray(currentPlayerData[key])) {
                                arrayPropertyName = key;
                                arrayPropertyFound = true;
                                break;
                            }
                        }
                        itemIndex++;
                    }
                    
                    const movements = [];
                    //add all the movements to an array and then sort by day
                    for(let i = 0; i < playerData.length; i++) {
                        const playerDataItem = playerData[i];
                        if(arrayPropertyName && playerDataItem.hasOwnProperty(arrayPropertyName)) {
                            playerDataItem[arrayPropertyName].forEach(movement => {
                                if(movement.current && movement.target) {
                                    const movementPercentage = this.getPercentage(movement.current, movement.target);
                                    //const racePercentage = movementPercentage === 100 ? 103 : movementPercentage; //we use 103 to ensure the player has crossed the finish line
                                    //const won = movementPercentage === 100;
                                    movements.push({
                                        id: playerDataItem.id,
                                        day: movement.day,
                                        percentage: movementPercentage
                                        //department: movement.department,
                                        //won
                                    });
                                }
                            })
                        }
                        //add the final movement                        
                        let percentage = 0;
                        if(playerDataItem.current && playerDataItem.target) {
                            percentage = this.getPercentage(playerDataItem.current, playerDataItem.target);
                            //const racePercentage = percentage === 100 ? 103 : percentage; //we use 103 to ensure the player has crossed the finish line
                        }
                        movements.push({
                            id: playerDataItem.id,
                            day: playerDataItem.day,
                            percentage,
                            department: playerDataItem.department,
                            current: playerDataItem.current,
                            target: playerDataItem.target
                        });
                    }
                    movements.sort((a, b) => {
                        return a.day - b.day;
                    });
                    if(this.state.timeLimit) {
                        let totalDistance = 0;
                        const playerDistances = { };
                        for(let i = 0; i < players.length; i++) {
                            playerDistances[players[i].clientUserID] = 0;
                        }
                        for(let i = 0; i < movements.length; i++) {
                            const currentMovement = movements[i];
                            if(playerDistances.hasOwnProperty(currentMovement.id)) {
                                totalDistance += Math.abs(currentMovement.percentage - playerDistances[currentMovement.id]);
                                playerDistances[currentMovement.id] = currentMovement.percentage;
                            }
                        }
                        speed = Math.max(speed, totalDistance / this.state.timeLimit);
                    }
                    //animate the movements
                    if(movements.length) {
                        this.setState({
                            isAnimating: true
                        });
                        let winningPlayer = null; 
                        this.startBgAnim();
                        this.startPlayerAnim();
                        for(let i = 0; i < movements.length; i++) {
                            const currentMovement = movements[i];
                            let timeMs = 0;
                            this.setState((state, props) => {
                                players = [...state.players];
                                const originalPlayer = players.find(p => p.clientUserID === currentMovement.id);
                                if(originalPlayer) {
                                    const percentage = currentMovement.percentage;
                                    const department = currentMovement.department || originalPlayer.department;
                                    let won = false;
                                    if(!winningPlayer && percentage === 100) {
                                        //check if this racer will later move backwards, otherwise set them as the winner
                                        const playerRemainingMovements = movements.slice(i).filter(m => m.id === originalPlayer.clientUserID);
                                        won = true; //initialise to true, we've change it if the player is going to eventually move backwards
                                        for(let j = 0; j < playerRemainingMovements.length; j++) {
                                            if(playerRemainingMovements[j].percentage < 100) {
                                                won = false;
                                                break;
                                            }
                                        }
                                        if(won) {
                                            winningPlayer = originalPlayer;
                                        }
                                    }
                                    const movement = Math.abs(percentage - (originalPlayer.percentage || 0));
                                    timeMs = Math.round((movement / speed) * 1000); //this gives us the time to allocate to the transition to get a uniform speed
                                    const transitionDuration = `${timeMs}ms`; 
                                    let updatedPlayer = Object.assign({}, originalPlayer, { percentage, transitionDuration, current: currentMovement.current, target: currentMovement.target, department });
                                    const playerIndex = _.indexOf(players, originalPlayer);
                                    players.splice(playerIndex, 1, updatedPlayer);
                                    //this.setState({ players});
                                    ////await processUtils.sleep(timeMs);
                                    //if(!this._isMounted){ return };
                                }
                                return { players };
                            });
                            if(timeMs) {
                                await processUtils.sleep(timeMs);
                            }
                            if(!this._isMounted){ return };
                        }
                        if(winningPlayer) { 
                            const playersCopy = [...this.state.players];
                            const playerIndex = _.findIndex(playersCopy, p => p.id === winningPlayer.id);
                            if(playerIndex > -1) {
                                winningPlayer = {...playersCopy[playerIndex]};
                                winningPlayer.won = true;
                                playersCopy.splice(playerIndex, 1, winningPlayer);
                                players = playersCopy;
                                this.setState({ players });
                            }
                        }                        
                    }
                }
                else if(this.state.animationStyle === 2) {
                    if(playerData.length) {
                        const speed = 10;
                        let maxDuration = 0;
                        for(let i = 0; i < playerData.length; i++) {
                            const playerDataItem = playerData[i];
                            const originalPlayer = players.find(p => p.clientUserID === playerDataItem.id);
                            if(originalPlayer) {
                                const playerIndex = _.indexOf(players, originalPlayer);
                                let percentage = 0;
                                if(playerDataItem.current && playerDataItem.target) {
                                    percentage = this.getPercentage(playerDataItem.current, playerDataItem.target);
                                }
                                const department = playerDataItem.department || originalPlayer.department;
                                
                                const timeMs = percentage ? Math.round((percentage / speed) * 1000) : 0; //this gives us the time to allocate to the transition to get a uniform speed
                                const transitionDuration = `${timeMs}ms`; 
                                maxDuration = Math.max(timeMs, maxDuration);
                                const updatedPlayer = Object.assign({}, originalPlayer, { percentage, transitionDuration, current: playerDataItem.current, target: playerDataItem.target, department})
                                players.splice(playerIndex, 1, updatedPlayer);
                            }
                        }
                        let winningPlayer = null;
                        for(let i = 0; i < players.length; i++) {
                            const player = players[i];
                            //compare the raw percentages
                            if(player.percentage >= 100 && (!winningPlayer || ((player.current / player.target) * 100) > ((winningPlayer.current / winningPlayer.target) * 100))) {
                                winningPlayer = player;
                            }
                        }
                        this.setState({
                            isAnimating: true
                        });
                        this.startBgAnim();
                        this.startPlayerAnim();
                        this.setState({ players }, async () => {
                            if(winningPlayer) {
                                const timeUntilWinner = parseInt(winningPlayer.transitionDuration.replace('ms', ''), 10);
                                //apply the won state as the winning player crosses the finish line
                                window.setTimeout(() => {
                                    const playersCopy = [...this.state.players];
                                    const playerIndex = _.findIndex(playersCopy, p => p.id === winningPlayer.id);
                                    if(playerIndex > -1) {
                                        winningPlayer = {...playersCopy[playerIndex]};
                                        winningPlayer.won = true;
                                        playersCopy.splice(playerIndex, 1, winningPlayer);
                                        this.setState({ players: playersCopy });
                                    }
                                }, timeUntilWinner);
                            }
                            
                            await processUtils.sleep(maxDuration);
                            if(!this._isMounted){ return };                            
                        });
                    }
                }
                else if(this.state.animationStyle === 3) {
                    const speed = 15;
                    if(playerData.length) {
                        this.setState({
                            isAnimating: true
                        });
                        this.startBgAnim();
                        this.startPlayerAnim();
                        
                        let matchingPlayerDataItems = [];
                        let winningPlayerData = null;
                        playerData.forEach(p1 => {
                            const matchingPlayer = players.find(p2 => p2.clientUserID === p1.id);
                            if(matchingPlayer) {
                                matchingPlayerDataItems.push(p1);
                                //compare the raw percentages
                                const currentPlayerPercentage = p1.current && p1.target ? (p1.current / p1.target) * 100 : 0;
                                if(currentPlayerPercentage >= 100 && (!winningPlayerData || (currentPlayerPercentage > ((winningPlayerData.current / winningPlayerData.target) * 100)))) {
                                    winningPlayerData = p1;
                                }
                            }
                        });
                        let winningPlayerIndex = -1;
                        for(let i = 0; i < matchingPlayerDataItems.length; i++) {
                            let timeMs = 0;
                            this.setState((state, props) => {
                                players = [...state.players];
                                const playerDataItem = matchingPlayerDataItems[i];
                                const originalPlayer = players.find(p => p.clientUserID === playerDataItem.id);
                                const playerIndex = _.indexOf(players, originalPlayer);
                                let percentage = 0;
                                if(playerDataItem.current && playerDataItem.target) {
                                    percentage = this.getPercentage(playerDataItem.current, playerDataItem.target);
                                }                                
                                const department = playerDataItem.department || originalPlayer.department;
                                timeMs = percentage ? Math.round((percentage / speed) * 1000) : 0; //this gives us the time to allocate to the transition to get a uniform speed
                                const transitionDuration = `${timeMs}ms`; 
                                const updatedPlayer = Object.assign({}, originalPlayer, { percentage, transitionDuration, current: playerDataItem.current, target: playerDataItem.target, department })
                                players.splice(playerIndex, 1, updatedPlayer);
                                /*if(winningPlayerData && winningPlayerData.id === originalPlayer.clientUserID) {
                                    winningPlayerIndex = playerIndex;
                                }*/
                                return { players };
                            });
                            if(timeMs) {
                                await processUtils.sleep(timeMs);
                            }
                            if(!this._isMounted){ return };
                        }
                        if(winningPlayerData) {
                            const playersCopy = [...this.state.players];
                            const winningPlayerIndex = _.findIndex(playersCopy, p => winningPlayerData.id === p.clientUserID)
                            if(winningPlayerIndex > -1) {
                                const winningPlayer = {...playersCopy[winningPlayerIndex]};
                                winningPlayer.won = true;
                                playersCopy.splice(winningPlayerIndex, 1, winningPlayer);
                                this.setState({ players: playersCopy });
                            }
                        }
                    }
                }               
                this.initialising = false;
                //check if the player data has changed while we were animating
                const playerDataUpdated = this.getPlayerData();
                if(JSON.stringify(playerData) !== JSON.stringify(playerDataUpdated)) {
                    //we have new data available, process the update
                    this.updatePlayers(playerDataUpdated);
                }
                this.stopBgAnim();
                this.stopPlayerAnim();
                await processUtils.sleep(5000);
                if(!this._isMounted){ return };
                //use the players from the state incase of any updates
                this.showScoreboard([...this.state.players]);
            }
        }
    }

    updatePlayers(playerData) {
        if(this.state.players) {
            const newPlayers = this.props.dashboard.Players;
            const newPlayerData = playerData || this.getPlayerData();
            //debugger;
            this.setState((state, props) => {
                const originalPlayers = state.players;
                const players = newPlayers.map(player => {
                    let updatedPlayer = null;
                    let userAvatar = null;
                    if(player.UserID) {
                        const user = this.props.users.find(u => u.ID === player.UserID);
                        if(user) {
                            userAvatar = user.Avatar;
                        }
                    }
                    //check if we already have the player, we don't want to overwrite the percentage and transition duration if so (if we are still initialising)
                    const originalPlayer = originalPlayers.find(p => p.id === player.ID);
                    if(originalPlayer) {
                        updatedPlayer = Object.assign({}, originalPlayer, { label: player.Label, order: player.Order, avatarSelection: player.AvatarSelection, userAvatar });
                    }
                    else {                        
                        updatedPlayer = {
                            label: player.Label,
                            id: player.ID,
                            userID: player.UserID,
                            clientUserID: player.ClientUserID,
                            userAvatar,
                            order: player.Order,
                            percentage: 0,
                            transitionDuration: '5s',
                            avatarSelection: player.AvatarSelection
                        };
                    }
                    if(!this.initialising) {
                        const playerDataItem = newPlayerData.find(p => p.id === updatedPlayer.clientUserID);
                        if(playerDataItem) {
                            if(playerDataItem.department) {
                                updatedPlayer.department = playerDataItem.department;
                            }
                            updatedPlayer.current = playerDataItem.current;
                            updatedPlayer.target = playerDataItem.target;
                            let percentage = 0;
                            if(playerDataItem.current && playerDataItem.target) {
                                updatedPlayer.percentage = this.getPercentage(playerDataItem.current, playerDataItem.target);
                            }  
                            let speed = 10; //the speed varies by the animation style, but let's default to 10 for updates after the initial animation
                            const timeMs = updatedPlayer.percentage ? Math.round((updatedPlayer.percentage / speed) * 1000) : 0;
                            updatedPlayer.transitionDuration = `${timeMs}ms`; 
                        }       
                    }
                    return updatedPlayer;
                });
                players.sort((a, b) => {
                    const aOrder = a.order;
                    const bOrder = b.order;
                    return aOrder - bOrder;
                });

                return { players };
            });
        }
    }

    getPercentage(current, target, maxPercentage = 100) {
        let percentage = target === 0 ? 0 : (current / target) * 100;
        if(percentage < 0) {
            percentage = 0;
        }
        if(maxPercentage && percentage > maxPercentage) {
            percentage = maxPercentage;
        }
        return percentage;
    }

    getPlayerData() {
        const playerData = []
        if(this.props.data && this.props.dashboard) {
            for(let i = 0; i < this.props.dashboard.Players.length; i++) {
                const player = this.props.dashboard.Players[i];
                let playerDashboardData = {};
                if(player.ClientUserID) {
                    playerDashboardData = Object.assign({}, this.props.data.find(d => d.id + '' === player.ClientUserID));
                }
                player.Adjustments.forEach(adjustment => {
                    if(adjustment.Append) {
                        playerDashboardData[adjustment.Name] += parseFloat(adjustment.Append);
                    }
                    else {
                        playerDashboardData[adjustment.Name] = adjustment.Override;
                    }
                });
                playerData.push(playerDashboardData);
            }
        }
        return playerData;
    }

    getBase64Url(rawString) {
        if(rawString) {
            return imageUtils.getBase64Url(rawString);
        }
        else {
            return "";
        }
    }

    isSingleTrack() {
        //the idea with this function was that if there is a single track, we don't render the multi track layout at all
        //and don't need to animate it. However, this means that if we switch from 14 to 15 players, the track will become
        //static so this idea has been scrapped for now
        return this.props.dashboard.Players.length > 14;
    }

    getMainClass() {
        let className = '';
        if(this.props.dashboard) {
            if(this.props.dashboard.Players.length > 14) {
                className += 'racetrack--more-than-14 ';
            }
            else if(this.props.dashboard.Players.length > 7) {
                className += 'racetrack--more-than-7 ';
            }
            if(this.props.dashboard.Players.length > 35) {
                className += 'racetrack--smallest-bikes ';
            }
            else if(this.props.dashboard.Players.length > 21) { //test with 28 - 35 to see if it's too big at this configuration, we might need another breakpoint with smaller-bikes
                className += 'racetrack--larger-bikes ';
            }
            else if(this.props.dashboard.Players.length > 14) {
                className += 'racetrack--ultralarge-bikes '; //racetrack--larger-bikes 
            }
            else if(this.props.dashboard.Players.length > 7) {
                className += 'racetrack--ultralarge-bikes '; //racetrack--largest-bikes
            }
            else {
                className += 'racetrack--ultralarge-bikes '; //racetrack--largest-bikes
            }
        }
        if(this.state.isAnimating) {
            className += 'racetrack--animate ';
        }
        if(this.state.finished) {
            className += 'showfinish ';
        }
        return className;
    }

    getAvatar(player) {
        return <Avatar avatarSelection={player.avatarSelection} dashboard={this.props.dashboard} setRef={this.setPlayerRef} />
    }

    async showScoreboard(players) {
        const scoreboardPlayers = players.map(p => ({...p, realPercentage: this.getPercentage(p.current, p.target, 0)}));
        if(this.state.animationStyle === 1) {
            scoreboardPlayers.sort((a, b) => {
                if(a.won && !b.won) {
                    return -1;
                }
                else if(b.won && !a.won) {
                    return 1;
                }
                else {
                    return (b.percentage || 0) - (a.percentage || 0);
                }
            });
        }
        else {
            scoreboardPlayers.sort((a, b) => {
                //sort by the maximum raw percentage
                return b.realPercentage - a.realPercentage;
            });
        }
        const scoreboardModal = <ScoreboardModal title={this.state.title} logo={this.state.logo} getBase64Url={this.getBase64Url} players={scoreboardPlayers} showQualified={this.state.showQualified} valueFormat={this.state.valueFormat} currencySymbol={this.state.currencySymbol} theme={this.state.theme} onAnimationFinished={this.props.onAnimationFinished} showRealPercentage={this.state.showRealPercentage} /> 
        this.props.showModal(() => scoreboardModal, { isOpen: true })
    }

    startBgAnim() {
        this.bg.play();
        TweenLite.fromTo(this.bg, 1, {timeScale:0},{timeScale:1});
        this.bgSingle.play();
        this.bgCurbTop.play();
        this.bgCurbBot.play();
        TweenLite.fromTo(this.bgSingle, 1, {timeScale:0},{timeScale:1});
        TweenLite.fromTo(this.bgCurbTop, 1, {timeScale:0},{timeScale:1});
        TweenLite.fromTo(this.bgCurbBot, 1, {timeScale:0},{timeScale:1});

        this.start.play();

        //-- Animate Track lines
        this.trackline.play();
    }
  
    async stopBgAnim() {
        this.setState({
            finished: true
        });
        await processUtils.sleep(1500);
        //TweenLite.to(this.bg, 1, {timeScale:0});
        TweenLite.fromTo(this.bg, 1, {timeScale:1},{timeScale:0});
        TweenLite.fromTo(this.bgSingle, 1, {timeScale:1},{timeScale:0});
        TweenLite.fromTo(this.bgCurbTop, 1, {timeScale:1},{timeScale:0});
        TweenLite.fromTo(this.bgCurbBot, 1, {timeScale:1},{timeScale:0});
        TweenLite.fromTo(this.trackline, 1, {timeScale:1},{timeScale:0});
        //-- Animate Finish line and flag
        //this.finish.play();
        //this.flag.play();

        //-- Stop the Track lines animation
        //this.trackline.pause();
    }

    startPlayerAnim() {
        this.playerTimelines.forEach(timeline => {
            if(timeline.paused()) {
                timeline.play();
                TweenLite.to(timeline, 1, {timeScale:1});
            }
        });
        this.playerAnimationStarted = true;
    }

    async stopPlayerAnim() {
        await processUtils.sleep(1000);
        this.playerTimelines.forEach(timeline => {
            TweenLite.to(timeline, 1, {timeScale:0});
        });
        this.playerAnimationStopped = true;
    }

    getCountdownClass() {
        let className = '';
        if(this.state.showCountdown) {
            className += 'in ';
            if(this.state.countdownSecs === 0) {
                className += 'out ';
            }
        }
        return className;
    }

    getPlayersMarkup(players) {
        const playersMarkup = [];
        let nextPlayerIndex = 0;
        if(players.length) {
            if(players.length <= 14) {
                for(let i = 0; i < 7; i++) {
                    const trackIndex = i;
                    if(players.length <= 7) {
                        //we want a single player on each track
                        if(trackIndex < players.length) {
                            playersMarkup.push(this.getPlayerMarkup(players[nextPlayerIndex]));
                            nextPlayerIndex++;
                        }
                    }
                    else { 
                        //display 2 or 3 on a track depending on the player count - 2nd or 3rd is either a player or an empty list item
                        const addExtraPlayerCount = players.length - 7;
                        const addExtraPlayer = trackIndex < addExtraPlayerCount;
                        if(!addExtraPlayer && players.length <= 14) {
                            //add the empty list item
                            playersMarkup.push(<li key={playersMarkup.length}></li>);
                        }
                        playersMarkup.push(this.getPlayerMarkup(players[nextPlayerIndex]));
                        nextPlayerIndex++;
                        /*if(players.length > 14) { //we shouldn't get here, we switch to no tracks once we hit > 14 players
                            //3 on a track so add an extra player
                            playersMarkup.push(this.getPlayerMarkup(players[nextPlayerIndex]));
                            nextPlayerIndex++;
                        }*/
                        if(addExtraPlayer) {
                            playersMarkup.push(this.getPlayerMarkup(players[nextPlayerIndex]));
                            nextPlayerIndex++;
                        }
                    }
                }
            }
            else {
                //we have a single track, return all the players
                players.forEach(player => playersMarkup.push(this.getPlayerMarkup(player)));
            }
        }
        return playersMarkup;
    }

    getPlayerMarkup(player) {
        return <li key={player.id} style={{left: `${player.percentage}%`, transitionDuration: player.transitionDuration}} className={`${player.won ? 'winner': ''} ${player.percentage > 5 ? 'racetrack--labelsback' : ''}`}>
            <div className="bike bike1">
                <p className="list-racers__name">{player.label}</p>
                {this.getAvatar(player)}
            </div>
        </li>;
    }

    render() {
        return (
            <div>
                <main className={`racetrack ${this.getMainClass()} ${this.state.theme === 'Dark' ? 'dark-theme' : ''}`}>
                    <section className="racetrack__head">
                        <div className="racetrack__bg" style={{backgroundImage: `url(${this.getBase64Url(this.state.backgroundImage)})`}}></div>
                           <Link to={this.props.playlistID ? '/Playlists' : '/Dashboards'} className="btn btn-back btn--withicon">
                                <i className="aheadicon-arrow"></i>
                                Back
                            </Link>
                        <div className="racetrack__title">
                            <h1>{this.state.title}</h1>
                        </div>
                        <div className="racetrack__logo">
                            {
                                this.state.logo ?
                                    <img src={this.getBase64Url(this.state.logo)} alt={this.state.title}/>
                                    : null
                            }
                            
                        </div>
                        <div className="ahead-logo">
                            {
                                this.state.theme === 'Dark' ? 
                                    <img src={require('../../../../../images/logo-ahead-of-the-game-wh-tr.png')} alt="Ahead of the game"/>
                                : 
                                    <img src={require('../../../../../images/logo-ahead-of-the-game-tr.png')} alt="Ahead of the game"/>
                            }                            
                        </div>
                        <div className="racetrack__flag">
                            <img src={require('../../../../../images/track-checkered-flag.png')} alt="Checkered flag"/>
                        </div>
                    </section>
                    
                    <section className="racetrack__track">
                        <ul className="list-unstyled list-racers">
                            {this.getPlayersMarkup(this.state.players)}
                        </ul>
                        <div className="tracks">
                            {[1,2,3,4,5,6,7].map(i => 
                                <Track key={i} theme={this.state.theme} />
                            )}
                        
                            <div className="track track--single">
                                <div className="track__top" style={{backgroundImage: `url(${require('../images/track-side.png')})`}}></div>
                                <div className="track__start"></div>
                                <div className="track__line" style={{backgroundImage: `url(${require('../images/track-bg-single.png')})`}}></div>
                                <div className="track-dirt-single" style={{backgroundImage: `url(${require('../images/track-dirt-single.png')})`}}></div>

                                {/*<div className="track__logo">
                                    <img src={this.getBase64Url(this.state.logoLarge)} alt={this.state.title}/>
                                </div>*/}

                                <div className="track__finish"></div>
                                <div className="track__bottom" style={{backgroundImage: `url(${require('../images/track-side.png')})`}}></div>
                            </div>
                        </div>
                        
                    </section>
                </main>
                <div className={`countdown ${this.getCountdownClass()}`}>
                    <div className="countdown__inner">
                        <p>Race start in</p>
                        <p className="countdown__time">0:{this.state.countdownSecs.toString().padStart(2, '0')}</p>
                    </div>
                </div>
            </div>
        );
    }
}

BikeRace.propTypes = {
    dashboard: PropTypes.object,
    users: PropTypes.array,
    data: PropTypes.array
};

export default withRouter(BikeRace);