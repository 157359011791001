import _ from 'lodash';

export function spacePascalCase(text) {
    //if we have a lowercase letter followed by an uppercase, add a space before the uppercase
    let lastLower = false;
    const groups = [];
    let index = 0;
    const letters = text.split('');
    letters.forEach(letter => {
        const isLower = letter === letter.toLowerCase();
        if(!isLower) {
            if(lastLower) {
                //start a new group
                index++;
            }
        }
        else {
            lastLower = true;
        }
        groups[index] = groups[index] ? groups[index] += letter : letter;
    });
    return groups.join(' ');
}

export function formatTime(secs) {
    const negative = secs < 0;
    secs = Math.abs(secs);
    const hours = Math.floor(secs / 3600);
    secs = secs % 3600;
    var minutes = Math.floor(secs / 60);
    secs = parseInt(secs % 60, 10);
    return `${negative ? '-' : ''}${_.padStart(hours, 2, '0')}:${_.padStart(minutes, 2, '0')}:${_.padStart(secs, 2, '0')}`;
}

export function pluralise(unit, number) {
    return number === 1 ? unit : `${unit}s`;
}