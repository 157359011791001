import * as types from '../actions/actionTypes';
import initialState from './initialState';
const _ = require('lodash');

export default function dashboardReducer(state = initialState.dashboard, action) {
    //check if this has the saved state included
    //debugger;
    
    switch(action.type) {
        case types.GET_DASHBOARDS_SUCCESS:
            const dashboards = [...action.dashboards];         
            return {
                ...state,
                dashboards
            }   
        case types.DASHBOARDS_LOADING:
        {
            const dashboardsLoading = action.loading;  
            return {
                ...state,
                dashboardsLoading
            }   
        }
        case types.CURRENT_DASHBOARD_LOADED:
        {
            const currentDashboard = { id: action.id, data: null };         
            return {
                ...state,
                currentDashboard
            }   
        }
        case types.DASHBOARD_LOADING:
        {
            const dashboardItemsLoading = [...state.dashboardItemsLoading];
            if(action.payload.loading) {
                dashboardItemsLoading.push(action.payload.dashboardID);
            }
            else {
                _.pull(dashboardItemsLoading, action.payload.dashboardID);
            }
            return {
                ...state,
                dashboardItemsLoading
            }   
        }
        case types.DASHBOARD_DATA_RECEIVED:
        {
            if(state.currentDashboard && state.currentDashboard.id === action.dashboardData.id) {
                const currentDashboard = {...action.dashboardData}; //{ id = state.currentDashboard.id, data = {...action.data} };            
                return {
                    ...state,
                    currentDashboard
                }            
            }
            else {
                //the data we received isn't for the current dashboard so ignore it
                return state;
            }  
        }
        case types.DASHBOARD_UPDATED:
        {
            const dashboards = [...state.dashboards];
            const dashboard = dashboards.find(d => d.ID === action.dashboard.ID);
            const dashboardIndex = _.indexOf(dashboards, dashboard);
            dashboards.splice(dashboardIndex, 1, action.dashboard);
            return {
                ...state,
                dashboards
            } 
        }
        case types.GET_WIDGETS_SUCCESS:
        {
            const widgets = action.widgets;
            return {
                ...state,
                widgets
            }   
        }
        case types.CREATE_WIDGET_SUCCESS:
        {
            const widgets = [...state.widgets];
            widgets.push(action.widget);
            return {
                ...state,
                widgets
            }
        }
        case types.LOGOUT:       
            return {
                ...initialState.dashboard
            };    
        default:
            return state;
    }
}