import React from 'react';
import * as imageUtils from '../../utils/imageUtils';
import roleTypes from '../../constants/roleTypes';

class AddUpdatePlayerModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
        this.toggleSelectUser = this.toggleSelectUser.bind(this);
        this.getUserClass = this.getUserClass.bind(this);
        this.getUserImage = this.getUserImage.bind(this);
        this.addPlayer = this.addPlayer.bind(this);
        this.savePlayer = this.savePlayer.bind(this);
        //is the user here the id or object?
        debugger;
        if(this.props.player) {
            let selectedUser = null;
            if(this.props.player.UserID) {
                selectedUser = this.props.users.find(u => u.ID === this.props.player.UserID);
            }
            this.state = { 
                selectedUser: selectedUser,
                playerName: this.props.player.Label,
                userID: this.props.player.ClientUserID,
                order: this.props.player.Order,
                avatarUserEditable: this.props.player.AvatarUserEditable
            };
        }
        else {
            this.state = { 
                selectedUser: null,
                playerName: '',
                userID: '',
                order: this.props.order || 1,
                avatarUserEditable: true
            };
        }
    }

    componentDidMount() {    
        const tooltipControls = window.$(this.tooltipControls);
        if (tooltipControls.length !== 0 ) {
            tooltipControls.tooltip();
            tooltipControls.on('click', function(e){
                e.preventDefault();
            });
        }  
    }

    getUserClass(user) {
        return this.state.selectedUser && this.state.selectedUser.ID === user.ID ? 'active' : null;
    }

    getUserImage(user) {
        const image = user.Avatar;
        const imageData = image ? imageUtils.getAvatarBase64Url(image) : require('../../images/avatar-empty.png');
        return imageData;
    }

    toggleSelectUser(event, user) {
        event.preventDefault();
        let selectedUser = this.state.selectedUser;
        if(!selectedUser || selectedUser.ID !== user.ID) {
            //the user has changed, select it
            selectedUser = user;
        }
        else {
            //the user is the same, unselect it
            selectedUser = null;
        }
        this.setState({
            selectedUser,
            playerName: selectedUser ? `${selectedUser.FirstName} ${selectedUser.LastName}` : this.state.playerName,
            userID: selectedUser ? selectedUser.ClientUserID || '' : this.userID
        });
    }

    addPlayer(event) {
        debugger;
        event.preventDefault();
        this.props.actions.addPlayer(this.props.dashboardID, this.state.selectedUser ? this.state.selectedUser.ID : null, this.state.userID, this.state.playerName, this.state.order, this.state.avatarUserEditable);
        window.$('#modal').modal('hide');
    }

    savePlayer(event) {
        debugger;
        event.preventDefault();
        this.props.actions.updatePlayer(this.props.dashboardID, this.props.player.ID, this.state.selectedUser ? this.state.selectedUser.ID : null, this.state.userID, this.state.playerName, this.state.order, this.state.avatarUserEditable);
        window.$('#modal').modal('hide');
    }

    onChange(event) {
        const field = event.target.name;
        if(field === 'avatarUserEditable') {
            const checked = event.target.checked;
            this.setState({
                avatarUserEditable: checked
            });
        }
        else {
            let newState = Object.assign({}, this.state);
            let value = event.target.value;
            newState[field] = value;
            return this.setState(newState);   
        }
    }

    render() {
        const {users} = this.props;
        return (     
            <div className="modal-dialog" role="document">
            <div className="modal-content">
                <form className="form" onSubmit={ this.props.player ? this.savePlayer : this.addPlayer}>
                    <div className="modal-header">
                        <h5 className="modal-title">{`${this.props.player ? 'Edit player' : 'Create new player'}`}</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                        </button>
                    </div>
                    <div className="modal-body">
                            <div className="modal__inner modal__inner--wide">
                                <div className="form-group">
                                    <label>Map user (optional)</label>
                                    <div className="list-users scrollbar">
                                    {
                                        users.length ?
                                            users.map(user =>
                                                <a key={user.ID} href={null} className={`user ${this.getUserClass(user)}`} onClick={(e) => this.toggleSelectUser(e, user)}>
                                                    <div className="avatar">
                                                        <img src={this.getUserImage(user)} alt="Avatar"/>
                                                    </div>
                                                    <div className="user__name">
                                                        <p className="user__title">{`${user.FirstName} ${user.LastName}`}</p>
                                                        <p className="small grey">{user.Email}</p>
                                                    </div>
                                                </a>
                                            )
                                        :
                                            <p /> /*Add some default text here*/
                                    }                                    
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Player name</label>
                                    <input className="form-control" type="text" name="playerName" required value={this.state.playerName} onChange={this.onChange} placeholder="Enter player name" />
                                </div>
                                <div className="form-group">
                                    <label>Data ID</label>
                                    <input className="form-control" type="text" name="userID" value={this.state.userID} onChange={this.onChange} placeholder="Enter data ID" />
                                    <p className="help-block">The data ID maps this player's data to the data source</p>
                                </div>
                                {
                                    this.state.selectedUser && this.state.selectedUser.Role === roleTypes.Viewer ?
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="avatarUserEditable" name="avatarUserEditable" checked={this.state.avatarUserEditable} onClick={this.onChange} />
                                                <label className="custom-control-label" htmlFor="avatarUserEditable">User can edit avatar</label>
                                            </div>                                
                                        </div>
                                        : null
                                }
                                
                            </div>
                        
                        
                    </div>
                    <div className="modal-footer modal-footer--center">
                        <button className="btn btn--default btn--halfwide" data-dismiss="modal">Cancel</button>
                        <input type="submit" className="btn btn--primary btn--halfwide" value={`${this.props.player? 'Update Player' : 'Create player'}`} />
                    </div>
                </form>
            </div>
        </div>
        );
    }
}

export default AddUpdatePlayerModal;


