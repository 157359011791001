import * as types from './actionTypes';
import TemplateService from '../services/templateService';

export function getTemplates(tag){
    return function(dispatch) {
        return TemplateService.getTemplates(tag).then(templates => {
            templates = templates.filter(t => !t.Purchased);
            if(tag && templates.length > 1) {
                //sort by the priority field in the tag (descending)
                templates.sort((a, b) => {
                    const aTag = a.Tags.find(t => t.Name.toLowerCase() === tag.toLowerCase());
                    const bTag = b.Tags.find(t => t.Name.toLowerCase() === tag.toLowerCase());
                    return bTag.Priority - aTag.Priority;
                });
            }
            const results = { templates, tag };
            dispatch({ type: types.GET_TEMPLATES_SUCCESS, results });
        }).catch(error => {
            throw(error);
        });
    };
}