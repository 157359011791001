import BaseService from '../services/baseService';
import * as types from '../actions/actionTypes';

export default function serviceMiddleware() {
    return ({ dispatch, getState }) => next => action => {
        if (action.type === types.LOGIN_SUCCESS || action.type === types.TOKEN_REFRESHED) {
            //const state = getState();
            BaseService.setToken(action.payload.access_token);
            dispatch({ type: types.SCHEDULE_TOKEN_REFRESH, payload: { tokenExpiry: action.payload[".expires"], refreshToken: action.payload.refresh_token} });
        }
        else if(action.type === types.LOGOUT) {
            debugger;
            BaseService.clearToken();
            dispatch({ type: types.CANCEL_TOKEN_REFRESH });
        }      
        return next(action);
    };
  }
