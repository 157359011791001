import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import 'url-search-params-polyfill';
import validator from 'validator';
import * as accountActions from '../../actions/accountActions';

class ResetPassword extends React.Component {
    constructor(props, context) {
        super(props, context);
        debugger;
        this.resetPassword = this.resetPassword.bind(this);
        this.onChange = this.onChange.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        const searchParams = new URLSearchParams(props.location.search);
        this.state = 
        { 
            email: searchParams.get('email'),
            code: searchParams.get('code'),
            password: '',
            passwordConfirmation: ''
         };
    }

    componentWillMount() {      
        this.props.actions.clearResetPassword();
    }

    resetPassword(event) {
        event.preventDefault();
        if(this.formIsValid()) {
            this.props.actions.resetPassword(this.state.email, this.state.code, this.state.password);
        }
    }

    onChange(event) {
        const field = event.target.name;
        const value = event.target.value;
        let newState = Object.assign({}, this.state);
		newState[field] = value;
		return this.setState(newState);
    }

    formIsValid() {
        const state = this.state;
        if(!validator.isEmail(state.email)) {
            this.props.actions.setResetPasswordError('The email address is missing');
            return false;
        }
        else if(validator.isEmpty(state.code)) {
            this.props.actions.setResetPasswordError('The password reset code is missing');
            return false;
        }
        else if(validator.isEmpty(state.password)) {
            this.props.actions.setResetPasswordError('The password is missing');
            return false;
        }
        else if(validator.isEmpty(state.passwordConfirmation)) {
            this.props.actions.setResetPasswordError('The password confirmation is missing');
            return false;
        }
        else if(!validator.equals(state.password, state.passwordConfirmation)) {
            this.props.actions.setResetPasswordError('The password and confirmation do not match');
            return false;
        }
        return true;
    }

    render() {
        return (     
            this.props.user ?
                <Redirect to='/' />
                :
                <section className="loginsignup">
                    <form className="form form--loginsetup" onSubmit={this.resetPassword}>
                        <div className="loginsetup__logo">
                            <img src={require('../../images/logo-ahead-of-the-game.png')} alt="Ahead of the game" />
                        </div>
                        <div className={`form__resetpass ${this.props.reset ? 'hidden-block':null}`}>  
                            <div className="loginsignup__head">
                                <h5 className="withline">Reset password</h5>
                                <p className="intro">Please enter your new password</p>
                            </div>
                            <div className="form-group form-group--tight">
                                <label>Password</label>
                                <input type="password" className="form-control" name="password" value={this.state.password} onChange={this.onChange} placeholder="Enter password" />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" name="passwordConfirmation" value={this.state.passwordConfirmation} onChange={this.onChange} placeholder="Repeat password" />
                            </div>
                            <div className="form-group">
                                <input type="submit" className="btn btn--primary btn-block btn-resetpassword" value="Reset password" />
                            </div>            
                            <div className="distancer"></div>        
                        </div> 
                        <div className={`form__resetpassconfirm ${this.props.reset ? null : 'hidden-block'}`}>           
                            <div className="loginsignup__head">
                                <svg height="49.586px" className="picto-confirmation" version="1.1" viewBox="0 0 49.587 49.586" width="49.587px" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px">
                                    <path d="M49.187,24.792c0,13.469-10.922,24.393-24.394,24.393C11.321,49.186,0.4,38.262,0.4,24.792C0.4,11.318,11.321,0.4,24.793,0.4 C38.265,0.4,49.187,11.318,49.187,24.792z" fill="none" stroke="#51BA67" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8"></path>
                                    <polyline fill="none" points="15.922,24.792 22.575,31.445 35.88,18.14" stroke="#51BA67" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8"></polyline>
                                </svg>     
                                <h5 className="withline">Password reset</h5>
                                <p className="intro">Your password has been successfully reset. Please use it next time you log in.</p>
                            </div>
                            <div className="form-group">
                                <Link to="/Account/Login" className="btn btn--primary btn-block btn-backtologin">Back to login</Link>
                            </div>
                        </div> 
                        {this.props.error ?
                            <React.Fragment>
                                <div className="distancer"></div>    
                                    <div className="alert alert-danger">
                                        {this.props.error}
                                        <button type="button" className="close" onClick={this.props.actions.clearResetPasswordError}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>                                    
                            </React.Fragment>
                            : null
                        }    
                    </form>
                </section>                  
        );
    }
}

ResetPassword.propTypes = {
    user: PropTypes.object,
    error: PropTypes.string,
    reset: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return { user: state.account.user, error: state.account.resetPasswordError, reset: state.account.passwordReset };
}

function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);