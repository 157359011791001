import BaseService from './baseService';

class UserService {
    static getUsers() {
        return BaseService.getData('/Users')
            .then(data => {
                return Object.assign([], data);
            })
            .catch(error => {
                console.log('Unable to get users');
                throw new Error('Unable to get users');
            });
    }

    static createUser(email, password, firstName, lastName, role, clientID, clientUserID, emailAlertsEnabled) {
        return BaseService.postData('/Users', { email, password, firstName, lastName, role, clientID, clientUserID, emailAlertsEnabled })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                let message;
                if(error.message.toLowerCase().indexOf('password') > -1 || error.message === 'Payment Required') {
                    message = error.message;
                }
                else {
                    message = 'Unable to create user';
                }
                console.log(message);
                throw new Error(message);                
            });
    }  

    static updateUser(id, email, firstName, lastName, role, clientUserID, avatar, emailAlertsEnabled) {
        return BaseService.putData(`/Users/${id}`, { email, firstName, lastName, role, clientUserID, avatar, emailAlertsEnabled})
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                console.log('Unable to update user');
                throw new Error('Unable to update user');                
            });
    } 

    static deleteUser(id) {
        return BaseService.deleteData(`/Users/${id}`)
            .then(data => {
                return data;
            })
            .catch(error => {
                console.log('Unable to delete user');
                throw new Error('Unable to delete user');
            });
    }

    static setPassword(userID, password) {
        return BaseService.putData(`/Users/${userID}/Password`, { password })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                let message;
                if(error.message.toLowerCase().indexOf('password') > -1) {
                    message = error.message;
                }
                else {
                    message = 'Unable to change password';
                }
                console.log(message);
                throw new Error(message);                              
            });
    } 
}

export default UserService;
