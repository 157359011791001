
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import * as imageUtils from '../../../../../utils/imageUtils';
import * as processUtils from '../../../../../utils/processUtils';
import * as textUtils from '../../../../../utils/textUtils';
import _ from 'lodash';

class ScoreboardModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getUserImage = this.getUserImage.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.maxPages = Object.keys(this.props.players).length;
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.state = { 
            page: 1,
            manualPageChange: false
        };
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    async componentDidMount() {    
        this._isMounted = true;
        window.$('#modal').addClass('modal-scoreboard');
        this.animatePlayers();
        while(true && !this.state.manualPageChange) {
            await processUtils.sleep(13000);
            if(!this._isMounted){ return };
            if(!this.state.manualPageChange) {
                const page = this.state.page;
                const nextPage = page < this.maxPages ? page + 1 : 1;
                if(nextPage === 1 && this.props.onAnimationFinished) {
                    window.$('#modal').modal('hide');
                    if(this.props.hideModal) {
                        this.props.hideModal();
                    }
                    this.props.onAnimationFinished();
                }
                else {
                    this.setState({
                        page: nextPage
                    });
                }
                //this.animatePlayers();
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.page !== this.state.page && !this.state.manualPageChange) {
            this.animatePlayers();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.handleClick, false);
        window.$('#modal').removeClass('modal-scoreboard');
    }

    animatePlayers() {
        const items = window.$('.list-scores li');
        items.removeClass('active');
        items.each(function(index, el){
            var bar = window.$(this);
            setTimeout(function(){
            bar.addClass('active');
            }, 300 * index);
        });
    }

    getUserImage(avatar) {
        const imageData = avatar ? imageUtils.getAvatarBase64Url(avatar) : require('../../../../../images/avatar-empty.png');
        return imageData;
    }

    getRowClass(playerName) {
        /*if(playerName === 'WorldRecord') {
            return 'goldalt';
        }
        else if(playerName === 'PersonalBest') {
            return 'silveralt';
        }
        else 
        {*/
            return null;
        //}
    }

    handleClick(e) {
        if(this.node.contains(e.target) || this.navNode.contains(e.target)) {
            //the click is inside the modal
            return;
        }
        else {
            window.$('#modal').modal('hide');
            if(this.props.hideModal) {
                this.props.hideModal();
            }
        }
    }

    getCurrentValue(player, kpi) {
        if(kpi === 'Placements') {
            return player.currentValue ? _.round(player.currentValue, 2).toLocaleString() : '-';
        }
        else if(kpi === 'Revenue') {
            return player.currentValue ? `£${_.round(player.currentValue, 2).toLocaleString()}` : '-';
        }
        else if(kpi === 'CallTime') {
            return player.currentValue ? textUtils.formatTime(player.currentValue) : '-';
        }
        else {
            return parseInt(player.currentValue, 10) || '-'
        }
    }

    getSplit(player, max, kpi) {
        const split = player.currentValue - max;
        if(kpi === 'Placements') {
            return split ? _.round(split, 2).toLocaleString() : '-';
        }
        else if(kpi === 'Revenue') {
            return split ? `£${_.round(split, 2).toLocaleString()}` : '-';
        }
        else if(kpi === 'CallTime') {
            return split ? textUtils.formatTime(split) : '-';
        }
        else {
            return parseInt(split, 10) || '-';
        }
    }

    prevPage(event) {
        event.preventDefault();
        const page = this.state.page === 1 ? this.maxPages : this.state.page - 1;
        this.setState({
            page,
            manualPageChange: true
        });
    }

    nextPage(event) {
        event.preventDefault();
        const page = this.state.page < this.maxPages ? this.state.page + 1 : 1;
        this.setState({
            page,
            manualPageChange: true
        });
    }

    render() {
        const {dashboardName, playerLabel, logo, getBase64Url, userAvatar, players} = this.props;
        const playerKeys = Object.keys(players);
        if(playerKeys.length) {
            const kpi = playerKeys[this.state.page - 1];
            const playersCurrentPage = players[kpi];
            /*playersCurrentPage.sort((a, b) => {
                const aVal = a.currentValue;
                const bVal= b.currentValue;
                return bVal - aVal;
            });*/
            const max = playersCurrentPage[0].currentValue;
            return (     
                <React.Fragment>
                    <div className="scoreboard__nav" ref={node => this.navNode = node}>
                        <button className="btn btn-prev" onClick={this.prevPage}>
                            <i className="aheadicon-arrow"></i>
                        </button>
                        <button className="btn btn-next" onClick={this.nextPage}>
                            <i className="aheadicon-arrow"></i>
                        </button>
                    </div>
                    <div className="modal-dialog modal-xl modal-dialog-centered" role="document" ref={node => this.node = node}>
                        <div className="modal-content">
                            <div className="modal-body nopadding">
                                <div className="scoreboard scoreboard--user">
                                    <div className="scoreboard__head">
                                        {/*<div className="scoreboard__logo">
                                            <img src={getBase64Url(logo)} alt={title}/>
                                        </div>*/}
                                        <div className="scoreboard__user">
                                            <div className="avatar">
                                                <img src={this.getUserImage(userAvatar)} alt="Avatar"/>
                                            </div>
                                        </div>
                                        <h2>{dashboardName} <span>{playerLabel}</span></h2>
                                        <div className="scoreboard__aheadlogo">
                                            <img src={require('../../../../../images/logo-ahead-of-the-game-wh-tr.png')} alt="Ahead of the game"/>
                                        </div>
                                    </div>
                                    <div className="scoreboard__body">
                                        <ul className="list-unstyled list-scores">
                                            <li className="list-scores__title">
                                                <p className="list-scores__name">
                                                    {textUtils.spacePascalCase(kpi)}
                                                </p>
                                            </li>
                                            <li className="list-scores__head">
                                                <p className="list-scores__name">KPI</p>
                                                <p className="list-scores__val">Value</p>
                                                <p className="list-scores__val">Split</p>
                                            </li>
                                            {
                                                playersCurrentPage.map((player, index) =>
                                                    <li key={index} className={this.getRowClass(player.bikeName)}>
                                                        <p className="list-scores__name">{player.bikeName === 'Current' ? player.bikeName : player.label}</p>
                                                        <p className="list-scores__val">{this.getCurrentValue(player, kpi)}</p>
                                                        <p className="list-scores__val">{this.getSplit(player, max, kpi)}</p>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        }
        else {
            return <div />
        }
    }
}

export default ScoreboardModal;