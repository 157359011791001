
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';

class Track extends Component {
  
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.label !== this.props.label || nextProps.theme !== this.props.theme;
    }

    render() {
        return (
            <div className="track">
                {
                    this.props.label ? <p className="track__label">{this.props.label}</p> : null
                }
                <div className="track__start">
                    <img src={require('./images/track-start.png')} alt="Track start"/>
                </div>
                <div className="track__line" style={{ backgroundImage: `url(${require('./images/track-line.png')})`}}>
                </div>
                <div className="track__finish">
                    {
                         this.props.theme === 'Dark' ?
                            <img src={require('./images/track-finish-dark.png')} alt="Track finish"/>
                         : 
                            <img src={require('./images/track-finish.png')} alt="Track finish"/>
                    }
                    
                </div>
            </div>
        );
    }
  }

export default Track;