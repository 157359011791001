import * as types from '../actions/actionTypes';
import initialState from './initialState';
import * as dataUtils from '../utils/dataUtils';
const _ = require('lodash');

export default function userReducer(state = initialState.user, action) {
    
    switch(action.type) {
        case types.GET_USERS_SUCCESS:
        {
            const users = [...action.users];
            return {
                ...state,
                users
            }
        }
        case types.USERS_LOADING:
            const usersLoading = action.loading;  
            return {
                ...state,
                usersLoading
            }   
        case types.USER_CREATED:
        {
            const users = [...state.users];
            users.push(action.user)     
            dataUtils.sortUsers(users);
            return {
                ...state,
                users
            } 
        }
        case types.USER_UPDATED:
        {
            const users = [...state.users];
            const user = users.find(u => u.ID === action.user.ID);
            const userIndex = _.indexOf(users, user);
            users.splice(userIndex, 1, action.user);
            dataUtils.sortUsers(users);
            return {
                ...state,
                users
            } 
        }
        case types.USER_DELETED:
        {
            const users = [...state.users];
            _.remove(users, u => u.ID === action.id);            
            return {
                ...state,
                users
            } 
        }
        case types.SET_CREATE_UPDATE_USER_ERROR:
            return {
                ...state,
                createUpdateUserError: action.error.message
            }
        case types.CLEAR_CREATE_UPDATE_USER_ERROR:
            return {
                ...state,
                createUpdateUserError: null
            }
        case types.LOGOUT:       
            return {
                ...initialState.user
            };    
        default:
            return state;
    }
}