export function sortUsers(users) {
    users.sort((a, b) => {
        if(a.Email < b.Email) {
            return -1;
        }
        else if(b.Email < a.Email) {
            return 1;
        }
        else {
            return 0;
        }
    });
    return users;
}

