
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as userActions from '../../actions/userActions';
import NewItemMenu from '../nav/NewItemMenu';
import UserItem from './UserItem';

class Users extends React.Component {
    
    componentWillMount() {

    }

    componentDidMount() {      
        this.props.actions.getUsers();
    }   

    render() {
        const usersLength = this.props.users.length;
        return (
            <React.Fragment>
                <div className="main__header">
                    <div className="main__stats">
                        <p>{`${usersLength} user${usersLength === 1 ? '' : 's'}`}</p>
                    </div>
                    <NewItemMenu />
                </div>
                
                <div className={`main__body scroll ${this.props.usersLoading ? 'spinner' : ''}`}>               
                    {
                        !this.props.usersLoading ?
                            <div className="userslist">
                            {
                                this.props.users.map(user =>
                                    <UserItem key={user.ID} user={user} actions={this.props.actions} />
                                )
                            }
                            </div>
                            : null
                    }
                </div>
                
            </React.Fragment>
        );
    }
}

Users.propTypes = {
    actions: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired
};

function mapStateToProps(state, ownProps) {
    const users = state.user.users;
    const usersLoading = state.user.usersLoading;
    return { users, usersLoading };
}

function mapDispatchToProps(dispatch) {
    return {
        //user actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, userActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);