
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import * as widgetConstants from '../../../../constants/widgetConstants';
import * as widgetUtils from '../../../../utils/widgetUtils';
import 'select2/dist/js/select2';
import 'select2/dist/css/select2.min.css'

class TransformationModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
        this.toggleRenameFields = this.toggleRenameFields.bind(this);
        this.updateTransformationActionField = this.updateTransformationActionField.bind(this);
        this.deleteTransformationAction = this.deleteTransformationAction.bind(this);
        this.addDerivedField = this.addDerivedField.bind(this);
        this.state = {
            renameFields: this.props.modifier.TransformationActions.find(transformationAction => !transformationAction.IsDerived && transformationAction.OutputName && transformationAction.OriginalName !== transformationAction.OutputName) != null
        }
    }

    componentDidMount() { 
        const control = window.$(this.control);
        control.select2({
            placeholder: 'Select one or more fields to include'
        })
        .on('select2:select', this.handleChange)
        .on('select2:unselect', this.handleChange);
        const initialData = this.props.modifier.TransformationActions.filter(action => !action.IsDerived).map(action => action.OriginalName);        
        control.val(initialData).trigger('change');
    }  

    componentDidUpdate(prevProps, prevState) {
    }

    handleChange = event => {
        const data = event.params.data;
        if(data) {
            const modifier = {...this.props.modifier};
            modifier.TransformationActions = [...modifier.TransformationActions];
            if(data.selected) {
                modifier.TransformationActions.push({
                    OriginalName: data.id,
                    IsDerived: false
                });
            }
            else{
                const existingAction = modifier.TransformationActions.find(a => a.OriginalName === data.id && !a.IsDerived);
                _.pull(modifier.TransformationActions, existingAction);
            }
            this.props.updateModifier(modifier);
        }
    }
    
    toggleRenameFields(event) {
        const checked = event.target.checked;
        this.setState({ renameFields: checked })
        if(!checked) {
            //clear any existing name changes
            const modifier = {...this.props.modifier};
            modifier.TransformationActions = [...modifier.TransformationActions];
            for(let i = 0; i < modifier.TransformationActions.length; i++) {
                let transformationAction = modifier.TransformationActions[i];
                if(!transformationAction.IsDerived && transformationAction.OutputName) {
                    transformationAction = {...transformationAction, OutputName: null};
                    modifier.TransformationActions.splice(i, 1, transformationAction);
                }
            }
            this.props.updateModifier(modifier);
        }
    }

    updateTransformationActionField(event, transformationAction, fieldName) {
        const modifier = {...this.props.modifier};
        modifier.TransformationActions = [...modifier.TransformationActions];
        const index = modifier.TransformationActions.indexOf(transformationAction);
        transformationAction = {...transformationAction};
        transformationAction[fieldName] = event.target.value;
        modifier.TransformationActions.splice(index, 1, transformationAction);
        this.props.updateModifier(modifier);
    }

    deleteTransformationAction(event, transformationAction) {
        event.preventDefault();
        const modifier = {...this.props.modifier};
        modifier.TransformationActions = [...modifier.TransformationActions];
        _.pull(modifier.TransformationActions, transformationAction);
        this.props.updateModifier(modifier);
    }

    addDerivedField(event) {
        event.preventDefault();
        const modifier = {...this.props.modifier};
        modifier.TransformationActions = [...modifier.TransformationActions];        
        modifier.TransformationActions.push({
            OutputName: '',
            IsDerived: true,
            Formula: ''
        });        
        this.props.updateModifier(modifier);
    }

    render() {  
        const modifier = this.props.modifier;
        const keys = this.props.initialItem ? Object.keys(this.props.initialItem) : [];
        const keysNotIncluded = keys;
        //const keysNotProjected = keys.filter(k => !modifier.Projections.map(p => p.ProjectionFieldName).includes(k));
        //const availableProjectionFields = keys.filter(k => !modifier.GroupBy.includes(k));
        return (
            <form className="form">
                <div className="form-group mb-2">
                    <label>Field(s) you want to include</label>
                    <select className="form-control form--multi" multiple="multiple" ref={(control) => this.control = control} data-placeholder="Select one or more inputs">
                        <option value="" disabled>Select one or more fields</option>
                        {
                            keysNotIncluded.map(key => 
                                <option key={key} value={key}>{key}</option>     
                            )
                        }
                    </select>
                </div>
                <div className="d-flex">
                    <a href="#" className="btn-text small" onClick={this.addDerivedField}>+ Add derived field</a>
                    <div className="rename-fields flex-fill pl-2 text-right">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="checkRename" defaultChecked={this.state.renameFields} onChange={this.toggleRenameFields} />
                            <label className="custom-control-label" htmlFor="checkRename">Rename fields</label>
                        </div>
                    </div>
                </div>

                <div className="form-group mt-4">
                    {
                        this.state.renameFields ?
                            <React.Fragment>
                                {
                                    modifier.TransformationActions.filter(a => !a.IsDerived).map(transformationAction => 
                                        <div className="row mx-n2 mb-1 rename-field">
                                            <div className="col-4 px-2">
                                                <div className="form-group mb-1">
                                                    <input className="form-control" type="text" name=" " id="" disabled value={transformationAction.OriginalName} />
                                                </div>
                                            </div>
                                            <div className="col-7 px-2">
                                                <div className="form-group mb-1">
                                                    <input className="form-control" type="text" placeholder="Enter new name" value={transformationAction.OutputName} onChange={(event) => this.updateTransformationActionField(event, transformationAction, 'OutputName')} />
                                                </div>
                                            </div>
                                            <div className="col-1 px-2">
                                                <button className="btn btn-simple btn-delete">
                                                    <i className="aheadicon-delete"></i>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                            </React.Fragment>
                            : null
                    }                  
                    {
                        modifier.TransformationActions.filter(a => a.IsDerived).map((transformationAction, index) =>
                            <div key={index} className="row mx-n2 derived-field">
                                <div className="col-11 px-2">
                                    <input className="form-control" type="text" placeholder="Enter derived field name" value={transformationAction.OutputName} onChange={(event) => this.updateTransformationActionField(event, transformationAction, 'OutputName')} />
                                    <textarea className="form-control form-area" name=" " id="" cols="30" rows="2" placeholder="Create derived field formula" value={transformationAction.Formula} onChange={(event) => this.updateTransformationActionField(event, transformationAction, 'Formula')}></textarea>
                                </div>
                                <div className="col-1 px-2">
                                    <button className="btn btn-simple btn-delete" onClick={(event) => this.deleteTransformationAction(event, transformationAction)}>
                                        <i className="aheadicon-delete"></i>
                                    </button>
                                </div>
                            </div>
                        )
                    }                                              
                </div>
            </form>  
        );
    }

}

export default TransformationModifier;