
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

class JoinModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateSeparator = this.updateSeparator.bind(this);    
        this.updateOutputType = this.updateOutputType.bind(this);  
        this.getOutputTypes = this.getOutputTypes.bind(this);  
    }

    componentDidMount() { 
               
    }  

    updateSeparator(separator) {    
        let modifier = {...this.props.modifier};
        modifier.Separator = separator;
        this.props.updateModifier(modifier);
    }  

    updateOutputType(outputType) {    
        let modifier = {...this.props.modifier};
        modifier.OutputType = outputType;
        this.props.updateModifier(modifier);
    }  

    getOutputTypes() {
        const outputTypes = [];
        if(this.props.inputs.length) {
            if(this.props.inputs.length === 1 && Array.isArray(this.props.inputs[0])) {
                //if the nested items are simple items, we can output text. If they're complex items, we should flatten 1st
                outputTypes.push('Text');
            }
            else {
                outputTypes.push('Array');
            }
        }
        return outputTypes;
    }

    render() {  
        const outputType = this.props.modifier.OutputType;
        const outputTypes = this.getOutputTypes();
        return (
            <form className="form">
                {
                    outputType === 'Text' ?
                        <div className="form-group">                    
                            <span className="text-muted small d-block">Separator:</span>
                            <input type="text" className="form-control" placeholder="Enter the character(s) you would like to use to separate the items" value={this.props.modifier.Separator} onChange={(event) => this.updateSeparator(event.target.value)} />
                        </div>
                    : null
                }
                {
                    outputTypes.length ?
                        <div className="form-group">
                            <span className="text-muted small d-block">Output Type:</span>
                            <select className="form-control" value={this.props.modifier.OutputType} onChange={(e) => this.updateOutputType(e.target.value)}>
                                {
                                    outputTypes.map(o =>
                                        <option key={o} value={o}>{o}</option>
                                    )
                                }
                            </select>
                        </div>
                    : null
                }
            </form>     
        );
    }

}

export default JoinModifier;