import React from 'react';
//import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as playlistActions from '../../actions/playlistActions';
import * as dashboardActions from '../../actions/dashboardActions';
import Dashboard from '../dashboards/Dashboard';

class Playlist extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialisePlaylist = this.initialisePlaylist.bind(this);
        this.rotateDashboard = this.rotateDashboard.bind(this);
        this.getPlaylistItems = this.getPlaylistItems.bind(this);
        this.timer = null;
        this.state = { 
            currentPlaylistItem: null
        };
    }

    componentDidMount() {    
        this._isMounted = true;
        if(!this.props.playlist) {
            //if we're browsing directly to this page, we might not have the playlist details
            this.props.actions.getPlaylists();
        }
        else {
            this.initialisePlaylist();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.playlist && this.props.playlist) {
            this.initialisePlaylist();
        }
    }

    componentWillUnmount() {
        if(this.timer) {
            window.clearTimeout(this.timer);
        }
        this._isMounted = false;
    }

    getPlaylistItems() {
        const playlistItems = [...this.props.playlist.Items];
        playlistItems.sort((a, b) => {
            const aOrder = a.Order;
            const bOrder = b.Order;
            return aOrder - bOrder;
        });
        return playlistItems;
    }

    initialisePlaylist() {
        if(this.timer) {
            window.clearTimeout(this.timer);
        }
        const playlistItems = this.getPlaylistItems();
        if(playlistItems.length > 0) {
            const initialItem = playlistItems[0];
            this.setState({
                currentPlaylistItem: initialItem
            });            
            if(initialItem.UseSpecifiedDuration) {
                this.timer = window.setTimeout(this.rotateDashboard, initialItem.Seconds * 1000)
            }            
            const dashboardIDs = playlistItems.map(i => i.DashboardID);
            const dashboardIDsToLoad = [];
            for(let i = 0; i < dashboardIDs.length; i++) {
                const dashboardID = dashboardIDs[i];
                if(!dashboardIDsToLoad.includes(dashboardID)) {
                    const dashboard = this.props.dashboards.find(d => d.ID === dashboardID);
                    if(!dashboard) {
                        dashboardIDsToLoad.push(dashboardIDs[i])
                    }
                }
            }
            if(dashboardIDsToLoad.length) {
                this.props.actions.getDashboardList(dashboardIDsToLoad);
            }
        }
    }

    rotateDashboard() {
        //we could have the same dashboard multiple times, match using the item id, not the dashboard id
        if(this.state.currentPlaylistItem) {
            let index = null;
            const playlistItems = this.getPlaylistItems();
            for(let i = 0; i < playlistItems.length; i++) {
                if(playlistItems[i].ID === this.state.currentPlaylistItem.ID) {
                    index = i;
                    break;
                }
            }
            if(index !== null) {
                let newItem;
                if(playlistItems.length > index + 1) {
                    newItem = {...playlistItems[index + 1]};
                }
                else {
                    newItem = {...playlistItems[0]};
                }
                if(!this._isMounted){ return };
                this.setState({
                    currentPlaylistItem: null
                }, () => {
                    //make sure we've hidden any modals before we show the next dashboard (as they're shown outside the dashboard component tree)                    
                    window.$('#modal').modal('hide');
                    this.setState({
                        currentPlaylistItem: newItem
                    })
                });
                if(newItem.UseSpecifiedDuration) {
                    this.timer = window.setTimeout(this.rotateDashboard, newItem.Seconds * 1000)
                }
            }
        }
    }

    render() {
        return (     
            <div>
                {
                    this.props.playlist ? 
                        this.state.currentPlaylistItem && this.props.dashboards.find(d => d.ID === this.state.currentPlaylistItem.DashboardID) ?
                            <Dashboard id={this.state.currentPlaylistItem.DashboardID} playlistID={this.props.playlist.ID} playerID={this.state.currentPlaylistItem.PlayerID} onAnimationFinished={ !this.state.currentPlaylistItem.UseSpecifiedDuration ? this.rotateDashboard : null } />
                            : 
                            !this.state.currentPlaylistItem ?
                                <div className="pane__body scrollbar">
                                    <div className="empty">
                                        <p>There are no dashboards in this playlist</p>
                                    </div>
                                </div>
                                :
                                this.props.dashboardsLoading || this.props.dashboardItemsLoading.includes(this.state.currentPlaylistItem.DashboardID) ? 
                                    <div className="spinner" />
                                    :
                                    <div className="pane__body scrollbar">
                                        <div className="empty">
                                            <p>Dashboard not found</p>
                                        </div>
                                    </div>

                    : 
                    this.props.playlistLoading ? 
                        <div>Loading...</div> 
                        : 
                        <div className="pane__body scrollbar">
                            <div className="empty">
                                <p>Playlist not found</p>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const playlistID = ownProps.match.params.id;
    const playlist = state.playlist.playlists.find(p => p.ID === playlistID)
    return { 
        playlist, 
        playlistLoading: state.playlist.playlistsLoading,
        dashboards: state.dashboard.dashboards.filter(d => d.Template),
        dashboardsLoading: state.dashboard.dashboardsLoading,
        dashboardItemsLoading: state.dashboard.dashboardItemsLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //playlist actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, playlistActions, dashboardActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);