
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

class IterationModifier extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateIterationDetails = this.updateIterationDetails.bind(this);        
    }

    componentDidMount() { 
               
    }  

    updateIterationDetails(fieldName, value) {    
        let modifier = {...this.props.modifier};
        modifier[fieldName] = value;
        this.props.updateModifier(modifier);
    }  

    render() {  
        return (
            <form className="form">
                <div className="form-group">
                    <span className="text-muted small d-block">Maximum Count:</span>
                    <input type="number" className="form-control" min="0" value={this.props.modifier.MaxCount} onChange={(event) => this.updateIterationDetails('MaxCount', event.target.value)} />
                    <span className="text-muted small d-block pt-2">Empty text:</span>
                    <input type="text" className="form-control" placeholder="No items found" value={this.props.modifier.EmptyText} onChange={(event) => this.updateIterationDetails('EmptyText', event.target.value)} />
                </div>
            </form>     
        );
    }

}

export default IterationModifier;