import BaseService from './baseService';

class PlaylistService {
    static getPlaylists() {
        return BaseService.getData('/Playlists')
            .then(data => {
                return Object.assign([], data);
            })
            .catch(error => {
                console.log('Unable to get playlists');
                throw new Error('Unable to get playlists');
            });
    } 

    static createPlaylist(name, clientID, thumbnailData) {
        return BaseService.postData('/Playlists', { name, clientID, thumbnailData })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                let message = 'Unable to create playlist';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static updatePlaylist(id, name, thumbnailData) {
        return BaseService.putData(`/Playlists/${id}`, { name, thumbnailData })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                console.log('Unable to update playlist');
                throw new Error('Unable to update playlist');                
            });
    } 

    static deletePlaylist(id) {
        return BaseService.deleteData(`/Playlists/${id}`)
            .then(data => {
                return data;
            })
            .catch(error => {
                console.log('Unable to delete playlist');
                throw new Error('Unable to delete playlist');
            });
    }

    static addPlaylistItem(playlistID, dashboardID, playerID, seconds, useSpecifiedDuration, waitUntilAnimationCompletes, order) {
        return BaseService.postData(`/Playlists/${playlistID}/Items`, { dashboardID, playerID, seconds, useSpecifiedDuration, waitUntilAnimationCompletes, order })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                let message = 'Unable to create playlist item';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static updatePlaylistItem(playlistID, itemID, dashboardID, playerID, seconds, useSpecifiedDuration, waitUntilAnimationCompletes, order) {
        return BaseService.putData(`/Playlists/${playlistID}/Items/${itemID}`, { id: itemID, dashboardID, playerID, seconds, useSpecifiedDuration, waitUntilAnimationCompletes, order })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                console.log('Unable to update playlist item');
                throw new Error('Unable to update playlist item');                
            });
    } 

    static deletePlaylistItem(playlistID, itemID) {
        return BaseService.deleteData(`/Playlists/${playlistID}/Items/${itemID}`)
            .then(data => {
                return data;
            })
            .catch(error => {
                console.log('Unable to delete playlist item');
                throw new Error('Unable to delete playlist item');
            });
    }

    static addUser(playlistID, userID) {
        return BaseService.postData(`/Playlists/${playlistID}/Users`, { userID })
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                debugger;
                const message = 'Unable to add user';
                console.log(message);
                throw new Error(message);                
            });
    }  

    static deleteUser(playlistID, userID) {
        return BaseService.deleteData(`/Playlists/${playlistID}/Users/${userID}`)
            .then(data => {
                return data;
            })
            .catch(error => {
                debugger;
                const message = 'Unable to delete user';
                console.log(message);
                throw new Error(message);  
            });
    }

}

export default PlaylistService;