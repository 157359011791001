export function getAvatarBase64Url(avatar) {
    if(avatar) {
        let extension = avatar.Filename.split('.').pop();
        return `data:image/${extension};base64,${avatar.FileData}`;
    }
    else {
        return null;
    }
}

export function getBase64Url(rawString) {
    const identifier = rawString.trim()[0];
    let extension;
    if(identifier === '/') {
        extension = 'jpg';
    }
    else if(identifier === 'i') {
        extension = 'png';
    }
    else if(identifier === 'R') {
        extension = "gif";
    }
    else {
        extension = "bmp";
    }
    return `data:image/${extension};base64,${rawString}`;
}

export function readImage(event, onloadCallback, fileSizeExceededCallback, fileSizeLimitMB = 2) {
    const reader = new FileReader();
    reader.onload = onloadCallback;
    const file = event.target.files[0];
    const fileSizeMB = file.size/1024/1024;
    if(fileSizeMB > fileSizeLimitMB) {
        if(fileSizeExceededCallback) {
            fileSizeExceededCallback(`The file selected is too big, please upload a file under ${fileSizeLimitMB}MB`);
        }
    }
    else if(/\.(jpe?g|png|gif)$/i.test(file.name)) {
        reader.readAsDataURL(file);
    }  
}

export function getCroppedImage(image, pixelCrop) {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
  
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );
  
    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');
    return base64Image;
}