
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ToastrService from '../../../services/toastrService';
import roleTypes from '../../../constants/roleTypes';
import ConfigureWidget from './ConfigureWidget';

class ConfigureWidgetModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.updateValue = this.updateValue.bind(this);
        this.createWidget = this.createWidget.bind(this);
        this.state = {
            widgetName: '',
            public: true, //for updates, we need to derive this based on the dashboards/clients selected
            clientIDs: this.props.user.Role === roleTypes.SuperUser ? [] : [this.props.user.ClientID],
            dashboardIDs: [],
            columnCount: 6,
            rowCount: 6
        };    
    }

    componentDidMount() {    
    }

    componentDidUpdate(prevProps, prevState) {
    }

    
    updateValue(fieldName, value) {
        const state = {...this.state};
        state[fieldName] = value;
        if(fieldName === 'public' && !value && this.props.user.Role !== roleTypes.SuperUser) {
            state.dashboardIDs = [this.props.dashboard.ID];
        }
        this.setState(state);
    }

    createWidget(event) {
        event.preventDefault();
        const toastrService = new ToastrService();        
        if(!this.state.widgetName) {
            toastrService.showError('', 'Please enter the widget name');
            return;
        }
        else if(!this.state.public) {
            if(this.props.user.Role !== roleTypes.SuperUser) {
                if(!this.state.dashboardIDs.length) {
                    toastrService.showError('', 'Please select a dashboard or set the widget to public');
                    return;
                }
            }
            else {
                if(!this.state.dashboardIDs.length || !this.state.clientIDs.length) {
                    toastrService.showError('', 'Please select a dashboard or client, or set the widget to public');
                    return;
                }
            }
        }
        this.props.history.push({
            pathname: `/Dashboards/${this.props.dashboard.ID}/NewWidget`,
            search: '',
            state: {...this.state}
        });      
    }

    render() {
        return (
            <div className="modal modal-side modal-side--short fade" tabIndex="-1" role="dialog" id="modal-new-customwidget" data-backdrop="false" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">New custom widget</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        <div className="modal-body scrollbar">
                            <p className="small text-muted mt-3">Consider a custom widget a group of other widgets you can lay out any way you want. After it is created, you can use it in different dashboards as a single widget.</p>                
                            <ConfigureWidget user={this.props.user} dashboard={this.props.dashboard} dashboards={this.props.dashboards} clients={this.props.clients} updateValue={this.updateValue} {...this.state} />
                        </div>
                        <div className="modal-footer">
                        <a href="#" className="btn btn-default" data-dismiss="modal">Cancel</a>
                        <a href="#" className="btn btn-primary" onClick={this.createWidget}>Create custom widget</a>
                        </div>
                    </div>
                </div>
            </div>            
        );
    }
};

export default ConfigureWidgetModal;