import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as playlistActions from '../../actions/playlistActions';
import validator from 'validator';
import CropModal from '../common/CropModal';
import * as imageUtils from '../../utils/imageUtils';
import { withModalContext } from '../../services/modalService';
import roleTypes from '../../constants/roleTypes';

class CreateUpdatePlaylist extends React.Component {
    constructor(props, context) {
        debugger;
        super(props, context);
        this.createPlaylist = this.createPlaylist.bind(this);
        this.savePlaylist = this.savePlaylist.bind(this);
        this.onChange = this.onChange.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.getExistingPlaylistDefaultState = this.getExistingPlaylistDefaultState.bind(this);
        this.getNewPlaylistDefaultState = this.getNewPlaylistDefaultState.bind(this);
        this.previewImage = this.previewImage.bind(this);
        this.imageCropped = this.imageCropped.bind(this);
        
        if(this.props.playlist) {
            this.state = this.getExistingPlaylistDefaultState(this.props);
        }
        else {
            this.state = this.getNewPlaylistDefaultState(this.props);
        }
    }

    componentDidMount() {     
        this.props.actions.getPlaylists();
    }

    componentWillUpdate(nextProps) {
        if(nextProps.playlist && !this.props.playlist) {
            this.setState(this.getExistingPlaylistDefaultState(nextProps));
        }
        else if(!nextProps.playlist && this.props.playlist) {
            this.setState(this.getNewPlaylistDefaultState(nextProps));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.playlist && !this.props.playlist) {
            //we've reloaded the page to create a new playlist, load the playlist list.
            //we need the playlist list for new playlists as otherwise when we create them and redirect 
            //to the /playlists endpoint, it will only have the single playlist present
            debugger;
            this.props.actions.getPlaylists();
        }
    }

    getNewPlaylistDefaultState(props) {
        return { 
            name: '',
            clientID: props.myClientID || ''
         };
    }

    getExistingPlaylistDefaultState(props) {
        return { 
            name: props.playlist.Name,
            thumbnail: props.playlist.Thumbnail ? imageUtils.getAvatarBase64Url(props.playlist.Thumbnail) : ''
         };
    }

    createPlaylist(event) {
        debugger;
        event.preventDefault();
        if(this.formIsValid()) {
            const thumbnail = this.state.thumbnail ? this.state.thumbnail.split(',')[1] : null;
            this.props.actions.createPlaylist(this.state.name, this.state.clientID, thumbnail);
        }        
    }

    savePlaylist(event) {
        debugger;
        event.preventDefault();
        if(this.formIsValid()) {
            const thumbnail = this.state.thumbnail ? this.state.thumbnail.split(',')[1] : null;
            this.props.actions.updatePlaylist(this.props.playlist.ID, this.state.name, thumbnail);
        }        
    }

    formIsValid() {
        const state = this.state;
        if(validator.isEmpty(state.name)) {
            this.props.actions.setCreateUpdatePlaylistError('The name is missing');
            return false;
        }
        else if(!this.props.playlist && validator.isEmpty(state.clientID)) {
            this.props.actions.setCreateUpdatePlaylistError('The client is missing');
            return false;
        }
        return true;
    }

    onChange(event) {
        const field = event.target.name;
        if(field === 'thumbnail') {
            this.props.actions.clearCreateUpdatePlaylistError();
            if(event.target.files.length) {
                imageUtils.readImage(event, this.previewImage, this.props.actions.setCreateUpdatePlaylistError, 2);
            }
            else {
                this.setState({
                    thumbnail: ''
                });
            }              
        }
        else {
            const value = event.target.value;
            let newState = Object.assign({}, this.state);
            newState[field] = value;
            return this.setState(newState);
        }       
    }

    previewImage(upload) {
        this.setState({
            thumbnail: upload.target.result
        }, () => {
            const cropImageModal = <CropModal imageCropped={this.imageCropped} avatar={this.state.thumbnail} aspectRatio={1.49} />
            this.props.showModal(() => cropImageModal, { isOpen: true })
        });
    };

    imageCropped(event, pixelCrop) {
        event.preventDefault();
        window.$('#modal').modal('hide');
        const image = window.$('#imgThumbnail')[0];
        const croppedImage = imageUtils.getCroppedImage(image, pixelCrop)
        this.setState({thumbnail: croppedImage});
    }

    render() {
        return (     
            <div className="main__body scroll">
                {
                    !this.props.match.params.id || this.props.playlist ?
                        <div className="pane pane--fixed pane--user">
                            <Link to="/Playlists" className="btn-close"><i className="aheadicon-x"></i></Link>
                            <div className="pane__head">
                                <h5>{this.props.playlist ? 'Edit Playlist' : 'Add Playlist'}</h5>
                            </div>
                            <div className="pane__body scroll">
                                <div className="pane__inner">
                                    <form className="form px-5 pt-2" onSubmit={this.props.playlist ? this.savePlaylist : this.createPlaylist}>
                                        <div className="form-group">
                                            <div className={`upload-avatar upload-avatar--full ${this.state.thumbnail ? 'upload-avatar--uploaded' : ''}`}>
                                                <div className="avatar">
                                                    {
                                                        this.state.thumbnail ?
                                                            <img id="imgThumbnail" src={this.state.thumbnail} alt="thumbnail" />
                                                            : 
                                                            <i className="aheadicon-plus"></i>
                                                    }                                        
                                                </div>
                                                <input type="file" name="thumbnail" accept="image/*" onChange={this.onChange} />
                                                <p>{this.state.thumbnail ? 'Change tile image' : 'Upload tile image'}</p>
                                            </div>
                                        </div> 
                                        <div className="form-group">
                                            <label>Name</label>
                                            <input type="text" required="required" className="form-control" name="name" value={this.state.name} onChange={this.onChange} placeholder="Enter playlist name" />
                                            <p className="form__error">This input is mandatory</p>
                                        </div> 
                                        {
                                            !this.props.playlist && this.props.myRole === roleTypes.SuperUser ?
                                                <div className="form-group">
                                                    <label>Client</label>
                                                    <select className="form-control" name="clientID" onChange={this.onChange} value={this.state.clientID}>
                                                        <option value=''></option>
                                                        {
                                                            this.props.clients.map(c => 
                                                                <option key={c.ID} value={c.ID}>{c.Name}</option>
                                                            )
                                                        }
                                                    </select>
                                                </div>
                                                : null
                                        }
                                        <div className="distancer distancer--short"></div>
                                        <div className="form-group form-group--multibutton">
                                            <Link to="/Playlists" className="btn btn--default">Cancel</Link>
                                            <button type="submit" className="btn btn--primary">{this.props.playlist ? 'Save' : 'Create Playlist'}</button>
                                        </div>

                                        {this.props.createUpdatePlaylistError ?
                                            <React.Fragment>
                                                <div className="distancer"></div>    
                                                <div className="alert alert-danger">
                                                    {this.props.createUpdatePlaylistError}
                                                    <button type="button" className="close" onClick={this.props.actions.clearCreateUpdatePlaylistError}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>                                    
                                            </React.Fragment>
                                            : null
                                        }
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    : 
                        this.props.playlistsLoading ? 
                            <div>Loading...</div> 
                            :
                            <div>Unable to find playlist</div> 
                }
            </div>
        );
    }
}

CreateUpdatePlaylist.propTypes = {
    playlist: PropTypes.object,
    actions: PropTypes.object.isRequired,
    createUpdatePlaylistError: PropTypes.string
};

function mapStateToProps(state, ownProps) {
    let playlist = null;
    if(ownProps.match.params.id) {
        playlist = state.playlist.playlists.find(p => p.ID === ownProps.match.params.id);
    }
    return { 
        playlist, 
        playlistsLoading: state.playlist.playlistsLoading, 
        playlists: state.playlist.playlists, 
        myClientID: state.account.user.ClientID, 
        myRole: state.account.user.Role, 
        clients: state.client.clients, 
        createUpdatePlaylistError: state.playlist.createUpdatePlaylistError
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //playlist actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, playlistActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(CreateUpdatePlaylist));