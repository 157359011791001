import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as accountActions from '../actions/accountActions';
import * as clientActions from '../actions/clientActions';
import roleTypes from '../constants/roleTypes';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import MobileSidebar from './nav/MobileSidebar';
import MainSidebar from './nav/MainSidebar';
import { ModalProvider } from './common/ModalContext';
import ModalRoot from './common/ModalRoot';
import * as urlUtils from '../utils/urlUtils';

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.shouldShowSidebar = this.shouldShowSidebar.bind(this);
  }

  componentDidMount() {
    const user = this.props.user;
    if(user && (user.Role === roleTypes.Admin || user.Role === roleTypes.SuperUser)) {
      this.props.actions.getClients();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const user = this.props.user;
    if(user && !prevProps.user && (user.Role === roleTypes.Admin || user.Role === roleTypes.SuperUser)) {
      this.props.actions.getClients();
    }
  }

  shouldShowSidebar() {
    return this.props.authenticated;
  }

  addMainClass() {
    const location = this.props.location.pathname;
    if(location.indexOf('Account/Settings') > -1) {
      return 'content--account';
    }
    else if(location.indexOf('Account/Login') > -1 || location.indexOf('/Account/ForgotPassword') > -1 || location.indexOf('/Account/ResetPassword') > -1) {
      return 'content--padded content--vertcenter';
    }
    else {
      return 'content--main';
    }
  }

  render() {
    const isDashboard = urlUtils.isDashboard(this.props.location.pathname);
    const isPlaylist = urlUtils.isPlaylist(this.props.location.pathname);
    const isWidgetSettings = this.props.location.pathname.indexOf('WidgetSettings') > -1;
    const isNewWidget = this.props.location.pathname.indexOf('NewWidget') > -1;
    const shouldShowSidebar = this.shouldShowSidebar();
    if(isDashboard || isPlaylist || isWidgetSettings || isNewWidget) {
      return (
        <ModalProvider>
          {this.props.children}
          <ModalRoot />
        </ModalProvider>
      );
    }
    else {
      return (
        <ModalProvider>
          <div className="App">
          {
            shouldShowSidebar ?
              <MobileSidebar />
              : null
            }     

            <main className={`content ${this.addMainClass()}`}>
            {
              shouldShowSidebar ?
                <MainSidebar user={this.props.user} actions={this.props.actions} location={this.props.location.pathname} />
                : null
            }

            <section className="main">         
              {this.props.children}
            </section>

            </main>
            
            <ModalRoot />
          </div>
        </ModalProvider>
      );
    }
  }
}

App.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  user: PropTypes.object,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    authenticated: state.account.user != null,
    user: state.account.user
  };
}

function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, accountActions, clientActions), dispatch)
    };
}

//we have to use withRouter here to prevent blocked updates:
//https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/blocked-updates.md
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));