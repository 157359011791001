/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../actions/dashboardActions';
import * as userActions from '../../actions/userActions';
import { withModalContext } from '../../services/modalService';
import 'spectrum-colorpicker/spectrum.css'
import * as spectrum from 'spectrum-colorpicker';
import $ from 'jquery';
import Avatar from './Avatar';
import * as imageUtils from '../../utils/imageUtils';

class AvatarSettings extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialiseColorPickers = this.initialiseColorPickers.bind(this);
        this.getDefaultColor = this.getDefaultColor.bind(this);
        this.colorInputChange = this.colorInputChange.bind(this);
        this.reset = this.reset.bind(this);
        this.initialisePlayerSelection = this.initialisePlayerSelection.bind(this);
        this.optionSelected = this.optionSelected.bind(this);
        this.updateSelectionCustomisation = this.updateSelectionCustomisation.bind(this);
        this.updateSelectionColor = this.updateSelectionColor.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.onCheckedChange = this.onCheckedChange.bind(this);
        this.swatchRefs = [];
        this.tooltipControls = [];
        this.state = {
            dropdownOpened: { }
         };
    }

    componentDidMount() {    
        const dashboardID = this.props.match.params.id;  
        if(!this.props.dashboard) {
            //if we're browsing directly to this page, we might not have the dashboard details            
            this.props.actions.getDashboard(dashboardID);
        }
        else {
            //check we have the latest version
            this.props.actions.checkDashboardVersion(dashboardID);
            if(!this.props.loading) {
                this.initialisePlayerSelection();
            }
        }
        const tooltipControls = window.$(this.tooltipControls);
        if (tooltipControls.length !== 0 ) {
            tooltipControls.tooltip({boundary: 'viewport'});
            tooltipControls.on('click', function(e){
                e.preventDefault();
            });
        }  
    }   

    componentDidUpdate(prevProps, prevState) {
        const dashboardLoaded = (prevProps.loading || !prevProps.dashboard) && !this.props.loading && this.props.dashboard;
        const dashboardUpdated = prevProps.dashboard && this.props.dashboard && prevProps.dashboard != this.props.dashboard;
        if(dashboardLoaded || dashboardUpdated) {
            this.initialisePlayerSelection();
            //this.initialiseColorPickers();
        }
        if(!prevState.playerSelection && this.state.playerSelection) {
            const tooltipControls = window.$(this.tooltipControls);
            if (tooltipControls.length !== 0 ) {
                tooltipControls.tooltip({boundary: 'viewport'});
                tooltipControls.on('click', function(e){
                    e.preventDefault();
                });
            }  
        }
    }

    initialisePlayerSelection() {
        if(this.props.player) {
           //clone the selections so we can make changes to them without affecting the actual objects
            const playerSelection = {
                Colours: this.props.player.AvatarSelection.Colours.map(c => {
                    return {...c};
                }),
                Customisations: this.props.player.AvatarSelection.Customisations.map(c => {
                    return {...c};
                }),
                PreventAutoOverrides: this.props.player.AvatarSelection.PreventAutoOverrides,
                SetAsDefault: this.props.player.AvatarSelection.SetAsDefault
            };
            debugger;
            this.setState({
                playerSelection
            }, this.initialiseColorPickers); //we delay the call to initialise color pickers so we have the correct colours defaulted
        }
    }

    getPalette(palette) {
        if(palette === 'Skin') {
            return [
                ["#FFDCB2","#E5C298","#E5B88F","#E3B98F","#E5A174","#DB9066","#CB8442","#C57957"],
                ["#A63901","#880400","#720201","#440001","#FFE0C4","#EECFB3","#DFAC7F","#DEB285"],
                ["#E0A676","#BE733C","#A01903","#5B0101","#302323","#FEE4C9","#F0D6BF","#ECBE9D"],
                ["#E1B89A","#D2926F","#BD9979","#BB6D49","#960900","#E3AEA6","#A4866A","#7C0000"],
                ["#730101","#3A0001","#FFFAF2"]
            ];
        }
        else if (palette === 'Hair') {
            return [
                ["#090806","#2C222B","#3B302A","#4E433F","#504444","#6A4D45","#554838","#A7856A"],
                ["#B89778","#DCD0BA","#DEBB9B","#977961","#E6CEA8","#E2C8AD","#A36B46","#91553D"],
                ["#533D32","#71635A","#B7A69E","#D6C4C2","#FFF5E1","#CBBFB1","#8D4A42","#B6523A"],
                ["#A83D1E","#D93600","#62B722","#36A380","#1564C4","#930ECB","#FFF","#F3DC96"]
            ];
        }
        else if (palette === 'Horse') {
            return [
                ["#EBE7E8","#CFC4C0","#CAC7B8","#DCC0A8","#ECBF48","#E0A65D","#A96236","#82390E"],
                ["#692F0C","#553119","#3D210C","#2A221F","#0B0505","#000000"]
            ];
        }
        else {
            return [
                ["#000","#444","#666","#999","#ccc","#eee","#f3f3f3","#fff"],
                ["#f00","#f90","#ff0","#0f0","#0ff","#00f","#90f","#f0f"],
                ["#f4cccc","#fce5cd","#fff2cc","#d9ead3","#d0e0e3","#cfe2f3","#d9d2e9","#ead1dc"],
                ["#ea9999","#f9cb9c","#ffe599","#b6d7a8","#a2c4c9","#9fc5e8","#b4a7d6","#d5a6bd"],
                ["#e06666","#f6b26b","#ffd966","#93c47d","#76a5af","#6fa8dc","#8e7cc3","#c27ba0"],
                ["#c00","#e69138","#f1c232","#6aa84f","#45818e","#3d85c6","#674ea7","#a64d79"],
                ["#900","#b45f06","#bf9000","#38761d","#134f5c","#0b5394","#351c75","#741b47"],
                ["#600","#783f04","#7f6000","#274e13","#0c343d","#073763","#20124d","#4c1130"]
            ];
        }
    }

    initialiseColorPickers() {
        debugger;
        //-- Color Picker
        this.swatchRefs.forEach(swatch => {
            if(swatch) {
                var element = $(swatch);
                const palette = this.getPalette(element[0].dataset.customPalette);
                const defaultColor = element[0].dataset.defaultColor;
                element.spectrum({
                    color: defaultColor,
                    showPaletteOnly: true,
                    togglePaletteOnly: true,
                    togglePaletteMoreText: 'more',
                    togglePaletteLessText: 'less',
                    showInput: true,
                    preferredFormat: "hex",
                    palette: palette,
                    change: (color) => {
                        const selectionName = element[0].dataset.selectionName;
                        this.updateSelectionColor(selectionName, color.toHexString());
                        element.spectrum("hide");                        
                    }
                });
            }
        });
    }

    getDefaultColor(selection) {
        if(this.state.playerSelection) {
            const override = this.state.playerSelection.Colours.find(c => c.Name === selection.Name);
            if(override) {
                return override.Selection;
            }
            else {
                return selection.DefaultColour;
            }
        }
        else {
            return selection.DefaultColour;
        }
    }

    colorInputChange(event, selectionName) {
        const color = event.target.value;
        this.updateSelectionColor(selectionName, color);
    }

    reset(event) {
        event.preventDefault();
        this.setState({
            /*selections: {
                customisations: [],
                colours: []
            },*/
            playerSelection: {
                Customisations: [],
                Colours: [],
                PreventAutoOverrides: this.props.player.AvatarSelection.PreventAutoOverrides,
                SetAsDefault: this.props.player.AvatarSelection.SetAsDefault
            }
        });
    }

    optionSelected(customisation, optionName) {
        let selected = false;
        if(this.state.playerSelection) {
            const playerCustomisation = this.state.playerSelection.Customisations.find(c => customisation.Name === c.Name);
            if(playerCustomisation) {
                selected = playerCustomisation.Selection === optionName;
            }
            else {
                selected = optionName === customisation.DefaultOption;
            }
        }
        return selected;
    }

    updateSelectionCustomisation(event, selectedCustomisation, selectedOptionName) {
        event.stopPropagation();
        const playerSelection = {...this.state.playerSelection};
        const customisations = [...playerSelection.Customisations];
        const customisationIndex = customisations.indexOf(customisations.find(c => c.Name === selectedCustomisation.Name));
        if(customisationIndex !== -1) {
            //update the existing selection
            const customisation = {...customisations[customisationIndex]};
            customisation.Selection = selectedOptionName;
            customisations[customisationIndex] = customisation;
        }
        else {
            //add the new selection
            customisations.push({ Name: selectedCustomisation.Name, Selection: selectedOptionName });
        }
        playerSelection.Customisations = customisations;
        this.setState({
            playerSelection
        });
        return false;
    }

    updateSelectionColor(selectedColourName, selection) {
        debugger;
        const playerSelection = {...this.state.playerSelection};
        const colours = [...playerSelection.Colours];
        const colourIndex = colours.indexOf(colours.find(c => c.Name === selectedColourName));
        if(colourIndex !== -1) {
            //update the existing selection
            const colour = {...colours[colourIndex]};
            colour.Selection = selection;
            colours[colourIndex] = colour;
        }
        else {
            //add the new selection
            colours.push({ Name: selectedColourName, Selection: selection });
        }
        playerSelection.Colours = colours;
        this.setState({
            playerSelection
        })
    }

    toggleDropdown(event, customisationName) {
        event.stopPropagation();
        const open = this.state.dropdownOpened[customisationName] ? true : false;
        const dropdownOpened = {};
        dropdownOpened[customisationName] = !open;
        this.setState({
            dropdownOpened
        });
    }

    onCheckedChange(event) {
        const field = event.target.name;
        let newState = Object.assign({}, this.state);
        let playerSelection = {...this.state.playerSelection};
        const checked = event.target.checked;
        playerSelection[field] = checked;
        newState.playerSelection = playerSelection;
        return this.setState(newState);
    }

    saveChanges() {
        this.props.actions.updatePlayerAvatar(this.props.dashboard.ID, this.props.player.ID, this.state.playerSelection)
    }

    render() {
        const dashboard = this.props.dashboard;
        const player = this.props.player;
        return (
            <div className={`main__body scroll  ${this.props.loading || !dashboard ? 'spinner' : ''}`} onClick={() => this.setState({dropdownOpened: {}})}>
            {
                !this.props.loading && dashboard ?
                    <div className="pane pane--fixed pane--dashboards">
                        <Link to={`/Dashboards/${dashboard.ID}/Settings`} className="btn-close"><i className="aheadicon-x"></i></Link>
                        <div className="pane__head">
                            <div className="pane__ctrl">
                                <div className="pane__info">
                                    <h5>Avatar Settings</h5>
                                    <p>Player: {player.Label}</p>
                                </div>
                                <div className="pane__actions">
                                    <button href={null} className="btn btn--secondary" onClick={this.reset}>Reset to Default</button>
                                    <button href={null} className="btn btn--primary" onClick={this.saveChanges}>Save Changes</button>
                                                
                                </div>
                            </div>
                        </div>
                        
                        <div className="pane__body scrollbar">
                            <form className="form">
                                <div className="playered">
                                    <div className="playered__side">
                                        {
                                            this.props.dashboard.AvatarOverrides.Customisations.map(customisation => {
                                                const dropdownClass = this.state.dropdownOpened[customisation.Name] ? 'show' : '';
                                                return customisation.Options.length > 1 ?
                                                    <div key={customisation.Name} className={`dropdown ${dropdownClass}`}>
                                                        <button className="btn btn-secondary dropdown-toggle" type="button" onClick={(e) => this.toggleDropdown(e, customisation.Name)} aria-haspopup="true" aria-expanded="false">
                                                            <img src={imageUtils.getAvatarBase64Url(customisation.ImageData ? customisation.ImageData : customisation.Options.find(option => option.Name === customisation.DefaultOption).ImageData)} alt={customisation.FriendlyName}/>
                                                        </button>
                                                        <div className={`dropdown-menu ${dropdownClass}`} onClick={this.dropdownClicked}>
                                                            {
                                                                customisation.Options.map(option =>
                                                                    <a key={option.Name} className={`dropdown-item ${this.optionSelected(customisation, option.Name) ? 'active' : ''}`} href={null} onClick={(e) => this.updateSelectionCustomisation(e, customisation, option.Name)}>
                                                                        <img src={imageUtils.getAvatarBase64Url(option.ImageData)} alt={option.Name}/>
                                                                    </a>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                            )
                                        }
                                        {
                                            this.props.dashboard.AvatarOverrides.Colours.length ? 
                                                <div className="dropdown dropdown--colors">
                                                    <button className="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <img src={require('../../images/player-colors.png')} alt="Colors"/>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                    {
                                                        this.props.dashboard.AvatarOverrides.Colours.map((colourSelection, index) =>
                                                            <React.Fragment key={colourSelection.Name}>
                                                                <p className={`dropdown-head ${index === 0 ? 'notopmargin' : ''} `}>
                                                                    <span>{colourSelection.Name}</span>
                                                                </p>
                                                                {colourSelection.Options.map(option => {
                                                                    const defaultColor = this.getDefaultColor(option);
                                                                    return <div key={option.Name} className="cpicker">
                                                                        <p>{option.Name}</p>
                                                                        <div className="cpicker__inner">
                                                                            <div className="cpicker__swatch" style={{backgroundColor: defaultColor}} data-selection-name={option.Name} data-custom-palette={option.ColourPalette} data-default-color={defaultColor} ref={(swatch) => this.swatchRefs.push(swatch)}></div>
                                                                            <input className="form-control" type="text" name=" " value={defaultColor} onChange={(e) => this.colorInputChange(e, option.Name)} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                                )}
                                                            </React.Fragment>
                                                        )
                                                    } 
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>

                                    <div className={`playered__content playered__content-center ${this.props.dashboard.Template.Slug === 'car-race' ? 'playered__content--cars' : ''} scrollbar`}>
                                        <div className="player-preview">
                                            {
                                                this.state.playerSelection ?
                                                    <React.Fragment>
                                                        <Avatar avatarSelection={this.state.playerSelection} dashboard={dashboard} />
                                                        <form className="form">
                                                            <div className="form-group nobottommargin">
                                                                <div className="checkbox checbox-switch switch-primary">
                                                                    <label>
                                                                        <input type="checkbox" name="SetAsDefault" onChange={this.onCheckedChange} checked={this.state.playerSelection.SetAsDefault} />
                                                                        <span className="dot"></span>
                                                                        <span className="label">Make this my default avatar <a href="#" className="btn-helper" ref={control => this.tooltipControls.push(control)} data-toggle="tooltip" data-placement="top" title="This avatar styling will be used across all other dashboards with this theme"><i className="aheadicon-help"></i></a></span>
                                                                    </label>
                                                                </div>
                                                                <div className="checkbox checbox-switch switch-primary">
                                                                    <label>
                                                                        <input type="checkbox" name="PreventAutoOverrides" onChange={this.onCheckedChange} checked={this.state.playerSelection.PreventAutoOverrides} />
                                                                        <span className="dot"></span>
                                                                        <span className="label">Don't override this avatar <a href="#" className="btn-helper" ref={control => this.tooltipControls.push(control)} data-toggle="tooltip" data-placement="top" title="If you have a default avatar set, selecting this option will prevent this avatar from being overriden by the default avatar."><i className="aheadicon-help"></i></a></span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </React.Fragment>
                                                : null
                                            }                                            
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    : null
            }
            </div>
        );
    }
}

AvatarSettings.propTypes = {
    actions: PropTypes.object.isRequired,
    dashboard: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const dashboardID = ownProps.match.params.id;
    const playerID = ownProps.match.params.playerID;
    const dashboard = state.dashboard.dashboards.filter(d => d.Template).find(d => d.ID === dashboardID); 
    const player = dashboard ? dashboard.Players.find(p => p.ID === playerID) : null;
    return { 
        dashboard, 
        player, 
        loading: state.dashboard.dashboardsLoading || state.dashboard.dashboardItemsLoading.includes(dashboardID)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //dashboard actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, dashboardActions, userActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(AvatarSettings));