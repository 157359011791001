import React from 'react';

const InfoModal = (props) => { 
    return (
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{props.title}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modal__inner modal__inner--wide">
                        <p className="text-center">{props.bodyText}</p>          
                    </div>
                </div>
                <div className="modal-footer modal-footer--center">
                    <button className="btn btn--primary btn--wide" data-dismiss="modal">{props.buttonText || 'OK'}</button>
                </div>
            </div>
        </div>
    );
};

export default InfoModal;