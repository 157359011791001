import _ from 'lodash';

export function isDashboard(url) {
    const urlParts = _.trim(url, '/').split('/');
    const isDashboard = (urlParts.length === 2 && urlParts[0] === 'Dashboards') || (urlParts.length === 4 && urlParts[0] === 'Dashboards' && urlParts[2] === 'Player');
    return isDashboard;
}

export function isPlaylist(url) {
    const urlParts = _.trim(url, '/').split('/');
    const isPlaylist = urlParts.length === 2 && urlParts[0] === 'Playlists';
    return isPlaylist;
}