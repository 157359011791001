
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../../utils/imageUtils';
import * as widgetUtils from '../../../utils/widgetUtils';
import * as widgetConstants from '../../../constants/widgetConstants';
import _ from 'lodash';
import DeleteConfirmationModal from '../../common/DeleteConfirmationModal';
import { withModalContext } from '../../../services/modalService';
import TemplateModifier from './modifiers/TemplateModifier';
import FormattingModifier from './modifiers/FormattingModifier';
import AggregateModifier from './modifiers/AggregateModifier';
import CalculationModifier from './modifiers/CalculationModifier';
import ToastrService from '../../../services/toastrService';
import SortingModifier from './modifiers/SortingModifier';
import FlattenModifier from './modifiers/FlattenModifier';
import IterationModifier from './modifiers/IterationModifier';
import FilterModifier from './modifiers/FilterModifier';
import FieldSelectionModifier from './modifiers/FieldSelectionModifier';
import JoinModifier from './modifiers/JoinModifier';
import GroupingModifier from './modifiers/GroupingModifier';
import TransformationModifier from './modifiers/TransformationModifier';

class NewTaskModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getInputOptions = this.getInputOptions.bind(this);
        this.createModifier = this.createModifier.bind(this);
        this.saveTask = this.saveTask.bind(this);
        this.showTaskNameOverlay = this.showTaskNameOverlay.bind(this);
        this.hideTaskNameOverlay = this.hideTaskNameOverlay.bind(this);
        this.getModifierName = this.getModifierName.bind(this);
        this.setModifierName = this.setModifierName.bind(this);
        this.saveModifierName = this.saveModifierName.bind(this);
        this.modifierNameKeyPress = this.modifierNameKeyPress.bind(this);
        this.taskInputTypes = widgetConstants.taskInputTypes;
        if(this.props.dashboard && this.props.dashboard.PlayerMode !== 'Single') {
            //update the name from Player to Players
            const playerType = this.taskInputTypes.find(t => t.type === 'Player');
            if(playerType) {
                const playerTypeIndex = this.taskInputTypes.indexOf(playerType);
                playerType.name = 'Players';
                this.taskInputTypes[playerTypeIndex] = playerType;
            }
        } 
        this.variableTypes = widgetConstants.variableTypes;       

        this.state = {
            taskName: this.props.task.Name,
            inlineEditingEnabledIDs: [],
            temporaryModifierNames: {}
        };
        
    }

    componentDidMount() { 
               
    }  

    componentWillUpdate(nextProps) {
        if(this.props.task.Name !== nextProps.task.Name) {
            this.setState({ taskName: nextProps.task.Name });
        }
    }

    toggleInlineEditing(id) {
        const inlineEditingEnabledIDs = [...this.state.inlineEditingEnabledIDs];
        if(inlineEditingEnabledIDs.includes(id)) {
            _.pull(inlineEditingEnabledIDs, id);
        }
        else {
            inlineEditingEnabledIDs.push(id);
            window.setTimeout(() => {
                window.$(`#inline-${id}`).focus();
            }, 300);
        }
        this.setState({ inlineEditingEnabledIDs });
    }

    modifierNameKeyPress(event, id) {
        if (event.key === "Enter") {
            this.saveModifierName(id);
        }
    }

    getModifierName(id) {
        return this.state.temporaryModifierNames[id] || '';
    }

    setModifierName(id, value) {
        const temporaryModifierNames = {...this.state.temporaryModifierNames};
        temporaryModifierNames[id] = value;
        this.setState({ temporaryModifierNames });
    }

    saveModifierName(id) {
        const value = this.getModifierName(id);
        const haveDuplicateModifierName = this.props.task.Variables.find(v => v.ID !== id && v.Name === value) != null;
        if(!haveDuplicateModifierName) {
            if(value) {
                const modifier = {...this.props.task.Variables.find(v => v.ID === id)};
                modifier.Name = value;
                this.props.updateModifier(modifier);
            }
            this.setModifierName(id, '');
            this.toggleInlineEditing(id);
        }
        else {
            const toastrService = new ToastrService();
            toastrService.showError('', `There is an existing item in this task named '${value}'`);
        }
    }

    getInputOptions() {
        return this.taskInputTypes.map(inputType =>
            <a key={inputType.type} className="dropdown-item btn-selectdata" href="#" onClick={() => this.props.openSecondModal(inputType.type)}>
                <i className={inputType.icon}></i>
                <p>{inputType.name}</p>
            </a>
        );
    }

    createModifier(type, inputIDs, modifiers) {
        let modifier;
        const id = this.props.getTempID(type);
        //the idea is that if we have a modifier (that isn't an input type), we use it as the input, else we use the taskGroup (input tasks)
        const inputs = modifiers.length ? 
            [modifiers[modifiers.length - 1].ID] 
            : inputIDs;
        if(type === 'Template') {
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                TemplateString: '',
                Inputs: inputs,
                OutputType: 'Text'
            }
        }
        else if(type === 'Formatting') {
            const input = inputs.length ? inputs[inputs.length - 1] : null;
            const variable = this.props.resolvedTasks[this.props.task.ID].variables[input];
            let inputType;
            if(variable) {
                if(variable.currentValue) {
                    inputType = widgetUtils.getObjectType(variable.currentValue);
                }
                else {
                    inputType = variable.OutputType;
                }
            }
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                FormatString: '',
                FormatFunction: null,
                FunctionInputs: [],
                InputVariableID: inputs.length ? inputs[inputs.length - 1] : null, //there should only be 1 input for the formatting type
                OutputType: inputType
            }
        }
        else if(type === 'Aggregate') {
            const input = inputs.length ? inputs[inputs.length - 1] : null;
            const variable = this.props.resolvedTasks[this.props.task.ID].variables[input].currentValue;
            const inputType = variable && variable.length ? widgetUtils.getObjectType(variable[0]) : null;
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                AggregateFunction: null,
                FunctionInputs: [],
                InputVariableID: input, //there should only be 1 input for the aggregate type
                OutputType: inputType
            }
        }
        else if(type === 'Calculation') {
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                Formula: '',
                Inputs: inputs,
                OutputType: 'Double'
            }
        }
        else if(type === 'Sorting') {
            const input = inputs.length ? inputs[inputs.length - 1] : null;
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                SortDirection: null,
                Sorts: [],
                InputVariableID: input, //there should only be 1 input for the sorting type
                OutputType: 'Array'
            }
        }
        else if(type === 'Flatten') {
            const input = inputs.length ? inputs[inputs.length - 1] : null;
            const variable = this.props.resolvedTasks[this.props.task.ID].variables[input].currentValue;
            const inputType = variable && variable.length ? widgetUtils.getObjectType(variable) : null;
            let nestedInputType;
            if(inputType) {
                if(inputType === 'Object') {
                    nestedInputType = widgetUtils.getNestedObjectType(variable);
                }
            }
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                Distinct: false,
                InputVariableID: input, //there should only be 1 input for the flatten type
                OutputType: nestedInputType || inputType
            }
        }
        else if(type === 'Iteration') {
            const input = inputs.length ? inputs[inputs.length - 1] : null;
            const variable = this.props.resolvedTasks[this.props.task.ID].variables[input].currentValue;
            const outputType = variable && variable.length ? widgetUtils.getObjectType(variable[0]) : null;
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                MaxCount: null,
                EmptyText: '',
                InputVariableID: input, //there should only be 1 input for the flatten type
                OutputType: outputType
            }
        }
        else if(type === 'Filter') {
            const input = inputs.length ? inputs[inputs.length - 1] : null
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                Formula: '',
                FormulaInputs: [],
                InputVariableID: input, //there should only be 1 input for the filter type which is the input array
                OutputType: 'Array'
            }
        }
        else if(type === 'FieldSelection') {
            const input = inputs.length ? inputs[inputs.length - 1] : null;
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                FieldName: null,
                InputVariableID: input, //there should only be 1 input for the field selection type
                OutputType: 'Object' //when resolving, we will overwrite it
            }
        }
        else if(type === 'Join') {
            //if we have a single item, default to a string output with an empty, else default to an array output with a null separator
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                Separator: inputs.length === 1 ? '' : null,
                Inputs: inputs,
                OutputType: inputs.length === 1 ? 'Text' : 'Array' //we can change this in the modifier settings, the input types will determine the output types possible
            }
        }
        else if(type === 'Grouping') {
            const input = inputs.length ? inputs[inputs.length - 1] : null
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                GroupBy: [],
                Projections: [],
                GroupingFunction: null,
                FunctionInputs: [],
                InputVariableID: input,
                OutputType: 'Array' 
            }
        }
        else if(type === 'Transformation') {
            const input = inputs.length ? inputs[inputs.length - 1] : null
            const variable = this.props.resolvedTasks[this.props.task.ID].variables[input].currentValue;
            const outputType = Array.isArray(variable) ? 'Array' : 'Object';
            modifier = {
                ID: id,
                Name: id,
                Type: type,
                TransformationActions: [],
                InputVariableID: input,
                OutputType: outputType
            }
        }
        this.props.createModifier(modifier, type);
    }

    deleteModifier(event, modifier) {
        event.preventDefault();
        const deleteAction = () => this.props.deleteModifier(modifier);
        const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to delete this modifier? This action is irreversible.`} />
        this.props.showModal(() => deleteConfirmationModal, { isOpen: true })
    }

    showPreview(event) {
        const control = window.$(event.target);
        const container = control.parent().parent().parent();
        const previewPane = container.find('.previewPane');
        previewPane.slideToggle();
    }

    getModifierMarkup(modifier) {
        const variableType = this.variableTypes.find(v => v.type === modifier.Type);
        const resolvedTask = this.props.resolvedTasks[this.props.task.ID];
        let output;
        let outputTypeText;
        if(resolvedTask) {
            const resolvedVariable = resolvedTask.variables[modifier.ID];
            if(resolvedVariable) {
                outputTypeText = resolvedVariable.OutputType;
                if(resolvedVariable.OutputType === 'Object' || resolvedVariable.OutputType === 'Array') {
                    //cap arrays at 50 items
                    output = resolvedVariable.currentValue ? JSON.stringify(resolvedVariable.OutputType === 'Array' ? resolvedVariable.currentValue.slice(0, 20) : resolvedVariable.currentValue, null, 2) : '-';
                    let nestedType;
                    if(resolvedVariable.OutputType === 'Object') {
                        nestedType = widgetUtils.getNestedObjectType(resolvedVariable.currentValue);
                        if(nestedType && nestedType !== 'Object') {
                            outputTypeText += ` containing a single ${nestedType}`;
                        }
                    }
                    else if(resolvedVariable.OutputType === 'Array' && resolvedVariable.currentValue && resolvedVariable.currentValue.length) {
                        nestedType = widgetUtils.getObjectType(resolvedVariable.currentValue[0]);
                        outputTypeText += resolvedVariable.currentValue.length === 1 ? ` containing a single ${nestedType}` : ` containing ${nestedType}s`;
                    }
                }
                else {
                    output = resolvedVariable && resolvedVariable.currentValue ? resolvedVariable.currentValue.toString() : '-';
                }
            }
        }        
        return <div key={modifier.ID} className="card card--step card--modifier card--icon">
            <i className={`${modifier.icon} card__picto`}></i>
            <div className="card-header py-3 d-flex">
                <p className="card__title m-0">
                    {
                        this.state.inlineEditingEnabledIDs.includes(modifier.ID) ?
                            <input id={`inline-${modifier.ID}`} className="form-control form-control--inline" type="text" value={this.getModifierName(modifier.ID)} onChange={(event) => this.setModifierName(modifier.ID, event.target.value)} onBlur={() => this.saveModifierName(modifier.ID)} onKeyPress={(event) => this.modifierNameKeyPress(event, modifier.ID)} />
                            : 
                            <span className="inline-edit inline-edit--icon" onClick={() => this.toggleInlineEditing(modifier.ID)}>{modifier.Name}</span>
                    }
                     <span className="card__selection text-muted small d-block">{variableType.description}</span>
                </p>
                <div className="card__actions ml-auto">
                    <a href="#" className="btn-text btn--withicon btn-textmuted small" onClick={this.showPreview}>
                        <i className="aheadicon-eye"></i>
                        Preview
                    </a>
                    <a href="#" className="btn-text btn--withicon btn-editcard small ml-3" onClick={(e) => this.deleteModifier(e, modifier)}>
                        <i className="aheadicon-delete"></i>
                        Delete
                    </a>
                </div>                
            </div>
            <div className="card-body pt-0">                
                {this.getModifierComponent(modifier)}          
                <div className="previewPane text-muted small mt-2" style={{display: 'inline'}}>Output:<br />Type: {outputTypeText}<br />Value:{output && output.length > 75 ? <br /> : ' '}{output}</div>
            </div>            
        </div>
    }

    getModifierComponent(modifier) {
        if(modifier.Type === 'Template') {
            return <TemplateModifier modifier={modifier} updateModifier={this.props.updateModifier} />
        }
        else if(modifier.Type === 'Formatting') {
            let inputType;
            const task = this.props.resolvedTasks[this.props.task.ID];
            if(task) {
                const variable = task.variables[modifier.InputVariableID];
                if(variable) {
                    inputType = variable.OutputType;// widgetUtils.getObjectType(variable.currentValue);
                    if(inputType === 'Object' && variable.currentValue) {
                        inputType = widgetUtils.getNestedObjectType(variable.currentValue);
                    }
                }  
            }                       
            return <FormattingModifier modifier={modifier} inputType={inputType} updateModifier={this.props.updateModifier} />
        }
        else if(modifier.Type === 'Aggregate') {
            let inputType;
            const task = this.props.resolvedTasks[this.props.task.ID];
            if(task) {
                const variable = task.variables[modifier.InputVariableID];
                if(variable && variable.currentValue && variable.currentValue.length) {
                    inputType = widgetUtils.getObjectType(variable.currentValue[0]);
                    /*if(inputType === 'Object') {
                        inputType = widgetUtils.getNestedObjectType(variable.currentValue);
                    }*/
                }
            }             
            return <AggregateModifier modifier={modifier} inputType={inputType} updateModifier={this.props.updateModifier} />
        }
        else if(modifier.Type === 'Calculation') {
            return <CalculationModifier modifier={modifier} updateModifier={this.props.updateModifier} task={this.props.task} />
        }
        else if(modifier.Type === 'Sorting') {
            let inputType = 'Array';        
            let initialItem;              
            const task = this.props.resolvedTasks[this.props.task.ID];
            if(task) {
                const variable = task.variables[modifier.InputVariableID];
                if(variable && variable.currentValue && variable.currentValue.length) {
                    initialItem = variable.currentValue[0];
                }
            }   
            return <SortingModifier modifier={modifier} inputType={inputType} initialItem={initialItem} updateModifier={this.props.updateModifier} />
        }
        else if(modifier.Type === 'Flatten') {   
            return <FlattenModifier modifier={modifier} updateModifier={this.props.updateModifier} />
        }
        else if(modifier.Type === 'Iteration') {   
            return <IterationModifier modifier={modifier} updateModifier={this.props.updateModifier} />
        }
        else if(modifier.Type === 'Filter') {
            return <FilterModifier modifier={modifier} input={this.props.task.Variables.find(v => v.ID === modifier.InputVariableID)} dashboard={this.props.dashboard} updateModifier={this.props.updateModifier} />
        }
        else if(modifier.Type === 'FieldSelection') {
            let input;              
            const task = this.props.resolvedTasks[this.props.task.ID];
            if(task) {
                const variable = task.variables[modifier.InputVariableID];
                if(variable && variable.currentValue) {
                    input = variable.currentValue
                }
            }   
            return <FieldSelectionModifier modifier={modifier} input={input} updateModifier={this.props.updateModifier} />
        }
        else if(modifier.Type === 'Join') {
            const task = this.props.resolvedTasks[this.props.task.ID];
            const inputs = [];
            if(task) {
                modifier.Inputs.forEach(inputID => {
                    const input = task.variables[inputID];
                    if(input) {
                        inputs.push(input.currentValue);
                    }
                })  
            }                       
            return <JoinModifier modifier={modifier} inputs={inputs} updateModifier={this.props.updateModifier} />
        }
        else if(modifier.Type === 'Grouping') {
            let initialItem;              
            const task = this.props.resolvedTasks[this.props.task.ID];
            if(task) {
                const variable = task.variables[modifier.InputVariableID];
                if(variable && variable.currentValue && variable.currentValue.length) {
                    initialItem = variable.currentValue[0];
                }
            } 
            return <GroupingModifier modifier={modifier} updateModifier={this.props.updateModifier} initialItem={initialItem} />
        }
        else if(modifier.Type === 'Transformation') {
            let initialItem;              
            const task = this.props.resolvedTasks[this.props.task.ID];
            if(task) {
                const variable = task.variables[modifier.InputVariableID];
                if(variable && variable.currentValue && variable.currentValue.length) {
                    initialItem = variable.currentValue[0];
                }
            } 
            return <TransformationModifier modifier={modifier} updateModifier={this.props.updateModifier} initialItem={initialItem} />
        }
    }

    saveTask(event) {
        event.preventDefault();
        const taskID = this.props.task.ID;
        if(this.props.dashboard.Tasks.find(t => t.ID !== taskID && t.Name === this.state.taskName) == null) {
            this.hideTaskNameOverlay(event);
            this.props.saveTask(taskID, this.state.taskName);
            window.$('#modal-newtask').modal('hide');
        }
        else {
            const toastrService = new ToastrService();
            toastrService.showError('', `There is an existing task in this dashboard named '${this.state.taskName}'`);
        }
   }

    showTaskNameOverlay(event) {
        event.preventDefault();
        window.$('#modal-newtask').addClass('modal-save');
    }

    hideTaskNameOverlay(event) {
        event.preventDefault();
        window.$('#modal-newtask').removeClass('modal-save');
    }

    render() {
        const {task} = this.props;
        const variables = task.Variables;
        const inputTypes = this.taskInputTypes.map(t => t.type);
        const inputVariables = [];
        const modifiers = [];
        //const linkedTransformationModifiers = [];
        variables.forEach(v => inputTypes.includes(v.Type) ? inputVariables.push(v) : modifiers.push(v));
        return (
            <div className="modal modal-side modal-side--newtask fade" tabIndex="-1" role="dialog" id="modal-newtask" data-backdrop="false" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">                
                        <div id="nameOverlay" className="modal-overlay">
                            <div className="modal-overlay__body w-100">
                                <form className="form form--save" onSubmit={this.saveTask}>
                                    <div className="form-group mb-2">
                                        <label>Please enter a name for this task</label>
                                        <input className="form-control" type="text" placeholder="Enter task name" required value={this.state.taskName} onChange={(event) => this.setState({ taskName: event.target.value })} />
                                    </div>
                                    <div className="form-group text-right mb-0">
                                        <button className="btn btn--outline btn-mo-cancel" onClick={this.hideTaskNameOverlay}>Cancel</button>
                                        <button type="submit" className="btn btn-primary btn-mo-save btn--wide ml-1">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>                
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.newTask ? 'New task' : `Existing Task - ${this.props.task.Name}`}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        <div className="modal-body">   
                        {
                            inputVariables.length ?
                                <div className="steps steps--timeline mb-5">
                                    {
                                        inputVariables.map((inputVariable, i) => {
                                            //const inputType = this.taskInputTypes.concat(widgetConstants.variableTypes).find(t => t.type === task.Type);
                                            const inputType = this.taskInputTypes.find(t => t.type === inputVariable.Type);
                                            const resolvedTask = this.props.resolvedTasks[this.props.task.ID];
                                            let resolvedVariable;
                                            /*let linkedTransformationModifier;
                                            if(inputVariable.Type === 'Player' || inputVariable.Type === 'Dashboard' || inputVariable.Type === 'DataSource') {
                                                linkedTransformationModifier = modifiers.find(m => m.Type === 'Transformation' && m.InputVariableID === inputVariable.ID);                                               
                                            }*/
                                            if(resolvedTask) {
                                                /*if(linkedTransformationModifier) {
                                                    linkedTransformationModifiers.push(linkedTransformationModifier);
                                                    resolvedVariable = resolvedTask.variables[linkedTransformationModifier.ID];
                                                }
                                                else {*/
                                                    resolvedVariable = resolvedTask.variables[inputVariable.ID];
                                                //}
                                            }
                                            let output;
                                            let outputTypeText;
                                            if(resolvedVariable) {
                                                outputTypeText = resolvedVariable.OutputType;
                                                if(resolvedVariable.OutputType === 'Object' || resolvedVariable.OutputType === 'Array') {
                                                    output = resolvedVariable.currentValue ? JSON.stringify(resolvedVariable.OutputType === 'Array' ? resolvedVariable.currentValue.slice(0, 20) : resolvedVariable.currentValue, null, 2) : '-';
                                                    let nestedType;
                                                    if(resolvedVariable.OutputType === 'Object') {
                                                        nestedType = widgetUtils.getNestedObjectType(resolvedVariable.currentValue);
                                                        if(nestedType && nestedType !== 'Object') {
                                                            outputTypeText += ` containing a single ${nestedType} field`;
                                                        }
                                                    }
                                                    else if(resolvedVariable.OutputType === 'Array' && resolvedVariable.currentValue && resolvedVariable.currentValue.length) {
                                                        nestedType = widgetUtils.getObjectType(resolvedVariable.currentValue[0]);
                                                        outputTypeText += resolvedVariable.currentValue.length === 1 ? ` containing a single ${nestedType} item` : ` containing ${nestedType}s`;
                                                    }
                                                }
                                                else {
                                                    output = resolvedVariable && resolvedVariable.currentValue ? resolvedVariable.currentValue.toString() : '-';
                                                }
                                            }
                                            return <div key={inputVariable.ID} className="card card--step card--icon">
                                                <i className={`${inputType.icon} card__picto`}></i>
                                                <div className="card-header py-3 d-flex">
                                                    <p className="card__title m-0">
                                                        {
                                                            this.state.inlineEditingEnabledIDs.includes(inputVariable.ID) ?
                                                                <input id={`inline-${inputVariable.ID}`} className="form-control form-control--inline" type="text" value={this.getModifierName(inputVariable.ID)} onChange={(event) => this.setModifierName(inputVariable.ID, event.target.value)} onBlur={(event) => this.saveModifierName(inputVariable.ID, event.target.value)} onKeyPress={(event) => this.modifierNameKeyPress(event, inputVariable.ID)} />
                                                                : 
                                                                <span className="inline-edit inline-edit--icon" onClick={() => this.toggleInlineEditing(inputVariable.ID)}>{inputVariable.Name}</span>
                                                        }
                                                        {/*linkedTransformationModifier && linkedTransformationModifier.TransformationActions.length ? <span className="card__selection text-muted small ml-3">{`${linkedTransformationModifier.TransformationActions.length} input${linkedTransformationModifier.TransformationActions.length !== 1 ? 's' : ''} selected`}</span> : null*/}
                                                        {inputVariable.IncludeFields && inputVariable.IncludeFields.length ? <span className="card__selection text-muted small ml-3">{`${inputVariable.IncludeFields.length} input${inputVariable.IncludeFields.length !== 1 ? 's' : ''} selected`}</span> : null}
                                                    </p>
                                                    <div className="card__actions ml-auto">
                                                        <a href="#" className="btn-text btn--withicon btn-textmuted small" onClick={this.showPreview}>
                                                            <i className="aheadicon-eye"></i>
                                                            Preview
                                                        </a>
                                                        <a href="#" className="btn-text btn--withicon btn-editcard small ml-3" onClick={() => this.props.openSecondModal(inputType.type, inputVariable)}>
                                                            <i className="aheadicon-edit"></i>
                                                            Change
                                                        </a>
                                                    </div>
                                                </div>
                                                {
                                                    inputVariable.IncludeFields && inputVariable.IncludeFields.length ?
                                                    //linkedTransformationModifier && linkedTransformationModifier.TransformationActions.length ?
                                                        <div className="card-body pt-0">
                                                            <div className="badges">
                                                                {
                                                                    /*linkedTransformationModifier.TransformationActions.map(transformationAction => 
                                                                        <div key={transformationAction.OutputName} className="badge badge--x">
                                                                            <p>{transformationAction.OutputName}</p>
                                                                            <a href="#" className="badge__close" onClick={() => this.props.removeModifierProperty(inputVariable, linkedTransformationModifier, transformationAction)}>
                                                                                <i className="aheadicon-x"></i>
                                                                            </a>
                                                                        </div>
                                                                    )*/
                                                                    inputVariable.IncludeFields.map(field => 
                                                                        <div key={field} className="badge badge--x">
                                                                            <p>{field}</p>
                                                                            <a href="#" className="badge__close" onClick={() => this.props.removeModifierIncludeField(this.props.task.ID, inputVariable, field)}>
                                                                                <i className="aheadicon-x"></i>
                                                                            </a>
                                                                        </div>
                                                                    )

                                                                }                                
                                                            </div>                
                                                        </div>
                                                        : null
                                                }
                                                <div className="previewPane text-muted small mt-2" style={{display: 'none'}}>Output:<br />Type: {outputTypeText}<br />Value:{output && output.length > 75 ? <br /> : ' '}{output}</div>
                                            </div> 
                                        })
                                    }  
                                    {
                                        //modifiers.filter(m => !linkedTransformationModifiers.includes(m)).map(modifier => {                    
                                        modifiers.map(modifier => {                    
                                            return this.getModifierMarkup(modifier);
                                        })                     
                                    }     
                                    <div className="card card--actionstep">
                                        {/*we can't add modifiers if the last modifier was an iteration*/}
                                        {
                                            !modifiers.length || modifiers[modifiers.length - 1].Type !== 'Iteration' ?                                        
                                                <div className="card-body d-flex align-items-center">                                                    
                                                {/* Modifier selector */}
                                                    { //we can't have modifiers without 1st having some input variables 
                                                        inputVariables.length ?
                                                            <div className="dropdown dropdown--data dropdown--left text-center">
                                                                <button className="btn btn--outline btn-addmodifier" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    + Add modifier
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu--blocks dropdown-menu--data" aria-labelledby="dropdownData">
                                                                    {
                                                                        this.variableTypes.filter(v => v.isEnabled(inputVariables, modifiers, this.props.resolvedTasks)).map(variableType => 
                                                                            <a key={variableType.type} className="dropdown-item btn-selectdata" href="#" onClick={() => this.createModifier(variableType.type, inputVariables.map(v => v.ID), modifiers)}>
                                                                                <i className={variableType.icon} />
                                                                                <p><strong>{variableType.name}</strong></p>
                                                                                <p className="text-muted mt-2">{variableType.description}</p>
                                                                            </a>
                                                                        )
                                                                    }                            
                                                                </div>
                                                            </div>
                                                            : null
                                                    }                    
                                                    {
                                                        //modifiers.filter(m => !linkedTransformationModifiers.includes(m)).length === 0 ?
                                                        modifiers.length === 0 ?
                                                        //we only want to be able to add a data source at the end if there are no modifiers yet (except it's a linked transformation as we use this to select fields from the data source)
                                                            <div className="dropdown dropdown--data dropdown--right text-center ml-1">
                                                                <button className="btn btn--outline" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    + Add another data source
                                                                </button>
                                                                <div className="dropdown-menu dropdown-menu--blocks dropdown-menu--data" aria-labelledby="dropdownData">
                                                                    {
                                                                        this.getInputOptions()
                                                                    }
                                                                </div>
                                                            </div>     
                                                            : null
                                                    }          
                                                </div>
                                                : null
                                        }
                                    </div> 
                                </div>
                                :
                                <form className="form">
                                    <p className="text-muted my-4">Select the input data for your task:</p>
                                    <div className="dropdown dropdown--data dropdown--embed">
                                        <div className="dropdown-menu dropdown-menu--blocks dropdown-menu--data" aria-labelledby="dropdownData">
                                            <a href="#" className="close">
                                                <i className="aheadicon-x"></i>
                                            </a>
                                            {
                                                this.getInputOptions()
                                            }
                                        </div>
                                    </div>
                                </form> 
                        }  
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-default" data-dismiss="modal">Cancel</a>
                            <button className="btn btn-primary btn-createtask" onClick={this.showTaskNameOverlay} disabled={!this.props.task.Variables.length}>{this.props.newTask ? 'Create task' : 'Save changes'}</button>
                        </div>
                    </div>
                </div>
            </div> 
        );
    }

}

export default withModalContext(NewTaskModal);