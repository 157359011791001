/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import * as imageUtils from '../../../../../utils/imageUtils';
import * as widgetUtils from '../../../../../utils/widgetUtils';

class Image extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialiseData = this.initialiseData.bind(this);
        this.getSource = this.getSource.bind(this);
        this.getStyle = this.getStyle.bind(this);
        this.getParentStyle = this.getParentStyle.bind(this);
        this.getShapeClass = this.getShapeClass.bind(this);
        this.state = {
        };
    }

    componentWillMount() {   
        this.initialiseData();
    }       

    componentDidUpdate(prevProps, prevState) {
        if(this.props.widget !== prevProps.widget || this.props.widgetSettings !== prevProps.widgetSettings || this.props.tasks !== prevProps.tasks) {
            this.initialiseData();
        }
    }

    initialiseData() {
        const data = {};
        this.props.widget.CustomVariables.forEach(variable => {
            data[variable.Name] = variable.DefaultValue;
        });
        this.props.widgetSettings.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
            if(adjustment.Append) {
                data[adjustment.Name] += parseFloat(adjustment.Append);
            }
            else {
                data[adjustment.Name] = adjustment.Override;
            }
        });
        data['tasks'] = this.props.tasks;
        //source, //visible
        
        this.setState(data);
    }

    getSource() {    
        let source;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'Source')) {
                    const sourceBinding = this.state.tasks[binding.TaskID];
                    if(sourceBinding && sourceBinding.currentValue) {                    
                        if(typeof sourceBinding === 'object') {          
                            const keys = Object.keys(sourceBinding.currentValue);
                            if(keys.includes('FileData')) {
                                source = imageUtils.getAvatarBase64Url(sourceBinding.currentValue);
                            }      
                            else if(keys.length === 1) {
                                //there could be a nested object containing the avatar
                                source = imageUtils.getAvatarBase64Url(sourceBinding.currentValue[keys[0]]);
                            }
                            break;
                        }
                    }
                }
            }    
        }         
        return source || this.state.source || (this.props.isEditable ? require('../images/avatar-empty.png') : null);
    }

    getVisibility() {
        let visible = true;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'Visible')) {
                    const visibilityBinding = this.state.tasks[binding.TaskID];
                    if(visibilityBinding && visibilityBinding.currentValue) {                    
                        visible = visibilityBinding.currentValue;
                        break;
                    }
                }
            }     
        }        
        return visible;
    }

    getStyle() {
        const style = {};        
        if(!this.getVisibility()) {
            style['display'] = 'none';
        }
        return style;
    } 
    
    getParentStyle() {
        const style = {};
        if(this.state.height) {
            style['height'] = this.state.height;
        }
        if(this.state.width) {
            style['width'] = this.state.width;
        }
        return style;
    } 

    getShapeClass() {
        if(this.state.shape) {
            if(this.state.shape === 'Round') {
                return 'avatar--rounded';
            }
        }
        return 'avatar--square';
    }

    render() {
        return (  
            <div className={`avatar ${this.getShapeClass()}`} style={this.getParentStyle()}>
                <img style={this.getStyle()} src={this.getSource()} /> 
            </div>                    
        );
    }
}

Image.propTypes = {
};

export default Image;