
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../../utils/imageUtils';
import * as widgetUtils from '../../../utils/widgetUtils';
import * as widgetConstants from '../../../constants/widgetConstants';
import _ from 'lodash';
import ToastrService from '../../../services/toastrService';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';
import ace from 'brace';
import 'brace/theme/solarized_dark';
import 'brace/mode/json';
//import 'brace/theme/github';
import moment from 'moment';

class ManualInputModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getInputControl = this.getInputControl.bind(this);
        this.saveData = this.saveData.bind(this);
        this.types = ['Text', 'Object', 'Array', 'Date', 'Integer', 'Double']
        this.state = {
            type: this.props.selectedModifier ? this.props.selectedModifier.OutputType : 'Text',
            value: this.props.selectedModifier ? this.props.selectedModifier.Value : this.getDefaultValue('Text')
        };
        
    }

    componentWillMount() {
    }

    componentDidMount() { 
               
    } 
    
    componentWillUpdate(nextProps, nextState) {
        if(this.props.selectedModifier && !nextProps.selectedModifier) {
            this.setState({ type: 'Text', value: this.getDefaultValue('Text') })
        }
        else if(!this.props.selectedModifier && nextProps.selectedModifier && nextProps.selectedModifier.Type === 'UserDefined') {
            this.setState({
                type: nextProps.selectedModifier.OutputType,
                value: nextProps.selectedModifier.Value
            })
        }
        else if(this.props.selectedModifier && nextProps.selectedModifier && this.props.selectedModifier !== nextProps.selectedModifier && nextProps.selectedModifier.Type === 'UserDefined') {
            this.setState({
                type: nextProps.selectedModifier.OutputType,
                value: nextProps.selectedModifier.Value
            })
        }
    }   

    componentDidUpdate(prevProps) {
        if(this.props.selectedModifier && prevProps.selectedModifier !== this.props.selectedModifier && ['Object', 'Array'].includes(this.props.selectedModifier.OutputType) && this.editorControl) {
            this.editorControl.jsonEditor.set(this.props.selectedModifier.Value);
        }
    }
    
    getInputControl() {
        const type = this.state.type;
        if(type === 'Text') {
            return <input type="text" className="form-control" value={this.state.value} placeholder="Enter input text" onChange={(event) => this.setState({ value: event.target.value })} />
        }
        else if(type === 'Object') {
            return this.getEditor(type, this.state.value, (json) => this.setState({ value: json })) 
        }
        else if(type === 'Array') {
            return this.getEditor(type, this.state.value, (json) => this.setState({ value: json })) 
        }
        else if(type === 'Integer') {
            return <input type="number" className="form-control" value={this.state.value} placeholder="0" pattern="[0-9]" step="1" onChange={(event) => this.setState({ value: parseInt(event.target.value, 10) })} />
        }
        else if(type === 'Double') {
            return <input type="number" className="form-control" value={this.state.value} placeholder="0.00" pattern="^\d*(\.\d{0,2})?$" step="0.01" onChange={(event) => this.setState({ value: event.target.value })} />
        }
        else if(type === 'Date') {
            return <input type="date" className="form-control" value={moment(this.state.value).format('YYYY-MM-DD')} placeholder="Enter date" onChange={(event) => this.setState({ value: new Date(event.target.value) })} />
        }
    }

    getEditor(key, value, onChange) {
        return <Editor 
            ace={ace} 
            key={key}
            mode={Editor.modes.code} 
            placeholder="Enter the array in JSON format" 
            value={value}                
            theme="ace/theme/solarized_dark"
            ref={(control) => this.editorControl = control}
            onChange={onChange} />
    }

    getDefaultValue(type) {
        if(type === 'Text') {
            return '';
        }
        else if(type === 'Object') {
            return {};
        }
        else if(type === 'Array') {
            return [];
        }
        else if(type === 'Integer') {
            return 0;
        }
        else if(type === 'Double') {
            return 0.00;
         }
        else if(type === 'Date') {
            return new Date();
        }
    }

    saveData(event) {
        event.preventDefault();
        if(this.state.value) {
            let outputType = this.state.type;
            let value = this.state.value;            
            let task = {...this.props.task};
            task.Variables = [...task.Variables];
            if(!this.props.selectedModifier) {
                //create the variable for the data input
                const variableID = this.props.getTempID('ManualInput');
                const variable = { ID: variableID, Name: variableID, Type: this.props.inputType, OutputType: outputType, Value: value };
                task.Variables.push(variable);
            }
            else {
                //update the task
                const variableIndex = task.Variables.indexOf(this.props.selectedModifier);
                const variable = {...this.props.selectedModifier, OutputType: outputType, Value: value };
                task.Variables.splice(variableIndex, 1, variable);
            }
            this.props.updateTask(task);
            this.setState({ type: 'Text', value: this.getDefaultValue('Text') });
            window.$('#modal-newdata-manual').modal('hide');
        }
        else {
            const toastrService = new ToastrService();
            toastrService.showError('', 'Please enter a value');
        }
    }

    render() {
        return (
            <div className="modal modal-side modal-side--newdata fade" tabIndex="-1" role="dialog" id="modal-newdata-manual" data-backdrop="false" data-keyboard="false">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Enter data manually</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="card p-5">
                                <div className="form-group">
                                    <span className="text-muted small d-block">Select the data type</span>
                                    <select className="form-control" value={this.state.type} onChange={(event) => this.setState({ type: event.target.value, value: this.getDefaultValue(event.target.value) })}>
                                        {
                                            this.types.map(type =>
                                                <option key={type} value={type}>{type}</option>    
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="form-group">
                                    {
                                        this.getInputControl()
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <a href="#" className="btn btn-default" data-dismiss="modal">Cancel</a>
                            <a href="#" className="btn btn-primary" onClick={this.saveData}>Save data</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default ManualInputModal;