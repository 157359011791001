/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import * as widgetUtils from '../../../../../utils/widgetUtils';

class Label extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialiseData = this.initialiseData.bind(this);
        this.getText = this.getText.bind(this);
        this.getStyle = this.getStyle.bind(this);
        this.getTextColour = this.getTextColour.bind(this);
        this.state = {
        };
    }

    componentWillMount() {   
        this.initialiseData();
    }       

    componentDidUpdate(prevProps, prevState) {
        if(this.props.widget !== prevProps.widget || this.props.widgetSettings !== prevProps.widgetSettings || this.props.tasks !== prevProps.tasks) {
            this.initialiseData();
        }
    }

    initialiseData() {
        const data = {};
        this.props.widget.CustomVariables.forEach(variable => {
            data[variable.Name] = variable.DefaultValue;
        });
        this.props.widgetSettings.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
            if(adjustment.Append) {
                data[adjustment.Name] += parseFloat(adjustment.Append);
            }
            else {
                data[adjustment.Name] = adjustment.Override;
            }
        });
        data['tasks'] = this.props.tasks;
        this.setState(data);
    }

    getText() {        
        let text;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                //we only get the widgetHierarchy prop if we're in a nested control
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'Text')) {
                    const textBinding = this.state.tasks[binding.TaskID];
                    const acceptableTypes = ['Text', 'DateTime', 'Date', 'Integer', 'Double', 'Boolean'];
                    if(textBinding && textBinding.currentValue && acceptableTypes.includes(widgetUtils.getObjectType(textBinding.currentValue))) {                    
                        text = textBinding.currentValue;
                        break;
                    }
                }
            }
        }             
        return text || this.state.text || (this.props.isEditable ? 'Label Text' : null) || '';
    }

    getTextColour() {        
        let textColour;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                //we only get the widgetHierarchy prop if we're in a nested control
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'TextColour')) {
                    const textColourBinding = this.state.tasks[binding.TaskID];
                    if(textColourBinding && textColourBinding.currentValue) {                    
                        textColour = textColourBinding.currentValue;
                        break;
                    }
                }
            }      
        }       
        return textColour || this.state.textColour || '#ffffff';
    }

    getVisibility() {
        let visible = true;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'Visible')) {
                    const visibilityBinding = this.state.tasks[binding.TaskID];
                    if(visibilityBinding && visibilityBinding.currentValue) {                    
                        visible = visibilityBinding.currentValue;
                        break;
                    }
                }
            }  
        }           
        return visible;
    }

    getStyle() {
        //fontSize, textColour, underline, bold
        const style = {};
        if(this.state.underline) {
            style['textDecoration'] = 'underline';
        }
        if(this.state.bold) {
            style['fontWeight'] = 'bold';
        }
        if(this.state.textColour) {
            style['color'] = this.getTextColour();
        }
        if(this.state.fontSize) {            
            if(this.state.fontSize === 'Small') {
                style['fontSize'] = '8px';
            }
            else if(this.state.fontSize === 'Medium') {
                style['fontSize'] = '12px';
            }
            else if(this.state.fontSize === 'Large') {
                style['fontSize'] = '16px';
            }
        }
        if(!this.getVisibility()) {
            style['display'] = 'none';
        }
        return style;
    }    

    render() {
        return (  
            <span style={this.getStyle()}>{this.getText()}</span>            
        );
    }
}

Label.propTypes = {
};

export default Label;