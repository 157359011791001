import React from 'react';
import ReactDOM from 'react-dom';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import AddUpdateItemModal from './AddUpdateItemModal';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../utils/imageUtils';
import * as textUtils from '../../utils/textUtils';
import PlaylistItemSettingsModal from './PlaylistItemSettingsModal';

class PlaylistSettingsDashboardItem extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.deleteItem = this.deleteItem.bind(this);
        this.updateItem = this.updateItem.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setRef = this.setRef.bind(this);
        this.viewSettings = this.viewSettings.bind(this);
        this.getDashboardDescription = this.getDashboardDescription.bind(this);
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    deleteItem(event) {
        event.preventDefault();
        const deleteAction = () => this.props.actions.deletePlaylistItem(this.props.playlist.ID, this.props.item.ID);
        const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to delete dashboard ${this.props.item.Name}? This action is irreversible.`} />
        this.props.showModal(() => deleteConfirmationModal, { isOpen: true })
    }

    setRef(control) {
        this.tooltipControls.push(control);
    }

    onChange(event) {
        const field = event.target.name;
        let newState = Object.assign({}, this.state);
        if(field === 'selected') {
            const checked = event.target.checked;
            newState[field] = checked;
            return this.setState(newState);
        }
        else {
            let value = event.target.value;
            newState[field] = value;
            return this.setState(newState);
        }       
    }

    viewSettings(event) {
        event.preventDefault();
        const playlistItemSettingsModal = <PlaylistItemSettingsModal actions={this.props.actions} item={this.props.item} playlistID={this.props.playlist.ID}  />
        this.props.showModal(() => playlistItemSettingsModal, { isOpen: true })
    }

    updateItem(event) {
        event.preventDefault();
        const addUpdateItemModal = <AddUpdateItemModal key={this.props.playlist.ID} actions={this.props.actions} item={this.props.item} playlistID={this.props.playlist.ID} dashboards={this.props.dashboards}  />
        this.props.showModal(() => addUpdateItemModal, { isOpen: true })
    }

    getDashboardDescription(item, dashboard) {
        if(dashboard && dashboard.PlayerMode !== 'None') {
            if(dashboard.PlayerMode === 'Multi') {
                return `Multi-player dashboard, ${dashboard.Players.length} player${dashboard.Players.length === 1 ? '' : 's'}`;
            }
            else if(dashboard.PlayerMode === 'Single') {
                const player = dashboard.Players.find(p => p.ID === item.PlayerID);
                if(player) {
                    return `Single-player dashboard - ${player.Label} (${player.ClientUserID})`;
                }
                else {
                    return 'Single-player dashboard, player not found';
                }
            }
        }
        return null;
    }

    getDuration(secs) {
        //seconds = this.state.seconds;
        const minutes = Math.floor(secs / 60);
        const seconds = parseInt(secs % 60, 10);
        return { minutes, seconds };
    }

    getDurationText(item) {
        if(item.UseSpecifiedDuration) {
            const { minutes, seconds } = this.getDuration(item.Seconds);
            return `${minutes} ${textUtils.pluralise('min', minutes)} ${seconds} ${textUtils.pluralise('sec', seconds)}`
        }
        else {
            return 'until animation end';
        }
    }

    getDashboardImage(dashboard) {
        const image = dashboard ? dashboard.Thumbnail : null;
        const imageData = image ? imageUtils.getAvatarBase64Url(image) : require('../../images/dash-blank.png');
        return imageData;
    }

    render() {
        const {playlist, item, dashboards} = this.props;
        return (     
            <div className={`useritem ${this.props.selected ? 'useritem--selected' : ''}`} index={this.props.index} order={this.props.order} data-item-id={item.ID}>
                <div className="useritem__drag">
                    <i className="aheadicon-drag"></i>
                </div>
                <div className="useritem__check">
                    <div className="custom-control custom-checkbox">
                        <input type="checkbox" name="selected" className="custom-control-input" id={`user${this.props.index}`} checked={this.props.selected} onChange={(e) => this.props.toggleSelectedItem(e, item.ID)} />
                        <label className="custom-control-label" htmlFor={`user${this.props.index}`}>&nbsp;</label>
                    </div>
                </div>
                <div className="useritem__pic">
                    <img src={this.getDashboardImage(dashboards.find(d => d.ID === item.DashboardID))}  alt="Avatar"/>
                </div>
                <div className="useritem__title">
                    <h5>{item.Name}</h5>
                    <p className="useritem__email">{this.getDashboardDescription(item, dashboards.find(d => d.ID === item.DashboardID))}</p>
                </div>
                <div className="useritem__opts">
                    <p><span className="grey">Duration:</span> {this.getDurationText(item)}</p>
                </div>
                <div className="useritem__override">
                    <a href={null} className="btn-text" onClick={this.viewSettings}>Settings</a>
                </div>
                <div className="useritem__actions">
                    <a href={null} className="btn-action" onClick={this.updateItem}>
                        <i className="aheadicon-edit"></i>
                    </a>
                    <a href={null} className="btn-action btn-delete" onClick={this.deleteItem}>
                        <i className="aheadicon-delete"></i>
                    </a>
                </div>
            </div>
        );
    }
}

/*PlaylistSettingsDashboardItem.propTypes = {
    user: PropTypes.object,
    error: PropTypes.string,
    emailSent: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired
};*/

export default PlaylistSettingsDashboardItem;