/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';
import * as playlistActions from '../../actions/playlistActions';
import * as userActions from '../../actions/userActions';
import UserAccessItem from '../common/UserAccessItem';
import { withModalContext } from '../../services/modalService';
import AddUserModal from '../common/AddUserModal';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
const _ = require('lodash');

class PlaylistAccess extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getUserCount = this.getUserCount.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.addUser = this.addUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.toggleSelectedUser = this.toggleSelectedUser.bind(this);
        this.isSelected = this.isSelected.bind(this);
        this.deleteSelectedUsers = this.deleteSelectedUsers.bind(this);
        this.getPlaylistUserIDs = this.getPlaylistUserIDs.bind(this);
        this.state = {
            selectedUserIDs: []
        }
    }

    componentDidMount() {      
        if(!this.props.playlist) {
            //if we're browsing directly to this page, we might not have the playlist details
            this.props.actions.getPlaylists();
        }
        if(!this.props.users || !this.props.users.length) {
            this.props.actions.getUsers();
        }
    }   

    getUserCount() {
        if(this.props.playlist && this.props.users && this.props.users.length) {
            const userIDs = this.getPlaylistUserIDs();
            if(userIDs.length) {
                return `${userIDs.length} user${userIDs.length === 1 ? '' : 's'}`;
            }
        }
        return '0 users';
    }

    getPlaylistUserIDs() {
        let userIDs = [];
        if(this.props.playlist) {
            if (this.props.playlist.UserIDs) {
                userIDs = this.props.playlist.UserIDs;
            }
        }
        return userIDs;
    }

    getUsers() {
        const users = this.props.users;
        let userIDs = [];
        if(this.props.playlist && this.props.users && this.props.users.length) {
            userIDs = this.getPlaylistUserIDs();
        }
        if(userIDs && userIDs.length) {
            let playlistUsers = userIDs.map(userID =>
                {
                    const user = users.find(u => u.ID === userID);
                    return user;
                }
            );
            playlistUsers.sort((a, b) => {
                const aName = a.FirstName + ' ' + a.LastName;
                const bName = b.FirstName + ' ' + b.LastName;
                return aName - bName
            });
            return playlistUsers;
        }
        else {
            return [];
        }
    }

    addUser(event) {       
        debugger;
        event.preventDefault();
        const playlistUserIDs = this.getPlaylistUserIDs();
        const users = this.props.users.filter(u => u.ClientID === this.props.playlist.ClientID && !playlistUserIDs.find(du => du === u.ID) && u.Role !== 'Admin');
        const playlistID = this.props.playlist.ID;
        const addUserModal = <AddUserModal subject="playlist" playlistID={playlistID} users={users} addUser={(userID) => this.props.actions.addUser(playlistID, userID)} />
        this.props.showModal(() => addUserModal, { isOpen: true })
    }

    toggleSelectedUser(event, userID) {
        let selectedUserIDs = [...this.state.selectedUserIDs];
        if(!selectedUserIDs.find(u => u === userID)) {
            selectedUserIDs.push(userID);
        }
        else {
            _.pull(selectedUserIDs, userID);
        }
        this.setState({
            selectedUserIDs
        })
    }

    isSelected(userID) {
        return this.state.selectedUserIDs.find(u => u === userID) ? true : false;
    }


    deleteSelectedUsers(event) {
        event.preventDefault();
        if(this.state.selectedUserIDs.length) {
            const deleteAction = () => {
                this.props.actions.removeUsers(this.props.playlist.ID, this.state.selectedUserIDs)
                    .then(_ => this.setState({
                        selectedUserIDs: []
                    }));
            } 
            const deleteConfirmationModal = <DeleteConfirmationModal deleteAction={deleteAction} text={`Are you sure you want to remove the selected user${this.state.selectedUserIDs.length > 1 ? 's' : ''} from this playlist?`} />
            this.props.showModal(() => deleteConfirmationModal, { isOpen: true })
        }
    }

    deleteUser(userID) {
        this.props.actions.removeUser(this.props.playlist.ID, userID);
    }
 
    render() {
        const playlist = this.props.playlist;
        const users = this.getUsers();
        return (
            <div className={`main__body scroll ${this.props.loading ? 'spinner' : ''}`}>
            {
                !this.props.loading ?
                    <div className="pane pane--fixed pane--dashboards">
                        <Link to="/Playlists" className="btn-close"><i className="aheadicon-x"></i></Link>
                        <div className="pane__head">                    
                            <div className="pane__ctrl">
                                <div className="pane__info">
                                    <h5>Playlist Access: {playlist ? playlist.Name : ''}</h5>
                                    <p>{this.getUserCount()}</p>
                                </div>
                                <div className="pane__actions">
                                    {
                                        this.state.selectedUserIDs.length ?
                                            <button href={null} className="btn btn--secondary btn-multiaction btn-delete btn--withlasticon" onClick={this.deleteSelectedUsers}>Delete selected<i className="aheadicon-delete"></i></button>
                                        : null
                                    }
                                    <button href={null} className={`btn btn--primary btn-newuser btn--withlasticon ${this.state.selectedUserIDs.length ? 'hidden-xs' : ''}`} onClick={this.addUser}>New user<i className="aheadicon-plus"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="pane__body scrollbar">
                            <form className="form">
                                {
                                    users && users.length ?
                                        <div className="userslist userslist--dash">
                                        {
                                            users.map((user, i) =>
                                                <UserAccessItem key={user.ID} index={i} subject="playlist" id={user.ID} playlistID={playlist.ID} email={user.Email} name={`${user.FirstName} ${user.LastName}`} avatar={user.Avatar} showModal={this.props.showModal} removeUser={this.deleteUser} toggleSelectedUser={this.toggleSelectedUser} selected={this.isSelected(user.ID)} />
                                            )
                                        }
                                        </div>
                                        :
                                        <div className="empty">
                                            <img className="empty__picto" src={require("../../images/empty-dashboard-access.png")} alt="No users with access to this playlist"/>
                                            <p>There are currently no users with access to this playlist. Add users by clicking on the New user button.</p>
                                        </div>
                                }
                                
                            </form>
                        </div>
                    </div> 
                    : null
                }
            </div>
        );
    }
}

PlaylistAccess.propTypes = {
    actions: PropTypes.object.isRequired,
    playlist: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const playlistID = ownProps.match.params.id;
    return { 
        playlist: state.playlist.playlists.find(p => p.ID === playlistID), 
        loading: state.playlist.playlistsLoading || state.user.usersLoading, 
        users: state.user.users 
    };
}

function mapDispatchToProps(dispatch) {
    return {
        //playlist actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, playlistActions, userActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(PlaylistAccess));