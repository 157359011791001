import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import roleTypes from '../../constants/roleTypes';
import { Link } from 'react-router-dom';
import { withModalContext } from '../../services/modalService';
import InfoModal from '../common/InfoModal';

const NewItemMenu = (props) => { 

    const showNewMenu = (event) => {
        event.preventDefault();
        window.$('.new').toggleClass('show');
        window.$(event.target).toggleClass('open');
    }

    const getMaxUsersModal = () => {
        return () => <InfoModal title="Maximum users reached" bodyText="You have reached the maximum number of users allowed for this account" />
    }

    const canCreateDashboard = false;
    const canCreatePlaylist = props.user.Role === roleTypes.Admin || props.user.Role === roleTypes.SuperUser;
    const canCreateUser = props.user.Role === roleTypes.Admin || props.user.Role === roleTypes.SuperUser;

    if(canCreateDashboard || canCreatePlaylist || canCreateUser) {
        return (
            <React.Fragment>
                <div className="main__actions">
                    {/*<div className="action">
                        <div className="btn-group btn-group--view">
                            <a href={null} className="btn active"><i className="aheadicon-thumbs"></i></a>
                            <a href={null} className="btn"><i className="aheadicon-list"></i></a>
                        </div>
                    </div>*/}
                    <div className="action">
                        <a href={null} className="btn btn--primary btn--withlasticon btn-new" onClick={showNewMenu}>+ New<i className="aheadicon-caret"></i></a>
                    </div>
                </div>
                
                <div className="new">
                    <p>Create new:</p>
                    <div className="new__blocks">
                        {
                            canCreateDashboard ?
                                <a href={null}>
                                    <i className="aheadicon-dashboards"></i>
                                    <h5>Dashboard</h5>
                                    <p>Data-driven competition</p>
                                </a>
                                : null
                        }
                        {
                            canCreatePlaylist ?
                            <Link to='/Playlists/Create'>
                                <i className="aheadicon-playlists"></i>
                                <h5>Playlist</h5>
                                <p>A sequence of dashboards</p>
                            </Link>
                            : null
                        }
                        {
                            canCreateUser ?
                                !props.maxUsersReached ?
                                    <Link to='/Users/Create'>
                                        <i className="aheadicon-users"></i>
                                        <h5>User</h5>
                                        <p>New dashboard user</p>
                                    </Link>
                                    : 
                                    <button onClick={() => props.showModal(getMaxUsersModal(), { isOpen: true })}>
                                        <i className="aheadicon-users"></i>
                                        <h5>User</h5>
                                        <p>New dashboard user</p>
                                    </button>
                            : null
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
    else {
        return null;
    }
};

NewItemMenu.propTypes = {
    user: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    const user = state.account.user;
    const client = user.Role === roleTypes.Admin ? state.client.clients.find(c => c.ID === user.ClientID) : null;
    return { 
        user,
        maxUsersReached: client && client.MaximumUserCount <= state.user.users.length
     };
}

export default withModalContext(connect(mapStateToProps)(NewItemMenu));