
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../../utils/imageUtils';
import * as widgetUtils from '../../../utils/widgetUtils';
import _ from 'lodash';
import ToastrService from '../../../services/toastrService';
import 'spectrum-colorpicker/spectrum.css'
import * as spectrum from 'spectrum-colorpicker';
import noUiSlider from "nouislider";
import "nouislider/distribute/nouislider.css";
import Label from '../templates/Widgets/controls/Label';
import Image from '../templates/Widgets/controls/Image';
import DropDownMultiSelect from '../templates/Widgets/controls/DropDownMultiSelect';
import DateRangePicker from '../templates/Widgets/controls/DateRangePicker';
import GaugeChart from '../templates/Widgets/controls/GaugeChart';
import BarLineChart from '../templates/Widgets/controls/BarLineChart';
import DatePicker from '../templates/Widgets/controls/DatePicker';
import GridItemNested from '../templates/Widgets/GridItemNested';
import DateRangeConfiguration from './DateRangeConfiguration';
import DropDown from '../templates/Widgets/controls/DropDown';
import {preDefinedDateRanges} from '../../../constants/widgetConstants';
import Grid from '../templates/Widgets/controls/Grid';

class EditWidgetModal extends React.Component {
    constructor(props, context) {
        super(props, context);      
        this.swatchRefs = [];
        this.initialiseColorPickers = this.initialiseColorPickers.bind(this);
        this.initialiseSlider = this.initialiseSlider.bind(this);
        this.getVariableValue = this.getVariableValue.bind(this);
        this.updateVariableValue = this.updateVariableValue.bind(this);
        this.updateWidgetValue = this.updateWidgetValue.bind(this);
        this.updateBinding = this.updateBinding.bind(this);
        this.onSlide = this.onSlide.bind(this);
        this.widgetNameKeyPress = this.widgetNameKeyPress.bind(this);
        this.toggleNameInlineEditing = this.toggleNameInlineEditing.bind(this);
        this.save = this.save.bind(this);
        this.getDefaultSelectedTab = this.getDefaultSelectedTab.bind(this);
        this.getTabContent = this.getTabContent.bind(this);
        this.getAdjustments = this.getAdjustments.bind(this);
        this.selectedInputUpdated = this.selectedInputUpdated.bind(this);
        this.initialiseTooltips = this.initialiseTooltips.bind(this);
        this.tooltipControls = [];
        this.state = {
            selectedTab: this.getDefaultSelectedTab(),
            selectedTask: null,
            selectedInput: this.props.widget && this.props.widget.Inputs.length ? this.props.widget.Inputs[0] : null,
            widgetSettings: this.props.widgetInstance,
            widgetSettingsParent: this.props.widgetInstanceParent,
            enableNameEditing: false,
            showDateRangeConfiguration: false
        };
        
    }

    componentWillMount() {
    }

    componentDidMount() { 
        this.initialiseColorPickers();
        this.initialiseSlider();      
        this.initialiseTooltips();
    } 
    
    componentWillUpdate(nextProps, nextState) {
        if(this.props.widget !== nextProps.widget || this.props.widgetInstance !== nextProps.widgetInstance || this.props.widgetInstanceParent !== nextProps.widgetInstanceParent) {
            const state = {...this.state};
            if(this.props.widget !== nextProps.widget || this.props.widgetInstance !== nextProps.widgetInstance) {
                state.selectedInput = nextProps.widget && nextProps.widget.Inputs.length ? nextProps.widget.Inputs[0] : null;
                state.selectedTab = this.getDefaultSelectedTab(nextProps);
            }
            if(this.props.widgetInstance !== nextProps.widgetInstance || this.props.widgetInstanceParent !== nextProps.widgetInstanceParent) {
                state.widgetSettings = nextProps.widgetInstance;
                state.widgetSettingsParent = nextProps.widgetInstanceParent;
            }
            this.setState(state)
        }
        if(this.state.selectedInput !== nextState.selectedInput || this.state.widgetSettings !== nextState.widgetSettings) {
            this.selectedInputUpdated(nextState);
        }
        this.swatchRefs = [];
    }

    componentDidUpdate(prevProps, prevState) {
        this.initialiseColorPickers();
        if(this.props.widget !== prevProps.widget || this.props.widgetInstance !== prevProps.widgetInstance || this.props.widgetInstanceParent !== prevProps.widgetInstanceParent || this.state.selectedTab !== prevState.selectedTab || this.props.tasks !== prevProps.tasks || this.state.widgetSettings !== prevState.widgetSettings ) {
            this.initialiseSlider();  
            this.initialiseTooltips();          
        }
    }

    initialiseTooltips() {
        const tooltipControls = window.$(this.tooltipControls.filter(t => t));
        if (tooltipControls.length !== 0 ) {
            tooltipControls.tooltip({boundary: 'viewport'});
            tooltipControls.on('click', function(e){
                e.preventDefault();
            });
        }  
    }

    selectedInputUpdated(state = this.state) {
        let selectedTask = null;
        if(state.widgetSettings) {
            if(state.selectedInput) {
                const binding = state.widgetSettings.FieldBindings.find(b => b.FieldName === state.selectedInput.Name);
                if(binding) {
                    selectedTask = this.props.tasks.find(t => t.ID === binding.TaskID);
                }
                this.setState({ selectedTask });
            }
            else if(this.props.widget && this.props.widget.Children) {
                //we're in a parent widget, we can only bind to an iteration, with the WidgetHierarchy empty
                const binding = state.widgetSettings.FieldBindings.find(b => !b.WidgetHierarchy.length);
                if(binding) {      
                    selectedTask = this.props.tasks.find(t => t.ID === binding.TaskID);  
                }
                this.setState({ selectedTask });
            }
        }
    }

    getDefaultSelectedTab(props = this.props) {
        if(props.widget && props.widget.Children) {
            return 'Layout';
        }
        else {
            return 'Visual Looks';
        }
    }

    initialiseColorPickers() {        
        const cPalette = [
            ["#20B99D", "#2ACD70", "#3597E0", "#965DAA", "#3B4657", "#2D3E4E", "#95A3A4"],
            ["#F2C216", "#E47F23", "#E44E3F", "#BCC4C7", "#7E8C8D", "#000", "#fff"]
        ];

        //-- Color Picker
        this.swatchRefs.forEach(swatch => {
            if(swatch) {
                const element = window.$(swatch);
                const colorInput = element.parents('.colorpick').find('input');

                const defaultColor = element[0].dataset.defaultColor;
                element.spectrum({
                    color: defaultColor,
                    showPaletteOnly: false,
                    showPalette: true,
                    showInput: true,
                    preferredFormat: "hex",
                    palette: cPalette,
                    allowEmpty:true,
                    showButtons: false,
                    appendTo: element.parent('.colorswatch'),
                    change: (color) => {        
                        const selectionName = element[0].dataset.selectionName;
                        this.updateVariableValue(null, { Name: selectionName }, () => color.toHexString())
                        element.spectrum("hide");
                        //colorInput.val(color);
                    }
                });
            }
        });
    }

    initialiseSlider() {
        const $ = window.$;
        $('.touchslide', $('#modal-edit-widget')).each((index, slider) => {   
            slider = $(slider); 
            //console.log(slider);    
            var sliderEl = slider.find('.touchslide__slider')[0],
                sliderUnit = slider.find('.touchslide__unit'),
                minValue = parseInt( slider.attr('data-min') ),
                maxValue = parseInt( slider.attr('data-max') ),
                initValue = parseInt( slider.attr('data-init') ),
                //sliderValue = slider.find('.touchslide__val'),
                sliderVal = slider.attr('data-val'),
                sliderUn = slider.attr('data-unit');
        
            sliderUnit.text(sliderUn);
            //sliderValue.text(initValue);
            
            if(!sliderEl.noUiSlider) {
                noUiSlider.create(sliderEl, {
                    start: [initValue],
                    connect: 'lower',
                    range: {
                      'min': minValue,
                      'max': maxValue
                    }
                });                  
                sliderEl.noUiSlider.on('slide', (values) => this.onSlide(values, slider));     
            }   
            else {
                //debugger;
            }            
          });
    }

    onSlide(values, slider) {
        const selectionName = slider.attr('data-selection-name');
        const unit = slider.attr('data-unit');        
        const sliderValue = slider.find('.touchslide__val');
        const slideValue = Math.floor(values[0]);
        sliderValue.text(slideValue);
        this.updateVariableValue(null, { Name: selectionName }, () => slideValue + unit)
    }

    getVariableValue(variable) {
        const adjustments = this.getAdjustments();// this.state.widgetSettingsParent ? this.state.widgetSettingsParent.Adjustments : this.state.widgetSettings.Adjustments;
        //if we're in a child widget, check that the adjustment is for the correct child
        const adjustment = adjustments.find(a => a.Name === variable.Name);// this.props.widgetInstanceParent ? adjustments.find(a => a.WidgetHierarchy.includes(this.state.widgetSettings.ID) && a.Name === variable.Name) : adjustments.find(a => a.Name === variable.Name);
        if(adjustment && adjustment.Enabled) {
            return adjustment.Override;
        }
        else {
            return variable.DefaultValue;
        }
    }

    updateWidgetValue(fieldName, value) {
        const widgetSettings = {...this.state.widgetSettings};
        widgetSettings[fieldName] = value;
        this.setState({ widgetSettings });
    }

    updateVariableValue(event, variable, getVal) {
        if(event && event.target.type !== 'checkbox') {
            event.preventDefault();
        }
        const widgetSettings = {...(this.state.widgetSettingsParent || this.state.widgetSettings)};
        widgetSettings.Adjustments = [...widgetSettings.Adjustments];
        let adjustment = this.state.widgetSettingsParent ? widgetSettings.Adjustments.find(a => a.WidgetHierarchy.includes(this.state.widgetSettings.ID) && a.Name === variable.Name) : widgetSettings.Adjustments.find(a => a.Name === variable.Name);
        let adjustmentIndex = -1;
        if(adjustment) {            
            adjustmentIndex = widgetSettings.Adjustments.indexOf(adjustment);
            adjustment = {...adjustment};
        }
        else {
            adjustment = { Name: variable.Name, Enabled: true };
            if(this.props.widgetInstanceParent) {
                adjustment.WidgetHierarchy = [this.state.widgetSettings.ID]
            }
        }
        adjustment.Override = getVal ? getVal(event) : event.target.value;
        if(adjustmentIndex === -1) {
            widgetSettings.Adjustments.push(adjustment);
        }
        else {
            widgetSettings.Adjustments.splice(adjustmentIndex, 1, adjustment);
        }
        this.setState(this.state.widgetSettingsParent ? { widgetSettingsParent: widgetSettings } : { widgetSettings });
    }

    getVariableMarkup(variable) {
        if(variable.Type && this.state.widgetSettings) {
            const type = variable.Type.toLowerCase().trim();
            if(type === 'string') {
                return <input type="text" 
                    className="form-control" 
                    value={this.getVariableValue(variable)} 
                    placeholder={`Enter ${variable.Name}`} 
                    onChange={(e) => this.updateVariableValue(e, variable)} />
            }
            else if(type === 'fontsize') {
                const getVal = (event) => event.target.innerText;
                return <div className="btn-group">
                    {['Small', 'Medium', 'Large'].map(val =>
                        <button key={val} className={`btn btn-default ${val === this.getVariableValue(variable) ? 'active' : ''}`} onClick={(e) => this.updateVariableValue(e, variable, getVal)}>{val}</button>
                    )}
                </div>
            }
            else if(type === 'boolean') {
                const getVal = (event) => event.target.checked;
                const checked = this.getVariableValue(variable);
                return <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" checked={checked} id={`customCheck-${variable.Name}`} onClick={(e) => this.updateVariableValue(e, variable, getVal)} />
                    <label className="custom-control-label" htmlFor={`customCheck-${variable.Name}`}>&nbsp;</label>
                </div>
            }
            else if(type === 'colour') {
                const colour = this.getVariableValue(variable) || '#ffffff';
                return <div className="colorpick">
                    <div className="colorpick__swatch" ref={(swatch) => this.swatchRefs.push(swatch)} data-selection-name={variable.Name} data-default-color={colour} style={{backgroundColor: colour}}></div>
                    <input type="text" className="form-control" value={colour} placeholder="#" onChange={(e) => this.updateVariableValue(e, variable)} />
                </div>
            }
            else if(type === 'dimension') {
                const value = this.getVariableValue(variable);
                const dimension = value ? value.replace('px', '') : '';
                return <div className="touchslide" data-min="0" data-max="300" data-init={dimension || '30'} data-unit="px" data-val="0" data-selection-name={variable.Name}>
                    <div className="touchslide__slider"></div>
                    <div className="touchslide__liveval"><span className="touchslide__val">{dimension}</span><span className="touchslide__unit">px</span></div>
                </div>
            }
            else if(type === 'imageshape') {
                const getVal = (event) => event.target.innerText;
                return <div className="btn-group">
                    {['Square', 'Round'].map(val =>
                        <button key={val} className={`btn btn-default ${val === this.getVariableValue(variable) ? 'active' : ''}`} onClick={(e) => this.updateVariableValue(e, variable, getVal)}>{val}</button>
                    )}
                </div>
            }
            else if(type === 'number') {
                return <input type="number" 
                    className="form-control" 
                    value={this.getVariableValue(variable)} 
                    placeholder={`Enter ${variable.Name}`} 
                    onChange={(e) => this.updateVariableValue(e, variable)} />
            }
            else if(type === 'imagesource') {
                return <input type="file" className="form-control" placeholder={`Select ${variable.Name}`}/>
            }
            else if(type === 'date') {
                const onDataChange = (widgetHierarchy, field, value) => this.updateVariableValue(null, variable, () => value);
                return <DatePicker onDataChange={onDataChange} />
            }
            else if(type === 'daterange') {
                const value = this.getVariableValue(variable);
                return (
                    <React.Fragment>
                        <div className="dateranges-selected">
                            {
                                value && value.length ?
                                    <ul className="small">
                                        {
                                            value.map(dateRange =>
                                                <li>
                                                {
                                                    dateRange.custom ? 
                                                        widgetUtils.getDateDisplay(dateRange) 
                                                        : 
                                                        preDefinedDateRanges.find(d => d.name === dateRange.value).displayText 
                                                }
                                                </li>    
                                            )
                                        }
                                    </ul>
                                    : null
                            }                            
                            <input type="button" onClick={() => this.setState({ showDateRangeConfiguration: true, dateRangeVariable: variable })} value={value && value.length ? 'Edit Date Ranges' : 'Configure Date Ranges'} className="btn btn--outline" />
                        </div>
                    </React.Fragment>
                )  
            }
        }
    }

    getAdjustments() {
        const adjustments = [...this.state.widgetSettings.Adjustments];
        if(this.state.widgetSettingsParent) {
            //we're in a child element, add the Adjustments from the parent to any adjustments on the child settings - these would have been taken from the original widget set-up
            this.state.widgetSettingsParent.Adjustments.filter(a => a.WidgetHierarchy.includes(this.state.widgetSettings.ID)).forEach(adjustment => {
                const originalAdjustment = adjustments.find(a => a.Name === adjustment.Name);
                if(originalAdjustment) {
                    const index = adjustments.indexOf(originalAdjustment);
                    adjustments[index] = adjustment;
                }
                else {
                    adjustments.push(adjustment);
                }
            });
        }
        return adjustments;
    }

    getPreviewElement(widget, resolvedTasks, widgetSettings) {
        //debugger;
        if(this.state.widgetSettingsParent) {
            const adjustments = this.getAdjustments();
            widgetSettings = {
                ...widgetSettings, 
                Adjustments: adjustments,
                FieldBindings: this.state.widgetSettingsParent.FieldBindings.filter(b => b.WidgetHierarchy.includes(widgetSettings.ID)),
            };
        }
        if(widget && resolvedTasks && widgetSettings) {
            const isEditable = true;
            if(widget.WidgetSource === 'System') {
                if(widget.Slug === 'label') {
                    return <Label widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                }
                else if(widget.Slug === 'image') {
                    return <Image widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                }
                else if(widget.Slug === 'dropdown-multi-select') {
                    return <DropDownMultiSelect widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                }
                else if(widget.Slug === 'dropdown') {
                    return <DropDown widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                }
                else if(widget.Slug === 'datepicker') {
                    return <DatePicker widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                }            
                else if(widget.Slug === 'daterangepicker') {
                    return <DateRangePicker widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                }
                else if(widget.Slug === 'gauge-chart') {
                    return (
                        <div style={{display: 'block', position: 'relative', width: '50%', height: '50%'}}>
                            <GaugeChart widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                        </div>
                    );
                }
                else if(widget.Slug === 'bar-chart') {
                    return (
                        <div style={{display: 'block', position: 'relative', width: '50%', height: '50%'}}>
                            <BarLineChart chartType="bar" widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                        </div>
                    ); 
                }
                else if(widget.Slug === 'line-chart') {
                    return (
                        <div style={{display: 'block', position: 'relative', width: '50%', height: '50%'}}>
                            <BarLineChart chartType="line" widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                        </div>
                    ); 
                }
                else if(widget.Slug === 'grid') {
                    return (
                        <div style={{display: 'block', position: 'relative', width: '75%', height: '50%'}}>
                            <Grid widget={widget} isEditable={isEditable} tasks={resolvedTasks} widgetSettings={widgetSettings} widgetHierarchy={[widgetSettings.ID]} />
                        </div>
                    ); 
                }
            }
            else if(widget.Children) {
                return <div>Preview unavailable, this is a layout widget</div>
            }
        }
    }
   
    updateBinding(task) {
        this.setState({selectedTask: task});
        const widgetSettings = {...(this.state.widgetSettingsParent || this.state.widgetSettings)};
        widgetSettings.FieldBindings = [...widgetSettings.FieldBindings];
        let binding;
        let bindingIndex = -1;
        if(this.props.widget && this.props.widget.Children) {
            //we're in a parent widget, we can only bind to an iteration, with the WidgetHierarchy empty
            binding = widgetSettings.FieldBindings.find(b => !b.WidgetHierarchy.length);
            if(binding) {            
                bindingIndex = widgetSettings.FieldBindings.indexOf(binding);
                binding = {...binding};
            }
            else {
                binding = { WidgetHierarchy: [] };
            }
        }
        else {
            binding = widgetSettings.FieldBindings.find(b => b.WidgetHierarchy.join(',') === this.state.widgetSettings.ID && b.FieldName === this.state.selectedInput.Name);
            if(binding) {            
                bindingIndex = widgetSettings.FieldBindings.indexOf(binding);
                binding = {...binding};
            }
            else {
                binding = { WidgetHierarchy: [this.state.widgetSettings.ID], FieldName: this.state.selectedInput.Name };
            }
        }
        if(binding.TaskID !== task.ID) {
            binding.TaskID = task.ID;
            if(bindingIndex === -1) {
                widgetSettings.FieldBindings.push(binding);
            }
            else {
                widgetSettings.FieldBindings.splice(bindingIndex, 1, binding);
            }
        }
        else {
            //remove the binding 
            widgetSettings.FieldBindings.splice(bindingIndex, 1);
        }
        this.setState(this.state.widgetSettingsParent ? { widgetSettingsParent: widgetSettings } : { widgetSettings });
    } 

    save(event) {
        event.preventDefault();
        this.props.saveWidget(this.state.widgetSettingsParent || this.state.widgetSettings);
    }

    toggleNameInlineEditing() {
        this.setState({ enableNameEditing: !this.state.enableNameEditing });
    }

    widgetNameKeyPress(event, id) {
        if (event.key === "Enter") {
            this.toggleNameInlineEditing();
        }
    }

    getTabContent() {
        if(this.state.selectedTab === 'Visual Looks') {
            return (
                <div className="tab-pane fade show active" id="style">                                        
                    <form className="form form--style">
                        {
                            this.state.showDateRangeConfiguration ? 
                                <DateRangeConfiguration 
                                    getVariableValue={this.getVariableValue} 
                                    variable={this.state.dateRangeVariable} 
                                    updateVariableValue={this.updateVariableValue} 
                                    close={() => this.setState({ showDateRangeConfiguration: false })} />
                            :
                            this.props.widget.CustomVariables.map(variable => 
                                <React.Fragment key={variable.Name}>
                                    <div className={`form-group mb-2 row ${variable.Type !== 'boolean' && variable.Type !== 'dimension' ? '' : 'align-items-center'}`}>
                                        <label className="col-sm-3 col-form-label text-right" data-toggle="tooltip" data-placement="top" ref={variable.HintText ? control => this.tooltipControls.push(control) : null} title={variable.HintText ? variable.HintText : ''}>{variable.Name}</label>
                                        <div className="col-sm-9">
                                            {
                                                this.getVariableMarkup(variable, this.props.widget)
                                            }                                                                    
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        }      
                    </form>
                </div>
            );
        }
        else if(this.state.selectedTab === 'Data') {
            return (
                <div className="tab-pane fade show active" id="data" role="tabpanel">     
                    <div className="d-flex data-panes">
                        <div className="data-panes__input">
                            <div className="inputs">
                                {
                                    this.props.widget ?
                                        this.props.widget.Inputs.map(input => 
                                            <a key={input.Name} href="#" className={input === this.state.selectedInput ? 'selected' : ''} onClick={(e) => this.setState({ selectedInput: input })} data-toggle="tooltip" data-placement="top" ref={input.HintText ? control => this.tooltipControls.push(control) : null} title={input.HintText ? input.HintText : ''}>{input.Name}</a>
                                        )
                                    : null
                                }                                                
                            </div>
                        </div>
                        <div className="data-panes__content">                      
    
                            {/* Attribute select */ }
                            <div className="steps mb-5">                    
                                {/* Step: dual */}
                                <div className="step__dual">                                        
                                    <div className="card card--step card--only">
                                        <div className="card-header py-3">
                                            <p className="m-0 text-muted small">Select a task to bind to</p>
                                        </div>
                                        <div className="card-body">                
                                            <div className="list-select scrollbar">                    
                                                {
                                                    this.props.tasks.map(task =>
                                                        <div key={task.ID} className={`list__item ${this.state.selectedTask === task ? 'list__item--selected' : ''}`} onClick={() => this.updateBinding(task)}>
                                                            <p className="list__name">{task.Name}</p>
                                                            <div className="checker">
                                                                <i className="aheadicon-checkmark"></i>
                                                            </div>
                                                        </div> 
                                                    )
                                                }  
                                            </div>                
                                        </div>
                                    </div>                                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div> 
            );
        }                                                        
        else if(this.state.selectedTab === 'Layout')  {
            const repetition = this.state.widgetSettings ? this.state.widgetSettings.RepeatDirection : null;
            const iterationTasks = this.props.tasks.filter(t => t.Variables.length && t.Variables[t.Variables.length -1].Type === 'Iteration');
            return (
                <React.Fragment>
                    <div className="form-group mb-2 row align-items-center">
                        <label className="col-sm-3 col-form-label text-right">Repetition</label>
                        <div className="col-sm-9">
                            <div className="btn-group">
                            {['None', 'Vertical', 'Horizontal'].map(val =>
                                <button key={val} className={`btn btn-default ${val === repetition ? 'active' : ''}`} onClick={(e) => this.updateWidgetValue('RepeatDirection', val)}>{val}</button>
                            )}
                            </div>                                                              
                        </div>
                    </div>
                    {
                        repetition !== 'None' ?
                            <div className="form-group mb-2 row">
                                <label className="col-sm-3 col-form-label text-right">Data</label>
                                <div className="col-sm-9">
                                    <div className="data-panes__content">  
                                        {/* Attribute select */ }
                                        <div className="steps mb-5">                    
                                            {/* Step: dual */}
                                            <div className="step__dual">                                        
                                                <div className="card card--step card--only">
                                                    <div className="card-header py-3">
                                                        <p className="m-0 text-muted small">Select an iteration task for the repetition</p>
                                                    </div>
                                                    <div className="card-body">                
                                                        <div className="list-select scrollbar" style={{height: 'auto', maxHeight: '300px'}}>                    
                                                            {
                                                                iterationTasks.map(task =>
                                                                    <div key={task.ID} className={`list__item ${this.state.selectedTask === task ? 'list__item--selected' : ''}`} onClick={() => this.updateBinding(task)}>
                                                                        <p className="list__name">{task.Name}</p>
                                                                        <div className="checker">
                                                                            <i className="aheadicon-checkmark"></i>
                                                                        </div>
                                                                    </div> 
                                                                )
                                                            }  
                                                        </div>                
                                                    </div>
                                                </div>                                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null
                    }
                    
                </React.Fragment>
            );
        }                                                       
    }

    render() {
        const { inputType } = this.props;
        const widgetName = this.state.widgetSettings ? this.state.widgetSettings.Name : '';
        this.tooltipControls = [];
        return (
            <div className="modal modal-side modal-side--full modal-edit-widget fade" role="dialog" id="modal-edit-widget">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit widget -&nbsp; 
                                {
                                    !this.state.enableNameEditing ?
                                        <span className={`${!this.state.widgetSettingsParent ? 'inline-edit inline-edit--icon' : ''}`} onClick={!this.state.widgetSettingsParent ? this.toggleNameInlineEditing : null}>{widgetName}</span>
                                    :   
                                        <input className="form-control form-control--inline" type="text" value={widgetName} onChange={(event) => this.updateWidgetValue('Name', event.target.value)} onBlur={this.toggleNameInlineEditing} onKeyPress={(event) => this.widgetNameKeyPress(event)} />
                                }
                            </h5>
                            <div className="modal-header-actions">
                                {
                                    !this.state.showDateRangeConfiguration ? <a href="#" className="btn btn--primary" onClick={this.save}>Save</a> : null
                                }
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">                            
                            {/* Widget editor */}
                            <div className="widget-editor">
                    
                                {/* Widget Preview */}
                                <div className="widget-preview">                    
                                    {this.getPreviewElement(this.props.widget, this.props.resolvedTasks, this.state.widgetSettings)}           
                                </div>
                
                                {/* Widget Options */}
                                <div className="widget-options">
                                    <div className="opblock">
                                        <h4>Block options</h4>                        
                                        <ul className="nav nav-tabs nav-tabs--simple" id="dataTab" role="tablist">
                                            {
                                                this.props.widget && this.props.widget.Children ?
                                                    <li className="nav-item">
                                                        <a className={`nav-link ${this.state.selectedTab === 'Layout' ? 'active' : ''}`} onClick={() => this.setState({ selectedTab: 'Layout' })}>Layout</a>
                                                    </li> 
                                                    :
                                                    <React.Fragment>
                                                        <li className="nav-item">
                                                            <a className={`nav-link ${this.state.selectedTab === 'Visual Looks' ? 'active' : ''}`} onClick={() => this.setState({ selectedTab: 'Visual Looks', showDateRangeConfiguration: false })}>Visual looks</a>
                                                        </li>     
                                                        {
                                                            !this.props.hideDataTab ? 
                                                                <li className="nav-item">
                                                                    <a className={`nav-link ${this.state.selectedTab === 'Data' ? 'active' : ''}`} onClick={() => this.setState({ selectedTab: 'Data' })}>Data</a>
                                                                </li>
                                                                : null
                                                        }                                                        
                                                    </React.Fragment>
                                            }  
                                        </ul>
                                        <div className="modal-body scrollbar">                         
                                        
                        
                                            {/* Steps list */}
                                            {
                                                this.props.widget ?
                                                    <div className="tab-content mt-4" id="dataTabContent">   
                                                        {
                                                            this.getTabContent()                                                       
                                                        }
                                                    </div>   
                                                    : null                                                    
                                            } 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default EditWidgetModal;