
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../../../utils/imageUtils';
import _ from 'lodash';
import 'url-search-params-polyfill';
import { withRouter } from 'react-router-dom';
import * as processUtils from '../../../../utils/processUtils';
import * as textUtils from '../../../../utils/textUtils';
//import Modal from '../../../common/Modal';

class Tournament extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getPlayerData = this.getPlayerData.bind(this);
        this.getPlayersForCurrentPage = this.getPlayersForCurrentPage.bind(this);
        this.startLiveTest = this.startLiveTest.bind(this);
        this.getDashboardID = this.getDashboardID.bind(this);
        this.formatValue = this.formatValue.bind(this);
        this.playerRefs = [];
        this.playerTimelines = [];
        this.playersPerPage = 10;
        this.initialDataProcessed = false;
        this.animatingPlayers = false;
        let testLiveMode = false;
        const searchParams = new URLSearchParams(props.location.search);
        const testLiveModeParam = searchParams.get('testLiveMode');
        if(testLiveModeParam) {
            testLiveMode = testLiveModeParam === 'true';
        }
        this.state = {
            isAnimating: false,
            finished: false,
            title: this.props.dashboard.Name,
            backgroundImage: null,
            logo: null,
            logoLarge: null,
            players: [],
            page: 1,
            testLiveMode,
            singlePageMode: false,
            processedData: null,
            listHeadHeight: 0,
            listItemHeight: 0
        }
        this.initialiseDashboard = this.initialiseDashboard.bind(this);
        this.initialisePlayers = this.initialisePlayers.bind(this);
        this.rotatePlayers = this.rotatePlayers.bind(this);
    }

    async componentDidMount() {    
        this._isMounted = true;
        this.initialiseDashboard();
        window.setTimeout(() => window.$(this.tournamentBackground).addClass('animate'), 100);
        window.$('body').addClass('scoreboard-tournament');
        this.backgroundAnimationInterval = window.setInterval(() =>
            window.$(this.tournamentBackground).toggleClass('animate'),
        60000);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const dashboardUpdated = this.getDashboardID() !== (nextProps.dashboard ? nextProps.dashboard.ID : nextProps.id) || this.props.dashboard !== nextProps.dashboard;
        const playersUpdated = this.state.players !== nextState.players;
        const pageUpdated = this.state.page !== nextState.page;
        const dataSet = !this.props.data && nextProps.data;
        let shouldProcessNewData = false;
        if(this.state.singlePageMode && this.props.data && nextProps.data && this.props.data !== nextProps.data && !this.animatingPlayers) {
            //for the single player mode, if the data has changed and we're not currently animating the rows in, trigger an update so we can pick up the new data
            shouldProcessNewData = true;
        }
        let listHeightSet = nextState.listHeadHeight !== this.state.listHeadHeight || nextState.listItemHeight !== this.state.listItemHeight;
        return dashboardUpdated || playersUpdated || pageUpdated || dataSet || shouldProcessNewData || listHeightSet;
    }

    componentWillUpdate(nextProps, nextState) {
        if(this.props.dashboard.ID !== nextProps.dashboard.ID) {            
            this.setState({               
                isAnimating: false,
                finished: false,
                title: nextProps.dashboard.Name,
                backgroundImage: null,
                logo: null,
                logoLarge: null,
                players: [],
                page: 1,
                singlePageMode: false,
                processedData: null,
                listHeadHeight: 0,
                listItemHeight: 0
            });
            this.playerRefs = [];
            this.playerTimelines = [];
            this.playersPerPage = 10;
            this.initialDataProcessed = false;
            this.animatingPlayers = false;
            
        }
        else if(nextProps.dashboard) {
            const singlePageMode = nextProps.dashboard.Players.length <= this.playersPerPage;
            if(singlePageMode !== this.state.singlePageMode) {
                this.setState({
                    singlePageMode
                });
            }
        }
        //reset this array as it will be populated again in the render
        this.playerRefs = [];
        if(this.props.data === null && nextProps.data !== null) {
            this.initialDataProcessed = false;
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if(prevProps.dashboard.ID !== this.props.dashboard.ID) {            
            //reset these values
            window.$('.list-scores')
                .css('height', '')
                .removeClass('animatable');  
            
            this.initialiseDashboard();          
        }
        if(prevProps.data === null && this.props.data !== null) {
            this.initialisePlayers();
        }
        if(this.state.singlePageMode !== prevState.singlePageMode) {
            //do some initialisation?
            this.initialDataProcessed = false;
            this.initialisePlayers();
        }
        if(!this.state.singlePageMode && this.state.page !== prevState.page) {
            if(this.state.page === 1) {
                //we're back at page 1, reload the data, this will call the animatePlayers function when it completes
                this.initialisePlayers();
            }
            else {
                this.animatePlayers();
            }
        }
        else if(this.state.singlePageMode && this.props.data && prevProps.data && (this.props.data !== prevProps.data || this.props.dashboard != prevProps.dashboard || this.props.users != prevProps.users) && !this.animatingPlayers) {
            this.initialisePlayers();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.$('body').removeClass('scoreboard-tournament');
        if(this.backgroundAnimationInterval) {
            window.clearInterval(this.backgroundAnimationInterval);
        }
    }

    async rotatePlayers() {
        if(this.state.players.length) {
            const dashboardID = this.getDashboardID();
            let haveMorePages = this.state.players.length > this.state.page * this.playersPerPage;
            if(haveMorePages) {
                while(dashboardID === this.getDashboardID() && this._isMounted) {
                    await processUtils.sleep(15000);
                    if(this._isMounted) {
                        const newPage = haveMorePages ? this.state.page + 1 : 1;
                        const rotationFinished = newPage === 1;
                        if(rotationFinished && this.props.onAnimationFinished) {
                            this.props.onAnimationFinished();
                            break;
                        }
                        else {
                            this.setState({
                                page: newPage
                            });
                        }
                        haveMorePages = this.state.players.length > newPage * this.playersPerPage;
                    }
                }
            }
            else {
                if(this.props.onAnimationFinished) {
                    await processUtils.sleep(15000);
                    if(this._isMounted) {
                        this.props.onAnimationFinished();
                    }
                }
            }
        }
    }

    async animatePlayers() {
        this.animatingPlayers = true;
        const data = this.props.data;
        const playerRefs = this.playerRefs.filter(p => p);
        if(playerRefs.length) {
            window.$(playerRefs).each((index, playerRef) => {
                playerRef = window.$(playerRef);
                setTimeout(async () => {
                    playerRef.addClass('active');
                    if(index === playerRefs.length - 1) {
                        this.animatingPlayers = false;
                        if(this.state.singlePageMode) {
                            //fix the table height, this will allow us to later animate changes to the row positions
                            const listLength = playerRefs.length + 1; //add the header
                            const listHeadHeight = parseInt(window.$('#listHeader').outerHeight(), 10);
                            const listItemHeight = parseInt(window.$(playerRefs[0]).outerHeight(), 10);
                            const tableHeight = listHeadHeight + 2 + ((listLength - 1) * (listItemHeight + 2));
                            window.$('.list-scores')
                                .css('height', tableHeight)
                                .addClass('animatable');
                            this.setState({
                                listHeadHeight,
                                listItemHeight
                            });
                            if(this.props.data !== data) {
                                //the data changed while we were animating, process the data now
                                this.initialisePlayers();
                            }
                            //if we're in a playlist, wait 15 seconds and then call the finished function so we can move to the next dashboard
                            if(this.props.onAnimationFinished) {
                                await processUtils.sleep(15000);
                                if(this._isMounted) {
                                    this.props.onAnimationFinished();
                                }
                            }
                        }
                    }
                }, 300 * index);
            });
        }
    }

    async startLiveTest() {
        const currentDashboardID = this.getDashboardID();
        while(true && currentDashboardID === this.getDashboardID()) {
            await processUtils.sleep(5000);
            if(!this._isMounted){ return };
            const data = [...this.props.data];
            for(let i = 0; i < data.length; i++) {
                let dataItem = data[i];
                let updated = false;
                const val = dataItem.current;
                const random = Math.random();
                let randomMultiplier = random * 0.4; //we'll get a random number between 0 and 1 and then cap it to 0.4 by multiplying by 0.4
                if(random <= 0.35) {
                    //increase the value 35% of the time
                    randomMultiplier = 1 + randomMultiplier;
                    updated = true;
                }
                else if(random >= 0.90) {
                    //decrease the value 10% of the time
                    randomMultiplier = 1 - randomMultiplier;
                    updated = true;
                }
                else {
                    //do nothing 55% of the time
                }
                if(updated) {
                    dataItem = {...dataItem};
                    dataItem.current = (val || 1) * randomMultiplier;
                    data[i] = dataItem;
                }
            }
            //make sure we haven't switched to a different dashboard
            if(currentDashboardID === this.props.dashboard.ID)  {
                this.props.actions.updateDashboardData(currentDashboardID, null, data);
            }
            await processUtils.sleep(5000);
        }
    }

    getDashboardID() {
        //if we're in a playlist, then we'll pass the dashboard in as a prop
        return this.props.dashboard ? this.props.dashboard.ID : this.props.id;
    }
    
    initialiseDashboard() {
        const dashboard = this.props.dashboard;
        let dashboardData = {};
        //initialise using the template fields and then override with the adjustments
        dashboard.Template.GameVariables.forEach(variable => {
            dashboardData[variable.Name] = variable.DefaultValue;
        });
        dashboard.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
            if(adjustment.Append) {
                dashboardData[adjustment.Name] += parseFloat(adjustment.Append);
            }
            else {
                dashboardData[adjustment.Name] = adjustment.Override;
            }
        });
        const players = dashboard.Players.map(player => {
            let userAvatar = null;
            if(player.UserID) {
                const user = this.props.users.find(u => u.ID === player.UserID);
                if(user) {
                    userAvatar = user.Avatar;
                }
            }
            return {
                label: player.Label,
                id: player.ID,
                userID: player.UserID,
                clientUserID: player.ClientUserID,
                userAvatar,
                percentage: 0,
                rawPercentage: 0,
                movement: 0
            };
        });
        dashboardData['players'] = players;
        this.setState(dashboardData, this.initialisePlayers);
    }
    
    initialisePlayers() {
        if(this.props.data) {
            const playerData = this.getPlayerData();
            if(playerData.length) {
                let players = [...this.state.players];
                for(let i = 0; i < playerData.length; i++) {
                    const playerDataItem = playerData[i];
                    let percentage = 0;
                    let rawPercentage = 0;
                    if(Object.keys(playerDataItem).includes('percentage')) {
                        //if we have it in the data or as a player game variable, get it from there
                        percentage = playerDataItem.percentage;
                        rawPercentage = percentage;
                    }
                    else {
                        if(playerDataItem.current && playerDataItem.target) {
                            percentage = this.getPercentage(playerDataItem.current, playerDataItem.target);
                            rawPercentage = (playerDataItem.current / playerDataItem.target) * 100;
                        }
                    }
                    const playerDataItemCalculated = {
                        id: playerDataItem.id,
                        percentage,
                        rawPercentage,
                        department: playerDataItem.department,
                        current: playerDataItem.current,
                        target: playerDataItem.target
                    };                    

                    const originalPlayer = players.find(p => p.clientUserID === playerDataItem.id);
                    if(originalPlayer) {
                        const playerIndex = _.indexOf(players, originalPlayer);
                        const percentage = playerDataItemCalculated.percentage;
                        const rawPercentage = playerDataItemCalculated.rawPercentage;
                        const department = playerDataItemCalculated.department;           
                        let label = originalPlayer.label;    
                        let userID = originalPlayer.userID;
                        const newPlayer = this.props.dashboard.Players.find(p => p.ID === originalPlayer.id);
                        if(newPlayer) {
                            label = newPlayer.Label;
                            userID = newPlayer.UserID;                            
                        }   
                        let userAvatar = null;
                        if(userID) {
                            const user = this.props.users.find(u => u.ID === userID);
                            if(user) {
                                userAvatar = user.Avatar;
                            }
                        }          
                        let updatedPlayer = Object.assign({}, originalPlayer, { percentage, rawPercentage, current: playerDataItemCalculated.current, target: playerDataItemCalculated.target, department, label, userAvatar });
                        players.splice(playerIndex, 1, updatedPlayer);
                    }
                }
                if(this.state.defaultSort === 'current') {
                    players.sort((a, b) => {
                        let val = (b.current || 0) - (a.current || 0);
                        if(val === 0) {
                            if(this.state.secondarySort === 'percentage') {
                                val = (b.rawPercentage || 0) - (a.rawPercentage || 0);
                            }
                        }
                        return val;
                    });
                }
                else {
                    players.sort((a, b) => {
                        let val = (b.rawPercentage || 0) - (a.rawPercentage || 0);
                        if(val === 0) {
                            if(this.state.secondarySort === 'current') {
                                val = (b.current || 0) - (a.current || 0);
                            }
                        }
                        return val;
                    });
                }
                const previousPlayers = this.state.players;
                //add the positions and movement
                players = players.map((player, index) => {
                    const position = index + 1;
                    let movement = 0;
                    const previousPlayer = previousPlayers.find(p => p.id === player.id);
                    if(previousPlayer && previousPlayer.hasOwnProperty('position')) {
                        movement = position - previousPlayer.position;
                    }
                    return Object.assign({}, player, { position }, { movement });
                });

                this.setState({ players, processedData: this.props.data }, () => {
                    if(!this.initialDataProcessed || !this.state.singlePageMode) {
                        //animate on every multi page load, or on the 1st single page load
                        this.animatePlayers();
                    }
                    if(!this.initialDataProcessed) {
                        if(!this.state.singlePageMode) {
                            this.rotatePlayers();
                        }
                        if(this.state.testLiveMode) {
                            this.startLiveTest();
                        }
                        this.initialDataProcessed = true;
                    }
                });
            }
        }
    }

    getPlayersForCurrentPage() {
        const page = this.state.page;
        const playersPerPage = this.playersPerPage;
        const playerStart = (page - 1) * playersPerPage;
        const playerEnd = playerStart + playersPerPage;
        let players = this.state.players.slice(playerStart, playerEnd);
        if(this.state.singlePageMode && this.state.listHeadHeight && this.state.listItemHeight) {
            let y = this.state.listHeadHeight + 2;
            for(let i = 0; i < players.length; i++) {
                players[i] = Object.assign({}, players[i], { top: y });
                y += this.state.listItemHeight + 2;
            }   
        }
        return players;
    }

    getPercentage(current, target) {
        let percentage = target === 0 ? 0 : (current / target) * 100;
        if(percentage < 0) {
            percentage = 0;
        }
        if(percentage > 100) {
            percentage = 100;
        }
        return percentage;
    }

    getPlayerData() {
        const playerData = [];
        if(this.props.data && this.props.dashboard) {
            const playerGameVariableTypes = {};
            this.props.dashboard.Template.PlayerGameVariables.forEach(variable => playerGameVariableTypes[variable.Name] = variable.Type);
            for(let i = 0; i < this.props.dashboard.Players.length; i++) {
                const player = this.props.dashboard.Players[i];
                let playerDashboardData = {};
                if(player.ClientUserID) {
                    playerDashboardData = Object.assign({}, this.props.data.find(d => d.id + '' === player.ClientUserID));
                }
                player.Adjustments.forEach(adjustment => {
                    if(adjustment.Append) {
                        playerDashboardData[adjustment.Name] += parseFloat(adjustment.Append);
                    }
                    else {
                        let override = adjustment.Override;
                        let type = playerGameVariableTypes[adjustment.Name];
                        if(type) {
                            if(type.toLowerCase() === 'integer') {
                                override = parseInt(override, 10);
                            }
                            else if(type.toLowerCase() === 'double') {
                                override = parseFloat(override);
                            }
                        }
                        playerDashboardData[adjustment.Name] = override;
                    }
                });
                playerData.push(playerDashboardData);
            }
        }
        return playerData;
    }

    getBase64Url(rawString) {
        if(rawString) {
            return imageUtils.getBase64Url(rawString);
        }
        else {
            return "";
        }
    }

    getUserImage(avatar) {
        const imageData = avatar ? imageUtils.getAvatarBase64Url(avatar) : require('../../../../images/avatar-empty.png');
        return imageData;
    }

    getScoreClass(percentage) {
        if(percentage >= 75) {
            return 'list-scores__perc--high'
        }
        else if(percentage >= 50) {
            return 'list-scores__perc--med'
        }
        else if(percentage >= 25) {
            return 'list-scores__perc--low'
        }
        else {
            return 'list-scores__perc--lowest'
        }
    }

    getMovementClass(movement) {
        //if the movement is negative, then we've moved up in position, e.g. from position 2 to 1
        if(movement < 0) {
            return 'aheadicon-arrow--up';
        }
        else if(movement > 0) {
            return 'aheadicon-arrow--dn';
        }
        else {
            return '';
        }
    }

    formatValue(value) {
        if(this.state.valueFormat === "callTime") {
            return value ? textUtils.formatTime(value) : '-';
        }
        else {
            //default to currency
            return `${this.state.currencySymbol}${_.round((parseFloat(value) || 0), 2).toLocaleString()}`;
        }
    }

    render() {
        const players = this.getPlayersForCurrentPage();
        return (
            <div className="modal-tournamentscore">
                <Link to={this.props.playlistID ? '/Playlists' : '/Dashboards'} className="btn btn-back btn--withicon">
                    <i className="aheadicon-arrow"></i>
                    Back
                </Link>
                <div className="modal-scoreboard-container">
                    <div className="modal-scoreboard-content">
                        <div className="modal-body nopadding">
                            <div className="scoreboard scoreboard--tour">
                                <div className="scoreboard__head">
                                    <div className="scoreboard__logo">
                                        <img src={this.getBase64Url(this.state.logo)} alt={this.state.title}/>
                                    </div>
                                    <h2>{this.state.title}</h2>
                                    <div className="scoreboard__aheadlogo">
                                        <img src={require('../../../../images/logo-ahead-of-the-game-wh-tr.png')} alt="Ahead of the game"/>
                                    </div>
                                </div>
                                <div className="scoreboard__body">
                                    <ul className="list-unstyled list-scores list-scores--altanim">
                                        <li id="listHeader" className="list-scores__head active">
                                            <p className="no"></p>
                                            {
                                                this.state.showAvatar ?
                                                    <div className="avatar"></div>
                                                    : null
                                            }                                            
                                            {
                                                this.state.showName ? 
                                                    <p className="list-scores__name">Name</p>
                                                    : null
                                            }
                                            {
                                                this.state.showDepartment ?
                                                    <p className="list-scores__dept">Department</p>
                                                    : null
                                            }
                                            {
                                                this.state.showValue ?
                                                    <p className="list-scores__val">{this.state.valueLabel || 'Value'}</p>
                                                    : null
                                            }
                                            {
                                                this.state.showTarget ?
                                                    <p className="list-scores__target">{this.state.targetLabel || 'Target'}</p>
                                                    : null
                                            }
                                            {
                                                this.state.showPercentage ?
                                                    <p className="list-scores__perc">{this.state.percentageLabel || 'Perc'}</p>
                                                    : null
                                            }
                                            {
                                                this.state.showTrend ?
                                                    <p className="list-scores__trend"></p>
                                                    : null
                                            }                                            
                                        </li>
                                        {
                                            players.map(player => 
                                                <li key={player.id} className="list-scores__item" ref={(control) => this.playerRefs.push(control)} style={player.top ? { top: player.top + 'px'} : null }>
                                                    <p className="no">{player.position}</p>
                                                    {
                                                        this.state.showAvatar ?
                                                            <div className="avatar">
                                                                <img src={this.getUserImage(player.userAvatar)} alt="Avatar"/>
                                                            </div>
                                                            : null
                                                    }
                                                    {
                                                        this.state.showName ?
                                                            <p className="list-scores__name">{player.label}
                                                                <span className="list-scores__sec">
                                                                    {player.department || '-'}
                                                                </span>
                                                            </p>
                                                            : null
                                                    }
                                                    {
                                                        this.state.showDepartment ?
                                                            <p className="list-scores__dept">{player.department || '-'}</p>
                                                            : null
                                                    }
                                                    {
                                                        this.state.showValue ?
                                                            <p className="list-scores__val">{this.formatValue(player.current)}</p>
                                                            : null
                                                    }
                                                    {
                                                        this.state.showTarget ?
                                                            <p className="list-scores__target">{this.formatValue(player.target)}</p>
                                                            : null
                                                    }
                                                    {
                                                        this.state.showPercentage ?                                                            
                                                            this.state.formatPercentage ?
                                                                <p className={`list-scores__perc ${this.getScoreClass(player.percentage)}`}>{Math.round(player.percentage || 0)}<span className="small">%</span></p>
                                                                :
                                                                <p className="list-scores__perc">{Math.round(player.percentage || 0)}</p>                                                                
                                                            : null
                                                    }
                                                    {
                                                        this.state.showTrend ?
                                                            <p className="list-scores__trend">
                                                                <i className={`aheadicon-arrow ${this.getMovementClass(player.movement)}`}></i>
                                                            </p>
                                                            : null
                                                    }                                                    
                                                </li>
                                            )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={(control) => this.tournamentBackground = control} className="scoreboard-tournament-bg" style={{backgroundImage: `url(${this.getBase64Url(this.state.backgroundImage)})`}}></div>
            </div>
        );
    }
}

Tournament.propTypes = {
    dashboard: PropTypes.object,
    users: PropTypes.array,
    data: PropTypes.array
};

export default withRouter(Tournament);