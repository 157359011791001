import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as userActions from '../../actions/userActions';
import validator from 'validator';
import CropModal from '../common/CropModal';
import InfoModal from '../common/InfoModal';
import * as imageUtils from '../../utils/imageUtils';
import { withModalContext } from '../../services/modalService';
import roleTypes from '../../constants/roleTypes';

class CreateUpdateUser extends React.Component {
    constructor(props, context) {
        debugger;
        super(props, context);
        this.createUser = this.createUser.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.formIsValid = this.formIsValid.bind(this);
        this.getExistingUserDefaultState = this.getExistingUserDefaultState.bind(this);
        this.getNewUserDefaultState = this.getNewUserDefaultState.bind(this);
        this.previewImage = this.previewImage.bind(this);
        this.imageCropped = this.imageCropped.bind(this);
        this.getCreateUserEnabled = this.getCreateUserEnabled.bind(this);
        this.showMaxUsersModal = this.showMaxUsersModal.bind(this);
        const roles = [];
        roles.push(roleTypes.Viewer);
        roles.push(roleTypes.Manager);
        roles.push(roleTypes.Admin);
        if(this.props.myRole === roleTypes.SuperUser) {
            roles.push(roleTypes.SuperUser);
        }
        if(this.props.user) {
            this.state = this.getExistingUserDefaultState(this.props, roles);
        }
        else {
            this.state = this.getNewUserDefaultState(this.props, roles);
        }
    }

    componentDidMount() {     
        this.props.actions.getUsers();
        if(!this.getCreateUserEnabled()) {
            this.showMaxUsersModal();
        }
    }

    componentWillUpdate(nextProps) {
        if(nextProps.user && !this.props.user) {
            this.setState(this.getExistingUserDefaultState(nextProps));
        }
        else if(!nextProps.user && this.props.user) {
            this.setState(this.getNewUserDefaultState(nextProps));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.user && !this.props.user) {
            //we've reloaded the page to create a new user, load the user list.
            //we need the user list for new users as otherwise when we create them and redirect 
            //to the /users endpoint, it will only have the single user present
            debugger;
            this.props.actions.getUsers();
        }
        if(!this.props.user && (prevState.clientID !== this.state.clientID || prevProps.clients.length !== this.props.clients.length) && this.state.clientID) {
            //we're creating a new user and have updated the client, check if we're allowed to create users for this client
            if(!this.getCreateUserEnabled()) {
                this.showMaxUsersModal();
            }
        }
    }

    getNewUserDefaultState(props, roles = this.state.roles) {
        return { 
            email: '',
            password: '',
            fullName: '',
            role: roleTypes.Viewer,
            clientUserID: '',
            clientID: props.myClientID || '',
            emailAlertsEnabled: false,
            roles
         };
    }

    getExistingUserDefaultState(props, roles = this.state.roles) {
        return { 
            email: props.user.Email,
            fullName: `${props.user.FirstName} ${props.user.LastName}`,
            role: props.user.Role,
            clientUserID: props.user.ClientUserID || '',
            avatar: props.user.Avatar ? imageUtils.getAvatarBase64Url(props.user.Avatar) : '',
            emailAlertsEnabled: props.user.EmailAlertsEnabled,
            roles
         };
    }

    createUser(event) {
        debugger;
        event.preventDefault();
        if(this.formIsValid()) {
            const nameArr = this.state.fullName.split(' ');
            const firstName = nameArr[0];
            const lastName = nameArr[1];
            this.props.actions.createUser(this.state.email, this.state.password, firstName, lastName, this.state.role, this.state.clientID, this.state.clientUserID, this.state.emailAlertsEnabled);
        }        
    }

    saveUser(event) {
        debugger;
        event.preventDefault();
        if(this.formIsValid()) {
            const nameArr = this.state.fullName.split(' ');
            const firstName = nameArr[0];
            const lastName = nameArr[1];
            const avatar = this.state.avatar ? this.state.avatar.split(',')[1] : null;
            this.props.actions.updateUser(this.props.user.ID, this.state.email, firstName, lastName, this.state.role, this.state.clientUserID, avatar, this.state.emailAlertsEnabled);
        }        
    }

    getCreateUserEnabled() {
        let createUserEnabled = true;
        if(!this.props.user) {
            //we're creating a user
            if(this.state.clientID) {
                debugger;
                const client = this.props.clients.find(c => c.ID === this.state.clientID);
                const userCount = this.props.users.filter(u => u.ClientID === this.state.clientID).length;
                if(client && userCount >= client.MaximumUserCount) {
                    createUserEnabled = false;
                }
            }
        }
        return createUserEnabled
    }

    showMaxUsersModal() {
        const bodyText = this.props.myRole === roleTypes.SuperUser ? "This client has reached the maximum number of users allowed" : "You have reached the maximum number of users allowed for this account";
        const maxUsersModal = <InfoModal title="Maximum users reached" bodyText={bodyText} />
        this.props.showModal(() => maxUsersModal, { isOpen: true });
    }

    formIsValid() {
        const state = this.state;
        if(!validator.isEmail(state.email)) {
            this.props.actions.setCreateUpdateUserError('The email address is missing or in an invalid format');
            return false;
        }
        else if(validator.isEmpty(state.fullName)) {
            this.props.actions.setCreateUpdateUserError('The name is missing');
            return false;
        }
        else if(validator.isEmpty(state.role)) {
            this.props.actions.setCreateUpdateUserError('The role is missing');
            return false;
        }
        else if(!this.props.user && validator.isEmpty(state.password)) {
            this.props.actions.setCreateUpdateUserError('The password is missing');
            return false;
        }
        //if i'm not creating a super user, the client ID is required
        else if(!this.props.user && state.role !== roleTypes.SuperUser && validator.isEmpty(state.clientID)) {
            this.props.actions.setCreateUpdateUserError('The client is missing');
            return false;
        }
        return true;
    }

    onChange(event) {
        const field = event.target.name;
        if(field === 'avatar') {
            this.props.actions.clearCreateUpdateUserError();
            if(event.target.files.length) {
                imageUtils.readImage(event, this.previewImage, this.props.actions.setCreateUpdateUserError, 2);
            }
            else {
                this.setState({
                    avatar: ''
                });
            }              
        }
        else if(field === 'emailAlertsEnabled') {
            const checked = event.target.checked;
            this.setState({
                emailAlertsEnabled: checked
            });
        }
        else {
            const value = event.target.value;
            let newState = Object.assign({}, this.state);
            newState[field] = value;
            if(field === 'role' && value === roleTypes.SuperUser) {
                newState['clientID'] = '';
            }
            return this.setState(newState);
        }       
    }

    previewImage(upload) {
        this.setState({
            avatar: upload.target.result
        }, () => {
            const cropImageModal = <CropModal imageCropped={this.imageCropped} avatar={this.state.avatar} isRounded={true} />
            this.props.showModal(() => cropImageModal, { isOpen: true })
        });
    };

    imageCropped(event, pixelCrop) {
        event.preventDefault();
        window.$('#modal').modal('hide');
        const image = window.$('#imgAvatar')[0];
        const croppedImage = imageUtils.getCroppedImage(image, pixelCrop)
        this.setState({avatar: croppedImage});
    }

    render() {
        return (     
            <div className="main__body scroll">
                {
                    !this.props.match.params.id || this.props.user ?
                        <div className="pane pane--fixed pane--user">
                            <Link to="/Users" className="btn-close"><i className="aheadicon-x"></i></Link>
                            <div className="pane__head">
                                <h5>{this.props.user ? 'Edit User' : 'Add User'}</h5>
                            </div>
                            <div className="pane__body scroll">
                                <div className="pane__inner">
                                    <form className="form" onSubmit={this.props.user ? this.saveUser : this.createUser}>
                                        {
                                            this.props.user ?
                                            <div className="form-group">
                                                <div className={`upload-avatar ${this.state.avatar ? 'upload-avatar--uploaded' : ''}`}>
                                                    <div className="avatar">
                                                        {
                                                            this.state.avatar ?
                                                                <img id="imgAvatar" src={this.state.avatar} alt="avatar" />
                                                                : 
                                                                <i className="aheadicon-plus"></i>
                                                        }                                        
                                                    </div>
                                                    <input type="file" name="avatar" accept="image/*" onChange={this.onChange} />
                                                    <p>Upload avatar</p>
                                                </div>
                                            </div> 
                                            : null
                                        }
                                        <div className="form-group">
                                            <label>Full name</label>
                                            <input type="text" required="required" className="form-control" name="fullName" value={this.state.fullName} onChange={this.onChange} placeholder="Enter full name" />
                                            <p className="form__error">This input is mandatory</p>
                                        </div>                
                                        <div className="form-group">
                                            <label>Email</label>
                                            <input type="email" required="required" className="form-control" name="email" value={this.state.email} onChange={this.onChange} placeholder="Enter email address" />
                                        </div>
                                        {
                                            !this.props.user ?
                                                <div className="form-group">
                                                    <label>Password</label>
                                                    <input name="password" type="password" required="required" className="form-control" value={this.state.password} onChange={this.onChange} placeholder="Enter password" />
                                                </div>
                                                : null
                                        }
                                        <div className="form-group">
                                            <label>User ID</label>
                                            <input type="text" className="form-control" name="clientUserID" value={this.state.clientUserID} onChange={this.onChange} placeholder="Enter user ID" />
                                        </div>
                                        <div className="form-group">
                                            <label>Role</label>
                                            <select className="form-control" name="role" onChange={this.onChange} value={this.state.role}>
                                                {
                                                    this.state.roles.map(r => 
                                                        <option key={r}>{r}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        {
                                            !this.props.user && this.props.myRole === roleTypes.SuperUser && this.state.role !== roleTypes.SuperUser ?
                                            <div className="form-group">
                                                <label>Client</label>
                                                <select className="form-control" name="clientID" onChange={this.onChange} value={this.state.clientID}>
                                                    <option value=''></option>
                                                    {
                                                        this.props.clients.map(c => 
                                                            <option key={c.ID} value={c.ID}>{c.Name}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            : null
                                        }
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="emailalerts" name="emailAlertsEnabled" checked={this.state.emailAlertsEnabled} onClick={this.onChange} />
                                                <label className="custom-control-label" htmlFor="emailalerts">Email Alerts Enabled</label>
                                            </div>                                
                                        </div>
                                        <div className="distancer distancer--short"></div>
                                        <div className="form-group form-group--multibutton">
                                            <Link to="/Users" className="btn btn--default">Cancel</Link>
                                            <button type="submit" className="btn btn--primary" disabled={!this.props.user && !this.getCreateUserEnabled()}>{this.props.user ? 'Save' : 'Create User'}</button>
                                        </div>

                                        {this.props.createUpdateUserError ?
                                            <React.Fragment>
                                                <div className="distancer"></div>    
                                                <div className="alert alert-danger">
                                                    {this.props.createUpdateUserError}
                                                    <button type="button" className="close" onClick={this.props.actions.clearCreateUpdateUserError}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>                                    
                                            </React.Fragment>
                                            : null
                                        }
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                    : 
                        this.props.usersLoading ? 
                            <div>Loading...</div> 
                            :
                            <div>Unable to find user</div> 
                }
            </div>
        );
    }
}

CreateUpdateUser.propTypes = {
    user: PropTypes.object,
    actions: PropTypes.object.isRequired,
    createUpdateUserError: PropTypes.string
};

function mapStateToProps(state, ownProps) {
    let user = null;
    if(ownProps.match.params.id) {
        user = state.user.users.find(u => u.ID === ownProps.match.params.id);
    }
    return { user: user, usersLoading: state.user.usersLoading, users: state.user.users, myClientID: state.account.user.ClientID, myRole: state.account.user.Role, clients: state.client.clients, createUpdateUserError: state.user.createUpdateUserError };
}

function mapDispatchToProps(dispatch) {
    return {
        //user actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, userActions), dispatch)
    };
}

export default withModalContext(connect(mapStateToProps, mapDispatchToProps)(CreateUpdateUser));