import React from 'react';

const DeleteConfirmationModal = ({deleteAction, text}) => { 
    return (
        <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Confirmation</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="modal__inner modal__inner--wide">
                        <p className="text-center">{text}</p>          
                    </div>
                </div>
                <div className="modal-footer modal-footer--center">
                    <button className="btn btn-default btn--halfwide" data-dismiss="modal">Cancel</button>
                    <button className="btn btn--red btn--halfwide" onClick={deleteAction} data-dismiss="modal">Delete</button>
                </div>
            </div>
        </div>
    )
};

export default DeleteConfirmationModal;