import BaseService from '../services/baseService';
import * as types from '../actions/actionTypes';
import {loadState} from '../utils/localStorage';
import * as processUtils from '../utils/processUtils';

const REFRESH_TOKEN_MINS = 5;

export default function tokenRefreshMiddleware() {
    return ({ dispatch, getState }) => next => action => {
        if (typeof action === 'function') {
            //we're making an async call, check if we need to refresh the token
            let state = getState();
            if(state) {
                const tokenExpiryMins = getTokenExpiryMins(state.account.tokenExpiry)
                if(state.account.token && tokenExpiryMins < REFRESH_TOKEN_MINS) {
                // make sure we are not already refreshing the token
                    if(!state.account.refreshingToken) {
                        return refreshToken(dispatch, state).then(() => next(action)).catch(error => {
                            //debugger;
                            console.log(error.message);
                        });
                    } 
                    else {
                        return state.account.refreshTokenPromise.then(() => next(action)).catch(error => {
                            //debugger;
                            console.log(error.message);
                        });
                    }
                }
            }
        }
        else if (action.type === types.SCHEDULE_TOKEN_REFRESH) {
            let state = getState();
            //we prioritise the token expiry sent in the action instead of the token expiry in the state as if we've just refreshed,
            //the TOKEN_REFRESHED action will schedule the next refresh (called in the serviceMiddleware) before the reducer has been run
            //but when we run at the scheduled time, we want to check the state
            let tokenExpiry = action.payload && action.payload.tokenExpiry ? action.payload.tokenExpiry : state.account.tokenExpiry;
            if(tokenExpiry) {
                const tokenExpiryMins = getTokenExpiryMins(tokenExpiry);
                if(tokenExpiryMins < REFRESH_TOKEN_MINS) {
                    //expiry is in less than 5 mins or has passed already, dispatch a refresh
                    // make sure we are not already refreshing the token
                    if(!state.account.refreshingToken) {
                        if(state.account.refreshTokenTimeoutHandle) {
                            //cancel the already scheduled check
                            dispatch({type: types.CANCEL_TOKEN_REFRESH});
                        }
                        refreshToken(dispatch, state);
                    } 
                }
                else {
                    //schedule another check for 5 mins before the expiry
                    const timeUntilRefresh = tokenExpiryMins - REFRESH_TOKEN_MINS;
                    let timeUntilRefreshMs = Math.min(timeUntilRefresh * 60 * 1000, 2147483647); //if we set a value larger than 2147483647, it will execute instantly
                    console.log(`${new Date()}: scheduling token refresh for ${timeUntilRefreshMs / 60 / 1000} mins time`);
                    const timeout = timeUntilRefreshMs;
                    const timeoutHandle = setTimeout(() => dispatch({ type: types.SCHEDULE_TOKEN_REFRESH}), timeout);
                    dispatch({type: types.SET_REFRESH_TOKEN_TIMEOUT_HANDLE, timeoutHandle});
                }
            }
        }
        else if(action.type === types.CANCEL_TOKEN_REFRESH) {
            console.log(`${new Date()}: cancelling token refresh`);
            let state = getState();
            if(state.account.refreshTokenTimeoutHandle) {
                clearTimeout(state.account.refreshTokenTimeoutHandle);
                dispatch({type: types.SET_REFRESH_TOKEN_TIMEOUT_HANDLE, timeoutHandle: null});
            }            
        }
        return next(action);
    };
  }

  function getTokenExpiryMins(tokenExpiry) {
    const diffMs = new Date(tokenExpiry) - new Date();
    const minsToExpiry = diffMs / 1000 / 60;
    return minsToExpiry;
  }

  function refreshToken(dispatch, state) {
      console.log(`${new Date()}: refreshing token`);
    let refreshTokenPromise = BaseService.postData('/Token', { username: state.account.user.Email, password: state.account.refreshToken, grant_type: 'refresh_token' })
    .then(data => {
        const payload = Object.assign({}, data);
        dispatch({type: types.TOKEN_REFRESHED, payload});
        console.log(`${new Date()}: refresh token complete, new expiry: ${payload[".expires"]}`);
        return Promise.resolve(payload);
    })
    .catch(async error => {
        console.log(`${new Date()}: Unable to refresh token`);     
        dispatch({type: types.TOKEN_REFRESH_FAILED});
        if(error.message === 'The username or token is invalid') {
            //check local storage to see if we've refreshed this token in another browser tab
            //wait 5 secs to give it time to save if it was refreshing at the same time
            console.log(`${new Date()}: Token is invalid, checking if it has been updated elsewhere`);
            await processUtils.sleep(5000)
            const persistedState = loadState();
            if(persistedState.account.user && persistedState.account.user.ID === state.account.user.ID && new Date(persistedState.account.tokenExpiry) > new Date(state.account.tokenExpiry)) {
                //we have a new token for this user
                console.log(`${new Date()}: Token has been updated elsewhere, retrieving updated token from local storage`);
                //debugger;
                const payload = {
                    access_token: persistedState.account.token, 
                    refresh_token: persistedState.account.refreshToken
                };
                payload['.expires'] = persistedState.account.tokenExpiry;
                dispatch({type: types.TOKEN_REFRESHED, payload});
                console.log(`${new Date()}: token has been updated`);
                return Promise.resolve(payload);
            }
            else {
                //delete the token so we stop trying to refresh
                dispatch({ type: types.LOGOUT });
            }
        }
        return Promise.reject({message: 'Unable to refresh token'});
    });

    // we want to keep track of the token promise in the state so that we don't try to refresh the token again while refreshing is in process  
    dispatch({type: types.TOKEN_REFRESHING, refreshTokenPromise});
    return refreshTokenPromise;
  }