import urls from '../constants/urls.js';

class BaseService {
    constructor() {
        this.token = null;
        this.setToken = this.setToken.bind(this);
        this.getHeaders = this.getHeaders.bind(this);
        this.getData = this.getData.bind(this);
     }
     
    static getData(relativeUrl) {    
        return fetch(urls.API_URL + relativeUrl, this.getHeaders())
            .then(response => {
                if(response.ok) {
                    return response.status !== 204 ? response.json() : '';
                }
                else {
                    throw new Error('Network response was not ok');
                }
            });
    } 

    static postData(relativeUrl, data) {
        return fetch(urls.API_URL + relativeUrl, Object.assign({}, this.getHeaders(), {
            method: 'POST',                
            body: JSON.stringify(data)
        }))
        .then(response => {
            //debugger;
            if(response.ok) {
                return response.status !== 204 ? response.json() : '';
            }
            else {
                if(response.status === 400) {
                    return response.json()
                        .then(function(err) {
                            if(err.error_description) {
                                throw new Error(err.error_description);
                            }
                            else if(err.Password) {
                                throw new Error(err.Password);
                            }
                            else if(err.NewPassword) {
                                throw new Error(err.NewPassword);
                            }
                            else if(err.PasswordTooWeak) {
                                throw new Error(err.PasswordTooWeak);
                            }
                            throw new Error(err.message);
                        });
                }
                else if(response.status === 402) {
                    throw new Error('Payment Required');
                }
                else {
                    throw new Error('Network response was not ok');
                }
            }
        });
    }

    static putData(relativeUrl, data) {
        return fetch(urls.API_URL + relativeUrl, Object.assign({}, this.getHeaders(), {
            method: 'PUT',                
            body: JSON.stringify(data)
        }))
        .then(response => {
            //debugger;
            if(response.ok) {
                return response.status !== 204 ? response.json() : '';
            }
            else {
                if(response.status === 400) {
                    return response.json()
                        .then(function(err) {
                            if(err.error_description) {
                                throw new Error(err.error_description);
                            }
                            else if(err.PasswordTooWeak) {
                                throw new Error(err.PasswordTooWeak);
                            }
                            throw new Error(err.message);
                        });
                }
                else {
                    throw new Error('Network response was not ok');
                }
            }
        });
    }

    static deleteData(relativeUrl) {
        return fetch(urls.API_URL + relativeUrl, Object.assign({}, this.getHeaders(), {
            method: 'DELETE'
        }))
        .then(response => {
            //debugger;
            if(response.ok) {
                return response.status !== 204 ? response.json() : '';
            }
            else {
                if(response.status === 409) {
                    return response.json()
                        .then(function(err) {
                            throw new Error('Conflict error');
                        });
                }
                else {
                    throw new Error('Network response was not ok');
                }
            }
        });
    }

    static getHeaders() {
        if(this.token) {
            return {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`
                },
                credentials: 'include'
            };
        }
        else {
            return {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
        }
    }

    static setToken(token) {
        this.token = token;
    }

    static clearToken() {
        this.token = null;
    }
}
export default BaseService;