/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import * as widgetUtils from '../../../../../utils/widgetUtils';

class Grid extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialiseData = this.initialiseData.bind(this);
        this.getVisibility = this.getVisibility.bind(this);
        this.getHeaderStyle = this.getHeaderStyle.bind(this);
        this.getBodyStyle = this.getBodyStyle.bind(this);
        this.getData = this.getData.bind(this);
        this.state = {
            page: 1
        };
    }

    componentWillMount() {   
        this.initialiseData();
    }       

    componentDidUpdate(prevProps, prevState) {
        if(this.props.widget !== prevProps.widget || this.props.widgetSettings !== prevProps.widgetSettings || this.props.tasks !== prevProps.tasks) {
            this.initialiseData();
        }
    }

    initialiseData() {
        const data = {};
        this.props.widget.CustomVariables.forEach(variable => {
            data[variable.Name] = variable.DefaultValue;
        });
        this.props.widgetSettings.Adjustments.filter(a => a.Enabled).forEach(adjustment => {
            if(adjustment.Append) {
                data[adjustment.Name] += parseFloat(adjustment.Append);
            }
            else {
                data[adjustment.Name] = adjustment.Override;
            }
        });
        data['tasks'] = this.props.tasks;
        this.setState(data);
    }

    getData() {        
        let data;
        for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
            const binding = this.props.widgetSettings.FieldBindings[i];
            //we only get the widgetHierarchy prop if we're in a nested control
            if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'Data')) {
                const dataBinding = this.state.tasks[binding.TaskID];
                if(dataBinding && dataBinding.currentValue) {    
                  data = dataBinding.currentValue;
                  break;
                }
            }
        }             
        return data;
    }

    getVisibility() {
        let visible = true;
        if(this.state.tasks) {
            for(let i = 0; i < this.props.widgetSettings.FieldBindings.length; i++) {
                const binding = this.props.widgetSettings.FieldBindings[i];
                if(widgetUtils.bindingMatch(binding.WidgetHierarchy && binding.WidgetHierarchy.length ? binding.WidgetHierarchy : [this.props.widgetSettings.ID], binding.FieldName, this.props.widgetHierarchy, 'Visible')) {
                    const visibilityBinding = this.state.tasks[binding.TaskID];
                    if(visibilityBinding && visibilityBinding.currentValue) {                    
                        visible = visibilityBinding.currentValue;
                        break;
                    }
                }
            }  
        }           
        return visible;
    }

    getHeaderStyle() {
        const style = {};        
        if(this.state.headerBold) {
            style['fontWeight'] = 'bold';
        }
        else {
            style['fontWeight'] = 'normal';
        }
        style['color'] = this.state.headerTextColour;
        if(this.state.fontSize) {            
            if(this.state.fontSize === 'Small') {
                style['fontSize'] = '8px';
            }
            else if(this.state.fontSize === 'Medium') {
                style['fontSize'] = '12px';
            }
            else if(this.state.fontSize === 'Large') {
                style['fontSize'] = '16px';
            }
        }
        return style;
    }    

    getBodyStyle() {
        const style = {};       
        style['color'] = this.state.bodyTextColour;
        if(this.state.fontSize) {            
            if(this.state.fontSize === 'Small') {
                style['fontSize'] = '8px';
            }
            else if(this.state.fontSize === 'Medium') {
                style['fontSize'] = '12px';
            }
            else if(this.state.fontSize === 'Large') {
                style['fontSize'] = '16px';
            }
        }
        return style;
    }    

    getInvertedRow(column, headerStyle, rows, bodyStyle) {
        const output = [];
        output.push(<th scope="col" style={headerStyle}>{column}</th>);
        rows.forEach(dataItem => output.push(<td style={bodyStyle}>{dataItem[column]}</td>));
        return output;
    }

    render() {
        const data = this.getData();
        if(this.getVisibility() && data && data.length) {
            const displayStripedRows = this.state.displayStripedRows;
            const displayCompact = this.state.displayCompact;
            const displayRowNumbers = this.state.displayRowNumbers;
            const columns = Object.keys(data[0]);
            const headerStyle = this.getHeaderStyle();
            const bodyStyle = this.getBodyStyle();
            const highlightRowsOnHover = this.state.highlightRowsOnHover;
            const displayBorder = this.state.displayBorder;
            const enablePaging = this.state.enablePaging;
            const pageSize = this.state.pageSize;
            const startRowNumber = enablePaging && pageSize ? pageSize * (this.state.page - 1) : 0;
            const rows = enablePaging && pageSize ? data.slice(startRowNumber, pageSize * this.state.page) : data;
            const maxPages = enablePaging && pageSize ? Math.ceil(data.length / (pageSize || 1)) : 0;
            return (    
                <div className="table-responsive" style={{height: '100%'}}>
                    <table className={`table table-dark ${displayStripedRows ? 'table-striped ' : ''}${displayCompact ? 'table-sm ' : ''}${highlightRowsOnHover ? 'table-hover ': ''}${displayBorder ? 'table-bordered ': ''}`}>
                        {
                            !this.state.invertColumns ?
                            <React.Fragment>
                                <thead>
                                    <tr>
                                        {
                                            displayRowNumbers ? <th scope="col" style={headerStyle}>#</th> : null
                                        }
                                        {
                                            columns.map(column =>
                                                <th scope="col" style={headerStyle}>{column}</th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody style={bodyStyle}>
                                    {
                                        rows.map((dataItem, index) => 
                                            <tr>
                                                {
                                                    displayRowNumbers ? <td scope="row">{startRowNumber + index + 1}</td> : null
                                                }
                                                {
                                                    columns.map(column =>
                                                        <td>{dataItem[column]}</td>
                                                    )
                                                }
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </React.Fragment>
                            :
                            <tbody>
                                {
                                    displayRowNumbers ?
                                        <tr>
                                            <th scope="col" style={headerStyle}>#</th>
                                            {
                                                rows.map((dataItem, index) => <td scope="row" style={bodyStyle}>{startRowNumber + index + 1}</td>)
                                            }
                                        </tr>
                                    : null
                                }
                                {
                                    columns.map(column => <tr>{this.getInvertedRow(column, headerStyle, rows, bodyStyle)}</tr>)
                                }
                            </tbody>
                        }
                    </table> 
                    {
                        this.state.enablePaging ?
                            <nav>
                                <ul className="pagination justify-content-center pt-2">
                                    <li className={`page-item ${this.state.page === 1 ? 'disabled' : null}`}>
                                        <a className="page-link" href="#" tabindex="-1" onClick={() => this.state.page > 1 ? this.setState({ page: this.state.page - 1 }) : null}>Previous</a>
                                    </li>
                                    {
                                        [...Array(maxPages).keys()].map(page =>
                                            <li className={`page-item ${page + 1 === this.state.page ? 'active' : ''}`}>
                                                <a className="page-link" href="#" onClick={() => this.setState({ page: page + 1 })}>{page + 1} {page + 1 === this.state.page ? <span className="sr-only">(current)</span> : null}</a>
                                            </li>
                                        )                                        
                                    }
                                    <li className={`page-item ${this.state.page === maxPages ? 'disabled' : null}`}>
                                        <a className="page-link" href="#" onClick={() => this.state.page < maxPages ? this.setState({ page: this.state.page + 1 }) : null}>Next</a>
                                    </li>
                            </ul>
                        </nav>
                        : null
                    }  
                </div>     
            );
        }
        else {
            return this.props.isEditable ? <div style={{border: 'solid 1px #555', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#555'}}>Grid</div> : null;
        }
    }
}

Grid.propTypes = {
};

export default Grid;