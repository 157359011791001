import React from 'react';
import * as imageUtils from '../../utils/imageUtils';
import roleTypes from '../../constants/roleTypes';
import ToastrService from '../../services/toastrService';

class AddUpdateItemModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
        this.toggleSelectItem = this.toggleSelectItem.bind(this);
        this.getItemClass = this.getItemClass.bind(this);
        this.getDashboardImage = this.getDashboardImage.bind(this);
        this.addItem = this.addItem.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.getDashboardPlayerModeDescription = this.getDashboardPlayerModeDescription.bind(this);
        this.isValid = this.isValid.bind(this);
        debugger;
        this.playerModes = ['Single', 'Multi', 'None'];
        if(this.props.item) {
            let selectedDashboard = null;
            let selectedPlayerID = null;
            if(this.props.item.DashboardID) {
                selectedDashboard = this.props.dashboards.find(d => d.ID === this.props.item.DashboardID);
                if(selectedDashboard && this.props.item.PlayerID) {
                    selectedPlayerID = this.props.item.PlayerID;
                }
            }
            this.state = { 
                playerMode: '',
                selectedDashboard,
                selectedPlayerID,
                name: this.props.item.Name,
                order: this.props.item.Order,
                seconds: this.props.item.Seconds,
                useSpecifiedDuration: this.props.item.UseSpecifiedDuration,
                waitUntilAnimationCompletes: this.props.item.WaitUntilAnimationCompletes
            };
        }
        else {
            this.state = { 
                playerMode: '',
                selectedDashboard: null,
                selectedPlayerID: null,
                name: '',
                order: this.props.order || 1,
                seconds: 0,
                useSpecifiedDuration: false,
                waitUntilAnimationCompletes: true
            };
        }
    }

    componentDidMount() {    
        
    }

    getItemClass(dashboard) {
        return this.state.selectedDashboard && this.state.selectedDashboard.ID === dashboard.ID ? 'active' : null;
    }

    getDashboardImage(dashboard) {
        const image = dashboard.Thumbnail;
        const imageData = image ? imageUtils.getAvatarBase64Url(image) : require('../../images/dash-blank.png');
        return imageData;
    }

    getDashboardPlayerModeDescription(dashboard) {
        if(dashboard.PlayerMode === 'Multi') {
            return 'Multi-player dashboard'
        }
        else if(dashboard.PlayerMode === 'Single') {
            return 'Single-player dashboard';
        }
        return null;
    }

    toggleSelectItem(event, dashboard) {
        event.preventDefault();
        let selectedDashboard = this.state.selectedDashboard;
        if(!selectedDashboard || selectedDashboard.ID !== dashboard.ID) {
            selectedDashboard = dashboard;
        }
        else {
            selectedDashboard = null;
        }
        this.setState({
            selectedDashboard,
            selectedPlayerID: null,
            name: dashboard.Name
        });
    }

    isValid() {
        const toastrService = new ToastrService();
        if(this.state.selectedDashboard) {
            if(this.state.selectedDashboard.PlayerMode !== 'Single' || this.state.selectedPlayerID) {
                return true;
            }
            else {
                toastrService.showInfo('', 'Please select a player');
            }
        }
        else {
            toastrService.showInfo('', 'Please select a dashboard');
        }
    }

    addItem(event) {
        event.preventDefault();
        if(this.isValid()) {
            this.props.actions.addPlaylistItem(this.props.playlistID, this.state.selectedDashboard.ID, this.state.selectedPlayerID, this.state.seconds, this.state.useSpecifiedDuration, this.state.waitUntilAnimationCompletes, this.state.order);
            window.$('#modal').modal('hide');
        }
    }

    saveItem(event) {
        event.preventDefault();
        if(this.isValid()) {
            this.props.actions.updatePlaylistItem(this.props.playlistID, this.props.item.ID, this.state.selectedDashboard.ID, this.state.selectedPlayerID, this.state.seconds, this.state.useSpecifiedDuration, this.state.waitUntilAnimationCompletes, this.state.order);
            window.$('#modal').modal('hide');
        }
    }

    onChange(event) {
        const field = event.target.name;
        let newState = Object.assign({}, this.state);
        let value = event.target.value;
        newState[field] = value;
        return this.setState(newState);   
    }

    render() {
        const dashboards = this.props.dashboards.filter(d => !this.state.playerMode || d.PlayerMode === this.state.playerMode);
        debugger;
        return (     
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form className="form" onSubmit={ this.props.item ? this.saveItem : this.addItem}>
                        <div className="modal-header">
                            <h5 className="modal-title">{`${this.props.item ? 'Edit dashboard' : 'Add dashboard'}`}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                                <div className="modal__inner modal__inner--wide">
                                    <div className="form-group">
                                        <label>Player Mode</label>
                                        <select className="form-control" name="playerMode" onChange={this.onChange} value={this.state.playerMode}>
                                            <option key="All" value="">All</option>
                                            {
                                                this.playerModes.map(r => 
                                                    <option key={r}>{r}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <div className="list-users list-users--longer list-users--dash scrollbar">
                                        {
                                            dashboards.length ?
                                                dashboards.map(dashboard =>
                                                    <a key={dashboard.ID} href={null} className={`user ${this.getItemClass(dashboard)}`} onClick={(e) => this.toggleSelectItem(e, dashboard)}>
                                                        <div className="avatar">
                                                            <img src={this.getDashboardImage(dashboard)} alt="Avatar"/>
                                                        </div>
                                                        <div className="user__name">
                                                            <p className="user__title">{dashboard.Name}</p>
                                                            {
                                                                dashboard.PlayerMode !== 'None' ?
                                                                    <React.Fragment>
                                                                        <p className="small grey">{this.getDashboardPlayerModeDescription(dashboard)}</p>
                                                                        <p className="small grey">{`${dashboard.Players.length} player${dashboard.Players.length === 1 ? '' : 's'}`}</p>
                                                                    </React.Fragment>
                                                                    : null
                                                            }
                                                        </div>
                                                    </a>
                                                )
                                            :
                                                <p>There are no existing dashboards</p>
                                        }                                    
                                        </div>
                                    </div>
                                    {
                                        this.state.selectedDashboard && this.state.selectedDashboard.PlayerMode === 'Single' ?
                                            <div className="form-group">
                                                <label>Select Player</label>
                                                <select className="form-control" name="selectedPlayerID" required value={this.state.selectedPlayerID} onChange={this.onChange}>
                                                    <option value=''></option>
                                                    {
                                                        this.state.selectedDashboard.Players.map(p => 
                                                            <option key={p.ID} value={p.ID}>{`${p.ClientUserID} - ${p.Label}`}</option>
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            : null
                                    }
                                    
                                    {/*<div className="form-group">
                                        <label>Data ID</label>
                                        <input className="form-control" type="text" name="userID" value={this.state.userID} onChange={this.onChange} placeholder="Enter data ID" />
                                        <p className="help-block">The data ID maps this player's data to the data source</p>
                                    </div>
                                    {
                                        this.state.selectedUser && this.state.selectedUser.Role === roleTypes.Viewer ?
                                            <div className="form-group">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="avatarUserEditable" name="avatarUserEditable" checked={this.state.avatarUserEditable} onClick={this.onChange} />
                                                    <label className="custom-control-label" htmlFor="avatarUserEditable">User can edit avatar</label>
                                                </div>                                
                                            </div>
                                            : null
                                    }*/}
                                    
                                </div>
                            
                            
                        </div>
                        <div className="modal-footer modal-footer--center">
                            <button className="btn btn--default btn--halfwide" data-dismiss="modal">Cancel</button>
                            <input type="submit" className="btn btn--primary btn--halfwide" value={`${this.props.item ? 'Update dashboard' : 'Add dashboard'}`} />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default AddUpdateItemModal;


