import React from 'react';
import DashboardService from '../../services/dashboardService';
import validator from 'validator';

class PlayerOverridesModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
        this.getTooltipText = this.getTooltipText.bind(this);
        this.setRef = this.setRef.bind(this);
        this.getAppendChecked = this.getAppendChecked.bind(this);
        this.getOriginalValue = this.getOriginalValue.bind(this);
        this.getOverrideValue = this.getOverrideValue.bind(this);
        this.increment = this.increment.bind(this);
        this.decrement = this.decrement.bind(this);
        this.adjustValue = this.adjustValue.bind(this);
        this.resetOverrides = this.resetOverrides.bind(this);
        this.submitAdjustments = this.submitAdjustments.bind(this);
        this.tooltipControls = [];
        const overrides = {}
        if(this.props.player.Adjustments) {
            this.props.player.Adjustments.forEach(adjustment =>{
                debugger;
                    overrides[adjustment.Name] = { value: adjustment.Override || adjustment.Append, append: adjustment.Append != null };
                }
            );
        }
        this.state = 
        { 
            dashboardData: null,
            overrides: overrides
        };
    }

    componentDidMount() {    
        const tooltipControls = window.$(this.tooltipControls);
        if (tooltipControls.length !== 0 ) {
            tooltipControls.tooltip({boundary: 'viewport'});
            tooltipControls.on('click', function(e){
                e.preventDefault();
            });
        }  
        debugger;
        DashboardService.getDashboardData(this.props.dashboard.ID, this.props.dashboard.PlayerMode === 'Single' ? this.props.player.ID : null)
            .then(data => this.setState({
                dashboardData: data
            }))
            .catch(err => {});
    }

    onChange(event) {
        const field = event.target.name;
        const fieldName = field === 'append' ? event.target.dataset.fieldName : field;
        let newState = Object.assign({}, this.state);
        if(!newState.overrides[fieldName]) {
            newState.overrides[fieldName] = {};
        }
        if(field === 'append') {
            const checked = event.target.checked;
            newState.overrides[fieldName]["append"] = checked;
        }
        else {
            let value = event.target.value;
            const variable = this.props.dashboard.Template.PlayerGameVariables.find(v => v.Name === fieldName);
            if(variable.Type.toLowerCase() !== 'string' && value && !validator.isNumeric(value)) {
                value = '';
            }
            newState.overrides[fieldName]["value"] = value;
        }
        return this.setState(newState);   
    }

    increment(event, field) {
        this.adjustValue(event, field, (v) => (parseInt(v, 10) + 1) + '', '1');
    }

    decrement(event, field) {
        this.adjustValue(event, field, (v) => (parseInt(v, 10) - 1) + '', '1');
    }

    adjustValue(event, field, action, defaultValue) {
        event.preventDefault();
        let newState = Object.assign({}, this.state);
        if(!newState.overrides[field]) {
            newState.overrides[field] = {};
        }
        let value = newState.overrides[field]["value"];
        if(value && validator.isNumeric(value) && action) {
            value = action(value);
        }
        else {
            value = defaultValue;
        }
        newState.overrides[field]["value"] = value;
        this.setState(newState);
    }

    setRef(control) {
        this.tooltipControls.push(control);
    }

    getTooltipText() {
        return "By default, the points you enter will override any imported values. Toggle this on to append to the imported value."
    }

    getAppendChecked(name) {
        const field = this.state.overrides[name];
        const value = field ? field["append"] === true : false;
        return value;
    }

    getOriginalValue(name) {
        if(this.state.dashboardData && this.props.player.ClientUserID) {
            const playerDashboardData = this.props.dashboard.PlayerMode === 'Single' ? this.state.dashboardData : this.state.dashboardData.find(d => d.id + '' === this.props.player.ClientUserID);
            if(playerDashboardData) {
                return playerDashboardData[name] || '';
            }
        }
        return '';
    }

    getOverrideValue(name) {
        const field = this.state.overrides[name];
        const value = field ? field["value"] : null;
        return value || '';
    }

    resetOverrides() {
        this.setState({
            overrides: []
        });
    }

    submitAdjustments(event) {
        event.preventDefault();
        const adjustments = [];
        if(this.props.player.Adjustments) {
            this.props.player.Adjustments.forEach(adjustment => {
                    const updatedAdjustment = this.state.overrides[adjustment.Name];
                    if(updatedAdjustment && updatedAdjustment.value) {
                        if(updatedAdjustment.append && updatedAdjustment.value !== adjustment.Append) {
                            adjustments.push({type: 'UPDATE', name: adjustment.Name, override: null, append: updatedAdjustment.value});
                        }
                        else if(!updatedAdjustment.append && updatedAdjustment.value !== adjustment.Override) {
                            adjustments.push({type: 'UPDATE', name: adjustment.Name, override: updatedAdjustment.value, append: null});
                        }
                    }
                    else {
                        //delete it
                        adjustments.push({type: 'DELETE', name: adjustment.Name});
                    }
                }
            );
        }
        const overrideNames = Object.keys(this.state.overrides);
        overrideNames.forEach(name => {
            debugger;
                const override = this.state.overrides[name];
                if(override.value && (!this.props.player.Adjustments || !this.props.player.Adjustments.find(a => a.Name === name))) {
                    //add the adjustment
                    if(override.append) {
                        adjustments.push({type: 'CREATE', name: name, override: null, append: override.value});
                    }
                    else {
                        adjustments.push({type: 'CREATE', name: name, override: override.value, append: null});
                    }
                }
            }
        );
        if(adjustments.length) {
            this.props.actions.applyPlayerAdjustments(this.props.dashboard.ID, this.props.player.ID, adjustments);
        }
        window.$('#modal').modal('hide');
    }

    render() {
        const {player, dashboard} = this.props;
        return (     
            <div className="modal--overrides">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <form className="form" onSubmit={this.submitAdjustments}>
                            <div className="modal-header">
                                <h5 className="modal-title">Override values for {player.Label}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="modal__intro">Here you can override the imported player data. By default, the values entered will override the original values.</p>
                                <div className="modal__overrides scrollbar">
                                    <div className="modal__inner modal__inner--overrides">
                                        <div className="distancer distancer--short"></div>
                                        <div className="overrides">
                                            {
                                                dashboard.Template.PlayerGameVariables.map(variable =>
                                                    <div key={variable.Name} className="overrides__row">
                                                        <div className="overrides__title">
                                                            <h5>{variable.Name}</h5>
                                                            <p className="help-block">Original value: <strong>{this.getOriginalValue(variable.Name)}</strong></p>
                                                        </div>
                                                        <div className="overrides__value">
                                                            <div className="form-group">
                                                                {
                                                                    variable.Type.toLowerCase() === "string" ?
                                                                        <input className="form-control" type="text" name={variable.Name} value={this.getOverrideValue(variable.Name)} onChange={this.onChange} />
                                                                    :
                                                                        <div className="form-stepper">
                                                                            <input className="form-control" name={variable.Name} value={this.getOverrideValue(variable.Name)} onChange={this.onChange} placeholder="0" />
                                                                            <div className="form-stepper__ctrl">
                                                                                <a href={null} className="plus" onClick={(e) => this.increment(e, variable.Name)}>+</a>
                                                                                <a href={null} className="minus" onClick={(e) => this.decrement(e, variable.Name)}>-</a>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="overrides__actions">
                                                            {
                                                                variable.Type === "String" || !player.ClientUserID ?
                                                                    <div>
                                                                        <p></p>
                                                                    </div>
                                                                :
                                                                    <div className="form-group nobottommargin">
                                                                        <div className="checkbox checbox-switch switch-primary">
                                                                            <label>
                                                                                <input type="checkbox" name="append" data-field-name={variable.Name} checked={this.getAppendChecked(variable.Name)} onChange={this.onChange} />
                                                                                <span className="dot"></span>
                                                                                <span className="label">Append <a href={null} className="btn-helper" ref={this.setRef} data-toggle="tooltip" data-placement="top" title={this.getTooltipText()}><i className="aheadicon-help"></i></a></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="modal__cta">
                                    <a href={null} className="btn-text btn-resetoverrides" onClick={this.resetOverrides}>Reset to original values</a>
                                </div>
                            </div>
                            <div className="modal-footer modal-footer--center">
                                <button type="submit" className="btn btn--default btn--halfwide" data-dismiss="modal">Cancel</button>
                                <input type="submit" className="btn btn--primary btn--halfwide" value="Override values" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default PlayerOverridesModal;