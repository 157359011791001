
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../../utils/imageUtils';
import roleTypes from '../../../constants/roleTypes';

class NewWidgetModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.initialiseCategories = this.initialiseCategories.bind(this);
        this.state = {
            selectedCategory: '',
            categories: [],
            searchText: ''
        };
        
    }

    componentDidMount() { 
        if(this.props.widgets && this.props.widgets.length) {
            this.props.initialise();
            this.initialiseCategories();
        }        
    }     

    shouldComponentUpdate(nextProps, nextState) {
        return this.props.widgets !== nextProps.widgets || this.state.categories !== nextState.categories || this.state !== nextState;
    }

    componentDidUpdate(prevProps) {
        if(this.props.widgets && prevProps.widgets && this.props.widgets.length && !prevProps.widgets.length) {
            this.props.initialise();
            this.initialiseCategories();
        } 
    }

    initialiseCategories() {
        const categories = this.props.widgets.map(w => w.Category);
        categories.unshift('All');
        const distinctCategories = [...new Set(categories)];
        this.setState({ categories: distinctCategories, selectedCategory: 'All' });
    }

    getWidgetIconClass(widget) {
        if(widget.Slug === 'dropdown') {
            return 'aheadicon-dropdown';
        }
        else if(widget.Slug === 'dropdown-multi-select') {
            return 'aheadicon-multi-select';
        }
        else if(widget.Slug === 'label') {
            return 'aheadicon-label';
        }
        else if(widget.Slug === 'image') {
            return 'aheadicon-image';
        }
        else if(widget.Slug === 'gauge-chart') {
            return 'aheadicon-gauge';
        }
        else if(widget.Slug === 'datepicker') {
            return 'aheadicon-date';
        }
        else if(widget.Slug === 'bar-chart') {
            return 'aheadicon-bar-chart';
        }
        else if(widget.Slug === 'line-chart') {
            return 'aheadicon-kpi-chart';
        }
        else if(widget.Slug === 'daterangepicker') {
            return 'aheadicon-date-range';
        }
        else if(widget.Slug === 'kpi-widget') {
            return 'aheadicon-kpi-chart';
        }
        else {
            return '';
        }
    }
 
    render() {
        const searchTextLower = this.state.searchText ? this.state.searchText.trim().toLowerCase() : null;
        const availableWidgets = this.props.dashboard && this.props.widgets ?
             this.props.widgets.filter(w => (this.state.selectedCategory === 'All' || w.Category === this.state.selectedCategory) && (!w.DashboardIDs || !w.DashboardIDs.length || w.DashboardIDs.includes(this.props.dashboard.ID)) && (!w.ClientIDs || !w.ClientIDs.length || w.ClientIDs.includes(this.props.dashboard.ClientID)) && (!searchTextLower || w.Name.toLowerCase().indexOf(searchTextLower)) > -1)
             : []
        return (
            <div className="modal modal-side modal-side--newwidget fade" tabIndex="-1" role="dialog" id="modal-new-widget" data-backdrop="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header align-items-center pt-2">
                            <h5 className="modal-title">Add widget</h5>
                            {
                                !this.props.newWidget ?
                                    <div className="modal-actions mr-4">
                                        <a href="#" className="btn-text btn-text-grey" data-toggle="modal" data-target="#modal-newtask" data-dismiss="modal" onClick={this.props.clearSelectedTask}>+ New task</a>
                                        {
                                            this.props.dashboard && this.props.user.Role !== roleTypes.Viewer ?
                                                <a href="#" className="btn btn--outline ml-3" data-toggle="modal" data-target="#modal-new-customwidget" data-dismiss="modal">+ New widget</a>
                                                : null
                                        }
                                    </div>
                                    : null
                            }                            
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form className="d-flex align-items-center mb-4">
                                <p className="text-muted m-0">{`${availableWidgets.length} widget${availableWidgets.length !== 1 ? 's' : ''} available`}</p>
                                <div className="form-group form-search mb-0 ml-auto">
                                    <i className="aheadicon-search form__picto" />
                                    <input className="form-control form-control-sm" type="text" placeholder="Quick search" value={this.state.searchText} onChange={(e) => this.setState({ searchText: e.target.value })} />
                                </div>
                                <div className="form-group form-type mb-0 ml-1">
                                    <select className="form-control form-control-sm" value={this.state.selectedCategory} onChange={(e) => this.setState({ selectedCategory: e.target.value })}>
                                        {
                                            this.state.categories.map(category =>
                                                <option key={category} value={category}>{category}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </form>                
                            <div className="widgets-list">
                                {
                                    availableWidgets.map(widget => 
                                        <a href="#" className="widget-item" key={widget.ID} data-wid={widget.ID}>
                                            <i className={this.getWidgetIconClass(widget)}></i>
                                            <p>{widget.Name}</p>
                                        </a>
                                    )
                                }
                            </div>                
                        </div>
                    </div>
                </div>
            </div>  
        );
    } 
};

export default NewWidgetModal;