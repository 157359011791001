import React from 'react';
import logo from '../../images/logo-ahead-of-the-game.png';

const MobileSidebar = (props) => { 

    const showSide = (event) => {
        event.preventDefault();
        window.$('body').addClass('showside');
    }

    return (
        <section className="mobilebar">
            <div className="mobilebar__menu">
              <button type="button" className="btn-mobilemenu" onClick={showSide}>
                <span className="sr-only">Toggle navigation</span>
                <span className="bars">
                  <span className="icon-bar icon-bar1"></span>
                  <span className="icon-bar icon-bar2"></span>
                  <span className="icon-bar icon-bar3"></span>
                </span>
                <span className="title">MENU</span>
              </button>
            </div>
            <div className="mobilebar__branding">
              <img src={logo} alt="Ahead of the Game"/>
            </div>
        </section> 
    );
};

export default MobileSidebar;