
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import * as imageUtils from '../../../../utils/imageUtils';
import * as processUtils from '../../../../utils/processUtils';

class ScoreboardModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getUserImage = this.getUserImage.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.maxPlayerCount = 7;
        this.currentPlayerEnd = 7;
        this.state = { 
            players: this.getPlayersWithPosition(1, this.props.players.slice(0, this.currentPlayerEnd))
        };
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    async componentDidMount() {    
        this._isMounted = true;
        window.$('#modal').addClass('modal-scoreboard');
        this.animatePlayers();
        let haveMorePages = this.props.players.length > this.currentPlayerEnd;
        if(haveMorePages) {
            while(haveMorePages) {
                await processUtils.sleep(13000);
                if(!this._isMounted){ return };
                const newPlayerEnd = this.currentPlayerEnd + 7;
                const players = this.getPlayersWithPosition(this.currentPlayerEnd + 1, this.props.players.slice(this.currentPlayerEnd, newPlayerEnd));
                this.setState({
                    players
                });
                this.currentPlayerEnd = newPlayerEnd;
                this.animatePlayers();
                if(newPlayerEnd >= this.props.players.length && this.props.onAnimationFinished) {
                    await processUtils.sleep(13000);
                    window.$('#modal').modal('hide');
                    this.props.onAnimationFinished();
                }
                haveMorePages = this.props.players.length > this.currentPlayerEnd;
            }
        }
        else if(this.props.onAnimationFinished) {
            //it's a single page, wait for it to appear and then call the animation finished
            await processUtils.sleep(13000);
            window.$('#modal').modal('hide');
            this.props.onAnimationFinished();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.page !== this.props.page) {
            this.animatePlayers();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.handleClick, false);
        window.$('#modal').removeClass('modal-scoreboard');
    }

    getPlayersWithPosition(startPosition, players) {
        var updatedPlayers = players.map((player, index) => {
            const position = {position: index + startPosition};
            return Object.assign({}, player, position);
        });
        //debugger;
        return updatedPlayers;
    }

    animatePlayers() {
        window.$('.list-scores li').each(function(index, el){
            var bar = window.$(this);
            setTimeout(function(){
            bar.addClass('active');
            }, 300 * index);
        });
    }

    getUserImage(avatar) {
        const imageData = avatar ? imageUtils.getAvatarBase64Url(avatar) : require('../../../../images/avatar-empty.png');
        return imageData;
    }

    getScoreClass(percentage, position) {
        //if(position > 3) {
            if(percentage >= 75) {
                return 'list-scores__perc--high'
            }
            else if(percentage >= 50) {
                return 'list-scores__perc--med'
            }
            else if(percentage >= 25) {
                return 'list-scores__perc--low'
            }
            else {
                return 'list-scores__perc--lowest'
            }
        //}
    }

    getRowClass(position) {
        if(position === 1) {
            return 'goldalt';
        }
        else if(position === 2) {
            return 'silveralt';
        }
        else if(position === 3) {
            return 'bronzealt';
        }
        else 
        {
            return null;
        }
    }

    handleClick(e) {
        if(this.node.contains(e.target)) {
            //the click is inside the modal
            return;
        }
        else {
            window.$('#modal').modal('hide');
        }
    }

    render() {
        const {title, logo, getBase64Url} = this.props;
        const players = this.state.players;
        return (     
            <div className="modal-dialog modal-xl modal-dialog-centered" role="document" ref={node => this.node = node}>
                <div className="modal-content">
                    <div className="modal-body nopadding">
                        <div className="scoreboard">
                            <div className="scoreboard__head">
                                {
                                    logo ?
                                        <div className="scoreboard__logo">
                                            <img src={getBase64Url(logo)} alt={title}/>
                                        </div>
                                        : null
                                }
                                <h2>Scoreboard</h2>
                                <div className="scoreboard__aheadlogo">
                                    <img src={require('../../../../images/logo-ahead-of-the-game-wh-tr.png')} alt="Ahead of the game"/>
                                </div>
                            </div>
                            <div className="scoreboard__body">
                                <ul className="list-unstyled list-scores">
                                <li className="list-scores__head">
                                    <p className="no"></p>
                                    <div className="avatar"></div>
                                    <p className="list-scores__name">Name</p>
                                    <p className="list-scores__dept">Department</p>
                                    <p className="list-scores__val">Current</p>
                                    <p className="list-scores__target">Target</p>
                                    <p className="list-scores__perc">Perc</p>
                                </li>
                                    {
                                        players.map((player) =>
                                            <li key={player.id} className={this.getRowClass(player.position)}>
                                                <p className="no">{player.position}</p>
                                                <div className="avatar">
                                                    <img src={this.getUserImage(player.userAvatar)} alt="Avatar"/>
                                                </div>
                                                <p className="list-scores__name">{player.label}
                                                    <span className="list-scores__sec">
                                                        {player.department || '-'}
                                                    </span>
                                                </p>
                                                <p className="list-scores__dept">{player.department || '-'}</p>
                                                <p className="list-scores__val">&pound;{(player.current || 0).toLocaleString()}</p>
                                                <p className="list-scores__target">&pound;{(player.target || 0).toLocaleString()}</p>
                                                <p className={`list-scores__perc ${this.getScoreClass(player.percentage, player.position)}`}>{Math.round((this.props.showRealPercentage ? Math.min(player.realPercentage, 999) : player.percentage) || 0)}<span className="small">%</span></p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScoreboardModal;