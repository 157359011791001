/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as dashboardActions from '../../../../../actions/dashboardActions';
import * as userActions from '../../../../../actions/userActions';
import $ from 'jquery';
import * as imageUtils from '../../../../../utils/imageUtils';
import Highcharts from 'highcharts';

class Chart extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
        };
    }

    componentDidMount() {    
        this.initPie(this.chartRef);
    }       

    componentDidUpdate(prevProps, prevState) {
    }

    initPie(container){
        this.chart = new Highcharts.Chart({
          chart: {
            color: '#fff',
            backgroundColor: 'transparent',
            renderTo: container,
            type: 'pie'
          },
          credits: {
            enabled: false
          },
          title: {
            text: null,
            style: {
              "color": "#fff"
            }
          },
          yAxis: {
            title: {
              text: 'Total percent market share'
            }
          },
          plotOptions: {
            pie: {
              shadow: false,
              borderColor: null
            }
          },
          tooltip: {
            formatter: function() {
              return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
            }
          },
          legend: {
            enabled: false,
            align: 'right',
            layout: 'vertical',
            verticalAlign: 'middle',
            symbolRadius: 0,
            symbolPadding: 10,
            itemMarginTop: 40,
            itemStyle: {
              "color": "#fff"
            }
          },
          series: [{
            name: 'User data',
            data: [{
              y: 8,
              name: "April 2019",
              color: "#09A709"
            }, {
              y: 4,
              name: "March 2019",
              color: "#E49923"
            }, {
              y: 2,
              name: "June 2019",
              color: "#29B8FC"
            }],
            size: '90%',
            innerSize: '75%',
            showInLegend: true,
            dataLabels: {
              enabled: false
            },
            marker: {
              symbol: 'square',
              radius: 12
            }
          }]
        });
    }


    render() {
        return (              
            <div className="widget widget--chart">
                <div className="widget-head">
                    <div className="widget-head__l">
                        <h4>Call time</h4>
                    </div>
                    <div className="widget-head__r">
                        <p className="widget-val">45<span className="small text-muted">/60</span></p>
                    </div>
                </div>
                <div className="widget-body">
                    <div className="chart chart--pie" id="chart-pie" ref={(control) => this.chartRef = control}></div>
                </div>
                <div className="widget-foot">
                    <div className="bar">
                        <div className="bar__title">
                            <p>April 2019</p>
                        </div>
                        <div className="bar__barbg">
                            <div className="bar__bar" style={{width: '34%'}}></div>
                        </div>
                        <div className="bar__score">
                            <p className="widget-val">34</p>
                        </div>
                    </div>
                    <div className="bar">
                        <div className="bar__title">
                            <p>May 2019</p>
                        </div>
                        <div className="bar__barbg">
                            <div className="bar__bar bar__bar--color2" style={{width: '65%'}}></div>
                        </div>
                        <div className="bar__score">
                            <p className="widget-val">65</p>
                        </div>
                    </div>
                    <div className="bar">
                        <div className="bar__title">
                            <p>May 2019</p>
                        </div>
                        <div className="bar__barbg">
                            <div className="bar__bar bar__bar--color3" style={{width: '82%'}}></div>
                        </div>
                        <div className="bar__score">
                            <p className="widget-val">82</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Chart.propTypes = {
};

export default Chart;