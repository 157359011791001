import React from 'react';
import * as textUtils from '../../utils/textUtils';
import validator from 'validator';
import _ from 'lodash';

class PlaylistItemSettingsModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.onChange = this.onChange.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.getDuration = this.getDuration.bind(this);
        this.getDurationText = this.getDurationText.bind(this);
        const {minutes, seconds} = this.getDuration(this.props.item.Seconds);
        this.state = { 
            name: this.props.item.Name,
            order: this.props.item.Order,
            minutes,
            seconds,
            useSpecifiedDuration: this.props.item.UseSpecifiedDuration,
            waitUntilAnimationCompletes: this.props.item.WaitUntilAnimationCompletes
        };
    }

    componentDidMount() {    
        if(this.state.useSpecifiedDuration) {
            window.$('#durationSelection').show();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.useSpecifiedDuration && !prevState.useSpecifiedDuration) {
            window.$('#durationSelection').slideToggle(300);
        }
    }

    saveItem(event) {
        debugger;
        event.preventDefault();
        const seconds = (this.state.minutes || 0) * 60 + (this.state.seconds || 0);
        this.props.actions.updatePlaylistItem(this.props.playlistID, this.props.item.ID, this.props.item.DashboardID, this.props.item.PlayerID, seconds, this.state.useSpecifiedDuration, this.state.waitUntilAnimationCompletes, this.state.order);
        window.$('#modal').modal('hide');
    }

    onChange(event) {
        const field = event.target.name;
        let newState = Object.assign({}, this.state);
        let value;
        if(field === 'useSpecifiedDuration') {
            value = event.target.checked;
        }
        else {
            value = event.target.value;
            if(value && !validator.isNumeric(value)) {
                value = 0;
            }
            else {
                if(value !== 0) {
                    value = parseInt(_.trimStart(value, '0'), 10);
                }
            }
        }
        newState[field] = value;
        if(field === 'useSpecifiedDuration') {
            newState['waitUntilAnimationCompletes'] = !value;
        }
        return this.setState(newState);   
    }
    
    getDuration(secs) {
        //seconds = this.state.seconds;
        const minutes = Math.floor(secs / 60);
        const seconds = parseInt(secs % 60, 10);
        return { minutes, seconds };
    }

    getDurationText(mins, secs) {
        return `${mins} ${textUtils.pluralise('min', mins)} ${secs} ${textUtils.pluralise('sec', secs)}`
    }

    render() {
        return (     
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <form className="form" onSubmit={this.saveItem}>
                        <div className="modal-header">
                            <h5 className="modal-title">Dashboard settings</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><i className="aheadicon-x"></i></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="modal__intro pb-3"><strong>{this.state.name}</strong></p>
                            <p className="modal__intro">Adjust the dashboard duration in the playlist</p>
                            <div className="modal__overrides scrollbar">
                                <div className="modal__inner modal__inner--wide">
                                    <div className="distancer distancer--short"></div>
                                    <div className="overrides">
                                        <div className="overrides__row">
                                            <div className="overrides__value overrides__value--single">
                                                <div className="form-group mb-2">
                                                    <p className="dash-duration__msg">
                                                    {
                                                        this.state.useSpecifiedDuration ? 
                                                            <React.Fragment>
                                                                <span>This dashboard will have a custom duration: </span>
                                                                <span><strong>{this.getDurationText(this.state.minutes, this.state.seconds)}</strong></span>
                                                            </React.Fragment>
                                                            : 
                                                            'This dashboard will play until the end of the animation'
                                                    }
                                                    </p>
                                                    <div className="checkbox checbox-switch switch-primary checbox-switch--duration">
                                                        <label>
                                                            <input type="checkbox" name="useSpecifiedDuration" checked={this.state.useSpecifiedDuration} onChange={this.onChange} />
                                                            <span className="dot"></span>
                                                            <span className="label">Set manual duration</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.useSpecifiedDuration ?
                                                        <div id="durationSelection" className="form-group hidden-block form-group--dash-duration">
                                                            <div className="dash-duration">
                                                                <div className="form-stepper">
                                                                    <input className="form-control" type="text" name="minutes" value={this.state.minutes} onChange={this.onChange} />
                                                                    <div className="form-stepper__ctrl">
                                                                        <a href={null} className="plus" onClick={() => this.setState({'minutes': this.state.minutes + 1})}>+</a>
                                                                        <a href={null} className="minus"  onClick={() => this.setState({'minutes': this.state.minutes - 1})}>-</a>
                                                                    </div>
                                                                </div>
                                                                <p>{textUtils.pluralise('Min', this.state.minutes)}</p>
                                                                <div className="form-stepper">
                                                                    <input className="form-control" type="text" name="seconds" value={this.state.seconds} onChange={this.onChange} />
                                                                    <div className="form-stepper__ctrl">
                                                                    <a href={null} className="plus" onClick={() => this.setState({'seconds': this.state.seconds + 1})}>+</a>
                                                                        <a href={null} className="minus"  onClick={() => this.setState({'seconds': this.state.seconds - 1})}>-</a>
                                                                    </div>
                                                                </div>
                                                                <p>{textUtils.pluralise('Sec', this.state.seconds)}</p>
                                                            </div>
                                                        </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer modal-footer--center">
                            <button className="btn btn--default btn--halfwide" data-dismiss="modal">Cancel</button>
                            <input type="submit" className="btn btn--primary btn--halfwide" value="Save settings" />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default PlaylistItemSettingsModal;


