//account actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
export const SET_SIGNUP_ERROR = 'SET_SIGNUP_ERROR';
export const CLEAR_SIGNUP_ERROR = 'CLEAR_SIGNUP_ERROR';
export const FORGOT_PASSWORD_EMAIL_SENT = 'FORGOT_PASSWORD_EMAIL_SENT';
export const SET_FORGOT_PASSWORD_ERROR = 'SET_FORGOT_PASSWORD_ERROR';
export const CLEAR_FORGOT_PASSWORD_ERROR = 'CLEAR_FORGOT_PASSWORD_ERROR';
export const SET_CHANGE_PASSWORD_ERROR = 'SET_CHANGE_PASSWORD_ERROR';
export const CLEAR_CHANGE_PASSWORD_ERROR = 'CLEAR_CHANGE_PASSWORD_ERROR';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const SET_RESET_PASSWORD_ERROR = 'SET_RESET_PASSWORD_ERROR';
export const CLEAR_RESET_PASSWORD_ERROR = 'CLEAR_RESET_PASSWORD_ERROR';
export const SET_ACCOUNT_SETTINGS_ERROR = 'SET_ACCOUNT_SETTINGS_ERROR';
export const CLEAR_ACCOUNT_SETTINGS_ERROR = 'CLEAR_ACCOUNT_SETTINGS_ERROR';
export const SCHEDULE_TOKEN_REFRESH = 'SCHEDULE_TOKEN_REFRESH';
export const TOKEN_REFRESHING = 'TOKEN_REFRESHING';
export const CANCEL_TOKEN_REFRESH = 'CANCEL_TOKEN_REFRESH'; //cancels the token refresh scheduled
export const TOKEN_REFRESHED = 'TOKEN_REFRESHED';
export const TOKEN_REFRESH_FAILED = 'TOKEN_REFRESH_FAILED';
export const SET_REFRESH_TOKEN_TIMEOUT_HANDLE = '';
//export const TOKEN_REFRESH_ERROR = 'TOKEN_REFRESH_ERROR';

//dashboard actions
export const GET_DASHBOARDS_SUCCESS = 'GET_DASHBOARDS_SUCCESS';
export const DASHBOARDS_LOADING = 'DASHBOARDS_LOADING';
export const CURRENT_DASHBOARD_LOADED = 'CURRENT_DASHBOARD_LOADED';
export const DASHBOARD_LOADING = 'DASHBOARD_LOADING';
export const DASHBOARD_DATA_RECEIVED = 'DASHBOARD_DATA_RECEIVED';
export const DASHBOARD_UPDATED = 'DASHBOARD_UPDATED';
export const GET_WIDGETS_SUCCESS = 'GET_WIDGETS_SUCCESS';
export const CREATE_WIDGET_SUCCESS = 'CREATE_WIDGET_SUCCESS';

//playlist actions
export const GET_PLAYLISTS_SUCCESS = 'GET_PLAYLISTS_SUCCESS';
export const PLAYLISTS_LOADING = 'PLAYLISTS_LOADING';
export const PLAYLIST_CREATED = 'PLAYLIST_CREATED';
export const PLAYLIST_UPDATED = 'PLAYLIST_UPDATED';
export const PLAYLIST_DELETED = 'PLAYLIST_DELETED';
export const SET_CREATE_UPDATE_PLAYLIST_ERROR = 'SET_CREATE_UPDATE_PLAYLIST_ERROR';
export const CLEAR_CREATE_UPDATE_PLAYLIST_ERROR = 'CLEAR_CREATE_UPDATE_PLAYLIST_ERROR';

//template actions
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';

//user actions
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const USERS_LOADING = 'USERS_LOADING';
export const USER_CREATED = 'USER_CREATED';
export const USER_UPDATED = 'USER_UPDATED';
export const USER_DELETED = 'USER_DELETED';
export const SET_CREATE_UPDATE_USER_ERROR = 'SET_CREATE_UPDATE_USER_ERROR';
export const CLEAR_CREATE_UPDATE_USER_ERROR = 'CLEAR_CREATE_UPDATE_USER_ERROR';

//client actions
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';