
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as accountActions from '../../actions/accountActions';

class LoginPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = 
        { 
            email: '',
            password: '',
            next: (this.props.location.state && this.props.location.state.from.pathname) || '/'
         };
    }

    componentDidMount() {      
        //if we're already logged in, we should go back to the homepage or show an error?   
        //const $ = window.$;
    }

    login(event) {
        event.preventDefault();
        this.props.actions.login(this.state.email, this.state.password, this.state.next);
    }

    onChange(event) {
        const field = event.target.name;
        const value = event.target.value;
        let newState = Object.assign({}, this.state);
		newState[field] = value;
		return this.setState(newState);
    }

    render() {
        return (       
            this.props.user ?
                <Redirect to='/' />
                :
                <section className="loginsignup">
                    <form className="form form--loginsetup" onSubmit={this.login}>
                        <div className="loginsetup__logo">
                            <img src={require('../../images/logo-ahead-of-the-game.png')} alt="Ahead of the game" />
                        </div>
                        <div className="form__login">
                            <div className="loginsignup__head">
                                <h5 className="withline">Login</h5>
                                <p className="intro">Please enter your credentials to log in</p>
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input name="email" type="email" required="required" className="form-control" value={this.state.email} onChange={this.onChange} placeholder="Enter your email" />                                               
                                <p className="form__error">This input is mandatory</p>
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input name="password" type="password" required="required" className="form-control" value={this.state.password} onChange={this.onChange} placeholder="Enter password" />
                            </div>
                            <div className="form-group">
                                <input value="Log in" type="submit" className="btn btn--primary btn-block" />
                            </div>            
                            <div className="distancer"></div>            
                            <div className="form-group text-center">
                                <Link to="/Account/ForgotPassword" className="btn-text btn-forgotpass">Forgot password?</Link>
                            </div>
                            {this.props.loginError ?
                                <React.Fragment>
                                    <div className="distancer"></div>    
                                    <div className="alert alert-danger">
                                        {this.props.loginError}
                                        <button type="button" className="close" onClick={this.props.actions.clearLoginError}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>                                    
                                </React.Fragment>
                                : null
                            }
                        </div>
                    </form>
                </section>
        );
    }
}

LoginPage.propTypes = {
    user: PropTypes.object,
    loginError: PropTypes.string,
    actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return { user: state.account.user, loginError: state.account.loginError };
}

function mapDispatchToProps(dispatch) {
    return {
        //account actions will now be available under this.props.actions
        actions: bindActionCreators(Object.assign({}, accountActions), dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);