import BaseService from './baseService';

class ClientService {
    static getClients() {
        return BaseService.getData('/Clients')
            .then(data => {
                return Object.assign([], data);
            })
            .catch(error => {
                console.log('Unable to get clients');
                throw new Error('Unable to get clients');
            });
    }

    static getClient(clientID) {
        return BaseService.getData(`/Clients/${clientID}`)
            .then(data => {
                return Object.assign({}, data);
            })
            .catch(error => {
                console.log('Unable to get client');
                throw new Error('Unable to get client');
            });
    }
}

export default ClientService;
