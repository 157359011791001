
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import * as imageUtils from '../../utils/imageUtils';
import { withModalContext } from '../../services/modalService';
import DeleteConfirmationModal from '../common/DeleteConfirmationModal';
import ChangePasswordModal from '../common/ChangePasswordModal';

const UserItem = ({user, actions, showModal}) => { 
    const getUserImage = () => {
        const image = user.Avatar;
        const imageData = image ? imageUtils.getAvatarBase64Url(image) : require('../../images/avatar-empty.png');
        return imageData;
    }

    const getRoleClass = () => {
        if(user.Role === 'SuperUser') {
            return 'badge--super';
        }
        else {
            return `badge--${user.Role.toLowerCase()}`;
        }
    }

    const deleteUser = (event) => {
        event.preventDefault();
        const deleteAction = () => actions.deleteUser(user.ID);
        const deleteConfirmationModal = <DeleteConfirmationModal user={user} deleteAction={deleteAction} text={`Are you sure you want to delete user ${user.Email}? This action is irreversible.`} />
        showModal(() => deleteConfirmationModal, { isOpen: true })
    }

    const setPassword = (event) => {
        event.preventDefault();
        const setPasswordModal = <ChangePasswordModal user={user} />
        showModal(() => setPasswordModal, { isOpen: true })
    }

    return (
        <div className="useritem">
            <div className="useritem__pic">
                <div className="avatar">
                    <Link to={`/Users/${user.ID}`}>
                        <img src={getUserImage()} alt="Avatar"/>
                    </Link>
                </div>
            </div>
            <div className="useritem__title">
                <Link to={`/Users/${user.ID}`}>
                    <h5>{user.Email}</h5>
                </Link>
            </div>
            <div className="useritem__type">
                <p><span className={`badge ${getRoleClass()}`}>{user.Role}</span></p>
            </div>
            <div className="useritem__actions">
                <Link to={`/Users/${user.ID}`} className="btn-action"><i className="aheadicon-edit"></i></Link>
                <a href={null} className="btn-action" onClick={deleteUser}><i className="aheadicon-delete"></i></a>
                <div className="dropdown">
                    <a href={null} className="btn-action" data-toggle="dropdown">
                        <i className="aheadicon-dots"></i>
                    </a>
                    <div className="dropdown-menu dropdown-menu--withicons dropdown-menu-right">
                        <Link to={`/Users/${user.ID}`} className="dropdown-item"><i className="aheadicon-edit"></i>Edit user</Link>
                        <a className="dropdown-item" href={null} onClick={setPassword}><i className="aheadicon-password"></i>Set Password</a>
                        <a className="dropdown-item" href={null} onClick={deleteUser}><i className="aheadicon-delete"></i>Delete user</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withModalContext(UserItem);