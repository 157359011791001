
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import * as widgetConstants from '../../../../constants/widgetConstants';
import * as widgetUtils from '../../../../utils/widgetUtils';

class FieldSelectionModifier extends React.Component {
    constructor(props, context) {
        //we're always receiving an object, and we allow you to drill into it's child property
        super(props, context);
        this.updateFieldName = this.updateFieldName.bind(this);   
    }

    componentDidMount() { 
               
    }  

    updateFieldName(fieldName) {    
        let modifier = {...this.props.modifier};
        modifier.FieldName = fieldName;
        if(this.props.input && modifier.FieldName) {
            modifier.OutputType = widgetUtils.getObjectType(this.props.input[modifier.FieldName]);
        }
        this.props.updateModifier(modifier);
    }  

    render() {  
        const fieldName = this.props.modifier.FieldName;
        const input = this.props.input;
        const fieldNames = input ? Object.keys(input) : [];
        return (
            <form className="form">
                <div className="form-group">
                    <select className="form-control" value={fieldName} onChange={(e) => this.updateFieldName(e.target.value)}>
                        <option value={null}></option>
                        {
                            fieldNames.map(f =>
                                <option key={f} value={f}>{f}</option>
                            )
                        }
                    </select>
                </div>
            </form>     
        );
    }

}

export default FieldSelectionModifier;